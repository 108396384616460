import { FC } from "react";

import { Column, Paragraph, Text } from "@hightouchio/ui";

import { Card } from "src/components/card";

type ChartButtonProps = {
  icon: React.ElementType;
  title: string;
  description: string;
  onClick: () => void;
};

export const ChartButton: FC<ChartButtonProps> = ({
  icon: Icon,
  title,
  description,
  onClick,
}) => (
  <Card
    as="button"
    p={4}
    gap={4}
    textAlign="left"
    outline="none !important"
    cursor="pointer"
    width="100%"
    _hover={{
      borderColor: "primary.border",
      background: "primary.background",
    }}
    _active={{
      borderColor: "primary.border",
      background: "primary.background",
    }}
    _focus={{ boxShadow: "outline" }}
    onClick={onClick}
  >
    <Icon />
    <Column gap={1}>
      <Text fontWeight="medium">{title}</Text>
      <Paragraph color="text.secondary" size="sm">
        {description}
      </Paragraph>
    </Column>
  </Card>
);
