import { useEffect } from "react";

import get from "lodash/get";
import { useFormContext } from "react-hook-form";

import { useFormkitContext } from "src/formkit/components/formkit-context";

import { OVERRIDE_CONFIG_FORM_KEY } from "./constants";

/**
 * This hook is used to ensure that the dependent field names are unlocked/locked
 * when the `fieldName` field is unlocked/locked
 *
 * It is used in the following cases:
 * - TEMPLATES: ensure that the dependent field names are unlocked/locked
 * - SYNCS WITH OVERRIDES: Set the missing relationship in the formkit context so
 *     that other components may react to it and reset values when needed
 */
export const useMissingFormkitRelationships = (
  fieldName: string,
  dependentFieldNames: string[],
) => {
  const { showLockUI, relationshipHierarchy, onAddMissingRelationships } =
    useFormkitContext();
  const { setValue, watch } = useFormContext();

  const overrideConfig = watch(OVERRIDE_CONFIG_FORM_KEY);

  // TEMPLATES: ensure that the dependent field names are unlocked/locked
  // when the `fieldName` field is unlocked/locked
  useEffect(() => {
    if (showLockUI) {
      for (const dependentFieldName of dependentFieldNames) {
        const overridableValue = get(overrideConfig, [
          fieldName,
          "overridable",
        ]);

        if (overridableValue !== undefined) {
          setValue(`${OVERRIDE_CONFIG_FORM_KEY}.${dependentFieldName}`, {
            overridable: overridableValue,
          });
        }
      }
    }
  }, [showLockUI, overrideConfig, dependentFieldNames]);

  // Set the missing relationship in the formkit context so that other components may react to it
  useEffect(() => {
    if (
      relationshipHierarchy &&
      relationshipHierarchy[fieldName] === undefined
    ) {
      onAddMissingRelationships(fieldName, dependentFieldNames);
    }
  }, [fieldName, dependentFieldNames, relationshipHierarchy]);
};
