import { Alert, Button, FormField, GroupedSelect, Row } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Controller } from "react-hook-form";
import { Form, useHightouchForm } from "src/components/form";
import {
  useUpdateModelMutation,
  useUpsertInteractionModelMutation,
  useDeleteInteractionModelMutation,
} from "src/graphql";
import { InteractionTypeToHumanName } from "src/pages/campaigns/constants";
import {
  GENERIC_INTERACTION,
  GENERIC_INTERACTION_OPTIONS,
  INTERACTION_TYPE_OPTIONS,
  SMS_INTERACTION_OPTIONS,
} from "src/pages/schema/constants";
import { getParams } from "src/pages/schema/graph/utils";
import { SchemaField, SchemaFieldForm } from "src/pages/schema/types";
import { useNavigate } from "src/router";
import { SchemaModelType } from "src/types/schema";

export const InteractionForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: upsertInteractionModel } =
    useUpsertInteractionModelMutation();
  const { mutateAsync: deleteInteractionModel } =
    useDeleteInteractionModelMutation();
  const { mutateAsync: updateModel } = useUpdateModelMutation();

  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async ({ interaction_type }) => {
      if (interaction_type === GENERIC_INTERACTION) {
        // "Generic" means "not an interaction" -- delete interaction model join row
        await deleteInteractionModel({ model_id: object.id });

        // And set the segment type to "event"
        await updateModel({
          id: object.id,
          input: { type: SchemaModelType.Event },
        });
      } else {
        // Add the interaction type on the join table
        await upsertInteractionModel({
          model_id: object.id,
          object: { model_id: object.id, type: interaction_type },
        });

        // update the model type if not already an interaction
        if (object.type !== SchemaModelType.Interaction) {
          await updateModel({
            id: object.id,
            input: { type: SchemaModelType.Interaction },
          });
        }
      }
    },
    values: {
      interaction_type: object.event?.interaction?.type || null,
    },
  });

  return (
    <Form form={form}>
      <InteractionField
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
    </Form>
  );
};

export const InteractionField: SchemaField = (props) => {
  return (
    <Controller
      name="interaction_type"
      render={({ field, fieldState: { error } }) => {
        return (
          <FormField label="Event type" error={error?.message}>
            <GroupedSelect
              {...props}
              isInvalid={Boolean(error)}
              optionGroups={[
                ...GENERIC_INTERACTION_OPTIONS,
                ...INTERACTION_TYPE_OPTIONS,
                ...SMS_INTERACTION_OPTIONS,
              ]}
              placeholder="Select an interaction type..."
              value={field.value ?? GENERIC_INTERACTION}
              onChange={field.onChange}
            />
          </FormField>
        );
      }}
    />
  );
};

export const MissingAssetModelWarning: SchemaField = ({ object }) => {
  const navigate = useNavigate();
  const { queryString } = getParams();

  const interactionType = object?.event?.interaction?.type;

  if (!interactionType || !InteractionTypeToHumanName[interactionType]) {
    Sentry.captureMessage(
      `Missing or unknon interaction type ${interactionType} in MissingAssetModelWarning`,
      { level: "warning" },
    );
  }

  const interactionName = interactionType
    ? InteractionTypeToHumanName[interactionType]
    : "<unknown>"; // should never happen, but type safety

  return (
    <Alert
      variant="inline"
      type="error"
      title="Missing asset model"
      message={`This event's type "${interactionName}" cannot be used until you connect a related asset.
      The related asset specifies information about the asset that the user interacted with.`}
      actions={
        <Row gap={2}>
          <Button
            onClick={() =>
              navigate(
                `/schema-v2/new${queryString}&id=${object?.id}&type=asset`,
              )
            }
          >
            Create a related asset
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/schema-v2/view/relationships/new${queryString}&id=${object?.id}`,
              )
            }
          >
            Connect to an existing asset
          </Button>
        </Row>
      }
    />
  );
};
