import { ReactNode, useEffect, FC, useMemo } from "react";

import {
  Checkbox,
  Column,
  Badge,
  Menu,
  MenuActionsButton,
  MenuList,
  MenuItem,
  LightningBoltIcon,
  InformationIcon,
  Tooltip,
  Button,
  Box,
  Row,
  Text,
  IconButton,
  FormField,
  TextInput,
  Combobox,
  GroupedCombobox,
  TraitIcon,
  CloseIcon,
} from "@hightouchio/ui";
import { get, isEmpty, noop, orderBy, isEqual } from "lodash";
import { QueryFunction, QueryKey, useQuery } from "react-query";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { formatOptionLabel } from "src/formkit/components/mapper-v2/mappings";
import { Arrow } from "src/ui/arrow";
import { FieldError } from "src/components/field-error";
import { flattenOptions } from "src/ui/select";
import { automap, suggest } from "src/utils/automap";
import {
  DEFAULT_MAPPINGS_DESCRIPTION,
  DEFAULT_MAPPINGS_LABEL,
  MAPPINGS_DESCRIPTION,
  MAPPINGS_LABEL,
  NEW_ICON_MAP,
} from "src/utils/destinations";
import { getObjectName } from "src/utils/syncs";

import { MappingsHeader } from "./mappings-header";
import { isExtendedTypes } from "@hightouch/formkit";
import { isTraitlike } from "src/formkit/components/mapper-v2/inline-mapper";
import { ColumnReference } from "src/types/visual";

const getOptionAccessory = (
  option: any,
):
  | { icon: (typeof NEW_ICON_MAP)[keyof typeof NEW_ICON_MAP]; type: "icon" }
  | undefined => {
  const icon =
    NEW_ICON_MAP[
      isExtendedTypes(option?.extendedType)
        ? option?.extendedType?.type
        : option?.type
    ];

  if (icon) {
    return {
      type: "icon",
      icon,
    };
  }

  return undefined;
};

type LookupConfig = {
  getQuery: (data: any) => { queryKey: QueryKey; queryFn: QueryFunction };
  getResults: (data: any) => any;
};

type MappingsFieldProps = {
  options?: any;
  reload?: () => void;
  loading?: boolean;
  disabled?: boolean;
  allEnabled?: boolean;
  isCustom?: boolean;
  isCreatable?: boolean;
  creatableTypes?: { label: string; value: string }[];
  error?: string;
  errors?: any;
  property?: string;
  lookup?: LookupConfig;
  createTypes?: any;
  path?: string[];
  hideIdMappings?: boolean;
  required?: boolean;
  settings?: any;
  lookupObjectsMap?: Record<string, unknown>;
};

export const MappingsField: FC<Readonly<MappingsFieldProps>> = ({
  path,
  options = null,
  loading = false,
  reload,
  disabled = false,
  allEnabled = false,
  property = null,
  isCustom = false,
  isCreatable = false,
  creatableTypes = null,
  error = null,
  errors: propErrors = null,
  lookup = null,
  hideIdMappings = false,
  required = false,
  settings,
  lookupObjectsMap,
}) => {
  const {
    slug,
    config,
    setConfig,
    errors: validationErrors,
    hightouchColumns,
    loadingModel,
  } = useDestinationForm();

  const errors = propErrors || validationErrors;

  const configKey = property
    ? property
    : isCustom
      ? "customMappings"
      : "mappings";

  const mapperErrors = errors
    ? Object.entries(errors)?.reduce((acc, [k, v]) => {
        if (k?.startsWith(configKey)) {
          acc[k] = v;
        }
        return acc;
      }, {})
    : null;

  const isRequired = (field) => {
    return options?.some((o) => o.required && o.value === field);
  };

  const configMappings =
    orderBy(config[configKey], [(m) => !isRequired(m?.to)]) || [];

  const setConfigMappings = (mappings) => {
    setConfig({ ...config, [configKey]: mappings });
  };

  useEffect(() => {
    const required =
      options
        ?.filter(
          (o) => o.required && !configMappings?.some((m) => o.value === m.to),
        )
        ?.filter(
          (o) =>
            hideIdMappings ||
            (config?.externalIdMapping?.to !== o?.value &&
              !config?.externalIdMappings?.some((m) => o?.value === m?.to)),
        ) || [];
    if (required?.length) {
      const requiredMappings = required.map((r) => ({
        from: null,
        to: r.value,
        object: r.object,
      }));

      const newConfigMappings = [...requiredMappings, ...configMappings];
      setConfigMappings(newConfigMappings);
    }
  }, [
    options,
    config?.externalIdMapping,
    config?.externalIdMappings,
    hideIdMappings,
  ]);

  useEffect(() => {
    if (required && configMappings?.length < 1) {
      setConfigMappings([{ from: null, to: null }]);
    }
  }, [required, configMappings]);

  const hightouchColumnGroups = useMemo(() => {
    return hightouchColumns.map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [hightouchColumns]);

  return (
    <FormField
      description={
        (path
          ? get(MAPPINGS_DESCRIPTION[slug ?? ""], path)?.[configKey]
          : MAPPINGS_DESCRIPTION?.[slug ?? ""]?.[configKey]) ||
        DEFAULT_MAPPINGS_DESCRIPTION[configKey]
      }
      error={
        (!isEmpty(mapperErrors)
          ? mapperErrors?.[configKey] ||
            "One or more of the mappings are incomplete."
          : null) || error
      }
      tip={
        isCreatable
          ? `Hightouch allows you to create fields in the destination. Type in a field name that does not exist and select "Create field...".`
          : undefined
      }
      label={
        (path
          ? get(MAPPINGS_LABEL[slug ?? ""], path)?.[configKey]
          : MAPPINGS_LABEL?.[slug ?? ""]?.[configKey]) ||
        DEFAULT_MAPPINGS_LABEL[configKey]
      }
    >
      {allEnabled && (
        <Checkbox
          mb={2}
          label="Sync all columns (columns will be synced with the same name)"
          isChecked={Boolean(config.autoSyncColumns)}
          onChange={(event) => {
            setConfig({
              ...config,
              autoSyncColumns: event.target.checked,
              mappings: [],
            });
          }}
        />
      )}
      {(!!configMappings?.length ||
        (config?.externalIdMapping?.from &&
          config?.externalIdMapping?.to &&
          !isCustom) ||
        (config?.externalIdMappings?.some((m) => m.from && m.to) &&
          !isCustom)) && (
        <MappingsHeader
          loading={loading}
          lookup={Boolean(lookup)}
          object={getObjectName(config?.object)}
          reload={reload}
        />
      )}

      <Column gap={4}>
        {!isCustom && !hideIdMappings && (
          <>
            {config?.externalIdMapping?.from &&
              config?.externalIdMapping?.to && (
                <Row align="center">
                  <GroupedCombobox
                    isDisabled
                    isLoading={loadingModel}
                    optionGroups={hightouchColumnGroups}
                    value={config?.externalIdMapping?.from}
                    width="100%"
                    onChange={noop}
                  />
                  <Arrow />
                  <Row align="center" width="100%">
                    <TextInput
                      isDisabled
                      isReadOnly
                      value={config?.externalIdMapping?.to}
                    />
                    <Box m={2} ml={4}>
                      <Tooltip message="Matches the identifier in your model with the identifier in the destination.">
                        <Box fontSize="16px" as={InformationIcon} />
                      </Tooltip>
                    </Box>
                  </Row>
                </Row>
              )}

            {config?.externalIdMappings?.some((m) => m.from && m.to) &&
              !config?.externalIdMapping &&
              config?.externalIdMappings
                ?.filter((m) => m.from && m.to)
                ?.map((m, i) => (
                  <Row key={i} align="center">
                    <GroupedCombobox
                      isDisabled
                      isLoading={loadingModel}
                      optionGroups={hightouchColumnGroups}
                      value={m?.from}
                      width="100%"
                      onChange={noop}
                    />
                    <Arrow />

                    <Row width="100%">
                      <TextInput
                        isDisabled
                        isReadOnly
                        value={m?.to}
                        width="100%"
                      />
                      <Box m={2} ml={4}>
                        <Tooltip message="Matches the identifier in your model with the identifier in the destination.">
                          <Box as={InformationIcon} fontSize="16px" />
                        </Tooltip>
                      </Box>
                    </Row>
                  </Row>
                ))}
          </>
        )}
        {configMappings?.length
          ? configMappings.map((mapping, idx) => {
              const toOptions = options?.map((o) => {
                if (
                  mapping?.to !== o?.value &&
                  (config?.externalIdMapping?.to === o?.value ||
                    config?.externalIdMappings?.find(
                      (m) => m?.to === o?.value,
                    ) ||
                    config?.[configKey]?.find((m) => m?.to === o?.value))
                ) {
                  return { ...o, disabled: true };
                } else {
                  return o;
                }
              });

              const selectedOption = toOptions?.find(
                (c) => c.value === mapping?.to,
              );

              const mappingSettings = {
                ...(settings || {}),
                ...(selectedOption?.settings || {}),
              };

              const setMapping = (mapping) => {
                const m = [...configMappings];
                m[idx] = mapping;
                setConfigMappings(m);
              };

              return (
                <Row key={`${idx}`} sx={{ alignItems: "center", gap: 2 }}>
                  {mapping?.type === "reference" && lookup ? (
                    <Lookup
                      errors={errors}
                      idx={idx}
                      labelsMap={lookupObjectsMap}
                      lookup={lookup}
                      mapping={mapping}
                      setMapping={setMapping}
                    />
                  ) : (
                    <GroupedCombobox
                      isDisabled={config.autoSyncColumns}
                      optionAccessory={(option) => {
                        if (isTraitlike(option.value)) {
                          return {
                            type: "icon",
                            icon: TraitIcon,
                          };
                        }

                        return undefined;
                      }}
                      isInvalid={Boolean(
                        mapperErrors &&
                          Object.keys(mapperErrors)?.some((e) =>
                            e?.includes(`[${idx}].from`),
                          ),
                      )}
                      isLoading={loadingModel}
                      optionGroups={hightouchColumnGroups}
                      optionValue={(option) => option}
                      placeholder="Select a column..."
                      width="100%"
                      value={hightouchColumnGroups
                        .flatMap((group) => group.options)
                        .find((option) => isEqual(mapping?.from, option.value))}
                      onChange={(selected) => {
                        if (!selected) return;
                        const m = [...configMappings];

                        if (!mapping?.to && toOptions?.length) {
                          m[idx] = suggest(
                            selected as {
                              label: string;
                              value: ColumnReference | string;
                            },
                            toOptions.filter((o) => !o.disabled),
                          );
                        } else {
                          m[idx] = { ...mapping, from: selected.value };
                        }

                        setConfigMappings(m);
                      }}
                    />
                  )}

                  <Arrow />

                  <MappingDestination
                    creatableTypes={creatableTypes}
                    disabled={disabled || config.autoSyncColumns}
                    error={Boolean(
                      mapperErrors &&
                        Object.keys(mapperErrors)?.some((e) =>
                          e?.includes(`[${idx}].to`),
                        ),
                    )}
                    isCreatable={isCreatable}
                    loading={loading}
                    lookup={lookup}
                    mapping={mapping}
                    options={toOptions}
                    readOnly={isRequired(mapping?.to)}
                    setMapping={setMapping}
                  />

                  {(selectedOption?.settings || settings) && (
                    <Menu>
                      <MenuActionsButton />
                      <MenuList>
                        {Object.entries(mappingSettings).map(([k, v]) => (
                          <MenuItem key={k} onClick={() => {}}>
                            <Checkbox
                              label={(v as any).label}
                              isChecked={Boolean(mapping?.[k])}
                              onChange={(event) => {
                                setMapping({
                                  ...mapping,
                                  [k]: event.target.checked,
                                });
                              }}
                            />
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  )}

                  {isRequired(mapping?.to) ? (
                    <Box m={2} ml={4}>
                      <Tooltip message="Required">
                        <Box as={InformationIcon} fontSize="16px" />
                      </Tooltip>
                    </Box>
                  ) : (
                    !(required && configMappings?.length <= 1) && (
                      <IconButton
                        ml={2}
                        aria-label="Remove mapping"
                        icon={CloseIcon}
                        onClick={(event) => {
                          event.stopPropagation();
                          const m = [...configMappings];
                          m.splice(idx, 1);
                          setConfigMappings(m);
                        }}
                      />
                    )
                  )}
                </Row>
              );
            })
          : null}

        <Row>
          <Button
            isDisabled={config.autoSyncColumns}
            onClick={() => {
              const m = [...configMappings];
              m.push({ from: null, to: null });
              setConfigMappings(m);
            }}
          >
            Add mapping
          </Button>
          <Tooltip placement="right" message="Suggest mappings">
            <IconButton
              aria-label="Suggest mappings"
              isDisabled={config.autoSyncColumns}
              icon={LightningBoltIcon}
              ml={1}
              onClick={() => {
                const flatColumns = flattenOptions(hightouchColumns);
                const unmappedOptions = (
                  !options?.length ? flatColumns : options
                ).filter(
                  (o) =>
                    !configMappings.some((m) => m.to === o.value) &&
                    !config?.externalIdMappings?.some(
                      (m) => m.to === o.value,
                    ) &&
                    config?.externalIdMapping?.to !== o.value,
                );
                const results = automap(hightouchColumns, unmappedOptions);
                setConfigMappings([...configMappings, ...results]);
              }}
            />
          </Tooltip>
        </Row>
      </Column>
    </FormField>
  );
};

type LookupProps = {
  idx: number;
  mapping: any;
  setMapping: any;
  lookup: LookupConfig;
  errors: any;
  labelsMap?: Record<string, unknown>; // Map of object ids to the label names if needed
};

const Lookup: FC<Readonly<LookupProps>> = ({
  idx,
  mapping,
  setMapping,
  lookup: lookupConfig,
  errors,
  labelsMap,
}) => {
  const {
    hightouchColumns: options,
    sourceDefinition,
    destinationDefinition,
  } = useDestinationForm();

  const lookup = mapping?.lookup;
  const setLookup = (lookup) => {
    setMapping({ ...mapping, lookup });
  };

  const query = lookupConfig.getQuery(lookup.object);

  const {
    data: objectFields,
    isLoading: objectLoading,
    error: objectError,
  } = useQuery<unknown, Error>({
    ...query,
    enabled: Boolean(lookup?.object && lookupConfig),
  });

  const fieldOptions = lookupConfig?.getResults(objectFields) || [];
  const lookupObjectLabel = labelsMap?.[lookup?.object];

  return (
    <Column width="100%">
      <Row align="center" width="100%">
        <Box
          sx={{
            color: "text.secondary",
            mr: 2,
            fontWeight: "semibold",
            whiteSpace: "nowrap",
          }}
        >
          Find{" "}
          <Badge ml={1} mb={-1} mr={2}>
            {lookupObjectLabel || mapping?.lookup?.object}
          </Badge>{" "}
          ID where
        </Box>
        <Combobox
          optionAccessory={() =>
            destinationDefinition?.icon
              ? {
                  type: "image",
                  url: destinationDefinition.icon,
                }
              : undefined
          }
          optionLabel={formatOptionLabel}
          optionValue={(option) => option}
          isInvalid={
            errors &&
            Object.keys(errors)?.some((e) => e?.includes(`[${idx}].lookup.by`))
          }
          isLoading={objectLoading}
          options={fieldOptions}
          placeholder="Select a field..."
          width="100%"
          value={
            lookup?.by
              ? fieldOptions?.find((o) => o?.value === lookup?.by)
              : null
          }
          onChange={(selected) => {
            setLookup({
              ...lookup,
              by: selected?.value,
              byType: selected?.type,
            });
          }}
        />
      </Row>
      <Row align="center" mt={2}>
        <Box
          sx={{
            color: "text.secondary",
            mr: 2,
            fontWeight: "semibold",
            whiteSpace: "nowrap",
          }}
        >
          Of{" "}
          <Badge mx={1} mb={-1}>
            {lookupObjectLabel || mapping?.lookup?.object}
          </Badge>{" "}
          equals
        </Box>
        <Combobox
          optionAccessory={() =>
            sourceDefinition?.icon
              ? {
                  type: "image",
                  url: sourceDefinition.icon,
                }
              : undefined
          }
          isInvalid={
            errors &&
            Object.keys(errors)?.some((e) =>
              e?.includes(`[${idx}].lookup.from`),
            )
          }
          options={options}
          optionValue={(option) => option}
          placeholder="Select a column..."
          width="100%"
          value={
            lookup?.from
              ? options?.find((o) => o?.value === lookup?.from)
              : null
          }
          onChange={(selected) => {
            setLookup({ ...lookup, from: selected?.value });
          }}
        />
      </Row>

      {objectError && <FieldError error={objectError?.message} />}
    </Column>
  );
};

interface MappingDestinationProps {
  mapping: any;
  setMapping: any;
  loading: boolean;
  disabled: boolean;
  readOnly?: boolean;
  error: boolean;
  options: {
    label: string;
    subLabel?: string;
    value: any;
    object: any;
    referenceObjects?: any[];
    type?: string;
  }[];
  isCreatable: boolean;
  creatableTypes: Array<{ label: string; value: string }> | undefined | null;
  lookup?: unknown;
  transformOnCreate?: (value: string) => void;
  empty?: ReactNode | null;
}

export const MappingDestination: FC<MappingDestinationProps> = ({
  mapping,
  setMapping,
  loading,
  disabled,
  readOnly = false,
  error = false,
  options,
  isCreatable,
  creatableTypes,
  lookup = null,
}) => {
  const selectedOption = options?.find((c) => c.value === mapping?.to);
  const referenceObjectOptions =
    selectedOption?.referenceObjects?.map((o) => ({
      label: o?.name,
      value: o?.id,
    })) ?? [];

  if (options) {
    if (!isCreatable) {
      return (
        <Column width="100%">
          <Combobox
            isDisabled={disabled || readOnly}
            optionLabel={formatOptionLabel}
            optionAccessory={getOptionAccessory}
            isInvalid={Boolean(error)}
            isLoading={loading}
            options={options}
            placeholder="Select a field..."
            optionValue={(option) => option}
            width="100%"
            value={options?.find((c) => c.value === mapping?.to) || null}
            onChange={(selected) => {
              let newMapping;
              if (selected?.type === "REFERENCE" && lookup) {
                if (mapping?.from) {
                  newMapping = {
                    lookup: {
                      by: null,
                      byType: null,
                      from: mapping?.from,
                      object: selected?.referenceObjects?.[0]?.id,
                    },
                    to: selected.value,
                    object: selected?.object?.id,
                    type: "reference",
                  };
                } else {
                  newMapping = {
                    lookup: {
                      from: undefined,
                      by: null,
                      byType: null,
                      object: selected?.referenceObjects?.[0]?.id,
                    },
                    to: selected.value,
                    object: selected?.object?.id,
                    type: "reference",
                  };
                }
              } else {
                if (mapping?.lookup) {
                  newMapping = {
                    from: mapping?.lookup?.from,
                    to: selected?.value,
                    object: selected?.object?.id,
                    type: "standard",
                  };
                } else {
                  newMapping = {
                    from: mapping?.from,
                    to: selected?.value,
                    object: selected?.object?.id,
                    type: "standard",
                  };
                }
              }
              setMapping(newMapping);
            }}
          />
          {selectedOption?.type === "REFERENCE" &&
            selectedOption?.referenceObjects &&
            selectedOption.referenceObjects.length > 1 && (
              <Row align="center" mt={2}>
                <Text
                  color="text.tertiary"
                  size="lg"
                  whiteSpace="nowrap"
                  mr={2}
                >
                  Linked to:
                </Text>
                <Combobox
                  options={referenceObjectOptions}
                  optionValue={(option) => option}
                  value={referenceObjectOptions?.find(
                    (o) => o.value === mapping?.lookup?.object,
                  )}
                  width="100%"
                  onChange={(selected) => {
                    const newMapping = {
                      lookup: {
                        by: null,
                        byType: null,
                        from: undefined,
                        object: selected?.value,
                      },
                      to: mapping?.to,
                      object: mapping?.object,
                      type: "reference",
                    };
                    setMapping(newMapping);
                  }}
                />
              </Row>
            )}
        </Column>
      );
    } else {
      return (
        <Column width="100%">
          <Combobox
            isClearable
            isDisabled={disabled}
            optionLabel={formatOptionLabel}
            optionAccessory={getOptionAccessory}
            isInvalid={Boolean(error)}
            isLoading={loading}
            options={options}
            placeholder="Select or add a field..."
            value={mapping?.to}
            width="100%"
            onChange={(value) => {
              const newMapping = { ...mapping, to: value };
              setMapping(newMapping);
            }}
            onCreateOption={(value) => {
              setMapping({ ...mapping, to: value });
            }}
          />
          {creatableTypes &&
            mapping?.to &&
            !options?.find((c) => c.value === mapping?.to) && (
              <Combobox
                options={creatableTypes}
                placeholder="Select the type of the field..."
                value={mapping?.fieldType}
                width="100%"
                onChange={(value) => {
                  const newMapping = {
                    ...mapping,
                    fieldType: value,
                  };
                  setMapping(newMapping);
                }}
              />
            )}
        </Column>
      );
    }
  } else {
    return (
      <TextInput
        isDisabled={disabled}
        isInvalid={!!error}
        placeholder="Enter a field..."
        value={mapping?.to || ""}
        width="100%"
        onChange={(e) => {
          const newMapping = { ...mapping, to: e.target.value || null };
          setMapping(newMapping);
        }}
      />
    );
  }
};
