import {
  Alert,
  Button,
  Column,
  Heading,
  SectionHeading,
  useToast,
  Text,
  DrawerFooter,
  DrawerBody,
  PlusIcon,
} from "@hightouchio/ui";
import { LinkButton, useOutletContext, useParams } from "src/router";
import { useMemo } from "react";

import {
  useEnableDecisionEngineFlowMessageMutation,
  useUpdateDecisionEngineMessageAndCollectionsMutation,
} from "src/graphql";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Variables } from "src/pages/decision-engines/flows/flow/messages/message/components/variables";
import { CollectionSelect } from "src/pages/decision-engines/flows/flow/messages/message/components/collection-select";
import { FlowMessageContext } from ".";
import { Card } from "src/components/card";
import { MessageCampaign } from "./components/campaign";
import { CampaignAttribution } from "./components/campaign-attribution";
import { Tags } from "./components/tags";
import { getKeyValueArray, getKeyValueObject } from "src/utils/key-value";

export const MessageConfiguration = () => {
  const { flowMessage, engine, flow } = useOutletContext<FlowMessageContext>();

  const currentCollections = useMemo(
    () =>
      flowMessage.message.collections.map((c) => {
        return {
          id: c.decision_engine_collection.id,
          enabled: true,
          item_count: c.item_count,
        };
      }),
    [flowMessage.message.collections],
  );

  const updateMutation = useUpdateDecisionEngineMessageAndCollectionsMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const collectionsToUpsert = data.collections
        .filter((c) => c.enabled)
        .map((c) => ({
          decision_engine_message_id: flowMessage.message.id,
          decision_engine_collection_id: c.id,
          item_count: c.item_count,
        }));
      await updateMutation.mutateAsync({
        flowId: flow.id,
        messageId: flowMessage.message.id,
        flowMessage: {
          config: data.flowMessageConfig,
        },
        message: {
          config: data.config,
          variables: data.variables,
          tags: getKeyValueObject(data.tags),
        },
        collectionsToUpsertIds: collectionsToUpsert.map(
          (c) => c.decision_engine_collection_id,
        ),
        collectionsToUpsert,
      });
    },
    values: {
      tags: getKeyValueArray(flowMessage.message.tags),
      flowMessageConfig: flowMessage.config,
      config: flowMessage.message.config,
      variables: flowMessage.message.variables,
      collections:
        engine.collections?.map((collection) => {
          const existingCollection = currentCollections.find(
            (c) => c.id === collection.id,
          );
          return {
            id: collection.id,
            name: collection.collection.name,
            enabled: !!existingCollection,
            item_count: existingCollection?.item_count ?? 1,
          };
        }) ?? [],
    },
  });

  return (
    <Form form={form}>
      <DrawerBody bg="gray.50">
        <Column flex={1} gap={6} pb={6} maxW="2xl" mx="auto">
          <Heading>Message configuration</Heading>
          {flowMessage.message.channel.type === "raw" ? (
            <CustomChannelSetup />
          ) : (
            <RegularChannelSetup />
          )}
          <Card>
            <MessageCampaign channel={flowMessage.message.channel} />
          </Card>
          <Card>
            <Variables />
          </Card>
          <Card>
            <CollectionSelect />
          </Card>
          <Card>
            <Tags />
          </Card>
          <Card>
            <CampaignAttribution />
          </Card>
        </Column>
      </DrawerBody>

      <DrawerFooter>
        <FormActions />
      </DrawerFooter>
    </Form>
  );
};

const EnableButton = ({ messageId, flowId }) => {
  const { toast } = useToast();
  const enableMutation = useEnableDecisionEngineFlowMessageMutation();

  return (
    <>
      {enableMutation.data &&
        enableMutation.data.enableDecisionEngineFlowMessage.__typename ===
          "DecisionEngineError" && (
          <Alert
            variant="inline"
            type="error"
            title="Unable to initialize message"
            message={enableMutation.data?.enableDecisionEngineFlowMessage.error}
          />
        )}
      <Button
        isLoading={enableMutation.isLoading}
        onClick={async () => {
          try {
            const response = await enableMutation.mutateAsync({
              messageId,
              flowId,
            });
            if (
              response.enableDecisionEngineFlowMessage.__typename ===
              "DecisionEngineSuccess"
            ) {
              toast({
                id: "enable-message",
                title: "Message initialized and enabled",
                variant: "success",
              });
            } else {
              toast({
                id: "enable-message",
                title: "Error initializing message",
                variant: "error",
                message: response.enableDecisionEngineFlowMessage.error,
              });
            }
          } catch (_err) {
            toast({
              id: "enable-message",
              title: "Error initializing message",
              variant: "error",
            });
          }
        }}
      >
        Initialize
      </Button>
    </>
  );
};

const CustomChannelSetup = () => {
  const { flowId } = useParams();
  const { flowMessage } = useOutletContext<FlowMessageContext>();

  if (flowMessage.enabled) {
    if (!flowMessage.model?.syncs?.length) {
      return (
        <Alert
          variant="inline"
          type="error"
          title="Message sync has been deleted"
          message="Please create a new sync to start using this message again."
        />
      );
    }
    return null;
  }

  if (!flowMessage.model?.syncs?.length) {
    return (
      <Card flex={1} gap={4} alignItems="flex-start">
        <Column>
          <SectionHeading>Add message sync</SectionHeading>
          <Text>You must add a sync before this message can be enabled.</Text>
        </Column>
        <LinkButton
          icon={PlusIcon}
          variant="primary"
          href={`/syncs/new?model=${flowMessage.model?.id}&destination=${flowMessage.message.channel.destination.id}`}
        >
          Add sync
        </LinkButton>
      </Card>
    );
  }

  return (
    <Card flex={1} gap={4} alignItems="flex-start">
      <Column>
        <SectionHeading>Draft message</SectionHeading>
        <Text>Initializing will make this message eligible to be sent.</Text>
      </Column>
      <EnableButton messageId={flowMessage.message.id} flowId={flowId} />
    </Card>
  );
};

const RegularChannelSetup = () => {
  const { flowId } = useParams();
  const { flowMessage } = useOutletContext<FlowMessageContext>();
  const isInitialized = Boolean(flowMessage.model?.syncs?.length);

  if (isInitialized) {
    return null;
  }

  if (flowMessage.enabled && !flowMessage.model?.syncs?.length) {
    return (
      <Alert
        variant="inline"
        type="error"
        title="Message syncs have been deleted"
        message="Please contact us to resolve this issue."
      />
    );
  }

  return (
    <Card flex={1} gap={4} alignItems="flex-start">
      <Column>
        <SectionHeading>Draft message</SectionHeading>
        <Text>
          Initializing will generate a model and a sync and make it eligible to
          be sent.
        </Text>
      </Column>
      <EnableButton messageId={flowMessage.message.id} flowId={flowId} />
    </Card>
  );
};
