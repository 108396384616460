import {
  Box,
  Checkbox,
  CheckboxGroup,
  Column,
  Combobox,
  FormField,
  Row,
  SectionHeading,
  Select,
  Text,
} from "@hightouchio/ui";
import { isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import * as y from "yup";

import { Card } from "src/components/card";
import TIME_ZONES from "./iana-time-zones.json";
import { useModelColumnsQuery } from "src/graphql";
import { OutletContext } from "../..";
import { useOutletContext } from "src/router";

// Temporary types until backend PR is merged
enum Day {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

enum Time {
  Midnight = "midnight",
  LateNight = "late_night",
  EarlyMorning = "early_morning",
  Morning = "morning",
  Afternoon = "afternoon",
  MidAfternoon = "mid_afternoon",
  Evening = "evening",
  Night = "night",
}

enum Frequency {
  Weekly1x = "weekly1x",
  Weekly2x = "weekly2x",
  Weekly3x = "weekly3x",
  Weekly4x = "weekly4x",
  Weekly5x = "weekly5x",
  Weekly6x = "weekly6x",
  Weekly7x = "weekly7x",
  Every2Week = "every2week",
  Monthly = "monthly",
}

export const Timing = () => {
  return (
    <Card gap={4}>
      <Column>
        <SectionHeading>Timing</SectionHeading>
        <Text color="text.secondary">
          Set guardrails for when and how often actions should be scheduled for
          each member
        </Text>
      </Column>
      <TimingFields />
      <TimeZoneFields />
    </Card>
  );
};

export const TimeZoneFields = () => {
  const context = useOutletContext<OutletContext>();

  const { data: columns, isLoading } = useModelColumnsQuery(
    {
      modelId: context.engine.segment.id,
    },
    { select: (data) => data.model_columns },
  );

  const { formState } = useFormContext();

  return (
    <>
      <FormField
        isOptional
        label="Time zone column"
        description="Column and format to use for time zone"
        error={
          (formState.errors as any)?.config?.timezone_column?.format?.message ||
          (formState.errors as any)?.config?.timezone_column?.name?.message
        }
      >
        <Row gap={2}>
          <Controller
            name="config.timezone_column.name"
            render={({ field, fieldState: { error } }) => (
              <Combobox
                isClearable
                isInvalid={Boolean(error)}
                placeholder="Select time zone column..."
                {...field}
                onChange={(value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    field.onChange(null);
                  }
                }}
                width="2xs"
                isLoading={isLoading}
                optionValue={(option) => option.name}
                optionLabel={(option) => option.alias ?? option.name}
                options={columns ?? []}
              />
            )}
          />
          <Controller
            name="config.timezone_column.format"
            render={({ field, fieldState: { error } }) => (
              <Select
                isClearable
                placeholder="Select format..."
                isInvalid={Boolean(error)}
                {...field}
                onChange={(value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    field.onChange(null);
                  }
                }}
                width="2xs"
                options={[
                  {
                    label: "IANA",
                    description: "e.g. America/Los_Angeles",
                    value: "iana",
                  },
                  {
                    label: "UTC Offset",
                    description: "e.g. -0800",
                    value: "offset_string",
                  },
                ]}
              />
            )}
          />
        </Row>
      </FormField>
      <Controller
        name="config.scheduling_timezone"
        render={({ field }) => (
          <FormField
            isOptional
            label="Fallback time zone"
            description="Time zone to use when no user-level time zone is available"
            tip="If left blank, the system default time zone is America/Los_Angeles"
          >
            <Combobox
              isClearable
              placeholder="Select a fallback time zone..."
              options={Object.keys(TIME_ZONES).map((tz) => ({
                label: tz,
                value: tz,
              }))}
              {...field}
              onChange={(value) => {
                if (value) {
                  field.onChange(value);
                } else {
                  field.onChange(null);
                }
              }}
            />
          </FormField>
        )}
      />
    </>
  );
};

export const timezoneColumnSchema = y
  .object()
  .shape(
    {
      name: y.string().when("format", {
        is: (v) => Boolean(v),
        then: y
          .string()
          .nullable()
          .required("You must select a time zone column"),
        otherwise: y.string().nullable(),
      }),
      format: y.string().when("name", {
        is: (v) => Boolean(v),
        then: y
          .string()
          .nullable()
          .required("You must select a time zone format"),
        otherwise: y.string().nullable(),
      }),
    },
    [["name", "format"]],
  )
  .nullable();

const TimingFields = () => {
  const { setValue, watch } = useFormContext();
  const frequency = watch("config.timing.frequency");

  return (
    <Column gap={2}>
      <Row gap={4}>
        <Controller
          name="config.timing.frequency"
          render={({ field, fieldState: { error } }) => {
            return (
              <Box
                as={FormField}
                label="Frequencies"
                maxW="288px"
                w="100%"
                error={error?.message}
              >
                <CheckboxGroup>
                  {frequencyOptions.map((option) => (
                    <Checkbox
                      key={option.value}
                      onChange={(event) => {
                        if (
                          event.target.checked &&
                          option.value === Frequency.Weekly7x
                        ) {
                          setValue("config.timing.days", [
                            Day.Monday,
                            Day.Tuesday,
                            Day.Wednesday,
                            Day.Thursday,
                            Day.Friday,
                            Day.Saturday,
                            Day.Sunday,
                          ]);
                        }
                        field.onChange(
                          event.target.checked
                            ? [...field.value, option.value]
                            : field.value.filter((v) => v !== option.value),
                        );
                      }}
                      isChecked={field.value.includes(option.value)}
                      label={option.label}
                    />
                  ))}
                </CheckboxGroup>
              </Box>
            );
          }}
        />
        <Controller
          name="config.timing.days"
          render={({ field, fieldState: { error } }) => {
            return (
              <Box
                as={FormField}
                label="Days of the week"
                maxW="288px"
                w="100%"
                error={error?.message}
              >
                <CheckboxGroup>
                  {dayOptions.map((option) => (
                    <Checkbox
                      key={option.value}
                      isDisabled={frequency.includes(Frequency.Weekly7x)}
                      onChange={(event) => {
                        field.onChange(
                          event.target.checked
                            ? [...field.value, option.value]
                            : field.value.filter((v) => v !== option.value),
                        );
                      }}
                      isChecked={field.value.includes(option.value)}
                      label={option.label}
                    />
                  ))}
                </CheckboxGroup>
              </Box>
            );
          }}
        />
        <Controller
          name="config.timing.times"
          render={({ field, fieldState: { error } }) => {
            return (
              <Box
                as={FormField}
                label="Times of day"
                maxW="288px"
                w="100%"
                error={error?.message}
              >
                <CheckboxGroup>
                  {timeOptions.map((option) => (
                    <Checkbox
                      key={option.value}
                      onChange={(event) => {
                        field.onChange(
                          event.target.checked
                            ? [...field.value, option.value]
                            : field.value.filter((v) => v !== option.value),
                        );
                      }}
                      description={option.description}
                      isChecked={field.value.includes(option.value)}
                      label={option.label}
                    />
                  ))}
                </CheckboxGroup>
              </Box>
            );
          }}
        />
      </Row>
    </Column>
  );
};

export const timingSchema = y
  .object()
  .shape({
    frequency: y
      .array()
      .of(y.mixed().oneOf(Object.values(Frequency)).required())
      .required("You must select at least one frequency"),
    times: y
      .array()
      .of(y.mixed().oneOf(Object.values(Time)))
      .required("You must select at least one time"),
  })
  .test(
    "days-frequency-validation",
    "Invalid days selection",
    function (value) {
      if (!value) return true;

      // @ts-expect-error - days is not defined in the base shape
      const { frequency, days } = value;

      for (const freq of frequency) {
        if (freq === Frequency.Weekly1x && days.length < 1) {
          return this.createError({
            path: "config.timing.days",
            message: "You must select at least one day for weekly frequency.",
          });
        }
        if (freq === Frequency.Weekly2x && days.length < 2) {
          return this.createError({
            path: "config.timing.days",
            message:
              "You must select at least two days for 2x weekly frequency.",
          });
        }
        if (freq === Frequency.Weekly3x && days.length < 3) {
          return this.createError({
            path: "config.timing.days",
            message:
              "You must select at least three days for 3x weekly frequency.",
          });
        }
        if (freq === Frequency.Weekly4x && days.length < 4) {
          return this.createError({
            path: "config.timing.days",
            message:
              "You must select at least four days for 4x weekly frequency.",
          });
        }
        if (freq === Frequency.Weekly5x && days.length < 5) {
          return this.createError({
            path: "config.timing.days",
            message:
              "You must select at least five days for 5x weekly frequency.",
          });
        }
        if (freq === Frequency.Weekly6x && days.length < 6) {
          return this.createError({
            path: "config.timing.days",
            message:
              "You must select at least six days for 6x weekly frequency.",
          });
        }
        if (freq === Frequency.Weekly7x && days.length < 7) {
          return this.createError({
            path: "config.timing.days",
            message: "You must select all days for daily frequency.",
          });
        }
      }

      return true;
    },
  );

export const timeOptions = [
  {
    label: "Early Morning",
    description: "6:00 AM",
    value: Time.EarlyMorning,
  },
  {
    label: "Morning",
    description: "9:00 AM",
    value: Time.Morning,
  },
  {
    label: "Noon",
    description: "12:00 PM",
    value: Time.Afternoon,
  },
  {
    label: "Afternoon",
    description: "3:00 PM",
    value: Time.MidAfternoon,
  },
  { label: "Evening", description: "6:00 PM", value: Time.Evening },
  {
    label: "Night",
    description: "9:00 PM",
    value: Time.Night,
  },

  {
    label: "Midnight",
    description: "12:00 AM",
    value: Time.Midnight,
  },
  {
    label: "Late Night",
    description: "3:00 AM",
    value: Time.LateNight,
  },
];

export const timeRangeLabel = (range) => {
  return timeOptions.find((option) => isEqual(option.value, range))!.label;
};

export const frequencyOptions = [
  {
    label: "Daily",
    value: Frequency.Weekly7x,
  },
  {
    label: "6x Weekly",
    value: Frequency.Weekly6x,
  },
  {
    label: "5x Weekly",
    value: Frequency.Weekly5x,
  },
  {
    label: "4x Weekly",
    value: Frequency.Weekly4x,
  },
  {
    label: "3x Weekly",
    value: Frequency.Weekly3x,
  },
  {
    label: "2x Weekly",
    value: Frequency.Weekly2x,
  },
  {
    label: "Weekly",
    value: Frequency.Weekly1x,
  },
  {
    label: "Every 2 Weeks",
    value: Frequency.Every2Week,
  },
  {
    label: "Monthly",
    value: Frequency.Monthly,
  },
];

export const frequencyLabel = (frequency) => {
  return frequencyOptions.find((option) => isEqual(option.value, frequency))!
    .label;
};

export const dayOptions = [
  { label: "Monday", value: Day.Monday },
  { label: "Tuesday", value: Day.Tuesday },
  { label: "Wednesday", value: Day.Wednesday },
  { label: "Thursday", value: Day.Thursday },
  { label: "Friday", value: Day.Friday },
  { label: "Saturday", value: Day.Saturday },
  { label: "Sunday", value: Day.Sunday },
];
