import {
  SectionHeading,
  Row,
  Column,
  DrawerBody,
  DrawerFooter,
  Text,
  Box,
} from "@hightouchio/ui";

import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUpdateDecisionEngineFlowMessageMutation } from "src/graphql";
import { ConditionGroup } from "src/pages/decision-engines/collections/collection/components/filter";
import { useOutletContext } from "src/router";
import { FlowMessageContext } from ".";
import { ConditionType } from "src/types/visual";
import {
  Condition,
  DecisionEngineFlowMessageConfig,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Card } from "src/components/card";
import { SendLimit } from "./components/send-limit";
import { Timeline } from "./components/timeline";

export const Guardrails = () => {
  const { flowMessage, engine, flow } = useOutletContext<FlowMessageContext>();

  const updateMutation = useUpdateDecisionEngineFlowMessageMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const config: DecisionEngineFlowMessageConfig = {
        ...data.flowMessageConfig,
        userFilter: data.filter,
      };
      await updateMutation.mutateAsync({
        flowId: flow.id,
        messageId: flowMessage.message.id,
        input: {
          config,
        },
      });
    },
    defaultValues: {
      filter:
        flowMessage.config?.userFilter ??
        ({
          type: ConditionType.And,
          conditions: [],
        } as Condition),
      // XXX: Filter is actually also in flowMessageConfig, but we'll refactor it after the filter is fixed
      flowMessageConfig: flowMessage.config,
    },
  });

  return (
    <Form form={form}>
      <DrawerBody bg="base.lightBackground">
        <Column gap={6}>
          <Column gap={4}>
            <Column>
              <SectionHeading>Frequency and timing</SectionHeading>
              <Text>
                Configure the frequency and timeliness of this message
              </Text>
            </Column>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
              <Card>
                <SendLimit />
              </Card>
              <Card>
                <Timeline />
              </Card>
            </Box>
          </Column>
          <Column gap={4}>
            <Row>
              <Column>
                <SectionHeading>Eligibility</SectionHeading>
                <Text>
                  This message will only be sent to users who meet the following
                  criteria
                </Text>
              </Column>
            </Row>
            <ConditionGroup
              leftModelId={engine.config.feature_model_id}
              leftTable="users"
            />
          </Column>
        </Column>
      </DrawerBody>
      <DrawerFooter>
        <FormActions />
      </DrawerFooter>
    </Form>
  );
};
