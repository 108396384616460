import { BookIcon, Box, Row } from "@hightouchio/ui";
import { FC } from "react";
import { usePartner } from "src/partner/context";

import { Link } from "src/router";

type Props = {
  href: string | null | undefined;
  label?: string;
  name?: string;
};

export const DocsLink: FC<Readonly<Props>> = ({ href, label, name }) => {
  const { isPartner } = usePartner();

  // Don't show Hightouch docs for embedded views
  if (isPartner) {
    return null;
  }

  if (!href) {
    return null;
  }

  return (
    <Link
      isExternal
      href={`${import.meta.env.VITE_DOCS_URL}${
        href.startsWith("/") ? href : `/${href}`
      }`}
    >
      <Row gap={1} align="center">
        <Box as={BookIcon} boxSize={4} />
        {label ?? `Docs for ${name}`}
      </Row>
    </Link>
  );
};
