// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Navigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useUser } from "src/contexts/user-context";

export const RootIndex = () => {
  const { user, slug, onboarding } = useUser();
  const { partnerUser } = useFlags();

  if (!user) {
    return <Navigate replace to="/signup" />;
  }

  if (!slug) {
    if (partnerUser) {
      return <Navigate replace to="/partner" />;
    }
    return <Navigate replace to="/workspaces" />;
  }

  return (
    <Navigate
      replace
      to={onboarding ? `/${slug}/onboarding` : `/${slug}/home`}
    />
  );
};
