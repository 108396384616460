import { FC, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  Select,
  TextInput,
  useToast,
} from "@hightouchio/ui";

import { Folder } from "src/components/folders/types";
import { useFolders } from "src/components/folders/use-folders";
import { useUpdateFoldersMutation, FolderType } from "src/graphql";
import { isChild } from "./utils";

interface Props {
  onClose: () => void;
  folder: Folder;
}

export const EditFolder: FC<Readonly<Props>> = ({ onClose, folder }) => {
  const [name, setName] = useState(folder.name);
  const {
    state: { flattenedFolders },
    loadingFolders,
  } = useFolders({ folderType: folder.type as FolderType, viewType: "models" });
  const [parentFolder, setParentFolder] = useState<string | null>(
    folder?.parentId,
  );
  const { mutateAsync: updateFolder } = useUpdateFoldersMutation();
  const { toast } = useToast();

  const options = flattenedFolders?.filter(
    (f) => f.id !== folder.id && !isChild(folder, f),
  );

  const onClick = async () => {
    try {
      await updateFolder({
        object: {
          name,
          parent_id: parentFolder,
        },
        ids: [folder.id],
      });
      onClose();
      toast({
        id: "update-folder",
        title: `Folder ${name} updated`,
        variant: "success",
      });
    } catch (_err) {
      toast({
        id: "update-folder",
        title: "There was an error updating the folder",
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      isOpen
      variant="form"
      title="Edit folder"
      actions={
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={!name}
            isLoading={loadingFolders}
            variant="primary"
            onClick={onClick}
          >
            Save
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <Column gap="4">
        <FormField label="Folder name">
          <TextInput
            placeholder="Folder name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormField>
        {(flattenedFolders?.length || 0) > 0 && (
          <FormField label="Parent folder">
            <Select
              isClearable
              isLoading={loadingFolders}
              optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
              optionValue={(folder) => folder.id}
              options={options || []}
              placeholder="Select a folder..."
              value={parentFolder}
              onChange={(folder) => {
                setParentFolder(folder || null);
              }}
            />
          </FormField>
        )}
      </Column>
    </Dialog>
  );
};
