import { useFormContext } from "react-hook-form";
import { useDebounce } from "use-debounce";

const DEBOUNCE_TIME_MS = 3_000;

// Hook to grab a debounced form value from the caller's form context.
// This is useful for form fields that can change somewhat frequently and trigger
// warehouse queries or other long running requests.
export const useDebouncedFormValue = <TValue>(
  name: string,
  debounceTime = DEBOUNCE_TIME_MS,
): [debouncedValue: TValue, isPending: () => boolean] => {
  const { watch } = useFormContext();
  const value = watch(name);

  const [debouncedValue, { isPending }] = useDebounce(value, debounceTime);
  return [debouncedValue, isPending];
};
