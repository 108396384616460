import { FC } from "react";

import {
  Box,
  BoxProps,
  Button,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Row,
  Text,
  useDisclosure,
} from "@hightouchio/ui";
import { format } from "date-fns";

import { Calendar, Props as CalendarProps } from "src/ui/calendar";
import { TimeSelect } from "src/ui/time-select";

export type Props = {
  hideTime?: boolean;
  onSelectClose?: (elementId: string) => void;
  onSelectOpen?: (elementId: string) => void;
  placeholer?: string;
} & CalendarProps &
  BoxProps;

export const DateTimeSelect: FC<Readonly<Props>> = ({
  hideTime,
  value,
  onChange,
  onSelectClose,
  onSelectOpen,
  minDate,
  maxDate,
  isClearable,
  placeholder = "Select a date...",
  ...props
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Row align="center" flexShrink={0} {...props}>
      <ChakraPopover
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
      >
        <ChakraPopoverTrigger>
          <Box
            as={Button}
            _hover={{
              borderColor: isOpen ? "primary.base" : "gray.border",
              bg: "base.lightBackground",
              color: value ? "text.primary" : "text.placeholder",
            }}
            _active={{
              border: "1px solid",
              borderColor: "primary.base",
            }}
            color={value ? "text.primary" : "text.placeholder"}
            px={2}
            gap={1}
            alignItems="center"
            height="32px"
            fontWeight="normal"
            border="1px solid"
            borderColor={isOpen ? "primary.base" : "base.border"}
            boxShadow={isOpen ? "outline" : "none"}
            borderRadius="6px"
            overflow="hidden"
            transition="all 120ms ease-in"
            whiteSpace="nowrap"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onToggle();
            }}
          >
            {value ? format(value, "PPP") : placeholder}
          </Box>
        </ChakraPopoverTrigger>

        <ChakraPopoverContent p={2} width="auto">
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            onChange={(value) => {
              onChange(value);
              onClose();
            }}
            isClearable={isClearable}
          />
        </ChakraPopoverContent>
      </ChakraPopover>
      {!hideTime && (
        <>
          <Text mx={2}>at</Text>
          <TimeSelect
            value={value}
            onChange={onChange}
            onSelectClose={onSelectClose}
            onSelectOpen={onSelectOpen}
          />
        </>
      )}
    </Row>
  );
};
