import { Controller, useFormContext } from "react-hook-form";

import { Column, FormField } from "@hightouchio/ui";
import { QueryBuilderProvider } from "src/components/explore/context/query-builder-context";
import { EventFilter } from "src/components/explore/visual/event-filter";
import { useParentModelAndRelationshipsQuery } from "src/graphql";
import { ParentModel } from "src/pages/audiences/types";
import { OutletContext } from "src/pages/decision-engines";
import { useOutletContext } from "src/router";
import {
  Audience,
  ConditionType,
  EventCondition,
  initialEventCondition,
} from "src/types/visual";

export const OutcomeModelFilter = () => {
  const form = useFormContext();
  const model = form.watch("model");
  const modelId = model?.id;

  const { engine } = useOutletContext<OutletContext>();
  const parentModelId = engine.segment.id;

  const parentModelAndRelationships = useParentModelAndRelationshipsQuery({
    id: parentModelId?.toString() ?? "",
  });

  const parentModel = parentModelAndRelationships.data?.segments_by_pk as
    | ParentModel
    | undefined;

  const eventRelationship =
    parentModelAndRelationships.data?.segments_by_pk?.relationships?.find(
      (r) => r.to_model.id == modelId,
    );

  return (
    <QueryBuilderProvider parentModel={parentModel}>
      <Controller
        name="filter"
        render={({ field }) => (
          <FormField label="Filter event model" isOptional>
            <Column
              bg="gray.50"
              borderRadius="sm"
              p={4}
              minWidth="xl"
              width="fit-content"
              gap={4}
            >
              <EventFilter
                disableEventSelect
                hideFunnelCondition
                hideOperatorFilter
                hideWindowCondition
                audience={{} as Audience}
                parent={parentModel}
                condition={
                  modelId && eventRelationship
                    ? ({
                        type: ConditionType.Event,
                        eventModelId: Number(modelId),
                        relationshipId: Number(eventRelationship?.id),
                        subconditions: field.value?.subconditions ?? [],
                      } as unknown as EventCondition)
                    : initialEventCondition
                }
                onChange={(value) =>
                  field.onChange({ subconditions: value.subconditions ?? [] })
                }
              />
            </Column>
          </FormField>
        )}
      />
    </QueryBuilderProvider>
  );
};
