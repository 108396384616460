import { FC, ReactNode } from "react";

import {
  Column,
  useToast,
  SectionHeading,
  Box,
  Text,
  Row,
  Button,
  Alert,
  ButtonGroup,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { capitalize } from "lodash";
import { useNavigate } from "src/router";

import { PermissionedButton } from "src/components/permission";
import { useUser } from "src/contexts/user-context";
import {
  ResourceToPermission,
  DraftsQuery,
  useUpdateDraftsStatusMutation,
  DraftStatus,
  DraftOperation,
  useDraftsQuery,
  useDeleteUpdateDraftMutation,
  useDeleteCreateDraftMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { ActionBar } from "src/components/action-bar";

interface DraftPageProps {
  children: ReactNode;
  resourceId: string;
  resourceType: "sync" | "model";
  draft: DraftsQuery["drafts"][0];
  onApprove: () => void;
  onWithdraw: (deny: boolean) => void;
  withdrawing: boolean;
  dependentResource?: {
    resourceId: string;
    resourceType: "model";
  };
}

export const DraftPage: FC<DraftPageProps> = ({
  draft,
  resourceType,
  resourceId,
  children,
  onApprove,
  onWithdraw,
  withdrawing,
  dependentResource,
}) => {
  const { user } = useUser();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { mutateAsync: updateDraftStatus, isLoading: updating } =
    useUpdateDraftsStatusMutation();

  const { mutateAsync: deleteUpdateDraft, isLoading: deletingUpdate } =
    useDeleteUpdateDraftMutation();
  const { mutateAsync: deleteCreateDraft, isLoading: deletingCreate } =
    useDeleteCreateDraftMutation();

  const { data: drafts } = useDraftsQuery(
    {
      resourceId: dependentResource?.resourceId.toString() ?? "",
      resourceType: dependentResource?.resourceType ?? "",
      status: "pending",
    },
    {
      enabled:
        Boolean(dependentResource?.resourceId) &&
        Boolean(dependentResource?.resourceType),
    },
  );
  const dependentDraft = drafts?.drafts?.[0];

  const capitalizedResourceType = capitalize(resourceType);

  const approve = () => {
    analytics.track(`Draft ${capitalizedResourceType} Approved`, {
      sync_id: resourceId,
      draft_id: draft.id,
    });

    onApprove();
  };

  const deleteDraft = async () => {
    try {
      if (draft.operation === DraftOperation.Update) {
        await deleteUpdateDraft({
          draftId: draft.id,
        });
      } else {
        await deleteCreateDraft({
          draftId: draft.id,
          resourceId: resourceId,
          stringResourceId: resourceId.toString(),
          sync: resourceType === ResourceToPermission.Sync,
          model: resourceType === ResourceToPermission.Model,
        });
        navigate(
          `${
            resourceType === ResourceToPermission.Sync ? "/syncs" : "/models"
          }`,
        );
      }

      analytics.track("Draft Deleted", {
        resource_id: resourceId,
        draft_id: draft.id,
        resource_type: resourceType,
      });

      toast({
        id: "delete-draft",
        title: "Draft was deleted",
        variant: "success",
      });
    } catch (err) {
      Sentry.captureException(err);

      toast({
        id: "delete-draft",
        title: "Couldn't delete this draft",
        variant: "error",
      });
    }
  };

  const onDeny = async () => {
    analytics.track("Draft Denied", {
      resource_id: resourceId,
      draft_id: draft.id,
      resource_type: resourceType,
    });

    onWithdraw(true);
  };

  const withdraw = async () => {
    analytics.track("Draft Withdrawn", {
      resource_id: resourceId,
      draft_id: draft.id,
      resource_type: resourceType,
    });

    onWithdraw(false);
  };

  const handleApprove = async () => {
    const extraDraftIds = dependentDraft ? [dependentDraft.id] : [];
    try {
      await updateDraftStatus({
        draftIds: [...extraDraftIds, draft.id],
        status: DraftStatus.Approved,
      });
      approve();

      toast({
        id: "approve-draft",
        title: `${capitalizedResourceType} was approved`,
        variant: "success",
      });
    } catch (err) {
      toast({
        id: "approve-draft",
        title: "Couldn't approve this draft",
        variant: "error",
      });

      Sentry.captureException(err);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: "flex-start" }}>
      <Row>
        <Column gap={8} flex={1} overflow="hidden">
          {dependentResource && dependentDraft && (
            <Alert
              variant="inline"
              type="info"
              title="Dependent model"
              message={
                <>
                  By approving this draft,{" "}
                  <Link href={`/models/${dependentResource?.resourceId}`}>
                    this
                  </Link>{" "}
                  dependent model will also be approved.
                </>
              }
            />
          )}

          {draft.comment && (
            <Column gap={2}>
              <SectionHeading>Draft comment</SectionHeading>
              <Text>{draft.comment || "No comment"}</Text>
            </Column>
          )}

          {children}
        </Column>

        <ActionBar>
          <ButtonGroup>
            <PermissionedButton
              size="lg"
              permission={{
                v2:
                  resourceType === "sync"
                    ? {
                        resource: "sync",
                        grant: "can_approve",
                        id: resourceId,
                      }
                    : {
                        resource: "model",
                        grant: "can_approve",
                        id: resourceId,
                      },
              }}
              variant="primary"
              isLoading={updating}
              onClick={handleApprove}
            >
              Approve & publish
            </PermissionedButton>
            <Button
              size="lg"
              isLoading={withdrawing}
              onClick={draft.created_by === user?.id ? withdraw : onDeny}
            >
              {draft.created_by === user?.id ? "Withdraw request" : "Deny"}
            </Button>
          </ButtonGroup>
          {draft.created_by === user?.id &&
            draft.operation !== DraftOperation.Create && (
              <Button
                size="lg"
                isLoading={deletingUpdate || deletingCreate}
                variant="danger"
                onClick={deleteDraft}
              >
                Delete draft
              </Button>
            )}
        </ActionBar>
      </Row>
    </Box>
  );
};
