import { FC } from "react";

import { Column } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import { TooltipProps } from "recharts";
import { isPresent } from "ts-extras";

import { GraphTooltip } from "../graph-tooltip";
import { formatMetricValue, getTooltipSuffixTextFromMetric } from "./utils";
import { shouldUsePercentFormat } from "src/pages/analytics/utils";

type Props = {
  hoveredLine: string | undefined;
} & TooltipProps<number, string>;

const Tooltip: FC<Readonly<Props>> = ({ payload, hoveredLine }) => {
  if (!payload || !hoveredLine) return null;

  const tooltipsToShow = payload.filter(
    ({ payload: metric }) => metric.seriesKey === hoveredLine,
  );

  if (tooltipsToShow.length === 0) return null;

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      {tooltipsToShow.map(
        ({ payload: metric, dataKey, color, name, value }, index) => {
          const displayedValue = isPresent(value)
            ? formatMetricValue(
                value,
                shouldUsePercentFormat(
                  metric.aggregation,
                  metric.normalization,
                ),
              )
            : "Not available";

          const subtitles = [
            formatDate(new Date(metric.calculatedAt), "LLL d, yyyy"),
          ];
          // XXX: The Tooltip updates the `name` to be the dataKey when the name
          // is empty string or undefined. We want to ignore that and don't show
          // the name
          if (name && dataKey !== name) {
            subtitles.unshift(name);
          }

          return (
            <GraphTooltip
              key={`${metric.id}-${index}`}
              color={color}
              title={metric.metricName}
              subtitles={subtitles}
              value={[
                {
                  value: displayedValue,
                  description: metric.aggregation
                    ? getTooltipSuffixTextFromMetric(
                        metric.aggregation,
                        metric.normalization,
                      )
                    : undefined,
                },
              ]}
            />
          );
        },
      )}
    </Column>
  );
};

export default Tooltip;
