import { FC, PropsWithChildren } from "react";

import { Box, BoxProps } from "@hightouchio/ui";
import { motion } from "framer-motion";

import { SlideInOutVariants } from "./variants";

const MotionBox = motion(Box);

type SlideInOutProps = {
  animate: keyof typeof SlideInOutVariants;
} & BoxProps;

export const SlideInOutAndHide: FC<PropsWithChildren<SlideInOutProps>> = ({
  animate,
  children,
  ...props
}) => {
  return (
    // Outer div is needed to hide the inner div from the rest of the content
    <Box {...props} pointerEvents={animate === "closed" ? "none" : "auto"}>
      <MotionBox
        animate={animate}
        variants={SlideInOutVariants}
        height="100%"
        initial="closed"
      >
        {children}
      </MotionBox>
    </Box>
  );
};
