import {
  AggregationOption,
  NormalizationType,
} from "@hightouch/lib/query/visual/types";
export { AggregationOption };
import {
  AttributionBasis,
  AttributionWindow,
  IntervalUnit,
} from "src/types/visual";
import { NormalizationOption } from "../analytics/types";

export const AggregationOptionsWithColumns = [
  AggregationOption.SumOfProperty,
  AggregationOption.SumOfPropertyPerUser,
  AggregationOption.AverageOfProperty,
  AggregationOption.AverageOfPropertyPerUser,
  AggregationOption.CountDistinctProperty,
];

export const AggregationOptionsWithAttributionMethods = [
  AggregationOption.Count,
  AggregationOption.SumOfProperty,
];

export type MetricAggregationOption = {
  value: AggregationOption;
  label: string;
  description: string;
};

export const AggregationOptions = [
  {
    value: AggregationOption.Count,
    label: "Total events",
    description: "Total number of events performed",
  },
  {
    value: AggregationOption.UniqueUsers,
    label: "Unique users",
    description: "Number of users who performed an event",
  },
  {
    value: AggregationOption.SumOfProperty,
    label: "Sum of property",
    description: "Sum of a property value across all events",
  },
  {
    value: AggregationOption.AverageOfProperty,
    label: "Average of property",
    description: "Average value of a property across all events",
  },
  {
    value: AggregationOption.CountDistinctProperty,
    label: "Distinct count of property",
    description: "Count of distinct property values across all events",
  },
  {
    value: AggregationOption.SumOfPropertyPerUser,
    label: "Sum of property per user",
    description:
      "Sum of a property value for each user, averaged across all users",
  },
  {
    value: AggregationOption.AverageOfPropertyPerUser,
    label: "Average of property per user",
    description:
      "Average of a property value for each user, averaged across all users",
  },
  {
    value: AggregationOption.PercentOfAudience,
    label: "% of audience",
    description: "Percentage of users in an audience who performed an event",
  },
];

export const ATTRIBUTION_BASIS_OPTIONS = [
  {
    value: AttributionBasis.Entry,
    label: "From audience entry",
    description:
      "Events are attributed between audience entry and (audience entry + attribution window)",
  },
  {
    value: AttributionBasis.Exit,
    label: "From audience exit",
    description:
      "Events are attributed between audience entry and (audience exit + attribution window)",
  },
];

export const DEFAULT_ATTRIBUTION_WINDOW: AttributionWindow = {
  unit: IntervalUnit.Day,
  quantity: 0,
  basis: AttributionBasis.Exit,
};

export const TIME_OPTIONS = [
  { value: IntervalUnit.Day, label: "Day(s)" },
  { value: IntervalUnit.Week, label: "Week(s)" },
  { value: IntervalUnit.Month, label: "Month(s)" },
];

export const NORMALIZATION_GROUP_OPTIONS: {
  label: string;
  options: NormalizationOption[];
}[] = [
  {
    label: "Size adjustment",
    options: [
      {
        value: NormalizationType.TreatmentSize,
        label: "Normalized to treatment size",
        description:
          "Display non-treatment group results as if they were the same size as the treatment group",
      },
      {
        value: NormalizationType.HundredPercent,
        label: "Normalized to entire population size",
        description:
          "Display all experiment group results as if they were rolled out to the entire population",
      },
    ],
  },
  {
    label: "Lift",
    options: [
      {
        value: NormalizationType.LiftPercent,
        label: "% lift",
        description: "Relative change in outcomes that the treatment drove",
      },
      {
        value: NormalizationType.AbsoluteLift,
        label: "Absolute lift",
        description: "Incremental outcomes that the treatment drove",
      },
    ],
  },
  {
    label: "Rates",
    options: [
      {
        value: NormalizationType.RatePerUser,
        label: "Per-user rate",
        description: "Divide metric by the number of users being measured",
      },
      {
        value: NormalizationType.RatePerInteraction,
        label: "Per-interaction rate",
        description: "Divide metric by the number of interactions sent",
      },
    ],
  },
];

export const isLiftNormalizationType = (
  normalization: NormalizationType | undefined,
) =>
  normalization === NormalizationType.LiftPercent ||
  normalization === NormalizationType.AbsoluteLift;
