import { FC, ReactNode, useEffect, useMemo } from "react";

import {
  Alert,
  Box,
  Button,
  Column,
  InformationIcon,
  MeetingIcon,
  Row,
  SectionHeading,
  Select,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { noop } from "ts-essentials";
import { isPresent } from "ts-extras";

import CrossAudienceGraph from "src/components/analytics/cross-audience-graph";
import { GraphScale } from "src/components/analytics/cross-audience-graph/constants";
import {
  Graph,
  GraphSeries,
} from "src/components/analytics/cross-audience-graph/types";
import { DateRangePicker } from "src/components/analytics/date-range-picker";
import { FunnelGraph } from "src/components/analytics/funnel-graph";
import { usePermissionContext } from "src/components/permission";
import { useHeaderHeight } from "src/contexts/header-height-context";
import { FunnelMetricDataForCohort } from "src/graphql";
import { PredefinedMetric } from "src/types/visual";
import { useRowSelect } from "src/ui/table/use-row-select";

import { AnalyticsBreakdown } from "./analytics-breakdown";
import { AnalyticsTable } from "./analytics-table";
import { GraphOptionsSelection } from "./common";
import { placeholderContentWidthPx } from "./constants";
import {
  AnalyticsPlaceholderImage,
  FunnelsPlaceholder,
  PermissionsPlaceholder,
  UnconfiguredPlaceholder,
} from "./placeholders";
import { AnalyticsSidebar } from "./sidebar";
import { useAnalyticsContext } from "./state";
import {
  AccumulationTypes,
  LookbackOptions,
  RollupFrequencies,
} from "./state/constants";
import { SummaryStats } from "./summary-stats";
import { ChartFormState, ChartTab, GraphType, TimeOptions } from "./types";
import { formatDatePickerLabel, shouldUsePercentFormat } from "./utils";

type AnalyticsContentProps = {
  errorMessage?: ReactNode;
  funnelsData: FunnelMetricDataForCohort[];
  funnelsErrors?: Record<string, string>;
  graph: Graph;
  isLoading?: boolean;
  metricSeriesErrors?: Record<string, string>;
  ignoreHeaderHeight?: boolean;
  supportsFunnels?: boolean;
  showLowConfidenceWarning?: boolean;
};

const shouldUsePercentScale = (series: GraphSeries[]) => {
  return series.every(({ aggregation, normalization }) =>
    shouldUsePercentFormat(aggregation, normalization),
  );
};

const LowConfidenceResultsWarning = () => {
  return (
    <Row
      pt={6}
      pl={6}
      pr={6}
      pb={4}
      width="100%"
      align="center"
      justify="center"
    >
      <Alert
        type="warning"
        title="Low confidence results"
        message={
          <>
            Your query is using sampled data, but the result is too small for
            accurate estimation. This may affect the reliability of the results.
            You can disable <strong>“Fast queries”</strong> above and run it
            again for more accurate results.
          </>
        }
      />
    </Row>
  );
};

export const AnalyticsContent: FC<AnalyticsContentProps> = ({
  errorMessage,
  funnelsErrors = {},
  funnelsData,
  graph,
  isLoading: externalLoading = false,
  metricSeriesErrors = {},
  ignoreHeaderHeight = false,
  supportsFunnels = true,
  showLowConfidenceWarning = false,
}) => {
  const { headerHeight } = useHeaderHeight();
  const { selectedRows, onRowSelect } = useRowSelect();

  const { setLookbackWindow, setSelectedDates } = useAnalyticsContext();

  const form = useFormContext<ChartFormState>();

  const funnelSteps = form.watch("funnelSteps");
  const chartTab = form.watch("chartTab");
  const graphType = form.watch("graphType");
  const groupByColumns = form.watch("groupByColumns");
  const metricSelection = form.watch("metricSelection");
  const selectedDateStrings = form.watch("selectedDates");
  const timeValue = form.watch("timeValue");

  const selectedDates = useMemo(
    () => selectedDateStrings.map((dateStr) => new Date(dateStr)),
    [selectedDateStrings?.[0], selectedDateStrings?.[1]],
  );

  const { unauthorized } = usePermissionContext();

  const isLoading = externalLoading;

  const scale = shouldUsePercentScale(graph.series)
    ? GraphScale.Percent
    : GraphScale.Linear;

  const hasSummaryStats = Boolean(graph.summary.length);
  const hasMetricOrEventSelected = metricSelection.some(({ name }) => name);
  const hasFunnelStepsSelected =
    funnelSteps.length > 1 &&
    funnelSteps.every(({ eventModelId }) => Boolean(eventModelId));

  const disableAccumulationSelection = metricSelection.some(
    ({ id }) => id === PredefinedMetric.AudienceSize,
  );
  useEffect(() => {
    if (disableAccumulationSelection) {
      form.setValue("cumulative", false);
    }
  }, [disableAccumulationSelection]);

  useEffect(() => {
    // TODO(Samuel): could have a bug here when other loading states are triggered...
    if (!isLoading) {
      onRowSelect(graph.series.map(({ key }) => key));
    }
  }, [isLoading, graph.series]);

  const isFunnelsChart = chartTab === ChartTab.Funnel;
  const isInsightsChart = chartTab === ChartTab.Insights;

  // Insights graphs
  const isLineGraph = graphType === GraphType.Line;
  const isBarGraph = graphType === GraphType.Bar;
  const isTableGraph = graphType === GraphType.Table;

  if (unauthorized) {
    return <PermissionsPlaceholder />;
  }

  return (
    <Row flex={1} pos="relative">
      <AnalyticsSidebar
        ignoreHeaderHeight={ignoreHeaderHeight}
        funnelsErrors={funnelsErrors}
        isLoading={isLoading}
        metricSeriesErrors={metricSeriesErrors}
        supportsFunnels={supportsFunnels}
      />

      <Column
        // Summary Stats height is not fixed. If we try to fit all of the cotent in one viewport,
        // the graph will likely be too small, so just make the content scrollable instead.
        height={
          ignoreHeaderHeight
            ? undefined
            : hasSummaryStats
              ? "100%"
              : `calc(100vh - ${headerHeight}px)`
        }
        width="100%"
        overflowX="auto"
      >
        {showLowConfidenceWarning && <LowConfidenceResultsWarning />}
        {isFunnelsChart &&
          (hasFunnelStepsSelected ? (
            <FunnelGraph
              data={funnelsData}
              hasErrors={Object.values(funnelsErrors).length > 0}
              isLoading={isLoading}
            />
          ) : (
            <FunnelsPlaceholder />
          ))}
        {isInsightsChart &&
          isBarGraph &&
          (hasMetricOrEventSelected ? (
            <AnalyticsBreakdown
              data={graph.series}
              groupByColumns={groupByColumns.filter(isPresent)}
              isLoading={isLoading}
              lookbackWindow={timeValue}
              selectedDates={selectedDates}
              onSelectDateRange={setSelectedDates}
              onUpdateLookbackWindow={setLookbackWindow}
            />
          ) : (
            <UnconfiguredPlaceholder />
          ))}
        {isInsightsChart &&
          isTableGraph &&
          (hasMetricOrEventSelected ? (
            <AnalyticsTable
              data={graph.series}
              isLoading={isLoading}
              lookbackWindow={timeValue}
              selectedDates={selectedDates}
              onSelectDateRange={setSelectedDates}
              onUpdateLookbackWindow={setLookbackWindow}
            />
          ) : (
            <UnconfiguredPlaceholder />
          ))}
        {isInsightsChart &&
          isLineGraph &&
          (hasMetricOrEventSelected ? (
            <>
              <Row
                align="center"
                justify="space-between"
                mt={showLowConfidenceWarning ? 0 : 6}
                pl={6}
                pr={6}
                gap={2}
                width="100%"
              >
                <Row gap={2}>
                  <Controller
                    control={form.control}
                    name="rollupFrequency"
                    render={({ field }) => (
                      <Select
                        options={RollupFrequencies}
                        value={field.value}
                        width="auto"
                        onChange={field.onChange}
                        size="sm"
                      />
                    )}
                  />
                  <ToggleButtonGroup
                    size="sm"
                    value={timeValue}
                    onChange={(value) => {
                      setLookbackWindow(value as TimeOptions);
                    }}
                  >
                    {LookbackOptions.map((option) => (
                      <ToggleButton key={option.value} {...option} />
                    ))}
                  </ToggleButtonGroup>
                  <DateRangePicker
                    selectedDates={selectedDates}
                    onChange={(dates) => {
                      setSelectedDates(dates);
                    }}
                  >
                    <Box
                      as={Button}
                      background={
                        timeValue === TimeOptions.Custom ? "gray.200" : "unset"
                      }
                      fontWeight={
                        timeValue === TimeOptions.Custom ? "semibold" : "normal"
                      }
                      icon={MeetingIcon}
                      size="sm"
                      onClick={noop}
                    >
                      {formatDatePickerLabel(selectedDates, timeValue)}
                    </Box>
                  </DateRangePicker>
                </Row>
                <Row align="center" gap={1}>
                  <Row gap={2}>
                    <Controller
                      control={form.control}
                      name="cumulative"
                      render={({ field }) => (
                        <Box width={32}>
                          <Select
                            isDisabled={disableAccumulationSelection}
                            optionAccessory={({ icon }) => ({
                              type: "icon",
                              icon,
                            })}
                            options={AccumulationTypes}
                            value={field.value}
                            size="sm"
                            onChange={field.onChange}
                            popoverWidth="2xs"
                            width="100%"
                          />
                        </Box>
                      )}
                    />
                    <GraphOptionsSelection />
                  </Row>
                  {disableAccumulationSelection && (
                    <Tooltip
                      message='Audience size can only be displayed "over time"'
                      placement="top-end"
                    >
                      <Text color="color.secondary">
                        <InformationIcon />
                      </Text>
                    </Tooltip>
                  )}
                </Row>
              </Row>
              {!isLoading && <SummaryStats summaryStats={graph.summary} />}
              <Column flex={1} minHeight={0}>
                <CrossAudienceGraph
                  graph={graph}
                  isLoading={isLoading}
                  scale={scale}
                  selectedRows={selectedRows}
                  onSelect={onRowSelect}
                  placeholder={
                    <>
                      <Column
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Column
                          textAlign="center"
                          width={placeholderContentWidthPx}
                        >
                          <AnalyticsPlaceholderImage />
                        </Column>
                        <SectionHeading>No data</SectionHeading>
                        <Column maxWidth="400px">{errorMessage}</Column>
                      </Column>
                    </>
                  }
                />
              </Column>
            </>
          ) : (
            <UnconfiguredPlaceholder />
          ))}
      </Column>
    </Row>
  );
};
