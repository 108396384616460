import { useVirtualizer } from "@tanstack/react-virtual";
import { RefObject, useCallback, useMemo } from "react";

export type VirtualItem<Option extends { label: string }> =
  | GroupVirtualItem
  | OptionVirtualItem<Option>;

interface GroupVirtualItem {
  type: "group";
  height: number;
  label: string;
}

export interface OptionVirtualItem<Option extends { label: string }> {
  type: "option";
  height: number;
  option: Option;
  index: number;
}

interface UseVirtualItemsProps<Option extends { label: string }> {
  /**
   * Option groups.
   */
  optionGroups?: Array<{
    label: string | null;
    options: Option[];
  }>;

  /**
   * Container for options.
   */
  containerRef: RefObject<HTMLDivElement>;
}

interface UseVirtualItemsResult<Option extends { label: string }> {
  /**
   * Items to render in a virtual list.
   */
  virtualItems: Array<VirtualItem<Option>>;

  /**
   * Virtualizer instance.
   */
  virtualizer: ReturnType<typeof useVirtualizer<HTMLElement, Element>>;
}

export function useVirtualItems<Option extends { label: string }>({
  optionGroups,
  containerRef,
}: UseVirtualItemsProps<Option>): UseVirtualItemsResult<Option> {
  const virtualItems = useMemo(() => {
    const items: Array<VirtualItem<Option>> = [];

    if (Array.isArray(optionGroups)) {
      let index = 0;

      for (const optionGroup of optionGroups) {
        if (optionGroup.label) {
          items.push({
            type: "group",
            height: 32,
            label: optionGroup.label,
          });
        }

        for (const option of optionGroup.options) {
          items.push({
            type: "option",
            height: 48,
            option,
            index: index++,
          });
        }
      }
    }

    return items;
  }, [optionGroups]);

  const getVirtualItemKey = (virtualIndex: number): string => {
    const item = virtualItems[virtualIndex];

    if (!item) {
      return String(virtualIndex);
    }

    if (item.type === "group") {
      return `${item.label}-${virtualIndex}`;
    }

    return `${item.option.label}-${virtualIndex}`;
  };

  const virtualizer = useVirtualizer<HTMLElement, Element>({
    count: virtualItems.length,
    getItemKey: getVirtualItemKey,
    getScrollElement() {
      return containerRef.current;
    },
    estimateSize: useCallback(
      (index) => virtualItems[index]?.height ?? 48,
      [virtualItems],
    ),
  });

  return {
    virtualItems,
    virtualizer,
  };
}
