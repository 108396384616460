import { FC, useMemo, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  ButtonGroup,
  CloseIcon,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  DrawerBody,
  EditableDescription,
  ExternalLinkIcon,
  FocusIcon,
  IconButton,
  Menu,
  MenuActionsButton,
  MenuList,
  Paragraph,
  Portal,
  Row,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";
import pluralize from "pluralize";

import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "src/router";
import { ColumnSettings } from "src/components/audiences/column-settings";
import {
  getSchemaModelType,
  getSchemaModelTypeNames,
} from "src/components/audiences/utils";
import { DetailBar } from "src/components/detail-bar";
import {
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { DisplaySlug } from "src/components/slug/display-slug";
import {
  useDeleteObjectMutation,
  useObjectRelatedResourcesQuery,
  useUpdateObjectMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { parentModelActivityMappers } from "src/pages/audiences/setup/parent-model-activity";
import {
  modelColumnIgnoreFields,
  modelColumnMappers,
} from "src/pages/models/model-activity";
import { getParams } from "src/pages/schema/graph/utils";
import { QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { formatDate } from "src/utils/time";
import {
  DependenciesModal,
  DependencyTableData,
} from "src/components/modals/dependencies-modal";
import { MatchBoostingSettingsWrapper } from "src/pages/schema/match-booster";
import { Relationships } from "src/pages/schema/relationships/relationships";
import { ParentModelSampling } from "src/pages/schema/sampling";
import { RelatedModelSampling } from "src/pages/schema/sampling/related-model-sampling";
import { QueryTab } from "./query";
import { SchemaObjectType } from "src/pages/schema/types";
import { useTabRoutes } from "./utils";
import { RouteTabs } from "src/components/route-tabs";
import { getUniqueObjectsByKey } from "src/utils/array";

// helper function for clarity: transform filterable_audience_columns or source_column_descriptions into
// a simple Record<string, string> dict where key is the lowercase column name, value is the description.
// Omit missing descriptions from the result dict.
function toColumnDescriptionDict(
  input: Array<{ name: string; description: string | null }>,
): Record<string, string | null> {
  return input.reduce(
    (dict, { name, description }) => {
      if (description !== null) {
        dict[name.toLowerCase()] = description;
      }
      return dict;
    },
    {} as Record<string, string>,
  );
}

export const SchemaObject: FC<
  Readonly<{
    object: SchemaObjectType;
    audienceCount: number | undefined;
  }>
> = ({ object, audienceCount }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const type = getSchemaModelType(object);
  const { typeName, typePath } = getSchemaModelTypeNames(type);
  const { appModelSamplingEnabled } = useFlags();
  const tabRoutes = useTabRoutes({
    schemaModelType: type,
    sourceType: object.connection?.type ?? "",
  });
  const [deleteQuery, setDeleteQuery] = useState(false);

  const objectRelatedResourcesQuery = useObjectRelatedResourcesQuery({
    id: object.id,
  });

  const queryType = object.query_type as QueryType;
  const columns = object.columns;
  const source = object.connection;
  const filterableAudienceColumns = object.filterable_audience_columns;
  const sourceColumnDescriptions = object.source_column_descriptions ?? [];
  const totalAudiencesCount = audienceCount ?? 0;

  // filterableAudienceColumns have source and dbt derived descriptions; we can
  // use those if column.description isn't set
  const columnsWithEnrichedDescriptions = useMemo(() => {
    const filteredAudienceDescDict = toColumnDescriptionDict(
      filterableAudienceColumns,
    );
    const sourceDescDict = toColumnDescriptionDict(sourceColumnDescriptions);

    return columns.map((col) => {
      const lowerColName = col.name.toLowerCase();
      return {
        ...col,
        description:
          col.description ||
          filteredAudienceDescDict[lowerColName] ||
          sourceDescDict[lowerColName] ||
          null,
      };
    });
  }, [columns, filterableAudienceColumns, sourceColumnDescriptions]);

  const idsOfJourneysBeingDeleted = new Set(
    objectRelatedResourcesQuery.data?.journeysBeingDeleted.map(({ id }) => id),
  );

  // Many journey version may be associated with this audience
  const uniqueJourneys = getUniqueObjectsByKey(
    objectRelatedResourcesQuery.data?.journeys ?? [],
    "id",
  );

  const dependencies: DependencyTableData[] = [
    {
      name: "Journeys",
      resources: uniqueJourneys.map((journey) => ({
        id: journey.id,
        name: journey.name,
        description: idsOfJourneysBeingDeleted.has(journey.id)
          ? "Deletion in progress"
          : undefined,
        url: `/journeys/${journey.id}`,
        created_at: journey.created_at,
        updated_at: journey.updated_at,
        created_by_user: journey.created_by_user,
        updated_by_user: journey.updated_by_user,
      })),
    },
    {
      name: "Saved charts",
      resources: (
        objectRelatedResourcesQuery.data?.saved_analytics_charts ?? []
      )?.map((chart) => ({
        id: chart.id,
        name: chart.name,
        description: capitalize(chart.chart_type),
        chart_type: chart.chart_type,
        url: `/analytics/${chart.id}`,
        created_at: chart.created_at,
        updated_at: chart.updated_at,
        created_by_user: chart.created_by_user,
        updated_by_user: chart.updated_by_user,
      })),
    },
  ].filter(({ resources }) => resources.length > 0);

  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: asyncDeleteObject } = useDeleteObjectMutation();

  const trackUpdate = () => {
    analytics.track("Model Updated", {
      model_id: object.id,
      model_type: queryType,
      model_name: object.name,
      source_id: source?.id,
      source_type: source?.type,
    });
  };

  const saveName = async (name: string) => {
    try {
      await updateObject({
        id: object.id,
        input: {
          name,
        },
      });
      toast({
        id: `update-name`,
        title: `Name was updated`,
        variant: "success",
      });

      trackUpdate();
    } catch (e) {
      toast({
        id: "update-name-error",
        title: "Failed to update name",
        message: e.message,
        variant: "error",
      });
    }
  };

  const saveDescription = async (description: string) => {
    try {
      await updateObject({
        id: object.id,
        input: {
          description,
        },
      });

      toast({
        id: "update-description",
        title: "Description was updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-description",
        title: "Failed to update description",
        message: e.message,
        variant: "error",
      });
    }

    trackUpdate();
  };

  const deleteObject = async () => {
    try {
      await asyncDeleteObject({
        id: object.id,
      });

      analytics.track("Model Deleted", {
        model_id: object.id,
        model_type: queryType,
        model_name: object.name,
        source_id: source?.id,
        source_type: source?.type,
      });

      toast({
        id: `delete-${typePath}`,
        title: `${capitalize(typeName)} was deleted`,
        variant: "success",
      });

      const { queryString } = getParams();
      navigate(`/schema-v2${queryString}`);
    } catch (error) {
      if (error.message.includes("Foreign key violation")) {
        toast({
          id: "delete-${typePath}",
          title: `Failed to delete ${capitalize(typeName)}`,
          message: `${capitalize(
            typeName,
          )} could not be deleted because other resources rely on it.`,
          variant: "error",
        });
      }

      captureException(error);
    }

    setDeleteQuery(false);
  };

  const hasPrimaryKeyIssue = Boolean(
    type === SchemaModelType.Parent &&
      object.columns?.length &&
      !object.columns.some((c) => c.name === object.primary_key),
  );

  const updatedByUsername =
    object.updated_by_user?.name || object.created_by_user?.name;

  return (
    <Routes>
      <Route
        element={
          <PermissionProvider
            permission={{
              v1: {
                resource: "audience_schema",
                grant: "update",
              },
              v2: {
                resource: "model",
                grant: "can_update",
                id: object.id,
              },
            }}
          >
            <Column
              borderTop="1px solid"
              borderLeft="1px solid"
              borderColor="base.border"
              overflow="hidden"
              height="100%"
            >
              <Column px={6} py={4} width="100%">
                <Row align="center" justify="space-between" gap={8}>
                  <PermissionedEditableHeading
                    permission={{
                      v1: {
                        resource: "audience_schema",
                        grant: "update",
                      },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: object.id,
                      },
                    }}
                    size="lg"
                    value={object.name ?? ""}
                    onChange={saveName}
                  />
                  <ButtonGroup size="lg">
                    {type === SchemaModelType.Parent && (
                      <Tooltip message="View model schema">
                        <IconButton
                          aria-label="View parent model graph"
                          variant="secondary"
                          icon={FocusIcon}
                          onClick={() => {
                            const { source } = getParams();
                            navigate(
                              `/schema-v2?source=${source}&parent=${object.id}`,
                            );
                          }}
                        />
                      </Tooltip>
                    )}
                    <Menu>
                      <MenuActionsButton variant="secondary" />
                      <Portal>
                        <MenuList>
                          <PermissionedMenuItem
                            permission={{
                              v1: {
                                resource: "audience_schema",
                                grant: "delete",
                              },
                              v2: {
                                resource: "model",
                                grant: "can_delete",
                                id: object.id,
                              },
                            }}
                            icon={DeleteIcon}
                            variant="danger"
                            onClick={() => {
                              setDeleteQuery(true);
                            }}
                          >
                            Delete
                          </PermissionedMenuItem>
                        </MenuList>
                      </Portal>
                    </Menu>
                    <IconButton
                      aria-label="Close"
                      icon={CloseIcon}
                      onClick={() => {
                        const { queryString } = getParams();
                        navigate(`/schema-v2${queryString}`);
                      }}
                    />
                  </ButtonGroup>
                </Row>
                <EditableDescription
                  value={object.description ?? ""}
                  onChange={saveDescription}
                />
                <DetailBar>
                  <Row align="center" gap={2} flexShrink={0}>
                    <Text>Last updated:</Text>
                    <Row gap={1} align="center">
                      {formatDate((object.updated_at || object.created_at)!)}
                      {updatedByUsername && (
                        <>
                          <Text>by</Text>
                          <Avatar size="xs" name={updatedByUsername} />
                        </>
                      )}
                    </Row>
                  </Row>
                  <Row align="center" gap={2} flexShrink={0} overflow="hidden">
                    <Text>Slug:</Text>
                    <DisplaySlug currentSlug={object.slug} />
                  </Row>
                  {totalAudiencesCount > 0 && (
                    <Row align="center" flexShrink={0}>
                      <Link href={`/audiences?parent=${object.id}`}>
                        <Row align="center" gap={1}>
                          {totalAudiencesCount}{" "}
                          {pluralize("audience", totalAudiencesCount)}
                          <Box as={ExternalLinkIcon} fontSize="14px" />
                        </Row>
                      </Link>
                    </Row>
                  )}
                </DetailBar>
              </Column>

              <RouteTabs tabs={tabRoutes} px={6} depth={4} />

              <DrawerBody p={0}>
                {hasPrimaryKeyIssue && (
                  <Alert
                    type="warning"
                    variant="banner"
                    title="Undefined primary key"
                    message="Without a primary key, your syncs may fail, or undefined behavior may occur. Please check to make sure that your primary key is set to a valid column."
                  />
                )}
                <Column height="100%">
                  <Outlet />
                </Column>
              </DrawerBody>
            </Column>

            <DependenciesModal
              isOpen={deleteQuery && dependencies.length > 0}
              resourceType={typeName}
              onClose={() => setDeleteQuery(false)}
              dependencies={dependencies}
            />

            <ConfirmationDialog
              confirmButtonText={`Delete ${typeName}`}
              isOpen={deleteQuery && dependencies.length === 0}
              title={`Delete ${typeName}`}
              variant="danger"
              onClose={() => {
                setDeleteQuery(false);
              }}
              onConfirm={deleteObject}
            >
              <Paragraph>
                Are you sure you want to delete this {typeName}? You won't be
                able to undo this.
              </Paragraph>
            </ConfirmationDialog>
          </PermissionProvider>
        }
      >
        <Route
          path="/"
          index
          element={
            <Navigate
              to={{ pathname: "query", search: location.search }}
              replace
            />
          }
        />
        <Route path="/query" element={<QueryTab object={object} />} />
        <Route
          path="/relationships/*"
          element={
            <Relationships
              type={type}
              modelId={String(object.id)}
              modelSlug={object.slug}
              sourceId={String(object.connection?.id)}
            />
          }
        />
        <Route
          path="/columns"
          element={
            <Column p={6} overflow="auto">
              <ColumnSettings
                columns={columnsWithEnrichedDescriptions}
                modelId={object.id}
                primaryKey={object.primary_key || undefined}
                source={source}
              />
            </Column>
          }
        />
        {type === SchemaModelType.Parent && (
          <Route
            path="/activity"
            element={
              <Column p={6} overflow="auto">
                <ResourceActivityTimeline
                  primaryResource={{
                    mappers: parentModelActivityMappers,
                    resourceNameOverride: "Parent Model",
                    resource: "Audience Schema",
                    resourceId: String(object.id),
                  }}
                  relatedResources={[
                    {
                      mappers: modelColumnMappers,
                      resource: "Model Column",
                      resourceNameOverride: "Model column",
                      resourceId: `model_id:${object.id}:%`,
                      ignoreColumns: modelColumnIgnoreFields,
                    },
                  ]}
                />
              </Column>
            }
          />
        )}
        {type === SchemaModelType.Parent && (
          <Route
            path="/match-booster"
            element={
              <MatchBoostingSettingsWrapper
                sidebar
                columns={columns}
                model={object}
                segmentType="schema"
              />
            }
          />
        )}
        {type === SchemaModelType.Parent && appModelSamplingEnabled && (
          <Route
            path="/sampling/*"
            element={
              <ParentModelSampling
                modelName={object.name}
                lightningEngineEnabled={Boolean(source?.plan_in_warehouse)}
                sourceId={source?.id?.toString()}
              />
            }
          />
        )}
        {(type === SchemaModelType.Related || type === SchemaModelType.Event) &&
          appModelSamplingEnabled && (
            <Route
              path="/sampling/*"
              element={
                <RelatedModelSampling
                  modelId={String(object.id)}
                  sourceId={String(source?.id)}
                />
              }
            />
          )}
      </Route>
    </Routes>
  );
};
