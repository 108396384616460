// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Navigate, useLocation } from "react-router-dom";

import { useUser } from "src/contexts/user-context";

export const AuthenticatedRoute = ({ children }) => {
  const { user, isCompanyRequired } = useUser();
  const location = useLocation();

  if (user) {
    if (isCompanyRequired) {
      if (location.pathname.includes("/restricted")) {
        return <>{children}</>;
      }
      if (
        location.pathname === "/welcome" ||
        location.pathname === "/workspaces"
      ) {
        return <Navigate state={location?.state} to="/restricted" />;
      }
      return (
        <Navigate
          state={location?.state}
          to={`/restricted?returnTo=${encodeURI(location?.pathname)}`}
        />
      );
    }
    return <>{children}</>;
  }
  return (
    <Navigate
      state={location?.state}
      to={`/login?returnTo=${encodeURI(location?.pathname)}`}
    />
  );
};
