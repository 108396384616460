import { Box } from "@hightouchio/ui";

export const BreakdownIcon = () => {
  return (
    <Box
      display="grid"
      height="46.67px"
      width="104px"
      gridTemplateRows="repeat(3, 1fr)"
      gap={1.5}
      opacity={0.5}
    >
      <Box
        gridColumn="1 / 5"
        backgroundColor="electric.400"
        borderRadius="2.67px"
      />
      <Box
        gridColumn="1 / 4"
        backgroundColor="teal.400"
        borderRadius="2.67px"
      />
      <Box
        gridColumn="1 / 3"
        backgroundColor="avocado.400"
        borderRadius="2.67px"
      />
    </Box>
  );
};
