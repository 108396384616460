import { FC, useEffect, useMemo } from "react";

import {
  Alert,
  Box,
  ButtonGroup,
  Column,
  FormField,
  Row,
  SectionHeading,
  Select,
  Switch,
  TextInput,
  Tooltip,
  useToast,
  Text,
  Heading,
  DrawerFooter,
  DrawerHeader,
  DrawerBody,
  IconButton,
  CloseIcon,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  LinkButton,
  useNavigate,
  Outlet,
  useOutletContext,
  useParams,
} from "src/router";
import { Card } from "src/components/card";
import { KeyValueMapping } from "src/components/destinations/key-value-mapping";
import dbtModelsImage from "src/components/extensions/assets/dbt-models.png";
import { Overview } from "src/components/extensions/overview";
import { ExtensionSyncStatusBadge } from "src/components/extensions/extension-sync-status-badge";
import { GitBranchSelector } from "src/components/git/git-branch-selector";
import { GitChecksToggle } from "src/components/git/git-checks-toggle";
import { GitCredentialsFields } from "src/components/git/git-credentials-fields";
import { GitRepositorySelector } from "src/components/git/git-repository-selector";
import { GitSyncableResourceToggle } from "src/components/git/git-syncable-resource-toggle";
import { DetailPage } from "src/components/layout";
import { PermissionedButton } from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  DbtSyncConfig,
  GitCredentials,
  SourcesQuery,
  useCreateDbtSyncConfigMutation,
  useDbtSyncConfigsQuery,
  useGitCredentialsQuery,
  useSourcesQuery,
  useUpdateDbtSyncConfigMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { QueryType } from "src/types/models";
import { DBTIcon } from "src/ui/icons";
import { PageSpinner } from "src/components/loading";
import { DocsLink } from "src/components/docs-link";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Drawer } from "src/components/drawer";

type Sources = SourcesQuery["connections"];

const SUPPORTED_DBT_VERSIONS = [
  { label: "0.19", value: "0.19" },
  { label: "0.20", value: "0.20" },
  { label: "0.21", value: "0.21" },
  { label: "1.0", value: "1.0" },
  { label: "1.1", value: "1.1" },
  { label: "1.2", value: "1.2" },
  { label: "1.3", value: "1.3" },
  { label: "1.4", value: "1.4" },
  { label: "1.5", value: "1.5" },
  { label: "1.6", value: "1.6" },
  { label: "1.7", value: "1.7" },
  { label: "1.8", value: "1.8" },
  { label: "1.9", value: "1.9" },
];

function getSourceSupportedDbtVersions(sourceType: string) {
  if (sourceType === "athena") {
    return SUPPORTED_DBT_VERSIONS.filter(
      ({ value }) => value && Number(value) > 1.2,
    );
  }
  return SUPPORTED_DBT_VERSIONS;
}

export const DbtModelsOverview: FC = () => {
  return (
    <Overview
      description="This integration works by connecting to the Git repository containing your dbt project. As models and analyses are added, they will automatically become available in Hightouch. Optionally, you can also write exposures back to dbt."
      icon={DBTIcon}
      image={dbtModelsImage}
      subtitle="Import models and analyses from dbt"
      title="dbt models"
    />
  );
};

export const DbtModels: FC = () => {
  const { sourceId } = useParams();

  const { data: credentials, isLoading: credsLoading } = useGitCredentialsQuery(
    undefined,
    {
      select: (data) => data.git_credentials?.[0],
    },
  );

  const { data: configs, isLoading: configsLoading } = useDbtSyncConfigsQuery(
    undefined,
    {
      refetchInterval: 3000,
      select: (data) => data.dbt_sync_config,
    },
  );

  const { data: allSources, isLoading: sourcesLoading } = useSourcesQuery(
    {
      limit: 1000,
    },
    { select: (data) => data.connections },
  );

  const sources = allSources?.filter(({ definition }) =>
    definition?.supportedQueries.includes(QueryType.DbtModel),
  );

  const source = sources?.find(({ id }) => id === Number(sourceId));

  const crumbs = source
    ? [
        { label: "Extensions", link: "/extensions" },
        { label: "dbt models", link: "/extensions/dbt-models" },
      ]
    : [{ label: "Extensions", link: "/extensions" }];

  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={crumbs}
      title="dbt models - Extensions"
      header={<Heading size="xl">dbt models</Heading>}
      tabs={[
        {
          path: "",
          title: "Overview",
        },
        {
          path: "configuration",
          title: "Configuration",
        },
      ]}
      hasBottomPadding
    >
      <Outlet
        context={{
          credentials,
          configs,
          sources,
          source,
          loading: configsLoading || credsLoading || sourcesLoading,
        }}
      />
    </DetailPage>
  );
};

interface OutletContext {
  loading: boolean;
  credentials: GitCredentials;
  configs: Array<DbtSyncConfig>;
  sources: Sources;
  source: Sources[0] | undefined;
}

export const DbtModelsConfiguration: FC = () => {
  const context = useOutletContext<OutletContext>();
  const { loading, credentials, configs, sources } = context;
  const { data: entitlementsData } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a source, upgrade your plan.";

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "workspace",
          grant: "can_update",
        },
      }}
    >
      <Column gap={6} maxW="2xl">
        <Row justify="space-between" align="center">
          <Heading>dbt models configuration</Heading>
          <DocsLink name="dbt models" href="models/dbt-models" />
        </Row>
        <Column>
          <SectionHeading mb={6}>Credentials</SectionHeading>
          <Column gap={8}>
            <GitCredentialsFields
              credentials={credentials}
              isSetup={Boolean(credentials?.id)}
              page="dbt-models"
            />
          </Column>
        </Column>
        <Column>
          <SectionHeading mb={6}>dbt connected sources</SectionHeading>
          <Column gap={6}>
            {!sources?.length && (
              <Card>
                <SectionHeading>No sources</SectionHeading>
                <Text my={4} color="text.secondary">
                  Add a compatible source to start using dbt models
                </Text>
                <Tooltip message={overageText} isDisabled={!overageLockout}>
                  <LinkButton href="/sources/new" isDisabled={overageLockout}>
                    Add a source
                  </LinkButton>
                </Tooltip>
              </Card>
            )}
            {sources?.length > 0 && !credentials?.id && (
              <Text color="text.secondary">
                Set up your Git credentials before configuring dbt models
              </Text>
            )}
            {sources.map(({ id, name, definition }) => {
              const config = configs?.find(
                (config) => String(config.connection_id) === String(id),
              );
              return (
                <Card opacity={credentials?.id ? 1 : 0.5} p={0} key={id}>
                  <Row p={6} align="center" justify="space-between">
                    <Row align="center" gap={2}>
                      <IntegrationIcon
                        src={definition?.icon}
                        name={definition?.name}
                      />
                      <Text fontWeight="semibold" size="lg">
                        {name}
                      </Text>
                    </Row>
                    <LinkButton
                      href={`/extensions/dbt-models/configuration/${id}`}
                    >
                      {config ? "Manage" : "Connect to dbt"}
                    </LinkButton>
                  </Row>
                  {config && (
                    <Column
                      p={6}
                      gap={6}
                      borderTop="1px"
                      borderColor="base.border"
                    >
                      <Row justify="space-between" align="center">
                        <Text fontWeight="medium">dbt to Hightouch</Text>
                        <ExtensionSyncStatusBadge
                          error={config?.error}
                          lastAttemptedAt={config?.last_run_at}
                          setup={!config?.last_run_at}
                        />
                      </Row>
                      {config?.exposure_sync_enabled && (
                        <Row justify="space-between" align="center">
                          <Text fontWeight="medium">Hightouch to dbt</Text>
                          <ExtensionSyncStatusBadge
                            error={config?.exposure_sync_error}
                            lastAttemptedAt={config?.exposure_sync_last_run_at}
                            setup={!config?.exposure_sync_last_run_at}
                          />
                        </Row>
                      )}
                      <Row justify="space-between" align="center">
                        <Text fontWeight="medium">dbt Schema</Text>
                        <Text color="text.secondary">
                          {config?.default_schema}
                        </Text>
                      </Row>
                    </Column>
                  )}
                </Card>
              );
            })}
          </Column>
        </Column>
      </Column>
      <Outlet context={context} />
    </PermissionProvider>
  );
};

export const DbtModelsSourceForm: FC = () => {
  const { configs, credentials, source } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const formMethods = useForm();
  const { sourceId } = useParams();

  const { mutateAsync: update, isLoading: updateLoading } =
    useUpdateDbtSyncConfigMutation();
  const { mutateAsync: create } = useCreateDbtSyncConfigMutation();

  const config = useMemo(
    () =>
      configs?.find(
        ({ connection_id }) => String(connection_id) === String(sourceId),
      ),
    [sourceId, configs],
  );

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting, errors },
  } = formMethods;

  const modelSyncEnabled = watch("enabled");
  const exposureSyncEnabled = watch("exposure_sync_enabled");

  const submit = async (data) => {
    try {
      if (!config?.id) {
        await create({
          object: {
            ...data,
            git_credentials_id: credentials?.id,
            connection_id: sourceId,
          },
        });
      } else {
        await update({
          id: config.id,
          object: data,
        });
      }

      toast({
        id: "save-dbt-config",
        title: "Configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "save-dbt-config",
        title: "Couldn't save your configuration",
        variant: "error",
      });

      Sentry.captureException(e);
    }
  };

  const fullResync = async (data) => {
    if (!config?.id) {
      return;
    }
    const resyncData = {
      ...data,
      exposure_sync_last_run_at: null,
      last_run_at: null,
      error: null,
      exposure_sync_error: null,
      full_resync: true,
      metadata: {},
      commit: null,
      failed_attempts: 0,
    };
    await update({
      id: config.id,
      object: resyncData,
    });
  };

  useEffect(() => {
    reset({
      repository: config?.repository ?? "",
      branch: config?.branch ?? "",
      exposure_sync_branch: config?.exposure_sync_branch ?? "",
      path: config?.path ?? "",
      target: config?.target ?? "",
      selector: config?.selector ?? "",
      version: config?.version ?? "",
      default_schema: config?.default_schema ?? "",
      enabled: config?.enabled ?? false,
      checks_enabled: config?.checks_enabled ?? false,
      exposure_sync_enabled: config?.exposure_sync_enabled ?? false,
      exposure_sync_path: config?.exposure_sync_path ?? "",
      connection_id: config?.connection_id,
      env_vars: config?.env_vars,
      use_database_overrides: config?.use_database_overrides ?? false,
    });
  }, [config]);

  const modelSyncError = (error: any) => {
    return (
      <Row sx={{ pt: "24px", width: "100%" }}>
        <Alert
          variant="inline"
          type="error"
          title="dbt error"
          message={
            <>
              <Text as="b">dbt command: {error.stage}</Text>
              <Box
                as="pre"
                sx={{ whiteSpace: "pre-wrap", fontSize: "10px", pt: 2 }}
              >
                {error.stdout}
              </Box>
              {error.stderr && (
                <Box
                  as="pre"
                  sx={{ whiteSpace: "pre-wrap", fontSize: "10px", pt: 2 }}
                >
                  {error.stderr}
                </Box>
              )}
            </>
          }
        />
      </Row>
    );
  };

  function checkBelowMinimumDbtVersion(
    version: string,
    minimum: number,
  ): boolean {
    return Number((version || "0.0").split(".", 2).join(".")) < minimum;
  }

  return (
    <Drawer
      size="lg"
      isOpen
      onClose={() => {
        navigate("/extensions/dbt-models/configuration");
      }}
    >
      <DrawerHeader>
        <Row justify="space-between" align="center" w="100%">
          <Heading>{source?.name} dbt model configuration</Heading>
          <IconButton
            aria-label="Close drawer"
            icon={CloseIcon}
            onClick={() => {
              navigate("/extensions/dbt-models/configuration");
            }}
          />
        </Row>
      </DrawerHeader>
      <FormProvider {...formMethods}>
        <DrawerBody>
          <Column gap={6} pb={12}>
            <Column gap={4}>
              <GitSyncableResourceToggle
                description="Use your dbt models and analyses in Hightouch Syncs"
                error={config?.error}
                fieldName="enabled"
                icon={<DBTIcon />}
                last_run_at={config?.last_run_at?.toString()}
                learnMoreUrl={
                  import.meta.env.VITE_DOCS_URL + "/models/dbt-models"
                }
                renderError={
                  config?.error?.stdout && config?.error?.stage
                    ? modelSyncError
                    : undefined
                }
                stdout={
                  config?.metadata?.success_stdout && {
                    text: config?.metadata?.success_stdout,
                    title:
                      "dbt model sync is running successfully with the below output",
                    status: "success",
                  }
                }
                title="dbt Model Sync"
                onToggle={(enabled) => {
                  if (!enabled) {
                    formMethods.setValue("exposure_sync_enabled", false);
                  }
                }}
              />
              {config?.version &&
                checkBelowMinimumDbtVersion(config.version, 1.0) && (
                  <Alert
                    variant="inline"
                    type="error"
                    title="dbt versions below 1.0.0 are no longer supported"
                    message="Please upgrade to a newer version of dbt to resume syncing"
                  />
                )}
              {modelSyncEnabled && (
                <Card>
                  <GitChecksToggle
                    credentials={credentials}
                    description="Check if changed dbt models will impact downstream Hightouch Syncs."
                  />
                </Card>
              )}
              <GitSyncableResourceToggle
                blocked={!config?.enabled}
                description={
                  "See which Hightouch Syncs are using your dbt models in your lineage graph. Hightouch must have write access to your dbt branch." +
                  (config?.enabled ? "" : " - dbt Model sync must be enabled.")
                }
                error={config?.exposure_sync_error}
                fieldName="exposure_sync_enabled"
                icon={<DBTIcon />}
                last_run_at={config?.exposure_sync_last_run_at?.toString()}
                learnMoreUrl="https://docs.getdbt.com/docs/building-a-dbt-project/exposures"
                title="dbt Exposures Sync"
              />
            </Column>

            <Column>
              <SectionHeading mb={6}>Configuration</SectionHeading>
              <Column gap={8}>
                <GitRepositorySelector credentials={credentials} />
                <GitBranchSelector credentials={credentials} />
                {exposureSyncEnabled && (
                  <>
                    <GitBranchSelector
                      credentials={credentials}
                      description="If dbt Exposure Sync is enabled, Hightouch will attempt to sync the generated dbt Exposures file to this branch. Defaults to your dbt Model Sync branch."
                      label="Exposure sync branch"
                      name="exposure_sync_branch"
                    />
                    <FormField
                      isOptional
                      description="If dbt Exposure Sync is enabled, Hightouch can use a different directory for your exposures file"
                      label="Exposure sync directory"
                    >
                      <Controller
                        name="exposure_sync_path"
                        render={({ field }) => <TextInput {...field} />}
                      />
                    </FormField>
                  </>
                )}
                <FormField label="dbt Version">
                  <Controller
                    name="version"
                    render={({ field }) => {
                      return (
                        <Select
                          options={getSourceSupportedDbtVersions(
                            source?.type || "",
                          )}
                          placeholder="Select a version..."
                          isOptionDisabled={(option) =>
                            checkBelowMinimumDbtVersion(option.value, 1.0)
                          }
                          value={field.value}
                          onChange={(selected) => {
                            field.onChange(selected);
                          }}
                        />
                      );
                    }}
                  />
                </FormField>
                <FormField
                  description="The default schema is the schema where dbt would normally materialize your tables to unless otherwise specified in project file. Generally, this is something like public or production or dbt_production."
                  label="Default schema"
                >
                  <Controller
                    name="default_schema"
                    render={({ field }) => (
                      <TextInput {...field} placeholder="production" />
                    )}
                  />
                </FormField>
                <FormField
                  description="Specify the path to your dbt_project.yml file relative to the root of your repository."
                  error={errors.path ? "Enter a valid project path" : undefined}
                  label="dbt project path"
                >
                  <Controller
                    name="path"
                    rules={{
                      required: modelSyncEnabled,
                    }}
                    render={({ field }) => (
                      <TextInput {...field} placeholder="./dbt_project.yml" />
                    )}
                  />
                </FormField>
                <FormField
                  isOptional
                  description="By default, Hightouch will select all the models. Specify a dbt selector here such as tag:hightouch or *."
                  label="dbt Selector"
                >
                  <Controller
                    name="selector"
                    render={({ field }) => <TextInput {...field} />}
                  />
                </FormField>
                <FormField
                  isOptional
                  description='By default, Hightouch uses the target name "prod" with the database credentials from the source. This is useful if you use a specific target.name variable in your dbt models.'
                  label="Custom target"
                >
                  <Controller
                    name="target"
                    render={({ field }) => <TextInput {...field} />}
                  />
                </FormField>
                <FormField
                  isOptional
                  description="By default, Hightouch uses the configured source database to query dbt models. This setting uses any custom database overrides set at the model-level (https://docs.getdbt.com/docs/build/custom-databases) for queries."
                  label="Database overrides"
                >
                  <Controller
                    name="use_database_overrides"
                    render={({ field }) => (
                      <Row gap={2} alignItems="center">
                        <Switch isChecked={field.value} {...field} />
                        <Text>Use custom database overrides</Text>
                      </Row>
                    )}
                  />
                </FormField>
                <FormField
                  isOptional
                  description="If set, Hightouch will run dbt commands with these environment variables."
                  label="Custom environment variables"
                >
                  <Controller
                    name="env_vars"
                    render={({ field }) => (
                      <KeyValueMapping
                        mapping={field.value}
                        setMapping={(map) => {
                          field.onChange(map);
                        }}
                      />
                    )}
                  />
                </FormField>
              </Column>
            </Column>
          </Column>
        </DrawerBody>

        <DrawerFooter>
          <ButtonGroup>
            <PermissionedButton
              permission={{
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              variant="primary"
              size="lg"
              isDisabled={!isDirty}
              isLoading={isSubmitting}
              onClick={handleSubmit(submit)}
            >
              Save changes
            </PermissionedButton>
            <PermissionedButton
              permission={{
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              tooltip="Re-compile the latest commit of your dbt repository using your configuration"
              size="lg"
              isDisabled={
                !config?.enabled ||
                (config.version
                  ? checkBelowMinimumDbtVersion(config.version, 1.0)
                  : undefined)
              }
              isLoading={updateLoading || !!config?.full_resync}
              onClick={handleSubmit(fullResync)}
            >
              Full resync
            </PermissionedButton>
          </ButtonGroup>
        </DrawerFooter>
      </FormProvider>
    </Drawer>
  );
};
