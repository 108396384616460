import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { CodeParams, SetupProps } from "./types";

function getPythonCode(params: CodeParams): string {
  return `
  import hightouch.htevents as htevents

  htevents.write_key = '${params.writeKey}'
  htevents.host = 'https://${params.apiHost}'
  
  htevents.identify('userId1', {
      'email': 'bat@example.com',
      'name': 'Person People',
  })
  
  htevents.track('userId1', 'Order Completed', {})
  `;
}

const pipInstall = "pip install events-sdk-python";

export const SetupPython: FC<SetupProps> = (props: SetupProps) => {
  return (
    <>
      <Heading>Installing the SDK</Heading>
      <Text>
        This SDK is available via{" "}
        <Link href="https://pypi.org/project/events-sdk-python/">pypi.org</Link>
        . See the package description for further documentation.
      </Text>
      <CodeSnippet code={pipInstall} />
      <Heading>Using the SDK</Heading>
      <CodeSnippet label="app.py" code={getPythonCode(props)} />
    </>
  );
};
