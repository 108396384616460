import { FC, useEffect, useState } from "react";

import {
  Row,
  Box,
  Button,
  ButtonGroup,
  FormField,
  Paragraph,
  Switch,
  Text,
  TextInput,
  useToast,
  Dialog,
  Heading,
} from "@hightouchio/ui";
import { Link } from "src/router";
import Ajv from "ajv";
import jsonSourceMap from "json-source-map";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Editor } from "src/components/editor/editor";
import { FeatureFull } from "src/components/feature-gates";
import { PermissionProvider } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useAddWorkspaceRoleMutation,
  useWorkspaceRolesQuery,
  useUpdateWorkspaceRoleMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Table } from "src/ui/table";
import { getlineNumberofChar } from "src/utils/json";

import { RolePermissionBuilder } from "./components/role-permission-builder";
import { PermissionedButton } from "src/components/permission";

export const resources = [
  "*",
  "workspace",
  "destination",
  "source",
  "model",
  "sync",
  "audience",
  "audience_schema",
  "sync_template",
  "workspace_membership",
  "alert",
];

export const actions = ["*", ...Object.values(ResourcePermissionGrant)];

type Resources = (typeof resources)[number];

type Actions = (typeof actions)[number];

export interface Permission {
  effect: "allow" | "deny";
  actions: Actions | Actions[];
  resource: Resources | Resources[];
  conditions?: {
    [key: string]: {
      in?: string | number | boolean;
      notin?: string | number | boolean;
      greaterthan?: string | number | boolean;
      lessthan?: string | number | boolean;
      exists?: string | number | boolean;
      equals?: string | number | boolean;
    };
  };
}

interface Policy {
  version: string;
  policies: Permission[];
}

interface Role {
  id: string;
  name: string;
  permissions: Policy;
  readonly?: boolean;
}

const policyJsonSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    version: {
      type: "string",
    },
    policies: {
      items: {
        type: "object",
        properties: {
          effect: {
            type: "string",
            enum: ["allow", "deny"],
          },
          actions: {
            oneOf: [
              {
                type: "string",
                enum: ["*", ...Object.values(ResourcePermissionGrant)],
              },
              {
                type: "array",
                items: {
                  type: "string",
                  enum: Object.values(ResourcePermissionGrant),
                },
              },
            ],
          },
          resource: {
            oneOf: [
              {
                type: "string",
                enum: [
                  "*",
                  "workspace",
                  "destination",
                  "source",
                  "model",
                  "sync",
                  "audience",
                  "audience_schema",
                  "sync_template",
                  "workspace_membership",
                  "alert",
                ],
              },
              {
                type: "array",
                items: {
                  type: "string",
                  enum: [
                    "workspace",
                    "destination",
                    "source",
                    "model",
                    "sync",
                    "audience",
                    "audience_schema",
                    "sync_template",
                    "workspace_membership",
                    "alert",
                  ],
                },
              },
            ],
          },
          conditions: {
            type: "object",
            patternProperties: {
              "^.*$": {
                type: "object",
                patternProperties: {
                  "^(greaterthan|lessthan|exists|equals|notequals)$": {
                    oneOf: [
                      {
                        type: "string",
                      },
                      {
                        type: "number",
                      },
                      {
                        type: "boolean",
                      },
                    ],
                  },
                  "^(in|notin)$": {
                    type: "array",
                  },
                },
                additionalProperties: false,
              },
            },
            additionalProperties: false,
          },
        },
        required: ["effect", "actions", "resource"],
      },

      type: "array",
    },
  },
};

const DEFAULT_POLICY: Policy = {
  version: "2022-04-26",
  policies: [{ effect: "allow", actions: "*", resource: "*" }],
};
const DEFAULT_EMPTY_POLICY: Policy = { version: "2022-04-26", policies: [] };

const placeholder = {
  title: "No roles",
  error: "Roles failed to load, please try again.",
};

const legacyRoles = {
  admin: '{"read":true,"admin":true,"write":true}',
  editor: '{"read":true,"write":true}',
  viewer: '{"read":true,"write":false}',
};
const newRoles: Record<"admin" | "editor" | "viewer", Policy> = {
  admin: {
    version: "2022-04-26",
    policies: [
      {
        effect: "allow",
        actions: "*",
        resource: "*",
      },
    ],
  },
  editor: {
    version: "2022-04-26",
    policies: [
      {
        effect: "allow",
        actions: "*",
        resource: [
          "destination",
          "source",
          "model",
          "sync",
          "audience",
          "audience_schema",
          "sync_template",
          "workspace_membership",
          "alert",
        ],
      },
    ],
  },
  viewer: {
    version: "2022-04-26",
    policies: [
      {
        effect: "allow",
        actions: ["read"],
        resource: [
          "destination",
          "source",
          "model",
          "sync",
          "audience",
          "audience_schema",
          "sync_template",
          "workspace_membership",
          "alert",
        ],
      },
    ],
  },
};

const newReadonlyRoleNames = [
  "Audience Editor",
  "Model & Sync Editor",
  "Model Sync Editor",
  "Workspace Editor",
  "Workspace Draft Contributor",
  "Workspace Viewer",
  "Sync Editor",
  "Source Admin",
  "Destination Admin",
];

const DEFAULT_NEW_ROLE = {
  name: "Custom role",
  permissions: JSON.parse(JSON.stringify(DEFAULT_EMPTY_POLICY)),
};

export const Roles = () => {
  const { workspace } = useUser();
  const { appAllowRoles } = useFlags();

  const [editingRole, setEditingRole] = useState<Partial<Role>>(
    JSON.parse(JSON.stringify(DEFAULT_NEW_ROLE)),
  );
  const [insertRole, setInsertRole] = useState<boolean>(false);

  const { isLoading, data: roles } = useWorkspaceRolesQuery(
    { workspaceId: workspace?.id },
    {
      select: (data) =>
        data.workspaces_by_pk?.roles.sort((a, b) =>
          Number(a.id) > Number(b.id) ? 1 : -1,
        ),
    },
  );

  const addRole = () => {
    setInsertRole(true);
  };

  const editRole = (role: Role) => {
    const permissionStr = JSON.stringify(role.permissions);
    if (legacyRoles.admin === permissionStr) {
      setEditingRole({
        ...role,
        permissions: newRoles.admin,
        readonly: true,
      });
    } else if (legacyRoles.editor === permissionStr) {
      setEditingRole({
        ...role,
        permissions: newRoles.editor,
        readonly: true,
      });
    } else if (legacyRoles.viewer === permissionStr) {
      setEditingRole({
        ...role,
        permissions: newRoles.viewer,
        readonly: true,
      });
    } else {
      if (newReadonlyRoleNames.includes(role.name)) {
        setEditingRole({
          ...role,
          readonly: true,
        });
      } else {
        setEditingRole({ ...role });
      }
    }
  };

  const addRoleDisabled =
    workspace?.organization?.plan?.sku !== "business_tier" && !appAllowRoles;

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "workspace",
          grant: "can_update",
        },
      }}
    >
      <FeatureFull
        enabled={!addRoleDisabled}
        featureDetails={{
          pitch: "Enterprise-grade role based access control (RBAC)",
          description:
            "Advanced roles gives admins fine-grained access control over the resources users can create and edit. This enables you to invite more teammates onto Hightouch while ensuring your business systems are protected from misuse.",
          bullets: [
            "Choose from more than 10 out-of-the-box roles (like Sync Editor, Audience Editor)",
            "Create your own custom roles using a flexible JSON policy builder",
            "Recommended for businesses that need to invite multiple teams onto Hightouch",
          ],
          image: {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/fb89c01bb9d10199cc7fc0679b1f699a4721deab-1236x592.png",
          },
        }}
        featureName="advanced roles"
      >
        <>
          <Row justifyContent="space-between" mb={8}>
            <Box maxWidth="2xl">
              <Heading>Roles</Heading>
              <Paragraph mt={1}>
                Manage custom roles that govern which actions a user can perform
                in this workspace. Learn more in our{" "}
                <Link href="https://hightouch.com/docs/workspace-management/rbac">
                  docs
                </Link>
                .
              </Paragraph>
            </Box>

            <PermissionedButton
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              variant="primary"
              onClick={addRole}
            >
              Add custom role
            </PermissionedButton>
          </Row>

          <Table
            columns={[
              {
                name: "Role",
                cell: ({ name }) => name,
              },
            ]}
            data={roles ?? []}
            loading={isLoading}
            placeholder={placeholder}
            onRowClick={editRole}
          />
        </>
      </FeatureFull>

      <RoleModal
        close={() => {
          setEditingRole(JSON.parse(JSON.stringify(DEFAULT_NEW_ROLE)));
          setInsertRole(false);
        }}
        insert={insertRole}
        open={insertRole || Boolean(editingRole.id)}
        role={editingRole}
        workspaceId={workspace?.id}
      />
    </PermissionProvider>
  );
};

interface RoleModalProps {
  workspaceId: string;
  role: Partial<Role>;
  insert: boolean;
  close: () => void;
  open: boolean;
}

export const RoleModal: FC<RoleModalProps> = ({
  workspaceId,
  role,
  insert,
  close,
  open,
}) => {
  const { toast } = useToast();
  const [editingRole, setRole] = useState<Partial<Role>>(role);
  const { isLoading: updating, mutateAsync: updateRole } =
    useUpdateWorkspaceRoleMutation();
  const { isLoading: creating, mutateAsync: addRole } =
    useAddWorkspaceRoleMutation();

  const mustUseJsonEditor =
    role.permissions?.policies.some((policy) => policy.effect === "deny") ||
    role?.readonly ||
    false;

  const [jsonEditor, setJsonEditor] = useState(!mustUseJsonEditor);
  const [policy, setPolicy] = useState<string>(
    JSON.stringify(role?.permissions ?? DEFAULT_EMPTY_POLICY, null, 2),
  );
  const [policyErrorLine, setPolicyErrorLine] = useState<number>();

  const checkValidPolicy = (permissions = "{}", shouldSetRole?: boolean) => {
    try {
      const parsed = JSON.parse(permissions);
      const validator = new Ajv({
        allErrors: true, // do not bail, optional
        jsonPointers: true, // totally needed for this
      });
      const valid = validator.validate(policyJsonSchema, parsed);
      if (!valid) {
        let errorMessage = "";
        const sourceMap = jsonSourceMap.parse(permissions);
        const error = validator.errors?.[0];
        if (error) {
          errorMessage += "\n\n" + validator.errorsText([error]);
          if (error.params?.["allowedValues"]) {
            errorMessage += `: ${error.params?.["allowedValues"].join(", ")}`;
          }
          const errorPointer = sourceMap.pointers[error.dataPath];
          setPolicyErrorLine(errorPointer.value.line + 1);
        }
        return errorMessage;
      } else {
        if (shouldSetRole) {
          setRole({ ...editingRole, permissions: parsed });
        }
        setPolicyErrorLine(0);
        return "";
      }
    } catch (e) {
      console.error(e);
      setPolicyErrorLine(
        getlineNumberofChar(
          permissions,
          parseInt(e.message.split("at position ")[1]),
        ),
      );
      return "Error: Can not parse JSON";
    }
  };
  const [policyErrors, setPolicyErrors] = useState<string>(() => {
    return checkValidPolicy(policy, false);
  });

  const setNewPolicy = (permissions?: string, shouldSetRole?: boolean) => {
    const permissionToSet = permissions
      ? permissions
      : JSON.stringify(
          jsonEditor ? DEFAULT_POLICY : DEFAULT_EMPTY_POLICY,
          null,
          2,
        );
    setPolicy(permissionToSet);
    setPolicyErrors(checkValidPolicy(permissionToSet, shouldSetRole));
  };

  useEffect(() => {
    // when toggling between the json editor, always set the existing role permissions if they exist.
    setNewPolicy(JSON.stringify(role.permissions, null, 2));
  }, [jsonEditor]);

  useEffect(() => {
    setRole(role);
    setNewPolicy(JSON.stringify(role.permissions, null, 2));
  }, [role]);

  const save = async () => {
    setNewPolicy(JSON.stringify(editingRole.permissions, null, 2));
    if (insert) {
      try {
        await addRole({
          name: editingRole.name ?? "",
          permissions: editingRole.permissions,
        });

        toast({
          id: "workspace-role",
          title: "Workspace role added",
          variant: "success",
        });

        analytics.track("Role Added", {
          workspace_id: workspaceId,
          name: editingRole.name,
        });
      } catch (_err: unknown) {
        toast({
          id: "workspace-role",
          title: "Something went wrong",
          message: "Failed to add a new workspace role, please try again.",
          variant: "error",
        });
      }
    } else if (role.id) {
      try {
        await updateRole({
          roleId: role.id,
          name: editingRole.name ?? "",
          permissions: editingRole.permissions,
        });

        toast({
          id: "workspace-role",
          title: "Workspace role updated",
          variant: "success",
        });

        analytics.track("Role Updated", {
          workspace_id: workspaceId,
          name: editingRole.name,
        });
      } catch (_err: unknown) {
        toast({
          id: "workspace-role",
          title: "Something went wrong",
          message: "Failed to add a new workspace role, please try again.",
          variant: "error",
        });
      }
    }
    handleClose();
  };

  const handleClose = () => {
    close();
  };

  const handleChangePolicy = (value: string) => {
    setNewPolicy(value, true);
  };

  return (
    <Dialog
      isOpen={open}
      variant="form"
      width="xl"
      title={`Manage role: ${role.name}`}
      actions={
        <ButtonGroup>
          <Button onClick={handleClose}>Close</Button>
          {role.readonly ? null : (
            <Button
              isDisabled={Boolean(policyErrors) || !editingRole.name}
              isLoading={updating || creating}
              variant="primary"
              onClick={save}
            >
              {insert ? "Add" : "Save"}
            </Button>
          )}
        </ButtonGroup>
      }
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        <FormField label="Name">
          <TextInput
            isDisabled={editingRole.readonly}
            isReadOnly={editingRole.readonly}
            placeholder="Enter a name..."
            value={editingRole.name ?? ""}
            onChange={(event) => {
              setRole({ ...(editingRole || {}), name: event.target.value });
            }}
          />
        </FormField>

        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Text fontWeight="medium">Policy</Text>
              <Paragraph mt={1}>
                Learn more in our{" "}
                <Link href="https://hightouch.com/docs/workspace-management/rbac#custom-roles">
                  documentation
                </Link>
                .
              </Paragraph>
            </Box>

            {!mustUseJsonEditor && (
              <Box alignItems="center" display="flex" gap={2}>
                <Switch isChecked={jsonEditor} onChange={setJsonEditor} />
                <Text>JSON builder</Text>
              </Box>
            )}
          </Box>

          <Box mt={2}>
            {jsonEditor ? (
              <Box
                _focusWithin={{ borderColor: "forest.600" }}
                _hover={{ borderColor: "gray.500" }}
                border="1px"
                borderColor="gray.400"
                borderRadius="md"
                height="80"
                transition="all"
                transitionDuration="faster"
              >
                <Editor
                  highlightErroredLine={policyErrorLine}
                  language="json"
                  placeholder="Enter a policy..."
                  readOnly={editingRole.readonly}
                  value={policy}
                  onChange={handleChangePolicy}
                />
              </Box>
            ) : (
              <RolePermissionBuilder
                permissions={JSON.parse(policy).policies}
                setPermissions={(permissions) => {
                  setNewPolicy(
                    JSON.stringify({
                      version: DEFAULT_POLICY.version,
                      policies: permissions,
                    }),
                    true,
                  );
                }}
              />
            )}
          </Box>

          {policyErrors}
        </Box>
      </Box>
    </Dialog>
  );
};
