import { FC } from "react";

import {
  Box,
  Column,
  ExternalLinkIcon,
  GroupedCombobox,
  IconButton,
  Row,
  SectionHeading,
  Tooltip,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useFormContext } from "react-hook-form";
import { noop } from "ts-essentials";

import { BaseGlobeIcon } from "src/pages/analytics/common";
import { useAnalyticsContext } from "src/pages/analytics/state";
import {
  ChartFormState,
  MeasurementScope,
  MeasuringSelection,
} from "src/pages/analytics/types";
import { Link } from "src/router";
import { isPresent } from "src/types/utils";
import { NULL_OPTION_VALUE } from "../state/constants";
import { MeasureIncrementality } from "./decision-engine/measure-incrementality";

const getMeasurementScopeTooltip = (scope: MeasurementScope) => {
  switch (scope) {
    case MeasurementScope.DecisionEngineFlow:
      return "View flow";
    case MeasurementScope.AllData:
    default:
      return NULL_OPTION_VALUE;
  }
};

const getMeasurementScopeLink = (measurement: MeasuringSelection) => {
  switch (measurement?.scope) {
    case MeasurementScope.DecisionEngineFlow:
      return `/ai/flows/${measurement.id}`;
    case MeasurementScope.AllData:
    default:
      return "";
  }
};

export const Measurements: FC = () => {
  const { decisionInsightsEnabled } = useFlags();

  const { selectMeasurement, measurementsOptions, measurementsOptionsLoading } =
    useAnalyticsContext();

  const form = useFormContext<ChartFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress circular reference error
  const selectedMeasurement = form.watch("measuringSelection");

  const optionGroups = [
    {
      label: "All data",
      options: [
        {
          label: "All users and events",
          value: {
            id: NULL_OPTION_VALUE, // Option value can't be null
            scope: MeasurementScope.AllData,
          },
          icon: BaseGlobeIcon,
        },
      ],
    },
    ...(measurementsOptions ?? []).map(({ groupLabel, options }) => ({
      label: groupLabel,
      options: options.map((o) => ({
        label: o.label,
        value: {
          id: o.id,
          scope: o.scope,
        },
        icon: o.icon,
      })),
    })),
  ].filter(isPresent);

  if (!decisionInsightsEnabled) {
    return null;
  }

  return (
    <Column gap={2}>
      <Row align="center" justifyContent="space-between">
        <SectionHeading>Measuring</SectionHeading>
      </Row>

      <Column
        bg="white"
        gap={2}
        p={2}
        border="1px solid"
        borderColor="base.border"
        borderRadius="md"
      >
        <Box display="grid" gridTemplateColumns="1fr max-content" gap={2}>
          <Controller
            control={form.control}
            name="measuringSelection"
            render={({ field }) => (
              <GroupedCombobox
                isLoading={measurementsOptionsLoading}
                value={field.value}
                optionGroups={optionGroups}
                placeholder="Select a measurement..."
                optionAccessory={(option) => ({
                  type: "icon",
                  icon: option.icon,
                })}
                onChange={(value) => {
                  if (
                    !isPresent(value) ||
                    value.id === selectedMeasurement?.id
                  ) {
                    return;
                  }

                  selectMeasurement(value);
                }}
                width="100%"
              />
            )}
          />

          {selectedMeasurement &&
            selectedMeasurement.scope !== MeasurementScope.AllData && (
              <Row alignItems="center" width="fit-content">
                <Link
                  href={getMeasurementScopeLink(selectedMeasurement)}
                  isExternal
                >
                  <Tooltip
                    message={getMeasurementScopeTooltip(
                      selectedMeasurement.scope,
                    )}
                  >
                    <IconButton
                      icon={ExternalLinkIcon}
                      aria-label="Link to selected audience"
                      variant="tertiary"
                      onClick={noop}
                    />
                  </Tooltip>
                </Link>
              </Row>
            )}
        </Box>

        {selectedMeasurement &&
          selectedMeasurement.scope === MeasurementScope.DecisionEngineFlow && (
            <MeasureIncrementality measuringSelection={selectedMeasurement} />
          )}
      </Column>
    </Column>
  );
};
