import { Column, Heading, Row, Text } from "@hightouchio/ui";

import { ActionBar } from "src/components/action-bar";
import { DocsLink } from "src/components/docs-link";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { ResourceGrantSelect } from "src/components/grant-select";
import { Grants } from "src/components/grant-select/types";
import { convertGrants } from "src/components/grant-select/util";
import { useUpdateDestinationV2Mutation } from "src/graphql";
import { useParams } from "src/router";

export const Element = () => {
  const { destination_id: id } = useParams<{ destination_id: string }>();
  const { mutateAsync: updateDestination } = useUpdateDestinationV2Mutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await updateDestination({
        id: String(id),
        grants: convertGrants(data.grants),
      });
    },

    defaultValues: {
      grants: {} as Grants<"destination">,
    },
  });

  return (
    <Form form={form}>
      <Column flex={1} gap={2}>
        <Row align="center" justifyContent="space-between">
          <Heading>Permissions</Heading>
          <DocsLink
            name="permission management"
            href="/workspace-management/roles#destination"
          />
        </Row>
        <Text mb={2}>
          Use the checkboxes below to configure which actions each user group
          can perform for this source and its related resources.
        </Text>
        <ResourceGrantSelect id={id!} type="destination" />
      </Column>
      <ActionBar>
        <FormActions
          permission={{
            v2: {
              resource: "destination",
              grant: "can_update",
              id: id!,
            },
          }}
        />
      </ActionBar>
    </Form>
  );
};
