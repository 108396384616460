import { Fragment, FC, ReactElement } from "react";

import { Box, Row, BoxProps, ChevronLeftIcon } from "@hightouchio/ui";

import { RouterLink } from "src/router";

export type Crumb = {
  label: string | ReactElement;
  link?: string;
};

type Props = {
  crumbs: Crumb[];
  p?: BoxProps["p"];
};

export const Breadcrumbs: FC<Readonly<Props & BoxProps>> = ({
  crumbs,
  p = "16px 24px",
  ...rest
}) => {
  return (
    <Row
      fontSize="sm"
      fontWeight="medium"
      gap={2}
      mx="auto"
      overflow="hidden"
      color="text.secondary"
      p={p}
      width="100%"
      align="center"
      {...rest}
    >
      {crumbs.map(({ label, link }, index) => (
        <Fragment key={index}>
          {link ? (
            <Box
              sx={{
                a: { color: "text.secondary", fontSize: "inherit" },
                "a:hover": { color: "link.default" },
              }}
            >
              <RouterLink to={link}>
                <Row align="center">
                  {index === 0 && (
                    <Box as={ChevronLeftIcon} boxSize={4} color="inherit" />
                  )}
                  {label}
                </Row>
              </RouterLink>
            </Box>
          ) : (
            <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
              {label}
            </Box>
          )}
          {index < crumbs.length - 1 && <Box flexShrink={0}>/</Box>}
        </Fragment>
      ))}
    </Row>
  );
};
