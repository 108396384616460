import { useEffect, FC } from "react";

import { Heading, useToast } from "@hightouchio/ui";
import { useNavigate, useParams } from "src/router";

import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { DetailPage } from "src/components/layout";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { ResourceToPermission, useModelQuery, ModelQuery } from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { ModelState, useModelRun, useUpdateQuery } from "src/utils/models";
import { useModelState } from "src/hooks/use-model-state";

export const ModelQueryWrapper: FC = () => {
  const { model_id: modelId } = useParams<{ model_id: string }>();
  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: modelId ?? "",
    },
    { enabled: Boolean(modelId) },
  );
  const navigate = useNavigate();

  if (modelLoading || !modelData?.segments_by_pk) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={modelData.segments_by_pk?.draft || false}
      resourceId={modelId}
      resourceType={ResourceToPermission.Model}
      onPublish={() => navigate(`/models/${modelId}`)}
    >
      <ModelQueryPage model={modelData.segments_by_pk} />
    </DraftProvider>
  );
};

interface Props {
  model: NonNullable<ModelQuery["segments_by_pk"]>;
}

const ModelQueryPage: FC<Props> = ({ model }: Props) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const modelState = useModelState(model);

  const {
    draft,
    editingDraft,
    editingDraftChanges,
    mergeResourceWithDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const update = useUpdateQuery();

  const {
    runQuery,
    getSchema,
    cancelQuery,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
    asyncPagination,
    rowsCount,
    page,
    setPage,
  } = useModelRun(modelState.state);

  useEffect(() => {
    if (draft && model) {
      const copy = mergeResourceWithDraft(model);
      modelState.set(copy as ModelState);
    } else {
      modelState.set(model);
    }
  }, [model, editingDraft]);

  const link =
    `/models/${model.id}` + (editingDraftChanges ? "?editing=true" : "");

  const save = async (data) => {
    await update({
      model: modelState.state,
      columns: data?.columns ?? rawColumns,
      overwriteMetadata: true,
      isDraft: model.draft,
      onUpdate: () => {
        toast({
          id: "update-model-query",
          title: "Query was updated",
          variant: "success",
        });
        navigate(link);
      },
    });
  };

  return (
    <DetailPage
      outsideTopbar={
        draft && (
          <EditingDraftWarning
            draft={draft}
            editingDraft={editingDraft}
            resourceType={ResourceToPermission.Model}
            setEditingDraft={setEditingDraft}
            sx={{ top: 0 }}
            onViewDraft={onViewDraft}
          />
        )
      }
      hasBottomPadding
      title={`${model.name ?? "Unnamed model"} - Models`}
      header={<Heading size="xl">{model.name ?? "Unnamed model"}</Heading>}
      crumbs={[
        { label: "All models", link: "/models" },
        {
          label: model?.name ?? "",
          link,
        },
      ]}
    >
      <ExploreWithSave
        modelState={modelState}
        asyncPagination={asyncPagination}
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        page={page}
        rows={rows}
        rowsCount={rowsCount}
        runQuery={runQuery}
        source={model.connection}
        onPageChange={setPage}
        onSave={save}
        rowsPerPage={100}
      />
    </DetailPage>
  );
};
