import { Box, BoxProps } from "@hightouchio/ui";

import { Card, CardProps } from "src/components/card";

export type ChartCardProps = {
  onClose: () => void;
};

export const ChartCard = (props: CardProps) => (
  <Card p={4} gap={4} {...props} />
);

export const LoadingBar = (props: BoxProps) => (
  <Box
    className="hightouch-ui-skeleton-box"
    height={4}
    borderRadius="sm"
    {...props}
  />
);
