// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Navigate } from "react-router-dom";

import { useUser } from "src/contexts/user-context";

export const SlugIndex = () => {
  const { slug, onboarding } = useUser();

  return (
    <Navigate
      replace
      to={onboarding ? `/${slug}/onboarding` : `/${slug}/home`}
    />
  );
};
