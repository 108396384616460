import { createContext, FC, ReactNode, useEffect, useMemo } from "react";

import { useParams } from "src/router";
import { isPresent } from "ts-extras";

import { ColumnOption } from "src/components/explore/visual/utils";
import { Form } from "src/components/form";
import { PageSpinner } from "src/components/loading";
import {
  ChartDefinition,
  SavedAnalyticsChartQuery,
  useSavedAnalyticsChartQuery,
} from "src/graphql";
import { chartTypeToGraphType } from "src/pages/copilot/constants";

import {
  AnalyticsContextType,
  GroupByColumn,
  TimeOptions,
} from "src/pages/analytics/types";
import {
  AnalyticsFrequency,
  isRawColumn,
  isRelatedColumn,
} from "src/types/visual";

import { transformSavedChartTabToFormChartTab } from "../utils";
import {
  DEFAULT_FUNNEL_CONVERSION_WINDOW,
  defaultActions,
  defaultFormState,
  defaultSchemaState,
} from "./constants";
import { useChartState } from "./use-chart-state";
import { useChartDependencies } from "./use-chart-dependencies";
import {
  getChartStateFromChartDefinition,
  getSyntheticColumnValuesGetter,
} from "./utils";
import { useMeasurementColumns } from "../hooks/use-measurement-columns";
import { useMeasuringMetricResources } from "../hooks/use-measuring-metric-resources";

// XXX: This is a transformation function for charts that saved the bar graph type, where
// the frequency is set to `all`. Since we default to line graph type, we need to transform
// this frequency until the saved_analytics_charts table supports saving graph types
const transformSavedChartFrequency = (frequency: string) => {
  if (frequency === "all") {
    return AnalyticsFrequency.Daily;
  }

  return frequency as AnalyticsFrequency;
};

type Props = {
  children: ReactNode;
  columns?: ColumnOption[];
};

const defaultContextValue: AnalyticsContextType = {
  ...defaultSchemaState,
  ...defaultActions,
};

export const AnalyticsContext =
  createContext<AnalyticsContextType>(defaultContextValue);

const formatSavedChartData = (
  savedChart: SavedAnalyticsChartQuery["saved_analytics_charts_by_pk"],
) => {
  if (savedChart) {
    return {
      ...savedChart,
      chartTab: transformSavedChartTabToFormChartTab(savedChart.chart_type),
      lookbackWindow: savedChart.lookback_window,
      parentModelId: savedChart.parent_model?.id,
      groupBy: savedChart.group_by,
      frequency: transformSavedChartFrequency(savedChart.frequency),
    };
  }

  return savedChart;
};

export const AnalyticsFormStateProvider: FC<Readonly<Props>> = ({
  children,
}) => {
  const { id: savedChartId } = useParams<{ id: string }>();

  const savedAnalyticsChartQuery = useSavedAnalyticsChartQuery(
    { id: savedChartId ?? "" },
    {
      enabled: Boolean(savedChartId),
      select: (data) => formatSavedChartData(data.saved_analytics_charts_by_pk),
    },
  );

  const savedChart = savedAnalyticsChartQuery.data;

  const { form, ...value } = useChartState({
    useUrlState: !savedChartId,
  });

  const parentModelId = form.watch("parentModelId");
  const measuringSelection = form.watch("measuringSelection");
  const measuringMode = form.watch("measuringMode");

  const chartDependencies = useChartDependencies({
    parentModelId,
  });

  const {
    columnOptions,
    additionalUserColumns,
    isLoading: isMeasurementColumnsLoading,
  } = useMeasurementColumns(measuringSelection, measuringMode);

  const measuringMetricResources = useMeasuringMetricResources({
    measuringSelection,
  });

  const syntheticColumnValuesGetter = getSyntheticColumnValuesGetter(
    measuringSelection,
    measuringMetricResources,
  );

  useEffect(() => {
    // Set parent model as the initial selection if none already selected
    if (!parentModelId && chartDependencies.parentModelOptions) {
      const firstOption = chartDependencies.parentModelOptions[0];
      if (firstOption) {
        value.selectParentModel(firstOption.value);
      }
    }
  }, [chartDependencies.parentModelOptions, parentModelId]);

  // Reset form state to saved chart state
  useEffect(() => {
    if (savedChart) {
      const savedChartState = getChartStateFromChartDefinition(
        {
          ...savedChart,
          chart_type: savedChart.chartTab,
          parent_model_id: savedChart.parent_model?.id,
        },
        chartDependencies,
      );
      if (savedChartState) {
        form.reset({
          ...defaultFormState,
          ...savedChartState,
          parentModelId: savedChart.parentModelId,
        });
      }
    }
  }, [savedChart, chartDependencies]);

  return (
    <AnalyticsContext.Provider
      value={{
        ...value,
        audiences: chartDependencies.audienceOptions,
        metrics: chartDependencies.metricOptions,
        events: chartDependencies.events,
        parent: chartDependencies.parentModel,
        measurementsOptions: chartDependencies.measurementOptions,
        measurementColumnOptions: columnOptions,
        additionalUserColumns,
        measuringMetricResources,
        syntheticColumnValuesGetter,
        audiencesAndMetricsLoading: chartDependencies.dependenciesLoading,
        parentModelLoading: chartDependencies.parentModelLoading,
        parentModelOptions: chartDependencies.parentModelOptions,
        parentModelOptionsLoading: chartDependencies.parentModelOptionsLoading,
        measurementsOptionsLoading:
          chartDependencies.measurementsOptionsLoading,
        measurementColumnsLoading: isMeasurementColumnsLoading,
      }}
    >
      {savedAnalyticsChartQuery.isLoading ? (
        <PageSpinner />
      ) : (
        <Form form={form}>{children}</Form>
      )}
    </AnalyticsContext.Provider>
  );
};

// TODO(samuel): move this into copilot, so that there may only be one provider
export const AnalyticsCopilotProvider: FC<
  Readonly<{ chartDefinition: ChartDefinition; children: ReactNode }>
> = ({ chartDefinition, children }) => {
  const { parentModelId: stringParentModelId } = chartDefinition;
  const parentModelId = Number(stringParentModelId);

  const goals = useMemo(
    () => [
      ...(chartDefinition.chartVariables.metricIds ?? []).map((id) => ({
        goal: { id },
      })),
      ...chartDefinition.chartVariables.metricDefinitions.map((definition) => ({
        goal_definition: {
          id: definition?.config?.relationshipId,
          ...definition,
        },
      })),
    ],
    [
      chartDefinition.chartVariables.metricIds,
      chartDefinition.chartVariables.metricDefinitions,
    ],
  );

  const cohorts = useMemo(
    () => [
      ...(chartDefinition.chartVariables.cohortIds ?? []).map(({ id }) => ({
        cohort: { id },
      })),
      ...chartDefinition.chartVariables.cohortDefinitions.map((definition) => ({
        cohort_definition: definition,
      })),
    ],
    [
      chartDefinition.chartVariables.cohortIds,
      chartDefinition.chartVariables.cohortDefinitions,
    ],
  );

  const groupBy = useMemo(
    () =>
      chartDefinition.chartVariables.groupByColumns
        .map((column: GroupByColumn) => {
          if (column.type === "raw") {
            return {
              ...column,
              // Backend returns strings here
              modelId: Number(column.modelId),
            };
          }

          if (isRelatedColumn(column) && isRawColumn(column.column)) {
            return {
              ...column,
              column: {
                ...column.column,
                // Backend returns strings here
                modelId: Number(column.column.modelId),
              },
            };
          }

          return null;
        })
        .filter(isPresent),
    [chartDefinition.chartVariables.groupByColumns],
  );

  const lookbackWindow = useMemo(
    () => ({
      lookback:
        chartDefinition.chartVariables.lookbackWindow ?? TimeOptions.SevenDays,
    }),
    [chartDefinition.chartVariables.lookbackWindow],
  );

  const { form, ...value } = useChartState({
    useUrlState: false,
  });

  const chartDependencies = useChartDependencies({ parentModelId });

  useEffect(() => {
    if (chartDefinition) {
      const savedChartState = getChartStateFromChartDefinition(
        {
          name: chartDefinition.chartName,
          description: null,
          frequency: chartDefinition.chartVariables.frequency,
          chart_type: transformSavedChartTabToFormChartTab(
            chartTypeToGraphType[chartDefinition.chartType],
          ),
          lookback_window: lookbackWindow,
          group_by: groupBy,
          parent_model_id: parentModelId,
          goals,
          cohorts,
          funnel_stages: [],
          // TODO: Add this from backend response
          // Copilot only works with performance and breakdown graphs.
          // Funnels aren't supported and the backend doesn't provide this property
          conversion_window: DEFAULT_FUNNEL_CONVERSION_WINDOW,
        },
        chartDependencies,
      );

      if (savedChartState) {
        form.reset({
          ...defaultFormState,
          ...savedChartState,
          parentModelId,
        });
      }
    }
  }, [chartDefinition, chartDependencies]);

  return (
    <AnalyticsContext.Provider
      value={{
        ...value,
        audiences: chartDependencies.audienceOptions,
        metrics: chartDependencies.metricOptions,
        events: chartDependencies.events,
        parent: chartDependencies.parentModel,
        audiencesAndMetricsLoading: chartDependencies.dependenciesLoading,
        parentModelLoading: chartDependencies.parentModelLoading,
        parentModelOptions: chartDependencies.parentModelOptions,
        parentModelOptionsLoading: chartDependencies.parentModelOptionsLoading,
      }}
    >
      <Form form={form}>{children}</Form>
    </AnalyticsContext.Provider>
  );
};
