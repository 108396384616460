/**
 * Separate type file for types that may be shared with the app.
 * Prevents pulling in unnecessary types into CI.
 */
import type { Transformation } from "../../types.ts";

// Convenience aliases to make it more obvious when passing around a string that
// represents something specific.
export type UUID = string;
export type ColumnName = string;

export type IDRv2IdentifierMergeRule = {
  identifier: string;
  transformations?: Transformation[];
};

export type IDRv2MergeRules = {
  identifiers: IDRv2IdentifierMergeRule[];
};

export type IDRv2LimitRules = {
  [identifier: string]: {
    limit: number;
  };
};

export type IDRv2Run = {
  id: UUID;
  previousRunStats?: IDRv2Stats;
  isFullRerun?: boolean;
};

export interface IDRv2Stats {
  models: ModelStats[];
  identifiers: IdentifierStats[];
  num_ht_ids: number;
  source_rows: number;
  new_num_ht_ids: number;
}

export interface ModelStats {
  id: string;
  name: string;
  source_rows: number;
  num_ht_ids: number;
}

export interface IdentifierStats {
  name: string;
  source_rows: number;
  num_ht_ids: number;
}

export const IDRv2OutputTableSuffix = "_resolved_identifiers";

export const IDRv2GoldenRecordTableSuffix = "_golden_records";
export const IDRV2GoldenRecordHightouchIdColumnName = "ht_id";
