import { FC, ReactNode, useState } from "react";

import { SyncRequestErrorInfo } from "@hightouch/lib/sync/errors";
import {
  Box,
  Button,
  ChevronUpIcon,
  CloseIcon,
  Column,
  DrawerBody,
  ErrorIcon,
  Heading,
  IconButton,
  NumberInput,
  PlusIcon,
  RefreshIcon,
  Row,
  Slider,
  SubtractIcon,
  SuccessIcon,
  Text,
} from "@hightouchio/ui";
import { format, parseISO } from "date-fns";
import { orderBy as lodashOrderBy } from "lodash";

import {
  TargetSyncRequestsQuery,
  TargetSyncRequestsQueryVariables,
  useTargetSyncRequestsQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { Drawer } from "src/components/drawer";
import { commaNumber } from "src/utils/numbers";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import {
  DEPRECATED_ERROR,
  getSyncRunOperations,
  getSyncStatusColor,
  SyncRunStatus,
} from "src/utils/syncs";
import { openUrlInNewTab } from "src/utils/urls";
import { CollapsableFilter } from "src/components/folders/filters";
import { SyncRequestErrorModal } from "src/pages/syncs/sync/components/error-modals";
import { Sync } from "src/pages/syncs/sync/components/error-origin-info-modal";
import { TargetSyncs } from "./sync-health-charts";
import {
  doesRunSatisfy,
  EnrichedSyncRunData,
  getDestinationOptions,
  getSourceOptions,
  getStatusOptions,
  useFilteredData,
} from "./sync-request-filters";
import { EnrichedSyncMetric, enrichSyncs } from "./transformations";

export const NumberPill = ({
  value,
  variant,
}: {
  value: number;
  variant: "success" | "danger";
}) => {
  const Icon = variant === "success" ? SuccessIcon : ErrorIcon;
  return (
    <Row
      bg={`${variant}.background`}
      px={2}
      py="2px"
      border="1px solid"
      borderColor={`${variant}.base`}
      borderRadius="full"
      alignItems="center"
      gap={1}
    >
      <Icon color={`${variant}.base`} />

      <Text color={`${variant}.base`} fontWeight="medium">
        {commaNumber(value)}
      </Text>
    </Row>
  );
};

export const DiffRow: FC<{ icon: ReactNode; value: number }> = ({
  icon,
  value,
}) => {
  return (
    <Row
      alignItems="center"
      gap={2}
      color={value ? "text.primary" : "text.secondary"}
    >
      {icon}
      <Text color={value ? "text.primary" : "text.secondary"}>
        {commaNumber(value)}
      </Text>
    </Row>
  );
};

const TOP_MARGIN = 56;

enum SortKeys {
  CreatedAt = "created_at",
  QuerySize = "query_size",
  ModelName = "model_name",
  TotalOperations = "total_operations",
  FailedRows = "failed_rows",
}

export const useFastTargetSyncRequestsQuery = getQueryWithOpts<
  TargetSyncRequestsQuery,
  TargetSyncRequestsQueryVariables
>(useTargetSyncRequestsQuery, { useFastEndpoint: true });

export const SyncRequestModal: FC<{
  targetSyncs: TargetSyncs;
  isOpen: boolean;
  onClose: () => void;
  defaultFilters?: Record<string, string[]>;
}> = ({ targetSyncs, defaultFilters, isOpen, onClose }) => {
  const { data, isLoading, isRefetching } = useFastTargetSyncRequestsQuery(
    targetSyncs,
    {
      enabled: targetSyncs && targetSyncs.destinationInstanceIds.length > 0,
      select(data) {
        const enrichedSyncs = enrichSyncs(
          data.syncs,
          data.getSourceDefinitions,
          data.getDestinationDefinitions,
        );
        const syncLookup: Record<
          string,
          EnrichedSyncMetric<TargetSyncRequestsQuery["syncs"][0]>
        > = {};

        enrichedSyncs.forEach((s) => {
          syncLookup[s.id] = s;
        });

        const enrichedSyncRequests = data.sync_requests.map((syncRequest) => ({
          ...syncRequest,
          sync: syncLookup[syncRequest.destination_instance_id],
        }));

        return enrichedSyncRequests;
      },
    },
  );

  const [runError, setRunError] = useState<
    SyncRequestErrorInfo & { syncStatus: SyncRunStatus; sync: Sync }
  >();

  const { limit, offset, page, setPage, onSort, orderBy } = useTableConfig<{
    [key in SortKeys]: "asc" | "desc";
  }>({
    limit: 30,
    defaultSortKey: "created_at",
    defaultSortDirection: "asc",
    sortOptions: Object.values(SortKeys),
  });

  const [failedThreshold, setFailedThreshold] = useState(0);

  const doesRunSatisfyRowFilter = (r: EnrichedSyncRunData) => {
    const attempt = r.sync_attempts?.[0];
    if (failedThreshold === 0) {
      return true;
    }

    return (
      r.status_computed === "failed" ||
      (r.status_computed === "warning" &&
        ((attempt?.add_rejected ?? 0) +
          (attempt?.change_rejected ?? 0) +
          (attempt?.remove_rejected ?? 0)) /
          ((r.add_executed ?? 0) +
            (r.change_executed ?? 0) +
            (r.remove_executed ?? 0) || 1) >=
          failedThreshold / 100)
    );
  };

  const { filteredData, filterProps } = useFilteredData({
    data,
    filters: {
      status: { getOptions: getStatusOptions, title: "Run status" },
      destination: { getOptions: getDestinationOptions, title: "Destination" },
      source: { getOptions: getSourceOptions, title: "Source" },
    },
    applyFilters: (run, state) =>
      doesRunSatisfyRowFilter(run) &&
      doesRunSatisfy(run, "status_computed", state.status) &&
      doesRunSatisfy(run, "sync.destination.id", state.destination) &&
      doesRunSatisfy(run, "sync.segment.connection.id", state.source),
    defaultFilters,
  });

  const DEFAULT_CELL_SX = { alignItems: "flex-start", py: 3 };

  const sortedData = lodashOrderBy(
    filteredData,
    (run) => {
      const key = Object.keys(orderBy ?? {})?.[0];
      switch (key) {
        case SortKeys.CreatedAt:
          return run.created_at;
        case SortKeys.QuerySize:
          return run.query_run?.size ?? 0;
        case SortKeys.ModelName:
          return run.sync?.segment?.name;
        case SortKeys.TotalOperations:
          return run.planner_type === "all"
            ? (run.query_run?.size ?? 0)
            : (run.add_executed ?? 0) +
                (run.change_executed ?? 0) +
                (run.remove_executed ?? 0);
        case SortKeys.FailedRows: {
          const attempt = run.sync_attempts?.[0];
          return (
            (attempt?.add_rejected ?? 0) +
            (attempt?.change_rejected ?? 0) +
            (attempt?.remove_rejected ?? 0)
          );
        }
        default:
          return run.created_at;
      }
    },
    [Object.values(orderBy ?? {})?.[0] ?? "asc"],
  );

  const paginatedData = sortedData?.slice(offset, offset + limit);

  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        py={4}
        px={6}
        borderBottom="1px solid"
        borderColor="base.border"
        width="100%"
      >
        <Row alignItems="baseline" gap={4}>
          <Heading size="xl">Sync runs</Heading>
          {targetSyncs.startPeriod && (
            <Text fontWeight="medium" color="text.secondary">
              {format(parseISO(targetSyncs.startPeriod), "EE p")} to{" "}
              {targetSyncs.endPeriod
                ? format(parseISO(targetSyncs.endPeriod), "EE p")
                : "now"}
            </Text>
          )}
        </Row>
        <IconButton
          aria-label="Close modal"
          icon={CloseIcon}
          onClick={onClose}
        />
      </Row>

      <DrawerBody p={0} mt={0}>
        <Row>
          <Column
            position="sticky"
            top={0}
            height={`calc(100vh - ${TOP_MARGIN + 65}px)`}
            width="320px"
            bg="base.lightBackground"
            borderRight="1px"
            borderColor="base.border"
            overflow="auto"
            p={4}
          >
            {filterProps.map((filter) => (
              <CollapsableFilter
                key={filter.title}
                {...filter}
                isDefaultOpen={true}
              />
            ))}
            {!(isLoading || isRefetching) && (
              <Column borderBottom="1px" mb={2} borderColor="base.divider">
                <Column py={2} px={2}>
                  <Text size="sm" color="text.primary" fontWeight="medium">
                    Failed row threshold
                  </Text>
                  <Text size="sm" color="text.secondary">
                    Filters warning syncs by the % of failed rows
                  </Text>
                  <Row mt={2} gap={2}>
                    <Row flex={1} mx={2}>
                      <Slider
                        value={failedThreshold}
                        onChange={(value) => {
                          setFailedThreshold(value);
                        }}
                        aria-label="Failed row threshold."
                      />
                    </Row>
                    <Row width="50px">
                      <NumberInput
                        formatOptions={{
                          style: "percent",
                        }}
                        width="100%"
                        isReadOnly
                        value={failedThreshold / 100}
                        onChange={() => {}}
                      />
                    </Row>
                  </Row>
                </Column>
              </Column>
            )}
          </Column>
          <Column flex={1}>
            <Table
              top={0}
              onRowClick={({ id, destination_instance_id: syncId }) => {
                analytics.track("[App Dashboard] Sync Run Modal Row Clicked", {
                  syncId,
                  syncRunId: id,
                });
                openUrlInNewTab(`/syncs/${syncId}/runs/${id}`);
              }}
              rowHeight="92px"
              columns={[
                {
                  max: "5px",
                  min: "5px",
                  cellSx: { ...DEFAULT_CELL_SX, p: "0 !important" },
                  cell: ({ status_computed }) =>
                    status_computed && (
                      <Row
                        width="100%"
                        height="100%"
                        bg={getSyncStatusColor(
                          status_computed as SyncRunStatus,
                        )}
                      />
                    ),
                },
                {
                  sortDirection: orderBy?.created_at,
                  onClick: () => onSort(SortKeys.CreatedAt),
                  name: "Date",
                  cellSx: DEFAULT_CELL_SX,
                  cell: ({ created_at }) => {
                    return (
                      <Column>
                        <Text fontWeight="medium">
                          {format(parseISO(created_at), "M/d/yyyy")}
                        </Text>
                        <Text color="text.secondary">
                          {format(parseISO(created_at), "h:mm:ss a")}
                        </Text>
                      </Column>
                    );
                  },
                },
                {
                  sortDirection: orderBy?.model_name,
                  onClick: () => onSort(SortKeys.ModelName),
                  name: "Sync",
                  min: "360px",
                  cellSx: DEFAULT_CELL_SX,
                  cell: ({ sync }) => {
                    return (
                      <Column gap={2} width="100%">
                        <Row gap={2} alignItems="center">
                          <Box as="img" src={sync?.sourceIcon} boxSize="16px" />
                          <Text fontWeight="medium" isTruncated>
                            {sync?.segment?.name}
                          </Text>
                        </Row>
                        <Row gap={2} alignItems="center">
                          <Box
                            as="img"
                            src={sync?.destinationIcon}
                            boxSize="16px"
                          />
                          <Text fontWeight="medium" isTruncated>
                            {sync?.destinationName}
                          </Text>
                        </Row>
                      </Column>
                    );
                  },
                },
                {
                  name: "Query size",
                  sortDirection: orderBy?.query_size,
                  onClick: () => onSort(SortKeys.QuerySize),
                  cellSx: DEFAULT_CELL_SX,
                  cell: ({ query_run }) => {
                    return (
                      <Column>
                        <Text>
                          {query_run?.size ? commaNumber(query_run.size) : "0"}
                        </Text>
                        <Text size="sm" color="text.secondary">
                          Rows
                        </Text>
                      </Column>
                    );
                  },
                },
                {
                  sortDirection: orderBy?.total_operations,
                  onClick: () => onSort(SortKeys.TotalOperations),
                  name: "Operations",
                  cellSx: DEFAULT_CELL_SX,
                  cell: ({
                    planner_type,
                    add_executed,
                    change_executed,
                    remove_executed,
                    query_run,
                  }) => {
                    const added = add_executed ?? 0;
                    const changed = change_executed ?? 0;
                    const removed = remove_executed ?? 0;

                    if (planner_type === "all") {
                      return (
                        <DiffRow
                          icon={<RefreshIcon />}
                          value={query_run?.size ?? 0}
                        />
                      );
                    }

                    return (
                      <Column>
                        <DiffRow icon={<PlusIcon />} value={added} />
                        <DiffRow icon={<ChevronUpIcon />} value={changed} />
                        <DiffRow icon={<SubtractIcon />} value={removed} />
                      </Column>
                    );
                  },
                },
                {
                  sortDirection: orderBy?.failed_rows,
                  onClick: () => onSort(SortKeys.FailedRows),
                  name: "Rows synced",
                  cellSx: DEFAULT_CELL_SX,
                  cell: ({
                    sync,
                    error,
                    planner_type,
                    query_run,
                    sync_attempts,
                    add_executed,
                    change_executed,
                    remove_executed,
                    error_code_detail,
                  }) => {
                    const attempt = sync_attempts?.[0];

                    const {
                      successful: { total: successful },
                      rejected: { total: rejected },
                    } = getSyncRunOperations({
                      attempt,
                      syncRequest: {
                        error,
                        planner_type,
                        add_executed,
                        change_executed,
                        remove_executed,
                      },
                      queryRun: query_run,
                    });

                    if (error && error.message !== DEPRECATED_ERROR) {
                      const errorSync = sync as EnrichedSyncMetric<
                        TargetSyncRequestsQuery["syncs"][0]
                      >;
                      if (errorSync?.destination) {
                        errorSync.destination["definition"] = {
                          name: errorSync.destinationFriendlyType,
                          icon: errorSync.destinationIcon,
                          docs: errorSync.destinationDocs,
                        };
                      }

                      if (errorSync?.segment?.connection) {
                        errorSync.segment.connection["definition"] = {
                          name: errorSync.sourceFriendlyType,
                          icon: errorSync.sourceIcon,
                          docs: errorSync.sourceDocs,
                        };
                      }

                      return (
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setRunError({
                              ...(error ?? {}),
                              errorCodeDetail: error_code_detail,
                              syncStatus: status as SyncRunStatus,
                              sync: errorSync,
                            });
                          }}
                        >
                          View error
                        </Button>
                      );
                    }
                    if (!successful && !rejected) {
                      return (
                        <Column alignItems="flex-start" gap={1}>
                          <Text color="text.secondary">No operations</Text>
                        </Column>
                      );
                    }

                    return (
                      <Column alignItems="flex-start" gap={1}>
                        {successful ? (
                          <NumberPill variant="success" value={successful} />
                        ) : (
                          <Text color="text.secondary">
                            No successful operations
                          </Text>
                        )}
                        {rejected ? (
                          <NumberPill variant="danger" value={rejected} />
                        ) : (
                          <Text color="text.secondary">No errors</Text>
                        )}
                      </Column>
                    );
                  },
                },
              ]}
              data={paginatedData}
              loading={isLoading || isRefetching}
              placeholder={{
                p: 8,
                title: "No sync runs within this time window",
                body: "Select another time window to view sync runs.",
                error: "Runs failed to load, please try again.",
              }}
            />
            <Row width="100%" p={4} bg="white">
              <Pagination
                count={filteredData?.length ?? 0}
                label="runs"
                page={page}
                setPage={setPage}
                rowsPerPage={limit}
              />
            </Row>
          </Column>
        </Row>
      </DrawerBody>

      <SyncRequestErrorModal
        sync={runError?.sync}
        isOpen={Boolean(runError)}
        onClose={() => setRunError(undefined)}
        syncRequestError={runError}
        syncStatus={runError?.syncStatus}
      />
    </Drawer>
  );
};
