import {
  Box,
  BreakdownIcon,
  Column,
  Combobox,
  FormField,
  MailIcon,
  PerformanceIcon,
  Row,
  Heading,
  Select,
  StatsItemTitle,
} from "@hightouchio/ui";
import { useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { InsightsContext } from ".";
import useQueryState from "src/hooks/use-query-state";
import { Table } from "src/ui/table";
import { FC, useMemo, useState } from "react";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { abbreviateNumber } from "src/utils/numbers";
import { PercentageBar } from "src/components/percentage-bar";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ContentType } from "recharts/types/component/DefaultLegendContent";
import { format } from "date-fns";
import { RangeToggle } from "./components/range-toggle";

export const generateMockMessageSends = (
  messages: Array<{ message: { id: string } }>,
  length: number,
) => {
  const data: any = [];
  const baseTotalSends = 100000 + Math.random() * 100000; // Base total sends
  const growthFactor = 0.5; // The target proportion of the total sends for the first message on the last day
  const dailyFluctuation = 0.1; // Fluctuation margin, can vary sends by ±10%

  for (let i = 0; i < length; i++) {
    const timeFactor = i / (length - 1); // A factor that grows from 0 to 1 as we approach the last day

    // Apply daily fluctuation to total sends
    const totalSendsToday =
      baseTotalSends * (1 + (Math.random() - 0.5) * dailyFluctuation * 2);

    const messageSends = messages.reduce((acc, message, messageIndex) => {
      let sends;
      if (messageIndex === 0) {
        // First message sends grow towards 90% over time
        const firstMessageProportion =
          timeFactor * growthFactor + (1 - growthFactor);
        sends = Math.round(
          firstMessageProportion * totalSendsToday + Math.random() * i * 500,
        );
      } else {
        // Other messages keep sending relatively constant amounts
        const remainingProportion =
          (1 - timeFactor * growthFactor) / (messages.length - 1);
        sends = Math.round(
          remainingProportion * totalSendsToday + Math.random() * 500,
        );
      }
      return { ...acc, [message.message.id]: sends };
    }, {});

    data.push({
      ...messageSends,
      timestamp: new Date(
        Date.now() - (length - i) * 1000 * 60 * 60 * 24,
      ).toISOString(),
    });
  }
  return data;
};

export const InsightsDecisions = () => {
  const { flow } = useOutletContext<InsightsContext>();

  const [mockData] = useState(generateMockMessageSends(flow.messages, 30));

  return (
    <Column gap={6} p={6}>
      <Breakdown sendsByMessage={mockData} />
      <Card height="500px">
        <MessagesOvertime messages={flow.messages} sendsByMessage={mockData} />
      </Card>
    </Column>
  );
};

const Breakdown = ({ sendsByMessage }) => {
  const { flow } = useOutletContext<InsightsContext>();
  const [range, setRange] = useQueryState("r1", "30d");
  const [selectedDecision, setSelectedDecision] = useQueryState(
    "d1",
    "message",
  );
  const [selectedOutcomeId, setSelectedOutcomeId] = useQueryState(
    "outcome",
    flow.outcomes[0]?.outcome.id,
  );

  const decisionOptions = [
    { label: "Action", value: "message", icon: MailIcon },
  ];

  const [mockData] = useState({
    message: flow.messages.map(({ message }) => ({
      message,
      sends: sendsByMessage.reduce((acc, row) => acc + row[message.id], 0),
      outcome: 1 + Math.random(),
    })),
  });

  const selectedOutcome = flow.outcomes.find(
    (o) => o.outcome.id === selectedOutcomeId,
  )!.outcome;

  const data = mockData[selectedDecision];

  return (
    <Card w="100%" gap={4}>
      <Row align="center" gap={4}>
        <Row
          align="center"
          justify="center"
          p={2}
          borderRadius="50%"
          bg="gray.100"
        >
          <Box as={BreakdownIcon} boxSize={5} color="text.secondary" />
        </Row>
        <Heading>Decision breakdown</Heading>
      </Row>
      <Row gap={4} align="flex-end" justify="space-between">
        <Row gap={4}>
          <Box as={FormField} label="Decision to analyze" width="200px">
            <Select
              value={selectedDecision}
              onChange={setSelectedDecision}
              options={decisionOptions}
              optionAccessory={(option) => ({
                type: "icon",
                icon: option.icon,
              })}
              width="100%"
            />
          </Box>
          <Box as={FormField} label="Outcome to measure" width="200px">
            <Combobox
              value={selectedOutcomeId}
              onChange={setSelectedOutcomeId}
              options={flow.outcomes.map(({ outcome }) => outcome) ?? []}
              optionValue={(o) => o.id}
              optionLabel={(o) => o.name}
              width="100%"
            />
          </Box>
        </Row>

        <RangeToggle value={range} onChange={setRange} />
      </Row>

      <Table
        columns={[
          {
            name: "Message",
            cell: (row) => (
              <TextWithTooltip fontWeight="medium">
                {row.message.name}
              </TextWithTooltip>
            ),
          },
          {
            name: "Sends",
            cell: (row) => (
              <PercentageBar
                label={abbreviateNumber(row.sends)}
                color="gray.500"
                value={row.sends / data.reduce((acc, r) => acc + r.sends, 0)}
              />
            ),
          },
          {
            name: `${selectedOutcome.name} (per 100 sends)`,
            cell: (row) => (
              <PercentageBar
                label={row.outcome.toFixed(2)}
                color="electric.500"
                value={
                  row.outcome / data.reduce((acc, r) => acc + r.outcome, 0)
                }
              />
            ),
          },
        ]}
        data={data}
      />
    </Card>
  );
};

const colors = [
  "var(--chakra-colors-electric-500)",
  "var(--chakra-colors-grass-500)",
  "var(--chakra-colors-teal-500)",
  "var(--chakra-colors-danger-500)",
  "var(--chakra-colors-warning-500)",
];

export const MessagesOvertime: FC<
  Readonly<{
    sendsByMessage: Array<{ timestamp: string }>;
    messages: Array<{ message: { id: string; name: string } }>;
  }>
> = ({ sendsByMessage, messages }) => {
  const [range, setRange] = useQueryState("r2", "30d");
  const [selectedDecision, setSelectedDecision] = useQueryState(
    "d2",
    "message",
  );

  const decisionOptions = [
    { label: "Action", value: "message", icon: MailIcon },
  ];

  const mockData = useMemo(() => {
    return { message: sendsByMessage };
  }, [sendsByMessage]);

  const data = mockData[selectedDecision];

  return (
    <Column gap={4} height="500px">
      <Row align="center" gap={4}>
        <Row
          align="center"
          justify="center"
          p={2}
          borderRadius="50%"
          bg="gray.100"
        >
          <Box as={PerformanceIcon} boxSize={5} color="text.secondary" />
        </Row>
        <Heading>Decisions over time</Heading>
      </Row>
      <Row align="flex-end" justify="space-between">
        <Box as={FormField} label="Decision to analyze" width="200px">
          <Select
            value={selectedDecision}
            onChange={setSelectedDecision}
            options={decisionOptions}
            width="100%"
          />
        </Box>
        <RangeToggle value={range} onChange={setRange} />
      </Row>
      <MessagesOvertimeChart
        data={data}
        sendsByMessage={sendsByMessage}
        messages={messages}
      />
    </Column>
  );
};

const tickFormatter = (timestamp: string) => {
  const tick = format(new Date(timestamp), "M/d");
  return tick;
};

export const MessagesOvertimeChart = ({ data, sendsByMessage, messages }) => {
  const renderLegend: ContentType = ({ payload }) => {
    return (
      <Row gap={6} mb={8}>
        {payload?.map((entry) => {
          const { value, color, id } = entry;
          const total = sendsByMessage.reduce(
            (acc, row) => acc + row[value],
            0,
          );
          return (
            <Column key={id}>
              <Box fontSize="2xl">{abbreviateNumber(total)}</Box>
              <Row gap={1} align="center">
                <Box bg={color} w="12px" h="12px" borderRadius="sm" />
                <StatsItemTitle>
                  {messages.find((m) => m.message.id === value)!.message.name}
                </StatsItemTitle>
              </Row>
            </Column>
          );
        })}
      </Row>
    );
  };

  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Legend
          layout="horizontal"
          align="center"
          verticalAlign="top"
          content={renderLegend}
        />
        <CartesianGrid
          vertical={false}
          stroke="var(--chakra-colors-base-divider)"
        />
        <YAxis axisLine={false} tickLine={false} />
        <XAxis
          tickLine={false}
          axisLine={false}
          dataKey="timestamp"
          tickFormatter={tickFormatter}
        />
        <Tooltip
          cursor={false}
          labelFormatter={tickFormatter}
          formatter={(value, name) => [
            value,
            messages.find((m) => m.message.id === name)!.message.name,
          ]}
        />
        {messages.map((message, index) => (
          <Area
            key={message.message.id}
            type="linear"
            dot={true}
            dataKey={message.message.id}
            stackId="1"
            stroke={colors[index]}
            fill={colors[index]}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};
