import { Column, Row, Text } from "@hightouchio/ui";
import { getSyncRunOperations } from "src/utils/syncs";
import { NumberBadge } from "./number-badge";
import { SyncRun } from "src/pages/syncs/sync/utils/types";

type Props = {
  showPendingRows: boolean;
  error: any;
  plannerType: string | null;
  queryRun: SyncRun["query_run"];
  syncAttempts: SyncRun["sync_attempts"];
  addExecuted: number | null;
  changeExecuted: number | null;
  removeExecuted: number | null;
};

export const RunOperationsColumn = ({
  showPendingRows,
  error,
  plannerType,
  queryRun,
  syncAttempts,
  addExecuted,
  changeExecuted,
  removeExecuted,
}: Props) => {
  const attempt = syncAttempts?.[0];

  const { successful, rejected } = getSyncRunOperations({
    attempt,
    syncRequest: {
      error,
      planner_type: plannerType,
      add_executed: addExecuted,
      change_executed: changeExecuted,
      remove_executed: removeExecuted,
    },
    queryRun,
  });

  const pending = queryRun?.pending_rows;

  const noRows =
    successful.total === 0 &&
    rejected.total === 0 &&
    (pending === 0 || pending === undefined);

  if (noRows) {
    return (
      <Column align="flex-end" flex={1}>
        <Text>--</Text>
      </Column>
    );
  }

  return (
    <Row gap={1} justifyContent="flex-end" flex={1}>
      <NumberBadge
        color="success"
        tooltip={`${successful.total} rows successfully synced to the destination`}
        value={successful.total}
      />
      <NumberBadge
        color="danger"
        tooltip={`${rejected.total} rows failed to sync to the destination`}
        value={rejected.total}
      />
      {showPendingRows && pending !== undefined && (
        <NumberBadge
          color="gray"
          tooltip="Pending operations appear in Match Boosted syncs if they're added to the model but not yet enriched. Rows are only synced after they have been enriched."
          value={pending}
        />
      )}
    </Row>
  );
};
