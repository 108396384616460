import { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  Button,
  ButtonGroup,
  ChevronLeftIcon,
  Column,
  EditableText,
  Heading,
  InformationIcon,
  Paragraph,
  PlusIcon,
  Row,
  Spinner,
  StatusBadge,
  Switch,
  Text,
  Tooltip,
  UpsellButton,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";
import { useFormContext } from "react-hook-form";
import { Link, LinkButton, useNavigate, useParams } from "src/router";
import { isPresent } from "ts-extras";

import bottomRightGradient from "src/assets/backgrounds/bottom-right-blue-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-green-gradient.svg";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import { PageHeader } from "src/components/layout";
import {
  SaveNameAndDescriptionModal,
  SaveNameAndDescriptionModalForm,
} from "src/components/modals/save-name-and-description-modal";
import { PageAlert, PageAlertProps } from "src/components/page-alert";
import {
  PermissionProvider,
  usePermissionContext,
} from "src/components/permission";
import { SplashPage } from "src/components/splash-page";
import { FormErrorProvider } from "src/contexts/form-error-context";
import {
  useCreateSavedAnalyticsChartMutation,
  useParentModelsForAnalyticsQuery,
  useSavedAnalyticsChartQuery,
  useUpdateSavedAnalyticsChartGoalsAndCohortsMutation,
  useUpdateSavedAnalyticsChartMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { newPylonMessage } from "src/lib/pylon";
import { AnalyticsFrequency } from "src/types/visual";
import { AnalyticsIcon } from "src/ui/icons/analytics";
import { useRowSelect } from "src/ui/table/use-row-select";

import { useFlags } from "launchdarkly-react-client-sdk";
import { AnalyticsContent } from "./analytics-content";
import { useFunnelMetrics } from "./hooks/use-funnel-metrics";
import { useMetricSeries } from "./hooks/use-metric-series";
import { AnalyticsBasePlaceholder } from "./placeholders";
import { SavedChartsDrawer } from "./saved-charts-drawer";
import { AnalyticsFormStateProvider, useAnalyticsContext } from "./state";
import { PLACEHOLDER_AUDIENCE } from "./state/constants";
import {
  ChartFormState,
  ChartTab,
  GraphType,
  GroupByColumn,
  MeasurementScope,
  MetricSelection,
  SelectedAudience,
  TimeOptions,
} from "./types";
import { getNumberOfSeconds, getSavedChartData } from "./utils";
import { useDebouncedFormValue } from "../../hooks/use-debounced-form-value";

const pageAlert: Record<string, PageAlertProps> = {
  parentModel: {
    title: "First, you need to configure a parent model",
    message:
      "This workspace doesn’t contain any parent models. Before you can view analytics, you’ll need to create a parent model and an audience.",
    link: (
      <Link href="/schema-v2">
        Go to schema setup <ArrowRightIcon />
      </Link>
    ),
  },
};

export const AnalyticsPageContent: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const isSavedView = Boolean(id);

  const navigate = useNavigate();
  const { toast } = useToast();

  const { unauthorized } = usePermissionContext();

  const savedAnalyticsChartQuery = useSavedAnalyticsChartQuery(
    { id: id ?? "" },
    {
      enabled: Boolean(id),
      select: (data) => data.saved_analytics_charts_by_pk,
    },
  );

  const {
    audiencesAndMetricsLoading,
    events,
    metrics,
    parent,
    parentModelOptions,
    resetView,
  } = useAnalyticsContext();

  const form = useFormContext<ChartFormState>();

  const parentModelId = form.watch("parentModelId");
  const chartTab = form.watch("chartTab");
  const measuringMode = form.watch("measuringMode");
  const measuringSelection = form.watch("measuringSelection");
  const funnelConversionWindow = form.watch("funnelConversionWindow");
  const funnelSteps = form.watch("funnelSteps");

  // We want to debounce these values since changes to these can trigger a new
  // warehouse query (if not already cached). We want to track the pending state
  // of the debounced value so that we can still show immediate user feedback with
  // a loading state as they're making changes
  const [metricSelection, isMetricSelectionPending] =
    useDebouncedFormValue<MetricSelection[]>("metricSelection");
  const [selectedAudiences, isSelectedAudiencesPending] =
    useDebouncedFormValue<SelectedAudience[]>("selectedAudiences");
  const [groupByColumns, isGroupByColumnsPending] =
    useDebouncedFormValue<GroupByColumn[]>("groupByColumns");
  const [cumulative, isCumulativePending] =
    useDebouncedFormValue<boolean>("cumulative");
  const [graphType, isGraphTypePending] =
    useDebouncedFormValue<GraphType>("graphType");
  const [rollupFrequency, isRollupFrequencyPending] =
    useDebouncedFormValue<AnalyticsFrequency>("rollupFrequency");
  const [timeValue, isTimeValuePending] =
    useDebouncedFormValue<TimeOptions>("timeValue");
  const [selectedDateStrings, isSelectedDateStrings] =
    useDebouncedFormValue<string[]>("selectedDates");

  const selectedDates = useMemo(
    () => selectedDateStrings.map((dateStr) => new Date(dateStr)),
    [selectedDateStrings?.[0], selectedDateStrings?.[1]],
  );

  const [savingChart, setSavingChart] = useState(false);
  const [showSavedCharts, setShowSavedCharts] = useState(false);
  const [isFastQueryEnabled, setIsFastQueryEnabled] = useState(false);
  // Only show the fast query option if the feature flag is enabled
  // TODO: (davidlumley): Remove feature flag once we've confirmed it's working
  const { appChartsSamplingEnabled } = useFlags();
  const isFastQueryAvailable =
    appChartsSamplingEnabled &&
    Boolean(parent?.enabled_sampled_segments?.length);
  useEffect(
    () => setIsFastQueryEnabled(isFastQueryAvailable),
    [isFastQueryAvailable],
  );

  const audiencesRef = useRef(selectedAudiences);
  audiencesRef.current = selectedAudiences;

  const createSavedAnalyticsChartMutation =
    useCreateSavedAnalyticsChartMutation();
  const updateSavedAnalyticsChartMutation =
    useUpdateSavedAnalyticsChartMutation();
  const updateSavedAnalyticsChartGoalsAndCohortsMutation =
    useUpdateSavedAnalyticsChartGoalsAndCohortsMutation();

  const parentModelsForAnalyticsQuery = useParentModelsForAnalyticsQuery(
    undefined,
    {
      refetchOnMount: "always",
    },
  );

  const saveChart = async (data: SaveNameAndDescriptionModalForm) => {
    try {
      const formattedData = getSavedChartData({
        cohorts: selectedAudiences ?? [],
        customDateRange: selectedDates,
        funnelSteps,
        metrics,
        metricSelection,
        parentModelId,
        timeValue,
      });

      const result = await createSavedAnalyticsChartMutation.mutateAsync({
        input: {
          name: data.name,
          description: data.description ?? null,
          frequency:
            graphType === GraphType.Bar
              ? AnalyticsFrequency.All
              : rollupFrequency,
          chart_type: graphType,
          conversion_window: funnelConversionWindow,
          lookback_window: formattedData.lookbackWindow,
          group_by: groupByColumns,
          parent_model_id: parentModelId as unknown as string, // casting but this is a number
          funnel_stages: {
            data: chartTab === ChartTab.Funnel ? formattedData.funnelSteps : [],
          },
          goals: {
            data: chartTab !== ChartTab.Funnel ? formattedData.metrics : [],
          },
          cohorts: {
            data: formattedData.cohorts,
          },
        },
      });
      setSavingChart(false);

      navigate(`/analytics/${result.insert_saved_analytics_charts_one?.id}`);
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  const updateChart = async (data: ChartFormState) => {
    if (!id) {
      Sentry.captureException(
        new Error("[Saved analytics charts] No chart ID provided for update"),
      );
      return;
    }

    try {
      const formattedData = getSavedChartData({
        cohorts: data.selectedAudiences ?? [],
        customDateRange: selectedDates,
        funnelSteps,
        metrics,
        metricSelection: data.metricSelection,
        parentModelId,
        timeValue: data.timeValue,
      });

      const goalsToRemove =
        savedAnalyticsChartQuery.data?.goals.map(({ id }) => id) ?? [];
      const cohortsToRemove =
        savedAnalyticsChartQuery.data?.cohorts.map(({ id }) => id) ?? [];
      const funnelStagesToRemove =
        savedAnalyticsChartQuery.data?.funnel_stages.map(({ id }) => id) ?? [];

      await updateSavedAnalyticsChartGoalsAndCohortsMutation.mutateAsync({
        id,
        input: {
          frequency: data.rollupFrequency,
          chart_type: data.chartTab,
          lookback_window: formattedData.lookbackWindow,
          group_by: data.groupByColumns,
          conversion_window: funnelConversionWindow,
          // Graphql type expects a string but it must be a number
          parent_model_id: parentModelId as unknown as string,
        },
        bridge_goal_row_ids_to_remove: goalsToRemove,
        bridge_cohort_row_ids_to_remove: cohortsToRemove,
        bridge_funnel_stage_row_ids_to_remove: funnelStagesToRemove,
        goals_to_add: formattedData.metrics.map((object) => ({
          ...object,
          saved_chart_id: id,
        })),
        cohorts_to_add: formattedData.cohorts.map((object) => ({
          ...object,
          saved_chart_id: id,
        })),
        funnel_stages_to_add: formattedData.funnelSteps.map((object) => ({
          ...object,
          saved_chart_id: id,
        })),
      });
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  const updateChartName = async (name: string) => {
    if (!id) {
      Sentry.captureException(
        new Error("[Saved analytics charts] No chart ID provided for update"),
      );
      return;
    }

    try {
      await updateSavedAnalyticsChartMutation.mutateAsync({
        id,
        input: {
          name,
        },
      });

      toast({
        id: "update-chart-name-toast",
        title: "Chart name updated successfully",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "update-chart-name-toast",
        title: "Failed to update chart name",
        message: error.message,
        variant: "error",
      });
    }
  };

  const {
    isPolling,
    data: metricResults,
    pollingError,
    errors: metricSeriesErrors,
  } = useMetricSeries({
    enabled: !unauthorized && chartTab !== ChartTab.Funnel,
    audiences: selectedAudiences ?? [],
    customDateRange: selectedDates,
    frequency:
      graphType !== GraphType.Line ? AnalyticsFrequency.All : rollupFrequency,
    groupByColumns: groupByColumns.filter(isPresent),
    metricSelection,
    metrics,
    parentModelId,
    timeValue,
    measuringSelection,
    measuringMode,
    cumulative,
    useSampledModels: isFastQueryEnabled,
  });

  const {
    data: funnelsData,
    isPolling: isFunnelsPolling,
    errors: funnelsErrors,
  } = useFunnelMetrics({
    completionWindow: getNumberOfSeconds(funnelConversionWindow),
    customDateRange: selectedDates,
    groupByColumns: groupByColumns.filter(isPresent),
    enabled: !unauthorized && chartTab === ChartTab.Funnel,
    parentModelId,
    selectedAudiences: selectedAudiences ?? [],
    stages: funnelSteps,
    timeValue,
    useSampledModels: isFastQueryEnabled,
  });

  const returnedOnlyErrors =
    metricResults.length > 0 &&
    metricResults.every(({ result }) => {
      if ("error" in result) {
        return true;
      }

      return false;
    });

  const { selectedRows, onRowSelect } = useRowSelect();

  // Low confidence results should only show up when sampling is used
  // When a user turns sampling off to run a full (and accurate) query,
  // we can hide the warning
  const [showLowConfidenceWarning, setShowLowConfidenceWarning] =
    useState(false);
  useEffect(() => {
    if (!isFastQueryEnabled) setShowLowConfidenceWarning(false);

    let lowConfidence = false;

    if (chartTab === ChartTab.Funnel) {
      lowConfidence = funnelsData?.some((data) => data.lowConfidenceResults);
    } else {
      lowConfidence = metricResults?.some((data) => {
        if ("error" in data.result) return false;

        return data.result.lowConfidenceResults;
      });
    }

    setShowLowConfidenceWarning(lowConfidence);
  }, [metricResults, funnelsData, isFastQueryEnabled, chartTab]);
  // Create a stable reference for the side effect below
  const graph = useMemo(
    () =>
      transformMetricDataForGraph({
        audiences: selectedAudiences ?? [],
        cumulative,
        events,
        groupByColumns: groupByColumns.filter(isPresent),
        metricResults,
        metrics,
        parent,
        transformForPerformance: graphType === GraphType.Line,
      }),
    [
      selectedAudiences,
      cumulative,
      events,
      metricResults,
      metricSelection.length,
      metrics,
      parent,
      graphType,
      groupByColumns,
    ],
  );

  useEffect(() => {
    if (!isPolling && metricResults.length > 0 && graph.series.length > 0) {
      onRowSelect(graph.series.map(({ key }) => key));
    }
  }, [isPolling, metricResults, graph.series]);

  const parentModelsLoading = parentModelsForAnalyticsQuery.isLoading;

  const areDebouncedFormValuesPending =
    isMetricSelectionPending() ||
    isSelectedAudiencesPending() ||
    isGroupByColumnsPending() ||
    isCumulativePending() ||
    isGraphTypePending() ||
    isRollupFrequencyPending() ||
    isTimeValuePending() ||
    isSelectedDateStrings();

  const noParentModels =
    !parentModelsLoading && parentModelOptions.length === 0;

  const noMetricsSelected = metricSelection.filter(({ id }) => id).length === 0;
  const noFunnelStepsSelected =
    funnelSteps.filter(({ eventModelId }) => eventModelId).length === 0;

  const hasAudienceSelected = Boolean(
    selectedAudiences?.filter((s) => s.id !== PLACEHOLDER_AUDIENCE.id)?.length,
  );
  const parentModelMetricError = metricSeriesErrors[parentModelId ?? ""];
  const defaultErrorMessage =
    !hasAudienceSelected && Boolean(parentModelMetricError)
      ? parentModelMetricError
      : "Query failed";

  const errorMessage =
    !isPolling && Boolean(pollingError || returnedOnlyErrors) ? (
      <Paragraph
        color={
          pollingError || returnedOnlyErrors ? "text.danger" : "text.primary"
        }
        wordBreak="break-word"
      >
        {pollingError
          ? pollingError.message
          : returnedOnlyErrors
            ? defaultErrorMessage
            : selectedRows.length > 0
              ? "Please adjust the time filter to view the graph"
              : "Please select at least one series to view the graph"}
      </Paragraph>
    ) : null;

  return (
    <>
      <Helmet>
        <title>Charts</title>
      </Helmet>

      <PageHeader
        outsideTopbar={
          <>
            {noParentModels && <PageAlert {...pageAlert.parentModel!} />}
            <Row
              align="center"
              justify="space-between"
              gap={6}
              px={4}
              height={16}
              borderBottom="1px solid"
              borderColor="base.border"
            >
              <Row gap={6}>
                {isSavedView ? (
                  <Row align="center" gap={2}>
                    <Link href="/analytics" onClick={() => resetView(chartTab)}>
                      <Text color="text.secondary" size="lg">
                        Charts
                      </Text>
                    </Link>
                    <Text color="text.secondary" size="lg">
                      /
                    </Text>
                    <Text fontWeight="medium">
                      <EditableText
                        size="lg"
                        value={
                          savedAnalyticsChartQuery.data?.name ?? "Saved chart"
                        }
                        onChange={updateChartName}
                      />
                    </Text>
                    {savedAnalyticsChartQuery.data?.archived_at && (
                      <StatusBadge variant="inactive">Archived</StatusBadge>
                    )}
                  </Row>
                ) : (
                  <Heading size="xl">Charts</Heading>
                )}
              </Row>
              {!unauthorized && parentModelOptions.length > 0 && (
                <ButtonGroup size="lg">
                  <ButtonGroup>
                    {isFastQueryAvailable && (
                      <Row alignItems="center" gap={2} px={2}>
                        <Switch
                          size="sm"
                          isChecked={isFastQueryEnabled}
                          onChange={setIsFastQueryEnabled}
                        />
                        <Row gap={1} alignItems="center">
                          <Text fontWeight="medium">Fast queries</Text>
                          <Tooltip message="Fast queries use sampled data to calculate analytics which may introduce minor error">
                            <InformationIcon />
                          </Tooltip>
                        </Row>
                      </Row>
                    )}
                    <Link href="/analytics">
                      <Button
                        icon={PlusIcon}
                        variant="tertiary"
                        onClick={() => resetView(chartTab)}
                      >
                        New chart
                      </Button>
                    </Link>
                    {isSavedView && (
                      <Button
                        isDisabled={
                          updateSavedAnalyticsChartMutation.isLoading ||
                          updateSavedAnalyticsChartGoalsAndCohortsMutation.isLoading ||
                          !form.formState.isDirty ||
                          form.formState.isSubmitting
                        }
                        onClick={() => form.reset()}
                      >
                        Discard changes
                      </Button>
                    )}
                    <Button
                      isDisabled={
                        (form && !form.formState.isDirty) ||
                        (chartTab !== ChartTab.Funnel && noMetricsSelected) ||
                        (chartTab === ChartTab.Funnel &&
                          noFunnelStepsSelected) ||
                        measuringSelection?.scope !== MeasurementScope.AllData
                      }
                      isLoading={
                        updateSavedAnalyticsChartMutation.isLoading ||
                        updateSavedAnalyticsChartGoalsAndCohortsMutation.isLoading
                      }
                      variant="primary"
                      onClick={
                        isSavedView
                          ? form.handleSubmit(updateChart)
                          : () => setSavingChart(true)
                      }
                    >
                      {isSavedView ? "Save changes" : "Save chart"}
                    </Button>
                  </ButtonGroup>
                  <Box borderRight="1px solid" borderColor="base.divider" />
                  <Button
                    directionIcon={ChevronLeftIcon}
                    variant="secondary"
                    onClick={() => setShowSavedCharts(true)}
                  >
                    Charts
                  </Button>
                </ButtonGroup>
              )}
            </Row>
          </>
        }
      />

      {parentModelsLoading || audiencesAndMetricsLoading ? (
        <Column align="center" justify="center" flex={1} minHeight={0}>
          <Spinner size="lg" />
        </Column>
      ) : noParentModels ? (
        <AnalyticsBasePlaceholder />
      ) : (
        <AnalyticsContent
          errorMessage={errorMessage}
          funnelsData={funnelsData}
          funnelsErrors={funnelsErrors}
          graph={graph}
          isLoading={
            isPolling || isFunnelsPolling || areDebouncedFormValuesPending
          }
          metricSeriesErrors={metricSeriesErrors}
          showLowConfidenceWarning={showLowConfidenceWarning}
        />
      )}

      <SavedChartsDrawer
        isOpen={showSavedCharts}
        onClose={() => setShowSavedCharts(false)}
      />

      <SaveNameAndDescriptionModal
        type="chart"
        isOpen={savingChart}
        onClose={() => setSavingChart(false)}
        onSubmit={saveChart}
      />
    </>
  );
};

export const Analytics = () => {
  const { data: entitlementsData } = useEntitlements(true, true);
  const analyticsEnabled = entitlementsData.entitlements.analytics;

  const unlockCampaignIntelligence = () => {
    newPylonMessage("Hi, I'd like to unlock Campaign Intelligence!");
  };
  const trackLearnMoreClick = () => {
    analytics.track("Campaign Intelligence Learn More Clicked");
  };

  if (!analyticsEnabled) {
    return (
      <SplashPage
        icon={<AnalyticsIcon />}
        eyebrow="Campaign Intelligence"
        heading="Self-serve answers to all your marketing questions, using AI"
        description="Measure, explore, and analyze customer data and campaign performance, directly from the data warehouse."
        actions={
          <>
            <UpsellButton onClick={unlockCampaignIntelligence}>
              Unlock Campaign Intelligence
            </UpsellButton>
            <LinkButton
              href="https://hightouch.com/platform/campaign-intelligence"
              onClick={trackLearnMoreClick}
            >
              Learn more
            </LinkButton>
          </>
        }
        visual={
          <Box
            as="img"
            src="https://cdn.sanity.io/images/pwmfmi47/production/bb688c73bd51a5858d653c745395fa9574b726bd-1224x947.webp"
            maxHeight="100%"
            width="100%"
          />
        }
        backgroundGradient={
          <>
            <Box
              as="img"
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
            />
            <Box
              as="img"
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
            />
          </>
        }
      />
    );
  }

  return (
    // We use the audience preview permission because we're effectively
    // running queries on an audience with additional filtering
    <PermissionProvider
      permission={{ v1: { resource: "audience", grant: "preview" } }}
    >
      <AnalyticsFormStateProvider>
        <FormErrorProvider>
          <AnalyticsPageContent />
        </FormErrorProvider>
      </AnalyticsFormStateProvider>
    </PermissionProvider>
  );
};
