import { SlugResourceType } from "src/utils/slug";
import { v4 as uuidv4 } from "uuid";
import {
  generateEventContractBaseSlug,
  generateEventSchemaBaseSlug,
  SluggableEvent,
} from "@hightouch/lib/slugs/eventSlugs";

/**
 * Returns a default, unique, slug for a new event contract.
 */
export const generateEventContractSlug = async (
  getSlug: (resourceType: SlugResourceType, name: string) => Promise<string>,
  contract: {
    name: string;
  },
): Promise<string> => {
  const baseSlug = generateEventContractBaseSlug(contract);
  const slug = await getSlug(SlugResourceType.EventPlans, baseSlug);

  return slug || uuidv4();
};

export const generateEventSchemaSlug = async (
  getSlug: (resourceType: SlugResourceType, name: string) => Promise<string>,
  contract: { slug: string },
  schema: SluggableEvent,
): Promise<string> => {
  const baseSlug = generateEventSchemaBaseSlug(schema, contract);
  const slug = await getSlug(SlugResourceType.EventSchemas, baseSlug);

  return slug;
};
