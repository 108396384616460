import { useState } from "react";

import {
  Box,
  Button,
  Column,
  FormField,
  Heading,
  Row,
  SectionHeading,
  Select,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "src/router";
import { isPresent } from "ts-extras";
import * as Yup from "yup";

import PlaceholderSrc from "src/assets/placeholders/generic.svg";
import { DetailPage } from "src/components/layout";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  AudiencesForPriorityListsQuery,
  useCreatePriorityListMutation,
  useMinimalParentModelsQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";

import { AudienceSelector } from "./audience-selector";
import { DragAndDropEditor } from "./drag-and-drop-editor";
import { ActionBar } from "src/components/action-bar";
import { DocsLink } from "src/components/docs-link";

const priorityListResolver = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  parentModelId: Yup.string().required("Parent model is required"),
  audiences: Yup.array().required("Select at least one audience"),
});

export const CreatePriorityList = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [showAddAudiences, setShowAddAudiences] = useState(false);

  const createPriorityList = useCreatePriorityListMutation();

  const minimalParentModelsQuery = useMinimalParentModelsQuery();
  const selectOptions =
    minimalParentModelsQuery.data?.segments.map(({ id, name }) => ({
      label: name,
      value: id,
    })) ?? [];

  const { control, handleSubmit, watch } = useForm<{
    name: string;
    parentModelId: number;
    audiences: AudiencesForPriorityListsQuery["segments"][0][];
  }>({
    resolver: yupResolver(priorityListResolver),
    defaultValues: {
      name: "",
      parentModelId: undefined,
      audiences: [],
    },
  });

  const { replace, remove } = useFieldArray({
    control: control,
    name: "audiences",
  });

  const audiences = watch("audiences");
  const parentModelId = watch("parentModelId");

  const savePriorityList = async (data) => {
    try {
      const { createPriorityList: newData } =
        await createPriorityList.mutateAsync({
          name: data.name,
          parentModelId: data.parentModelId.toString(),
          audienceIds: data.audiences.map(({ id }) => id.toString()),
        });

      analytics.track("Priority List Created", {
        priority_list_id: newData?.id,
        parent_model_id: data.parentModelId,
        priority_list_name: data.name,
      });

      toast({
        id: "priority-list-creation-toast",
        title: `Priority list "${data.name}" created`,
        variant: "success",
      });

      if (newData?.id !== undefined) {
        navigate(`/priority-lists/${newData.id}`);
      } else {
        navigate("/priority-lists");
      }
    } catch (error) {
      toast({
        id: "priority-list-creation-toast",
        title: "Failed to create a priorty list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <PermissionProvider
      permission={{
        v1: { resource: "audience", grant: "create" },
      }}
    >
      <DetailPage
        crumbs={[{ label: "All priority lists", link: "/priority-lists" }]}
        title="New priority list"
        header={<Heading size="xl">Create priority list</Heading>}
        hasBottomPadding
      >
        <Column flex={1} gap={6}>
          <Row align="center" justify="space-between">
            <Heading>Configuration</Heading>
            <DocsLink
              href="/customer-studio/priority-lists"
              name="adding a priority list"
            />
          </Row>
          <Row flex={1} justify="space-between" minWidth={0}>
            <Column flex={1} minWidth={0}>
              <Column mb={8}>
                <Box mb={8}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState: { error } }) => (
                      <FormField error={error?.message} label="Name">
                        <TextInput
                          {...field}
                          isInvalid={Boolean(error)}
                          placeholder="Name"
                        />
                      </FormField>
                    )}
                  />
                </Box>
                <Controller
                  control={control}
                  name="parentModelId"
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      description="All audiences in the priority list must have the same parent model."
                      error={
                        minimalParentModelsQuery.error?.message ||
                        error?.message
                      }
                      label="Parent model"
                    >
                      <Select
                        {...field}
                        isInvalid={Boolean(
                          minimalParentModelsQuery.error?.message ||
                            error?.message,
                        )}
                        isLoading={minimalParentModelsQuery.isLoading}
                        options={selectOptions}
                        placeholder="Select a parent model"
                        width="sm"
                        onChange={(value) => {
                          replace([]);
                          field.onChange(value);
                        }}
                      />
                    </FormField>
                  )}
                />
              </Column>

              <Row align="center" justify="space-between">
                <SectionHeading>Set priority</SectionHeading>
                <Button
                  isDisabled={!watch("parentModelId")}
                  onClick={() => setShowAddAudiences(true)}
                >
                  Add audiences
                </Button>
              </Row>
              {audiences?.length > 0 ? (
                <>
                  <DragAndDropEditor
                    data={audiences}
                    onRemove={remove}
                    onUpdate={replace}
                  />
                </>
              ) : (
                <Column align="center" justify="center" mt={8}>
                  <Box as="img" src={PlaceholderSrc} sx={{ mb: 4 }} />
                  <Text>
                    Select a parent model and add audiences to get started
                  </Text>
                </Column>
              )}
            </Column>
          </Row>
        </Column>
        <ActionBar>
          <Button
            size="lg"
            isDisabled={
              audiences.length < 1 ||
              !watch("name") ||
              !isPresent(parentModelId)
            }
            isLoading={createPriorityList.isLoading}
            variant="primary"
            onClick={handleSubmit(savePriorityList)}
          >
            Save changes
          </Button>
        </ActionBar>

        <AudienceSelector
          isOpen={showAddAudiences}
          parentModelId={parentModelId}
          selectedData={audiences}
          onClose={() => setShowAddAudiences(false)}
          onSubmit={(data) => replace(data)}
        />
      </DetailPage>
    </PermissionProvider>
  );
};
