import { useState, FC } from "react";

import {
  Text,
  Box,
  Checkbox,
  Column,
  Row,
  FormField,
  StatusIndicator,
  TextInput,
  Tooltip,
  WarningIcon,
  InformationIcon,
  Dialog,
  Button,
} from "@hightouchio/ui";
import moment from "moment";

import { alertTypes } from "src/components/alerts";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { usePermissionContext } from "src/components/permission/permission-context";
import { AlertsOrderBy } from "src/graphql";
import { Section } from "src/ui/section";
import { Table } from "src/ui/table";
import { TableColumn } from "src/ui/table/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { isNumericString } from "src/utils/string";
import { ProcessedSyncAlerts } from "src/pages/syncs/sync/alerts";

import { isSuccessAlertType } from "./types";

type Props = {
  thresholds: any;
  workspaceAlerts: any;
  alerts: ProcessedSyncAlerts;
  orderBy: AlertsOrderBy | undefined;
  onAlertsChange: (alerts: any) => void;
  onSort: (sortKey: string) => void;
  onThresholdsChange: (thresholds: any) => void;
  showStatuses: boolean;
};

export enum WorkspaceAlertSortKeys {
  Name = "name",
  FatalError = "fatal_error_default",
  RowError = "row_error_default",
  OnSuccess = "on_success_default",
  LastAttempted = "alert_statuses_aggregate.min.last_attempted",
}

export const AlertSelector: FC<Readonly<Props>> = ({
  showStatuses,
  workspaceAlerts,
  alerts,
  orderBy,
  thresholds,
  onAlertsChange,
  onThresholdsChange,
  onSort,
}) => {
  const [alertError, setAlertError] = useState<string | undefined | null>();

  const setAlert = (alertId, errorType, state, defaultState) => {
    const alert = alerts?.[alertId] || {};
    if (defaultState) {
      alert[errorType] = state ? null : false;
    } else {
      alert[errorType] = state ? true : null;
    }

    onAlertsChange({ ...alerts, [alertId]: alert });
  };

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(WorkspaceAlertSortKeys.Name),
      cell: ({ name, type }) => (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon name={name} src={alertTypes[type].icon} />
          <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
        </Row>
      ),
    },
    {
      name: "Fatal error",
      sortDirection: orderBy?.fatal_error_default,
      onClick: () => onSort(WorkspaceAlertSortKeys.FatalError),
      cell: (workspaceAlert) => (
        <AlertState
          alertType="fatal"
          alerts={alerts}
          workspaceAlert={workspaceAlert}
          onChange={setAlert}
        />
      ),
    },
    {
      name: "Row error",
      sortDirection: orderBy?.row_error_default,
      onClick: () => onSort(WorkspaceAlertSortKeys.RowError),
      cell: (workspaceAlert) => (
        <AlertState
          alertType="row"
          alerts={alerts}
          workspaceAlert={workspaceAlert}
          onChange={setAlert}
        />
      ),
    },
    {
      name: "On success",
      sortDirection: orderBy?.on_success_default,
      onClick: () => onSort(WorkspaceAlertSortKeys.OnSuccess),
      cell: (workspaceAlert) => {
        return (
          <AlertState
            alertType="success"
            alerts={alerts}
            isUnavailable={!isSuccessAlertType(workspaceAlert.type)}
            workspaceAlert={workspaceAlert}
            onChange={setAlert}
          />
        );
      },
    },
  ];

  if (showStatuses) {
    columns.push({
      name: "Last attempted",
      sortDirection: orderBy?.alert_statuses_aggregate?.min?.last_attempted,
      onClick: () => onSort(WorkspaceAlertSortKeys.LastAttempted),
      cell: ({ alert_statuses: statuses }) => {
        return (
          <Box
            cursor={statuses?.[0]?.error ? "pointer" : undefined}
            userSelect="none"
            onClick={
              statuses?.[0]?.error
                ? () => {
                    setAlertError(JSON.stringify(statuses?.[0]?.error));
                  }
                : undefined
            }
          >
            <StatusIndicator
              variant={
                statuses?.[0]?.last_attempted
                  ? statuses?.[0]?.error
                    ? "error"
                    : "success"
                  : "inactive"
              }
            >
              {statuses?.[0]?.last_attempted
                ? moment(statuses?.[0]?.last_attempted).calendar()
                : "Never"}
            </StatusIndicator>
          </Box>
        );
      },
    });
  }

  return (
    <>
      <Column gap={12}>
        <Table
          columns={columns}
          data={workspaceAlerts}
          placeholder={{
            title: "No alerts",
            body: "Set up alerts in the Extensions page.",
            error: "Alerts failed to load, please try again.",
          }}
          sortingEnabled={false}
        />
        <Section>
          <FormField
            description="Row alerts will only be triggered if the error percentages are above both of the thresholds."
            label="Set row alert thresholds"
          >
            <Box
              display="grid"
              gap={4}
              gridTemplateColumns="max-content"
              justifyContent="space-between"
              width="100%"
            >
              <Row align="center" gap={3} justify="space-between">
                <Column>
                  <Text>Errors as % of total query rows</Text>
                </Column>
                <Column>
                  <TextInput
                    min="0"
                    placeholder="Enter a threshold percentage (default: 0%)..."
                    type="number"
                    value={thresholds?.total}
                    width="xs"
                    onChange={(event) => {
                      const threshold = event.target.value;
                      onThresholdsChange({
                        ...thresholds,
                        total: isNumericString(threshold)
                          ? Number(threshold)
                          : null,
                      });
                    }}
                  />
                </Column>
              </Row>
              <Row alignItems="center" gap={3} justifyContent="space-between">
                <Column>
                  <Text>Errors as % of synced rows</Text>
                </Column>
                <Column>
                  <TextInput
                    min="0"
                    placeholder="Enter a threshold percentage (default: 0%)..."
                    type="number"
                    value={thresholds?.attempted}
                    width="xs"
                    onChange={(event) => {
                      const threshold = event.target.value;
                      onThresholdsChange({
                        ...thresholds,
                        attempted: isNumericString(threshold)
                          ? Number(threshold)
                          : null,
                      });
                    }}
                  />
                </Column>
              </Row>
            </Box>
          </FormField>
        </Section>
      </Column>

      <Dialog
        isOpen={Boolean(alertError)}
        variant="info"
        width="xl"
        title="Error"
        actions={<Button onClick={() => setAlertError(null)}>Close</Button>}
        onClose={() => setAlertError(null)}
      >
        <Box sx={{ whiteSpace: "pre-line" }}>{alertError}</Box>
      </Dialog>
    </>
  );
};

interface AlertStateProps {
  alertType: "row" | "fatal" | "success";
  onChange: (
    alertId: string,
    errorType: string,
    state: boolean,
    defaultState: boolean,
  ) => void;
  alerts: ProcessedSyncAlerts;
  workspaceAlert: {
    id: string;
    row_error_default: boolean;
    fatal_error_default: boolean;
    on_success_default: boolean;
  };
  isUnavailable?: boolean;
}

const AlertState: FC<AlertStateProps> = ({
  alerts,
  onChange,
  workspaceAlert: {
    id,
    row_error_default,
    on_success_default,
    fatal_error_default,
  },
  alertType,
  isUnavailable = false,
}) => {
  const defaultState = {
    row: row_error_default,
    fatal: fatal_error_default,
    success: on_success_default,
  };

  const permission = usePermissionContext();

  return (
    <Row align="center" gap={2}>
      {!isUnavailable ? (
        <>
          <Checkbox
            isChecked={Boolean(
              alerts?.[id]?.[alertType] ?? defaultState[alertType],
            )}
            isDisabled={permission?.unauthorized}
            onChange={(event) => {
              onChange(
                id,
                alertType,
                event.target.checked,
                defaultState[alertType],
              );
            }}
          />
          {(alerts?.[id]?.[alertType] === null ||
            alerts?.[id]?.[alertType] === undefined) &&
            defaultState[alertType] && (
              <Tooltip message="This is the alert default">
                <Box as={InformationIcon} boxSize={5} color="ocean.600" />
              </Tooltip>
            )}
          {alerts?.[id]?.[alertType] === false && defaultState[alertType] && (
            <Tooltip message="This value overrides the alert default">
              <Box as={WarningIcon} boxSize={5} color="warning.400" />
            </Tooltip>
          )}
        </>
      ) : (
        <Text color="text.secondary">(N/A)</Text>
      )}
    </Row>
  );
};
