import {
  Row,
  Column,
  ButtonGroup,
  useToast,
  Dialog,
  Button,
  RefreshIcon,
  Heading,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { isEqual, omit, omitBy } from "lodash";
import { useEffect, useState } from "react";

import { ActionBar } from "src/components/action-bar";
import { PermissionedButton } from "src/components/permission";
import { SourceForm } from "src/components/sources/setup";
import {
  DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  WarehouseSchemaConfig,
} from "src/components/sources/setup/constants";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import {
  ListSourceTestStepsQueryVariables,
  useSourceQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import { SourceConfig } from "src/utils/sources";
import { Testing } from "src/components/sources/testing";
import { useParams } from "src/router";
import { DocsLink } from "src/components/docs-link";

export const SourceConfiguration = () => {
  const { id } = useParams<{ id: string }>();
  const { data: source } = useSourceQuery(
    { id: String(id) },
    { select: (data) => data.connections_by_pk, suspense: true },
  );
  const { toast } = useToast();
  const [config, setConfig] = useState<SourceConfig | undefined>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [lightningEnabled, setLightningEnabled] = useState<
    boolean | undefined
  >();
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [schema, setSchema] = useState<WarehouseSchemaConfig>(
    DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  );
  const [testConnectionModalOpen, setTestConnectionModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [lightningSchemaMode, setLightningSchemaMode] = useState<
    "shared" | "separate" | undefined
  >();

  const { isLoading: updateLoading, mutateAsync: updateSource } =
    useUpdateSourceV2Mutation();

  const {
    results: testResults,
    steps: testSteps,
    getTestSteps,
    runTest,
    cancelRunTest,
    timeElapsed,
  } = useSourceTesting();

  const configChanged = !isEqual(
    config
      ? omit(
          omitBy(config, (v) => v === undefined),
          ["methodKey"],
        )
      : undefined,
    omit(source!.config, ["methodKey"]) ?? undefined,
  );
  const tunnelChanged = !isEqual(tunnelId, source!.tunnel?.id ?? undefined);
  const credentialChanged = !isEqual(
    credentialId,
    source!.credential_id ?? undefined,
  );
  const lightningEnabledChanged = !isEqual(
    lightningEnabled,
    source!.plan_in_warehouse ?? undefined,
  );
  const plannerDatabaseChanged = !isEqual(
    plannerDatabase,
    source!.plan_in_warehouse_config?.plannerDatabase ?? undefined,
  );
  const schemaConfigChanged = !isEqual(
    schema,
    source!.plan_in_warehouse_config?.schema ?? undefined,
  );

  const dirty =
    tunnelChanged ||
    configChanged ||
    credentialChanged ||
    lightningEnabledChanged ||
    plannerDatabaseChanged ||
    schemaConfigChanged;
  const complete = tunnelId !== null;

  const variables: ListSourceTestStepsQueryVariables = {
    sourceType: source!.definition?.type,
    sourceId: String(source!.id),
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled
      ? { plannerDatabase, schema }
      : undefined,
  };

  const save = async () => {
    setIsSaving(true);
    let updatedConfig = config;

    if (tunnelId) {
      updatedConfig = {
        ...updatedConfig,
        host: null,
        server: null,
        port: null,
      };
    }
    try {
      await updateSource({
        id: source!.id.toString(),
        source: {
          tunnel_id: tunnelId ? tunnelId : null,
          credential_id:
            credentialId != null ? String(credentialId) : undefined,
          config: updatedConfig,
          plan_in_warehouse: lightningEnabled || undefined,
          plan_in_warehouse_config: lightningEnabled
            ? { plannerDatabase, schema }
            : undefined,
        },
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-source",
        title: "Couldn't update source",
        message: error.message,
        variant: "error",
      });
    }

    setIsSaving(false);
  };

  useEffect(() => {
    if (!testConnectionModalOpen) cancelRunTest();
  }, [testConnectionModalOpen]);

  useEffect(() => {
    setConfig(source?.config ?? undefined);
    setTunnelId(source?.tunnel?.id ?? undefined);
    setCredentialId(source?.credential_id ?? undefined);
    setLightningEnabled(source?.plan_in_warehouse ?? undefined);
    setPlannerDatabase(
      source?.plan_in_warehouse_config?.plannerDatabase ?? undefined,
    );

    const schema =
      source?.plan_in_warehouse_config?.schema ??
      DEFAULT_LIGHTNING_SCHEMA_CONFIG;
    const separateSchema = new Set(Object.values(schema)).size > 1;
    setSchema(schema);
    setLightningSchemaMode(separateSchema ? "separate" : "shared");
  }, [source]);

  return (
    <>
      <Column gap={6} flexGrow={1} minWidth={0}>
        <Row align="center" justify="space-between">
          <Heading>{source!.definition?.name} configuration</Heading>
          <DocsLink
            href={source!.definition?.docs ?? ""}
            name={source!.definition?.name ?? ""}
          />
        </Row>
        {source!.definition && (
          <SourceForm
            //Cheeky way to reset child componenents to initial state after save. (EX: Reseting senstive field edit state)
            key={`${updateLoading}`}
            hideSecret
            config={config}
            credentialId={credentialId}
            definition={source!.definition}
            hasSetupLightning={Boolean(source!.plan_in_warehouse)}
            isSetup={false}
            lightningEnabled={lightningEnabled}
            plannerDatabase={plannerDatabase}
            setConfig={setConfig}
            setCredentialId={setCredentialId}
            setLightningEnabled={setLightningEnabled}
            setPlannerDatabase={setPlannerDatabase}
            setLightningSchemaMode={setLightningSchemaMode}
            schema={schema}
            setSchema={setSchema}
            lightningSchemaMode={lightningSchemaMode}
            setTunnelId={setTunnelId}
            sourceId={id}
            tunnelId={tunnelId}
          />
        )}
        <ActionBar>
          <ButtonGroup size="lg">
            <PermissionedButton
              permission={{
                v2: {
                  resource: "source",
                  grant: "can_update",
                  id: source!.id,
                },
              }}
              variant="primary"
              size="lg"
              isDisabled={!dirty || !complete}
              isLoading={isSaving}
              onClick={save}
            >
              Save changes
            </PermissionedButton>
            {!source!.definition?.disableTest && config ? (
              <PermissionedButton
                permission={{
                  v2: {
                    resource: "source",
                    grant: "can_update",
                    id: source!.id,
                  },
                }}
                size="lg"
                isDisabled={!complete}
                onClick={async () => {
                  await getTestSteps(variables);
                  runTest(variables);
                  setTestConnectionModalOpen(true);
                }}
              >
                Test connection
              </PermissionedButton>
            ) : null}
          </ButtonGroup>
        </ActionBar>
      </Column>
      <Dialog
        isOpen={testConnectionModalOpen}
        variant="form"
        width="2xl"
        title={`Test connection to ${source!.definition?.name}`}
        actions={
          <ButtonGroup>
            <Button
              variant={
                testResults?.success !== false && !dirty
                  ? undefined
                  : "secondary"
              }
              onClick={() => {
                setTestConnectionModalOpen(false);
              }}
            >
              Close
            </Button>
            {testResults?.success === false ? (
              <Button icon={RefreshIcon} onClick={() => runTest(variables)}>
                Test again
              </Button>
            ) : dirty ? (
              <Button
                isDisabled={!testResults?.success}
                isLoading={updateLoading}
                onClick={async () => {
                  await save();
                  setTestConnectionModalOpen(false);
                }}
              >
                Save changes
              </Button>
            ) : null}
          </ButtonGroup>
        }
        onClose={() => {
          setTestConnectionModalOpen(false);
        }}
      >
        <Testing
          config={config}
          credentialId={credentialId}
          isSetup={false}
          plannerDatabase={plannerDatabase}
          results={testResults}
          sourceDefinition={source!.definition}
          steps={testSteps}
          timeElapsed={timeElapsed}
        />
      </Dialog>
    </>
  );
};
