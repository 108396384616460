import {
  useDecisionEngineFlowOutcomesQuery,
  useMinimalDecisionEngineFlowMessagesQuery,
} from "src/graphql";
import { MeasurementScope, type MeasuringSelection } from "../types";

/**
 * Hook for fetching the current measuring selection's metric resources to do
 * any specific processing or formatting needed for the measuring selection
 * The return object is meant to be flexible so each asset can do what it needs
 */
export const useMeasuringMetricResources = ({
  measuringSelection,
}: {
  measuringSelection: MeasuringSelection | undefined;
}): Record<string, unknown> => {
  const { data: outcomesEvents } = useDecisionEngineFlowOutcomesQuery(
    {
      flowId: measuringSelection?.id.toString() ?? "",
    },
    {
      enabled: Boolean(
        measuringSelection?.scope === MeasurementScope.DecisionEngineFlow,
      ),
      select: (data) =>
        data.decision_engine_flow_outcomes.map((o) => o.outcome),
    },
  );

  const { data: flowMessages } = useMinimalDecisionEngineFlowMessagesQuery(
    {
      flowId: measuringSelection?.id?.toString() ?? "",
    },
    {
      enabled: Boolean(
        measuringSelection?.scope === MeasurementScope.DecisionEngineFlow,
      ),
      select: (data) => data.decision_engine_flow_messages,
    },
  );

  switch (measuringSelection?.scope) {
    case MeasurementScope.DecisionEngineFlow: {
      const outcomes = outcomesEvents?.map((o) => ({
        ...o,
      }));

      return {
        outcomes: outcomes ?? [],
        flowMessages: flowMessages ?? [],
      };
    }
    case MeasurementScope.AllData:
    default:
      return {};
  }
};
