import { Navigate, Route, Routes } from "src/router";

import { CreateIdentityResolutionGraph } from "./create";
import {
  IdentityResolutionGraph,
  IdentityResolutionGraphLoader,
} from "./graph";
import { IdentityResolutionGraphs } from "./graphs";
import { Summary } from "./graph/summary";
import { Models } from "./graph/models";
import { Configuration } from "./graph/configuration";
import { CreateIdentityResolutionModel } from "./graph/create-model";
import { Rules } from "./graph/rules";
import { Runs } from "./graph/runs";
import { Model } from "./graph/model";
import { GoldenRecord } from "./graph/golden-record";
import { GoldenRecordColumn } from "./graph/golden-record-column";
import { Inspector } from "./graph/inspector";
import { InspectorDetails } from "./graph/inspector-details";

export const IdentityResolutionRoutes = () => {
  return (
    <Routes>
      <Route index element={<IdentityResolutionGraphs />} />
      <Route path="/new" element={<CreateIdentityResolutionGraph />} />
      <Route path="/:id/*" element={<IdentityResolutionGraphLoader />}>
        <Route element={<IdentityResolutionGraph />}>
          <Route
            index
            element={
              <Navigate
                to={{ pathname: "summary", search: location.search }}
                replace
              />
            }
          />
          <Route path="summary" element={<Summary />} />
          <Route path="runs" element={<Runs />} />
          <Route path="models/*" element={<Models />}>
            <Route path=":modelId" element={<Model />} />
            <Route path="new" element={<CreateIdentityResolutionModel />} />
          </Route>
          <Route path="rules" element={<Rules />} />
          <Route path="configuration" element={<Configuration />} />
          <Route path="golden-record" element={<GoldenRecord />}>
            <Route path=":columnName" element={<GoldenRecordColumn />} />
          </Route>
          <Route path="inspector" element={<Inspector />}>
            <Route path=":htId" element={<InspectorDetails />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
