/*eslint "no-restricted-imports": ["error", {
    "patterns": [
      {
        "group": [^(\./slugify)],
        "message": "This file is used on the frontend, so try not to import extra code."
      }
    ]
  } 
]*/

import { slugifyLocalName } from "./slugify";

export interface SluggableEvent {
  event_name?: string;
  event_type?: string;
  event_version?: string;
}

/** Returns the base slug for an object shaped like an event schema. */
export function generateEventSchemaBaseSlug(
  schema: SluggableEvent,
  contract: { slug: string },
) {
  const type = schema.event_name || schema.event_type;
  const version = schema.event_version || "default";
  return `${slugifyLocalName(type)}-${slugifyLocalName(contract.slug)}-${slugifyLocalName(version)}`;
}

/** Returns a unique default slug for an object shaped like an event contract. */
export function generateEventContractBaseSlug(contract: { name?: string }) {
  return slugifyLocalName(contract.name);
}
