import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { isPresent } from "ts-extras";

import {
  useBulkDeleteSyncDraftsByResourceIdMutation,
  useUpdateManySyncsMutation,
  useUpdateSyncMutation,
} from "src/graphql";
import {
  getDetachedSyncInputFromSync,
  SyncWithTemplateInformation,
} from "src/utils/sync-templates";

/**
 * Actions for the sync template page
 * This hook does not handle creating drafts. It only handles detaching syncs from their templates.
 */
export const useSyncTemplateActions = () => {
  const { toast } = useToast();

  const updateSyncMutation = useUpdateSyncMutation();
  const updateManySyncsMutation = useUpdateManySyncsMutation();
  const bulkDeleteSyncDraftsByResourceIdMutation =
    useBulkDeleteSyncDraftsByResourceIdMutation();

  /**
   * Detach multiple syncs from their templates and clear out their pending draft updates.
   */
  const detatchSyncsFromTemplates = async (
    syncs: SyncWithTemplateInformation[],
  ) => {
    try {
      const results = await updateManySyncsMutation.mutateAsync({
        updates: syncs.map((sync) => ({
          where: { id: { _eq: sync.id } },
          _set: getDetachedSyncInputFromSync(sync),
        })),
      });

      const resourceIds =
        results.update_destination_instances_many?.flatMap((response) =>
          response?.returning
            .map((syncUpdateResponse) => syncUpdateResponse.id.toString())
            .filter(isPresent),
        ) ?? [];

      if (resourceIds.length > 0) {
        // Clear out pending updates for the syncs that were successfully detached
        await bulkDeleteSyncDraftsByResourceIdMutation.mutateAsync({
          resourceIds,
        });
      }

      toast({
        id: "update-syncs",
        title: `Detached ${pluralize("sync", syncs.length, true)}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-syncs",
        title: "Failed to detach syncs",
        message: error.message,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return {
    detatchSyncsFromTemplates,
    isLoasding: updateSyncMutation.isLoading,
  };
};
