import { FC, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Button,
  Column,
  ResetIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import pluralize from "pluralize";
import { useFormContext, useWatch } from "react-hook-form";

import { useFormkitContext } from "src/formkit/components/formkit-context";
import { getFieldContext } from "src/components/destinations/is-node-visible";

type SyncOverrideProps = {
  /**
   * The keys of the child nodes that this component will manage.
   */
  childNodeKeys: string[];
};

function getTemplateText(value: unknown) {
  if (Array.isArray(value)) {
    return pluralize("item", value.length, true);
  }

  if (typeof value === "object" && value !== null) {
    return JSON.stringify(value, null, 2);
  }

  return String(value);
}

/**
 * Some fields are set in the form state after the initial `useWatch` hook is called.
 *
 * useWatch's execution order matters, which means if an update to a form value occurs before the subscription is in place, then the value updated will be ignored.
 */
const useFormValues = (keys: string[]) => {
  const { control, getValues } = useFormContext();

  return {
    ...useWatch({ control, name: keys }), // subscribe to form value updates
    ...getValues(keys), // always merge with latest form values
  };
};

export const SyncOverride: FC<SyncOverrideProps> = ({
  childNodeKeys: initialChildNodeKeys,
}) => {
  const { enableSyncTemplateOverrides } = useFlags();

  const {
    missingRelationshipsInFormkitDefinition,
    syncTemplate,
    overrideConfig,
    showRestoreDefaultUI,
    relationshipHierarchy,
  } = useFormkitContext();
  const { setValue } = useFormContext();

  const [originalValues, setOriginalValues] = useState({});

  const childNodeKeys = useMemo(() => {
    const keysToAdd: string[] = [];

    for (const key in missingRelationshipsInFormkitDefinition) {
      if (initialChildNodeKeys.includes(key)) {
        keysToAdd.push(...(missingRelationshipsInFormkitDefinition[key] ?? []));
      }
    }

    return [...keysToAdd, ...initialChildNodeKeys];
  }, [missingRelationshipsInFormkitDefinition, initialChildNodeKeys]);

  const watchedValues = useFormValues(childNodeKeys);

  const fieldValues: Array<[string, unknown]> = useMemo(
    () => childNodeKeys.map((key, index) => [key, watchedValues[index]]),
    [childNodeKeys, watchedValues],
  );

  // RULE: If the field's children are all unlocked, or if any ancestor is unlocked, then this field's value may be overriden.
  // Note: All ancestors, even non-immediate ancestors, are considered.
  const isUnlocked = useMemo(() => {
    return childNodeKeys.some((key) => {
      const { isUnlocked, isAnyAncestorUnlocked } = getFieldContext({
        key,
        overrideConfig,
        relationshipHierarchy,
      });

      return isUnlocked || isAnyAncestorUnlocked;
    });
  }, [childNodeKeys, overrideConfig, relationshipHierarchy]);

  // Store original values on mount or when the config changes
  useEffect(() => {
    if (enableSyncTemplateOverrides) {
      const initialConfigValues = {};

      childNodeKeys.forEach((nodeKey) => {
        initialConfigValues[nodeKey] = get(syncTemplate?.config, [nodeKey]);
      });

      setOriginalValues(initialConfigValues);
    }
  }, [enableSyncTemplateOverrides, childNodeKeys, syncTemplate?.config]);

  // Must run on each field update
  const hasOverrides = useMemo(
    () =>
      fieldValues.some(
        ([nodeKey, value]) =>
          // Default to `null` so that `undefined` and `null` are considered equal
          !isEqual(get(originalValues, nodeKey, null), value ?? null),
      ),
    [originalValues, fieldValues],
  );

  const reset = () => {
    childNodeKeys.forEach((nodeKey) => {
      setValue(nodeKey, originalValues[nodeKey]);
    });
  };

  if (!enableSyncTemplateOverrides || !showRestoreDefaultUI || !isUnlocked) {
    return null;
  }

  return (
    <Row justify="end" gap={2}>
      {hasOverrides && <Badge variant="primary">Custom value</Badge>}
      <Tooltip
        message={
          <Column align="flex-start" gap={2}>
            <Text color="white" fontWeight="medium">
              Template default
            </Text>
            <Column
              px={1}
              py={0.5}
              bg="gray.800"
              borderRadius="sm"
              align="flex-start"
            >
              {childNodeKeys.map((nodeKey) => (
                <Text
                  color="gray.background"
                  key={nodeKey}
                  wordBreak="break-word"
                >
                  {childNodeKeys.length > 1 ? `${nodeKey}: ` : ""}
                  {getTemplateText(get(syncTemplate?.config, [nodeKey]))}
                </Text>
              ))}
            </Column>
          </Column>
        }
      >
        <Button
          icon={ResetIcon}
          isDisabled={!hasOverrides}
          variant="tertiary"
          size="sm"
          onClick={reset}
        >
          Restore default
        </Button>
      </Tooltip>
    </Row>
  );
};
