import { FC, useState } from "react";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Alert,
  ButtonGroup,
  Column,
  ConfirmationDialog,
  EditIcon,
  Paragraph,
  UnlinkIcon,
  useToast,
} from "@hightouchio/ui";
import isEmpty from "lodash/isEmpty";
import { isPresent } from "ts-extras";

import { LinkButton, useOutletContext } from "src/router";
import { OverageContentAlert } from "src/components/overage/overage-content-alert";
import { DestinationForm } from "src/components/destinations/sync-form";
import { PermissionedButton } from "src/components/permission";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { useDraft } from "src/contexts/draft-context";
import {
  useExternalSegmentsQuery,
  useSyncTemplateQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import { useUser } from "src/contexts/user-context";
import { useHeaderHeight } from "src/contexts/header-height-context";
import {
  hasUnlockedFields,
  keepDefined,
} from "src/components/destinations/utils";
import { getDetachedSyncInputFromSync } from "src/utils/sync-templates";

import { Context } from ".";

export const Configuration: FC = () => {
  const { enableSyncTemplateOverrides, schemaV2 } = useFlags();
  const { sync, onRefetch } = useOutletContext<Context>();
  const id = String(sync.id);
  const { workspace } = useUser();
  const { toast } = useToast();
  const { headerHeight } = useHeaderHeight();

  const [confirmSyncTemplateDetach, setConfirmSyncTemplateDetach] =
    useState(false);
  const topDisplacement = headerHeight + 16;

  const updateSyncMutation = useUpdateSyncMutation();
  const updateSyncMutationAndInvalidateCatche = useUpdateSyncMutation({
    onSuccess: () => {
      onRefetch();
    },
  });

  const syncTemplateQuery = useSyncTemplateQuery(
    {
      id: sync?.sync_template_id ?? -1,
    },
    { enabled: Boolean(sync?.sync_template_id) },
  );

  const syncTemplateName = syncTemplateQuery.data?.sync_templates_by_pk?.name;

  const model = sync?.segment;
  const destination = sync?.destination;
  const source = model?.connection;
  const usesSyncTemplate = isPresent(sync?.sync_template_id);
  // TODO(samuel): add a util that checks to see which nodes are visible, even if overrides are present.
  // If none, then don't show the form.
  const showSyncForm =
    !usesSyncTemplate ||
    (enableSyncTemplateOverrides &&
      hasUnlockedFields(sync?.sync_template?.override_config));

  const { updateResourceOrDraft } = useDraft();

  const { data: externalSegmentsData } = useExternalSegmentsQuery(
    { syncId: sync?.id },
    { enabled: !!sync },
  );
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort(
    (a, b) => {
      return a.created_at > b.created_at ? -1 : 1;
    },
  )?.[0];

  const { data: entitlementsData } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destination?.definition?.name,
      schedule_type: sync.schedule?.type,
      source_type: model?.connection?.type,
    });

    if (!workspace?.approvals_required) {
      toast({
        id: "update-sync",
        title: "Sync was updated",
        variant: "success",
      });
    }
  };

  const updateConfig = async ({ sync_template_overrides, ...config }) => {
    if (!id || !sync) {
      return;
    }

    const updatePayload: {
      config?: Record<string, any>;
      sync_template_overrides?: Record<string, any>;
      approved_draft_id: null;
    } = {
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (sync.sync_template_id && sync_template_overrides) {
      // Only send overrides that are not `undefined`. `null` is an allowed value
      const syncTemplateOverrides = keepDefined(sync_template_overrides);

      updatePayload["sync_template_overrides"] = !isEmpty(syncTemplateOverrides)
        ? {
            ...syncTemplateOverrides,
            configVersion: sync?.config?.configVersion,
          }
        : null;
    } else {
      // Config may only be updated on syncs not created from a template
      updatePayload["config"] = {
        ...config,
        configVersion: sync?.config?.configVersion,
      };
    }

    if (updateResourceOrDraft) {
      await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        () =>
          updateSyncMutation.mutateAsync({
            id,
            object: updatePayload,
          }),
        sync.draft || false,
      );
    }
  };

  const detachSyncTemplate = async () => {
    if (!id || !sync) {
      return;
    }

    try {
      if (updateResourceOrDraft) {
        const updatePayload = getDetachedSyncInputFromSync(sync);

        await updateResourceOrDraft(
          { _set: updatePayload },
          onUpdate,
          () =>
            updateSyncMutationAndInvalidateCatche.mutateAsync({
              id,
              object: updatePayload,
            }),
          sync.draft || false,
        );
      }
    } catch (error) {
      toast({
        id: "detach-sync-template",
        title: "Could not detach template",
        message: error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Column gap={6}>
        {overageLockout && <OverageContentAlert />}
        {!overageLockout && usesSyncTemplate && (
          <Alert
            type="info"
            variant="inline"
            title="Sync template"
            message="This sync uses a shared configuration template"
            actions={
              <ButtonGroup size="lg">
                {!workspace?.sync_templates_only && (
                  <PermissionedButton
                    permission={{
                      v2: {
                        resource: "sync",
                        grant: "can_update",
                        id: sync?.id,
                      },
                    }}
                    icon={UnlinkIcon}
                    isLoading={updateSyncMutationAndInvalidateCatche.isLoading}
                    onClick={() => setConfirmSyncTemplateDetach(true)}
                  >
                    Detach from template
                  </PermissionedButton>
                )}
                <LinkButton
                  isDisabled={updateSyncMutationAndInvalidateCatche.isLoading}
                  icon={() => (
                    <Column fontSize="20px">
                      <EditIcon />
                    </Column>
                  )}
                  href={
                    schemaV2
                      ? `/schema-v2/settings/sync-templates/${sync?.sync_template_id}`
                      : `/schema/sync-templates/${sync?.sync_template_id}`
                  }
                >
                  Edit the template
                </LinkButton>
              </ButtonGroup>
            }
          />
        )}

        {!overageLockout &&
          model &&
          source?.definition &&
          destination &&
          destination?.definition &&
          showSyncForm && (
            <DestinationForm
              permission={{
                v2: {
                  resource: "sync",
                  grant: "can_update",
                  id: id ?? "",
                },
              }}
              testPermission={{
                v2: {
                  resource: "sync",
                  grant: "can_test",
                  id: id ?? "",
                },
              }}
              destination={destination}
              destinationDefinition={destination.definition}
              externalSegment={externalSegment}
              model={model}
              slug={destination?.definition?.type}
              sidebarTop={`${topDisplacement}px`}
              sourceDefinition={source.definition}
              sync={sync}
              onSubmit={updateConfig}
            />
          )}
      </Column>

      <ConfirmationDialog
        confirmButtonText="Detach"
        isOpen={confirmSyncTemplateDetach}
        title="Detach from template"
        variant="warning"
        onClose={() => setConfirmSyncTemplateDetach(false)}
        onConfirm={detachSyncTemplate}
      >
        <Paragraph>
          Detaching this sync from template &quot;{syncTemplateName}&quot; means
          future updates to the template will not be propagated to this sync.
          Are you sure you want to detach?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
