import { Maybe, MetricGroupByKey } from "src/graphql";
import { isSyntheticColumn } from "src/types/visual";
import { SyntheticColumnValuesGetter } from "./types";

export const formatSyntheticGroupBys = (
  groupBy: Maybe<MetricGroupByKey[]>,
  syntheticColumnValuesOptions: SyntheticColumnValuesGetter | undefined,
) => {
  const formattedGroupBy: MetricGroupByKey[] = [];
  for (const gb of groupBy ?? []) {
    const column = gb.column;

    if (isSyntheticColumn(column)) {
      const valueOptions = syntheticColumnValuesOptions?.(column) ?? [];
      const value = valueOptions.find((v) => v.value === gb.value);

      formattedGroupBy.push({
        ...gb,
        value: value?.label ?? gb.value,
      });
    } else {
      formattedGroupBy.push(gb);
    }
  }

  return formattedGroupBy;
};
