import { FC, useEffect, useState } from "react";

import {
  Alert,
  Avatar,
  Code,
  Column,
  ConfirmationDialog,
  EditableDescription,
  EditableHeading,
  FormField,
  Paragraph,
  Row,
  SectionHeading,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import capitalize from "lodash/capitalize";
import { useNavigate, useParams } from "src/router";

import { ColumnSettings } from "src/components/audiences/column-settings";
import { Relationships } from "src/components/audiences/relationships";
import {
  getSchemaModelType,
  getSchemaModelTypeNames,
} from "src/components/audiences/utils";
import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { SaveWarning } from "src/components/modals/save-warning";
import { ColumnSelect } from "src/components/models/column-select";
import { PrimaryKeyDialog } from "src/components/models/primary-key-dialog";
import { Query } from "src/components/models/query";
import { PermissionProvider } from "src/components/permission/permission-context";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { DisplaySlug } from "src/components/slug/display-slug";
import { SchemaTraitsTable } from "src/components/traits/schema-traits-table";
import { Warning } from "src/components/warning";
import {
  ObjectQuery,
  useDeleteObjectMutation,
  useObjectQuery,
  useUpdateAudiencesByParentIdMutation,
  useUpdateEventMutation,
  useUpdateObjectMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import {
  modelColumnIgnoreFields,
  modelColumnMappers,
} from "src/pages/models/model-activity";
import { MatchBoostingSettingsWrapper } from "src/pages/schema/match-booster/index";
import { QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { PageSpinner } from "src/components/loading";
import { isTimestampColumn } from "src/utils/models";
import { formatDate } from "src/utils/time";

import { PermissionedButton } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { parentModelActivityMappers } from "./parent-model-activity";
import { useModelState } from "src/hooks/use-model-state";

export const AudienceObject: FC = () => {
  const { id } = useParams<{ id?: string }>();

  const { data: object, isLoading } = useObjectQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      notifyOnChangeProps: "tracked",
      select: (data) => data.segments_by_pk,
    },
  );

  if (!id || isLoading) {
    return <PageSpinner />;
  }

  if (!object) {
    return (
      <Warning
        subtitle="This resource may have been deleted"
        title="Not found"
      />
    );
  }

  return <SchemaObject object={object} />;
};

const SchemaObject: FC<
  Readonly<{ object: NonNullable<ObjectQuery["segments_by_pk"]> }>
> = ({ object }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const type = getSchemaModelType(object);
  const { typeName, typePath, typeNameCapitalized } =
    getSchemaModelTypeNames(type);
  const modelState = useModelState(object);

  const isParentModel = type === SchemaModelType.Parent;
  const isEvent = type === SchemaModelType.Event;

  const [timestampColumn, setTimestampColumn] = useState<string | undefined>(
    object.event?.timestamp_column,
  );
  const [updatingPrimaryKey, setUpdatingPrimaryKey] = useState<boolean>(false);
  const [updatingTimestamp, setUpdatingTimestamp] = useState<boolean>(false);
  const [updatingPrimaryLabel, setUpdatingPrimaryLabel] =
    useState<boolean>(false);
  const [updatingSecondaryLabel, setUpdatingSecondaryLabel] =
    useState<boolean>(false);
  const [deleteQuery, setDeleteQuery] = useState<boolean>(false);
  const [primaryLabel, setPrimaryLabel] = useState<string | undefined | null>(
    "",
  );
  const [secondaryLabel, setSecondaryLabel] = useState<
    string | undefined | null
  >("");

  const [newPrimaryKey, setNewPrimaryKey] = useState<string | undefined>();

  const queryType = object.query_type as QueryType;
  const columns = object.columns;
  const source = object.connection;

  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: updateEvent } = useUpdateEventMutation();
  const { mutateAsync: asyncDeleteObject } = useDeleteObjectMutation();

  const { mutateAsync: updateAudiencesByParentId } =
    useUpdateAudiencesByParentIdMutation();

  const trackUpdate = () => {
    analytics.track("Model Updated", {
      model_id: object.id,
      model_type: queryType,
      model_name: object.name,
      source_id: source?.id,
      source_type: source?.type,
    });
  };

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: object.id },
    v1: { resource: "audience_schema", grant: "update", id: object.id },
  });

  const saveName = async (name: string) => {
    try {
      await updateObject({
        id: object.id,
        input: {
          name,
        },
      });
      toast({
        id: `update-name`,
        title: `Name was updated`,
        variant: "success",
      });

      trackUpdate();
    } catch (e) {
      toast({
        id: "update-name-error",
        title: "Failed to update name",
        message: e.message,
        variant: "error",
      });
    }
  };

  const saveDescription = async (description: string) => {
    try {
      await updateObject({
        id: object.id,
        input: {
          description,
        },
      });
      toast({
        id: "update-object-description",
        title: "Description updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-object-description",
        title: "Failed to update description",
        message: e.message,
        variant: "error",
      });
    }

    trackUpdate();
  };

  const updatePrimaryKey = async (value: string) => {
    setUpdatingPrimaryKey(true);
    modelState.onChange({ primary_key: value });
    const oldPrimaryKey = object.primary_key;
    try {
      await updateObject({
        id: object.id,
        input: {
          primary_key: value,
          visual_query_primary_label: primaryLabel,
          visual_query_secondary_label: secondaryLabel,
        },
      });
      if (isParentModel) {
        // Update the primary key for all the audiences associated with this parent model
        await updateAudiencesByParentId({
          parent_id: object.id,
          input: {
            primary_key: value,
          },
        });
      }
      toast({
        id: "update-primary-key",
        title: "Primary key updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-key-error",
        title: "Failed to update primary key",
        message: e.message,
        variant: "error",
      });
      modelState.onChange({ primary_key: oldPrimaryKey ?? "" });
    }
    setUpdatingPrimaryKey(false);
  };

  const updatePrimaryLabel = async (value: string) => {
    setUpdatingPrimaryLabel(true);
    setPrimaryLabel(value);
    const oldPrimaryLabel = object.visual_query_primary_label;
    try {
      await updateObject({
        id: object.id,
        input: {
          visual_query_primary_label: value,
        },
      });
      toast({
        id: "update-primary-key",
        title: "Primary label updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-label-error",
        title: "Failed to update primary label",
        message: e.message,
        variant: "error",
      });
      setPrimaryLabel(oldPrimaryLabel);
    }
    setUpdatingPrimaryLabel(false);
  };

  const updateSecondaryLabel = async (value: string) => {
    setUpdatingSecondaryLabel(true);
    setSecondaryLabel(value);
    const oldSecondaryLabel = object.visual_query_secondary_label;
    try {
      await updateObject({
        id: object.id,
        input: {
          visual_query_secondary_label: value,
        },
      });
      toast({
        id: "update-primary-key",
        title: "Primary label updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-label-error",
        title: "Failed to update primary label",
        message: e.message,
        variant: "error",
      });
      setSecondaryLabel(oldSecondaryLabel);
    }
    setUpdatingSecondaryLabel(false);
  };

  const updateTimestamp = async (value: string) => {
    setUpdatingTimestamp(true);
    setTimestampColumn(value);
    const oldTimestamp = object.event?.timestamp_column;
    try {
      await updateEvent({
        id: object.id,
        set: {
          timestamp_column: value,
        },
      });

      toast({
        id: "update-timestamp",
        title: "Timestamp column updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-timestamp-error",
        title: "Failed to update timestamp column",
        message: e.message,
        variant: "error",
      });
      setTimestampColumn(oldTimestamp ?? "");
    }
    setUpdatingTimestamp(false);
  };

  const deleteObject = async () => {
    try {
      await asyncDeleteObject({
        id: object.id,
      });

      analytics.track("Model Deleted", {
        model_id: object.id,
        model_type: queryType,
        model_name: object.name,
        source_id: source?.id,
        source_type: source?.type,
      });

      toast({
        id: `delete-${typePath}`,
        title: `${capitalize(typeName)} was deleted`,
        variant: "success",
      });

      navigate(`/schema/${typePath}`);
    } catch (error) {
      if (error.message.startsWith("Foreign key violation")) {
        toast({
          id: `delete-${typePath}`,
          title: `Failed to delete this audience`,
          message: `This ${capitalize(
            typeName,
          )} cannot be deleted because other resources rely on it.`,
          variant: "error",
        });

        captureException(error);
      }

      setDeleteQuery(false);
    }
  };

  const dirty =
    modelState.isDirty || timestampColumn !== object.event?.timestamp_column;

  useEffect(() => {
    modelState.reset(object);
    setPrimaryLabel(object.visual_query_primary_label);
    setSecondaryLabel(object.visual_query_secondary_label);
  }, [object]);

  const hasPrimaryKeyIssue = Boolean(
    isParentModel &&
      object.columns?.length &&
      !object.columns.some((c) => c.name === object.primary_key),
  );

  const updatedByUsername =
    object.updated_by_user?.name || object.created_by_user?.name;

  return (
    <>
      <PermissionProvider
        permission={{
          v2: { resource: "model", grant: "can_update", id: object.id },
          v1: { resource: "audience_schema", grant: "update" },
        }}
      >
        <DetailPage
          crumbs={[
            {
              label: "Schema",
              link: `/schema/${typePath}`,
            },
          ]}
          title={`${object.name} - ${typeNameCapitalized} - Audiences`}
          hasBottomPadding
          header={
            <Column>
              <Row
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 8,
                }}
              >
                <EditableHeading
                  isDisabled={!hasUpdatePermission}
                  size="lg"
                  value={object.name}
                  onChange={saveName}
                />
                <PermissionedButton
                  permission={{
                    v1: { resource: "audience_schema", grant: "delete" },
                    v2: {
                      resource: "model",
                      grant: "can_delete",
                      id: object.id,
                    },
                  }}
                  onClick={() => {
                    setDeleteQuery(true);
                  }}
                >
                  Delete
                </PermissionedButton>
              </Row>
              <Row>
                <EditableDescription
                  value={object.description ?? ""}
                  onChange={saveDescription}
                />
              </Row>
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon
                    src={source?.definition?.icon}
                    name={source?.definition?.name}
                  />
                  <Text size="lg" fontWeight="medium">
                    {source?.name}
                  </Text>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate((object.updated_at || object.created_at)!)}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Slug:</Text>
                  <DisplaySlug currentSlug={object.slug} />
                </Row>
              </DetailBar>
            </Column>
          }
        >
          <Row sx={{ width: "100%", flex: 1 }}>
            <Column sx={{ flex: 1, minWidth: 0 }}>
              <Tabs>
                <TabList>
                  <Tab>Query</Tab>
                  <Tab>Columns</Tab>
                  {isParentModel && <Tab>Relationships</Tab>}
                  <Tab>Traits</Tab>
                  {isParentModel && <Tab>Activity</Tab>}
                  {isParentModel && <Tab>Match Booster</Tab>}
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Column gap={8}>
                      <Query
                        model={modelState.state}
                        source={object.connection}
                        actions={
                          <PermissionedButton
                            permission={{
                              v1: {
                                resource: "audience_schema",
                                grant: "update",
                              },
                              v2: {
                                resource: "model",
                                grant: "can_update",
                                id: object.id,
                              },
                            }}
                            onClick={() =>
                              navigate(`/schema/${typePath}/${object.id}/query`)
                            }
                          >
                            Edit
                          </PermissionedButton>
                        }
                      />

                      {(isParentModel || isEvent) && hasUpdatePermission && (
                        <Column gap={6}>
                          <SectionHeading>Configuration</SectionHeading>
                          {isEvent && (
                            <FormField label="Timestamp">
                              <ColumnSelect
                                isLoading={updatingTimestamp}
                                columns={object.columns?.filter(
                                  isTimestampColumn,
                                )}
                                model={object}
                                value={timestampColumn}
                                onChange={updateTimestamp}
                              />
                            </FormField>
                          )}
                          {isParentModel && (
                            <>
                              <FormField
                                label="Primary key"
                                tip="Key that is unique and consistent across queries. E.g. “id” or “email”"
                              >
                                <ColumnSelect
                                  isLoading={updatingPrimaryKey}
                                  model={object}
                                  // when they are changing the primary key, use the new state
                                  value={
                                    newPrimaryKey ??
                                    modelState?.state.primary_key
                                  }
                                  onChange={setNewPrimaryKey}
                                />
                              </FormField>
                              <FormField
                                label="Primary label"
                                tip="Used when previewing results from an audience. E.g. “full_name”"
                              >
                                <ColumnSelect
                                  isLoading={updatingPrimaryLabel}
                                  model={object}
                                  value={primaryLabel ?? ""}
                                  onChange={updatePrimaryLabel}
                                />
                              </FormField>
                              <FormField
                                label="Secondary label"
                                tip="Used when previewing results from an audience. E.g. “email”"
                              >
                                <ColumnSelect
                                  isLoading={updatingSecondaryLabel}
                                  model={object}
                                  value={secondaryLabel ?? ""}
                                  onChange={updateSecondaryLabel}
                                />
                              </FormField>
                            </>
                          )}
                        </Column>
                      )}
                    </Column>
                  </TabPanel>
                  <TabPanel>
                    <ColumnSettings
                      columns={columns}
                      modelId={object.id}
                      primaryKey={object.primary_key || undefined}
                      source={source}
                    />
                  </TabPanel>
                  {isParentModel && (
                    <TabPanel>
                      <Relationships model={object} />
                    </TabPanel>
                  )}
                  <TabPanel>
                    <SchemaTraitsTable parentModelId={object.id} />
                  </TabPanel>
                  {isParentModel && (
                    <TabPanel>
                      <ResourceActivityTimeline
                        primaryResource={{
                          mappers: parentModelActivityMappers,
                          resourceNameOverride: "Parent Model",
                          resource: "Audience Schema",
                          resourceId: String(object.id),
                        }}
                        relatedResources={[
                          {
                            mappers: modelColumnMappers,
                            resource: "Model Column",
                            resourceNameOverride: "Model column",
                            resourceId: `model_id:${object.id}:%`,
                            ignoreColumns: modelColumnIgnoreFields,
                          },
                        ]}
                      />
                    </TabPanel>
                  )}
                  {isParentModel && (
                    <TabPanel>
                      <MatchBoostingSettingsWrapper
                        sidebar={false}
                        columns={columns}
                        model={object}
                        segmentType="schema"
                      />
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>

              {hasPrimaryKeyIssue && (
                <Alert
                  mb={8}
                  variant="inline"
                  type="warning"
                  title="Undefined primary key"
                  message={
                    <FormField
                      label={
                        <>
                          Your primary key is set to an <Code>undefined</Code>{" "}
                          column.
                        </>
                      }
                    >
                      As a result, your syncs may fail or unwanted behavior may
                      occur. Go to the Configuration tab to select a new primary
                      key.
                    </FormField>
                  }
                />
              )}
            </Column>
          </Row>
        </DetailPage>
      </PermissionProvider>

      <PrimaryKeyDialog
        oldPrimaryKey={modelState?.state.primary_key}
        newPrimaryKey={newPrimaryKey}
        onClose={() => {
          setNewPrimaryKey(undefined);
        }}
        onConfirm={async () => {
          if (newPrimaryKey) {
            await updatePrimaryKey(newPrimaryKey);
          }
        }}
      />

      <ConfirmationDialog
        confirmButtonText={`Delete ${typeName}`}
        isOpen={deleteQuery}
        title={`Delete ${typeName}`}
        variant="danger"
        onClose={() => {
          setDeleteQuery(false);
        }}
        onConfirm={deleteObject}
      >
        <Paragraph>
          Are you sure you want to delete this {typeName}? You won't be able to
          undo this.
        </Paragraph>
      </ConfirmationDialog>

      <SaveWarning dirty={dirty && !deleteQuery} />
    </>
  );
};
