import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  FolderIcon,
  Heading,
  LightningBoltIcon,
  LightningBoltWithSlashIcon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Paragraph,
  PlayIcon,
  RefreshIcon,
  Row,
  SearchInput,
  TagIcon,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useLocation, useNavigate } from "src/router";
import { isPresent } from "ts-extras";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import syncPlaceholder from "src/assets/placeholders/sync.svg";
import { DraftIcon } from "src/components/drafts/draft-icon";
import {
  createdFilter,
  isFilterActive,
  labelFilter,
} from "src/components/folders/filter-helpers";
import {
  Filters,
  SyncProgressFilter,
  createdByFilterConfig,
  destinationFilterConfig,
  labelFilterConfig,
  sourceFilterConfig,
  syncHealthFilterConfig,
  syncProgressFilterConfig,
  syncStatusFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { PageSidebar } from "src/components/layout/page-sidebar";
import { ConfettiModal } from "src/components/onboarding/confetti-modal";
import { PageAlert } from "src/components/page-alert";
import {
  PermissionedLinkButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";
import {
  OrderBy,
  SegmentsBoolExp,
  SyncsBoolExp,
  SyncsLabelsQuery,
  SyncsOrderBy,
  SyncsQuery,
  SyncsQueryVariables,
  useAddLabelsToSyncsMutation,
  useDeleteSyncsMutation,
  useDraftsQuery,
  useStartSyncRunMutation,
  useSyncFiltersQuery,
  useSyncsLabelsQuery,
  useSyncsQuery,
  useUpdateSyncsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import {
  PageTable,
  SortOption,
  TableColumn,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import {
  ActiveSyncStatuses,
  SyncRunStatus,
  describeWhenSyncRuns,
  syncRunStatusOrUnknownStatus,
} from "src/utils/syncs";
import { openUrl } from "src/utils/urls";
import { SyncLastRunDisplay } from "./sync/components/sync-last-run-display";
import { isEnum } from "src/types/utils";
import { isSyncMatchBoosted } from "./sync/matchbooster";
import { DecisionEnginesFolder, JourneysFolder } from "./components/folders";
import { DestinationCell } from "./components/destination-cell";
import { ResyncWarning } from "./components/full-resync-warning";
import { SyncHealthOrLastStatusBadge } from "./sync/components/sync-health-or-last-status-badge";
import { useSearchQueryState } from "src/hooks/use-search-query-state";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { ModelCell } from "./components/model-cell";
import { ElementOf } from "ts-essentials";

const initialSort: SortOption<keyof SyncsOrderBy> = {
  key: "created_at",
  direction: OrderBy.Desc,
  label: "Newest",
};

const getBulkDeleteSyncMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") &&
    error.message.includes("sync_sequence")
    ? "One or more of the selected syncs cannot be deleted because they are used in sequences"
    : error.message;
};

const useFastSyncsQuery = getQueryWithOpts<SyncsQuery, SyncsQueryVariables>(
  useSyncsQuery,
  { useFastEndpoint: true },
);

type SyncRowData = ElementOf<SyncsQuery["syncs"]> & {
  labels?: ElementOf<SyncsLabelsQuery["syncs"]>["labels"];
};

export const Syncs: FC = () => {
  const { workspace } = useUser();
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const routeState = location.state as { onboardingSync: string };
  const { search, setSearch, debouncedSearch, isSearchPending } =
    useSearchQueryState();

  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [confirmBulkRun, setConfirmBulkRun] = useState<
    "run" | "resync" | undefined
  >(undefined);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [processingAction, setProcessingAction] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const {
    selectedFolder,
    selectedFolderType,
    setSelectedFolder,
    setSelectedFolderType,
    setMovingToFolder,
    movingToFolder,
    header,
    refetchFolders,
    clearFolderType,
    totalCount,
    audienceCount,
    journeyCount,
    decisioningCount,
    modelCount,
    nestedFolders: folders,
    loadingFolders,
  } = useFolderState({ search, resourceType: "syncs" });

  const sortOptions: SortOption<keyof SyncsOrderBy>[] = [
    ...(workspace?.alerting_v2_enabled
      ? [{ key: "health", direction: OrderBy.Asc, label: "Health" }]
      : [{ key: "status", direction: OrderBy.Asc, label: "Status" }]),
    { key: "segment.name" as any, direction: OrderBy.Asc, label: "Model name" },
    {
      key: "destination.name" as any,
      direction: OrderBy.Asc,
      label: "Destination name",
    },
    {
      key: "last_run_at" as any,
      direction: OrderBy.DescNullsLast,
      label: "Last run",
    },
    initialSort,
    { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
  ];

  const { limit, offset, page, setPage } = useTableConfig<SyncsOrderBy>();
  const searchParamsOrderBy = useTableSort<SyncsOrderBy>(
    initialSort,
    sortOptions,
  );

  /**
   * "Health" is a combined field that considers both the sync health and whether it's disabled.
   * To sort by "Health", we need to sort by "schedule_paused" first to find diabled syncs, then by "health".
   */
  const orderByAdjustedForHealth = isPresent(searchParamsOrderBy?.health)
    ? { ...searchParamsOrderBy, schedule_paused: searchParamsOrderBy.health }
    : searchParamsOrderBy;

  const { data: allSyncs, isLoading: filtersLoading } = useSyncFiltersQuery(
    undefined,
    { select: (data) => data.syncs },
  );

  const filterDefinitions = useMemo(() => {
    const filters: {
      viewKey: string;
      loading: boolean;
      filters: Record<string, any>;
    } = {
      viewKey: "sync",
      loading: filtersLoading,
      filters: {
        progress: {
          options: syncProgressFilterConfig(),
          title: "Progress",
        },
        destination: {
          options: destinationFilterConfig(allSyncs || []),
          title: "Destination",
        },
        source: {
          options: sourceFilterConfig(allSyncs || []),
          title: "Source",
        },
        created: {
          options: createdByFilterConfig(allSyncs || []),
          title: "Creator",
        },
        label: { options: labelFilterConfig(allSyncs || []), title: "Label" },
      },
    };

    if (workspace?.alerting_v2_enabled) {
      filters.filters.health = {
        options: syncHealthFilterConfig(),
        title: "Health",
      };
    } else {
      filters.filters.status = {
        options: syncStatusFilterConfig(),
        title: "Status",
      };
    }

    if (
      allSyncs?.some(
        (s) =>
          s.segment?.matchboosting_enabled ||
          s.segment?.parent?.matchboosting_enabled,
      )
    ) {
      filters.filters.matchboosting = {
        title: "Match Booster",
        canSearch: false,
        options: [
          {
            id: "boosted_models_syncs",
            label: "Boosted models and syncs",
          },
          {
            id: "boosted_models",
            label: "Boosted models",
          },
        ],
      };
    }
    return filters;
  }, [allSyncs]);

  const {
    result: { state: filterState, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    if (!allSyncs?.length) {
      return {};
    }

    const folderFilter: SegmentsBoolExp | undefined = (() => {
      const folderIds: string[] = [];

      if (selectedFolder?.id) {
        folderIds.push(selectedFolder.id);
      }
      if (selectedFolder?.flattenedChildren?.length) {
        folderIds.push(...selectedFolder.flattenedChildren.map((f) => f.id));
      }

      const folderParams = folderIds.length
        ? { folder_id: { _in: folderIds } }
        : {};

      switch (selectedFolderType) {
        case "models":
          return {
            query_type: { _nin: [QueryType.Visual, QueryType.JourneyNode] },
            is_schema: { _eq: false },
            ...folderParams,
          };
        case "journeys":
          return {
            query_type: { _eq: QueryType.JourneyNode },
            ...folderParams,
          };
        case "decisioning": {
          return {
            flow_message: {},
            ...folderParams,
          };
        }
        case "audiences":
          return {
            query_type: { _eq: QueryType.Visual },
            ...folderParams,
          };
        default:
          return undefined;
      }
    })();

    const matchboostingFilter = (): SyncsBoolExp => {
      if (isFilterActive(filterState.matchboosting)) {
        switch (filterState.matchboosting?.selected?.[0]?.id) {
          case "boosted_models_syncs":
            return {
              _or: [
                {
                  _and: [
                    { segment: { matchboosting_enabled: { _eq: true } } },
                    {
                      config: {
                        _cast: {
                          String: {
                            _like: `%"type": "boosted"%`,
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  _and: [
                    {
                      segment: {
                        parent: { matchboosting_enabled: { _eq: true } },
                      },
                    },
                    {
                      config: {
                        _cast: {
                          String: {
                            _like: `%"type": "boosted"%`,
                          },
                        },
                      },
                    },
                  ],
                },
              ],
            };
          case "boosted_models":
            return {
              segment: { matchboosting_enabled: { _eq: true } },
            };
          default:
            return {};
        }
      }
      return {};
    };

    const segmentFilter = () => {
      if (!folderFilter && !isFilterActive(filterState.source)) {
        return {};
      }
      return {
        segment: {
          ...(isFilterActive(filterState.source)
            ? {
                connection_id: {
                  _in: filterState.source.selected.map((filter) => filter.id),
                },
              }
            : {}),
          ...(folderFilter || {}),
        },
      };
    };

    const destinationFilter = () => {
      if (isFilterActive(filterState.destination)) {
        return {
          destination_id: {
            _in: filterState.destination.selected.map((filter) => filter.id),
          },
        };
      }
      return {};
    };

    const statusFilter = () => {
      if (isFilterActive(filterState.status)) {
        return {
          status: {
            _in: filterState.status.selected.map((filter) => filter.id),
          },
        };
      }
      return {};
    };

    const healthFilter = () => {
      if (isFilterActive(filterState.health)) {
        const selectedHealths = filterState.health.selected.map(
          (filter) => filter.id,
        );
        return {
          _or: [
            {
              _and: [
                {
                  health: {
                    _in: selectedHealths,
                  },
                },
                {
                  schedule_paused: { _eq: false },
                },
                {
                  _not: {
                    status: { _eq: SyncRunStatus.PENDING },
                  },
                },
              ],
            },
            ...(selectedHealths.includes(MonitorStatus.Disabled)
              ? [
                  {
                    schedule_paused: {
                      _eq: true,
                    },
                  },
                ]
              : []),
            ...(selectedHealths.includes(SyncRunStatus.PENDING)
              ? [
                  {
                    status: { _eq: SyncRunStatus.PENDING },
                  },
                ]
              : []),
          ],
        };
      }
      return {};
    };

    const progressFilter = () => {
      if (isFilterActive(filterState.progress)) {
        const selectedProgress = filterState.progress.selected
          .map((filter) => filter.id)
          .filter(isEnum(SyncProgressFilter));

        return {
          _or: [
            ...(selectedProgress.includes(SyncProgressFilter.Running)
              ? [
                  {
                    status: {
                      _in: [...ActiveSyncStatuses],
                    },
                  },
                ]
              : []),
            ...(selectedProgress.includes(SyncProgressFilter.NotRunning)
              ? [
                  {
                    _not: {
                      status: {
                        _in: [...ActiveSyncStatuses],
                      },
                    },
                  },
                ]
              : []),
          ],
        };
      }
      return {};
    };

    const andFilter = () => {
      const filter = {
        ...destinationFilter(),
        ...segmentFilter(),
        ...statusFilter(),
        ...healthFilter(),
        ...matchboostingFilter(),
        ...progressFilter(),
      };
      if (Object.keys(filter).length) {
        return {
          _and: [filter],
        };
      }
      return {};
    };

    const hasuraFilters: SyncsBoolExp = {
      ...andFilter(),
      ...labelFilter(filterState.label),
      ...createdFilter(filterState.created),
    };

    return hasuraFilters;
  }, [filterState, selectedFolder, selectedFolderType]);

  const hasuraFiltersWithSearch = useMemo(() => {
    if (debouncedSearch) {
      const searchFilters: SyncsBoolExp[] = [
        { segment: { name: { _ilike: `%${debouncedSearch}%` } } },
        { destination: { name: { _ilike: `%${debouncedSearch}%` } } },
        { destination: { type: { _ilike: `%${debouncedSearch}%` } } },
      ];
      return { _and: [hasuraFilters, { _or: searchFilters }] };
    } else {
      return hasuraFilters;
    }
  }, [hasuraFilters, debouncedSearch]);

  const syncsQuery = useFastSyncsQuery(
    {
      offset,
      limit,
      filters: hasuraFiltersWithSearch,
      orderBy: orderByAdjustedForHealth,
    },
    {
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const syncsLabelsQuery = useSyncsLabelsQuery(
    {
      offset,
      limit,
      filters: hasuraFiltersWithSearch,
      orderBy: orderByAdjustedForHealth,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const { data: drafts } = useDraftsQuery({
    resourceType: "sync",
    status: "pending",
  });

  const { mutateAsync: bulkDeleteSyncs } = useDeleteSyncsMutation();
  const { mutateAsync: updateSyncs } = useUpdateSyncsMutation();
  const { mutateAsync: addLabels } = useAddLabelsToSyncsMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();

  const { data: entitlementsData } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText =
    destinationOverageText + " To create a sync, upgrade your plan.";
  const audiencesEnabled = entitlementsData.entitlements.audiences;

  const bulkUpdateStatus = async (enabled: boolean) => {
    setProcessingAction(true);
    try {
      const res = await updateSyncs({
        ids: selectedRows.map(String),
        object: {
          schedule_paused: !enabled,
        },
      });

      if (
        res.update_destination_instances?.affected_rows !== selectedRows.length
      ) {
        throw new Error(
          "One or more of the selected syncs cannot be updated. Ensure you have the required permissions to perform this action.",
        );
      }

      toast({
        id: "bulk-update-syncs",
        title: `Selected syncs were ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-update-syncs",
        title: "Error updating syncs",
        message: error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
    setProcessingAction(false);
  };

  const bulkEditLabels = async (labels: Record<string, string>) => {
    const res = await addLabels({ ids: selectedRows.map(String), labels });

    // This flow is a little different from the other bulk actions because <EditLabelModal>
    // controls the success message and closing the modal.
    // So all we need to do is throw an error not all the updates were successful.
    if (
      res.update_destination_instances?.affected_rows !== selectedRows.length
    ) {
      throw new Error(
        "One or more of the selected syncs cannot be updated. Ensure you have the required permissions to perform this action.",
      );
    }
    onRowSelect([]);
  };

  const bulkDelete = async () => {
    setProcessingAction(true);
    try {
      const res = await bulkDeleteSyncs({
        ids: selectedRows.map(String),
        stringIds: selectedRows.map(String),
      });

      if (
        res.delete_destination_instances?.affected_rows !== selectedRows.length
      ) {
        throw new Error(
          "One or more of the selected syncs cannot be deleted. Ensure you have the required permissions to perform this action.",
        );
      }

      setConfirmingDelete(false);
      toast({
        id: "bulk-delete-syncs",
        title: "Selected syncs were deleted",
        variant: "success",
      });
      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-syncs",
        title: "Error deleting syncs",
        message: getBulkDeleteSyncMessage(error),
        variant: "error",
      });
      Sentry.captureException(error);
    }
    setProcessingAction(false);
  };

  const bulkRun = async (resync: boolean) => {
    setProcessingAction(true);
    try {
      await Promise.all(
        selectedRows.map((id) =>
          forceRun({ id: Number(id), full_resync: resync }),
        ),
      );
      toast({
        id: "bulk-run-syncs",
        title: `Selected ${pluralize("sync", selectedRows.length)} ${pluralize(
          "is",
          selectedRows.length,
        )} ${resync ? "resyncing" : "running"}`,
        variant: "success",
      });
      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-run-syncs",
        title: "Error running syncs",
        message:
          "One or more of the selected syncs cannot be run. Ensure you have the required permissions to perform this action.",
        variant: "error",
      });
      Sentry.captureException(error);
    }
    setProcessingAction(false);
  };

  const syncs: Array<SyncRowData> | undefined = useMemo(() => {
    const labelData = syncsLabelsQuery.data?.syncs;
    if (labelData) {
      return syncsQuery.data?.syncs?.map((s) => ({
        ...s,
        labels: labelData.find((d) => s.id === d.id)?.labels,
      }));
    }
    return syncsQuery.data?.syncs;
  }, [syncsQuery.data, syncsLabelsQuery.data]);

  const syncsCount = syncsQuery.data?.syncs_aggregate?.aggregate?.count ?? 0;

  const selectedSegmentQueryTypes =
    syncs
      ?.filter((sync) => selectedRows.includes(sync.id))
      ?.map((sync) => sync.segment?.query_type) || [];

  const moveToFolderType =
    selectedFolderType ||
    (selectedSegmentQueryTypes.includes("visual") ? "audiences" : "models");

  const areJourneySyncsSelected = selectedRows.some(
    (id) =>
      syncs?.find((sync) => sync.id === id)?.segment?.query_type ===
      QueryType.JourneyNode,
  );

  const selectedSyncsMixesModelsAndAudiences =
    selectedSegmentQueryTypes.some((type) => type === QueryType.Visual) &&
    selectedSegmentQueryTypes.some((type) => type !== QueryType.Visual);

  const columns = useMemo(
    (): Array<TableColumn<SyncRowData>> => [
      {
        name: workspace?.alerting_v2_enabled ? "Health" : "Status",
        min: "160px",
        max: "160px",
        cell: ({
          id,
          status,
          health,
          sync_requests,
          schedule_paused,
          last_run_at,
          draft: isInitialDraft,
        }: NonNullable<typeof syncs>[number]) => {
          const draft = drafts?.drafts.find(
            (d) => String(d.resource_id) === String(id),
          );

          // If alerting v2 is not enabled, or it's not an initial draft,
          // then add a little extra metadata about the draft state or current status.
          return (
            <Column gap={1}>
              <Row align="center" gap={2}>
                <SyncHealthOrLastStatusBadge
                  status={status}
                  health={health}
                  syncRequests={sync_requests}
                  schedulePaused={Boolean(schedule_paused)}
                  lastRunAt={last_run_at}
                  isInitialDraft={Boolean(isInitialDraft)}
                />
                {draft && <DraftIcon draft={draft} />}
              </Row>
            </Column>
          );
        },
        breakpoint: "sm",
      },
      {
        name: "Model",
        cell: ({ segment }) => {
          return <ModelCell segment={segment} />;
        },
      },
      {
        name: "Destination",
        cell: ({ destination, labels, config }) => {
          const syncBoosted = isSyncMatchBoosted(config);
          return (
            <DestinationCell
              destinationName={destination?.name ?? ""}
              metadata={labels}
              definition={destination?.definition}
              isSyncBoosted={syncBoosted}
            />
          );
        },
      },
      {
        name: "Last run",
        min: "180px",
        max: "180px",
        cell: ({
          sync_requests,
          schedule,
        }: NonNullable<typeof syncs>[number]) => {
          const latestSyncRequest = sync_requests?.[0];

          return (
            <Column minW={0}>
              <Row fontWeight="medium">
                <SyncLastRunDisplay
                  isStreaming={
                    schedule?.type === ScheduleType.STREAMING || false
                  }
                  status={syncRunStatusOrUnknownStatus(
                    latestSyncRequest?.status_computed,
                  )}
                  finishedAt={latestSyncRequest?.finished_at || null}
                  createdAt={latestSyncRequest?.created_at || null}
                  completionRatio={latestSyncRequest?.completion_ratio || null}
                />
              </Row>

              <Row>
                <TextWithTooltip size="sm" color="text.secondary">
                  {describeWhenSyncRuns(schedule)}
                </TextWithTooltip>
              </Row>
            </Column>
          );
        },
        breakpoint: "sm" as const,
      },
      { ...LastUpdatedColumn, breakpoint: "md" },
      {
        name: "Labels",
        cell: ({ tags }) => {
          if (isEmpty(tags)) {
            return "--";
          }
          return <Labels labels={tags} />;
        },
        breakpoint: "lg",
      },
    ],
    [drafts],
  );

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/syncs/${id}`, navigate, event),
    [navigate],
  );

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No syncs found",
      error: "Syncs failed to load, please try again.",
    }),
    [],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  const disabledRowsSelected = Boolean(
    syncs?.some(
      ({ id, status }) =>
        status === SyncRunStatus.DISABLED &&
        selectedRows.find((rowId) => rowId === id),
    ),
  );

  return (
    <PermissionProvider
      permission={{
        v1: {
          resource: "sync",
          grant: "create",
        },
      }}
    >
      <Page
        sidebar={
          <PageSidebar
            header={
              <SearchInput
                placeholder="Search all syncs..."
                value={search ?? ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            }
          >
            <Column>
              <Folders
                totalCount={totalCount}
                folders={folders ?? []}
                loading={loadingFolders}
                modelCount={modelCount}
                audienceCount={audienceCount}
                audiencesRootName={
                  audiencesEnabled ? "Audience syncs" : undefined
                }
                modelsRootName="Model syncs"
                refetchFolders={refetchFolders}
                rootFolder={selectedFolderType}
                selectedFolder={selectedFolder}
                setRootFolder={setSelectedFolderType}
                setSelectedFolder={setSelectedFolder}
                viewType="syncs"
                activeSearch={Boolean(search)}
                clearFolderType={clearFolderType}
              />
              <JourneysFolder
                setSelectedFolder={setSelectedFolder}
                setSelectedFolderType={setSelectedFolderType}
                isSelected={selectedFolderType === "journeys"}
                count={journeyCount}
              />
              <DecisionEnginesFolder
                setSelectedFolder={setSelectedFolder}
                setSelectedFolderType={setSelectedFolderType}
                isSelected={selectedFolderType === "decisioning"}
                count={decisioningCount}
              />
            </Column>
            <Box borderBottom="1px solid" borderColor="base.divider" />
            <Filters
              clearFilters={clearFilters}
              createView={createView}
              creatingView={creatingView}
              deleteView={deleteView}
              filters={filterData}
              resetFilters={resetViewFilters}
              resource="sync"
              selectView={selectView}
              selectedView={selectedView}
              updateCurrentView={updateCurrentView}
              updatingView={updatingView}
              viewNotSaved={viewNotSaved}
              views={views}
            />
          </PageSidebar>
        }
        title="Syncs"
      >
        <PageTable
          sortOptions={sortOptions}
          header={
            <>
              <Heading isTruncated size="xl">
                {selectedFolderType === "journeys"
                  ? "All journey syncs"
                  : header}
              </Heading>
              <Row flexShrink={0} gap={3}>
                {selectedRows.length > 0 && (
                  <Row align="center" gap={2}>
                    <Text>{`${pluralize(
                      "sync",
                      selectedRows.length,
                      true,
                    )} selected`}</Text>
                    <Menu>
                      <MenuButton>Actions</MenuButton>
                      {/* 
                          Note: the actions below are for running bulk operations on selected syncs.
                          In permissions v2, we don't have a good app-ui for checking if the user has permission to perform all the actions. 
                          So instead, we'll let any users click these buttons, but the backend will enforce the permission checks.
                          If the user doesn't have permission, the backend will return an error, and the frontend will show a toast message.
                      */}
                      <MenuList>
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "sync", grant: "update" },
                          }}
                          icon={FolderIcon}
                          isDisabled={
                            processingAction || areJourneySyncsSelected
                          }
                          tooltip={
                            areJourneySyncsSelected
                              ? "Journey syncs may not be added to folders."
                              : undefined
                          }
                          onClick={() => {
                            setMovingToFolder(true);
                          }}
                        >
                          Move to folder
                        </PermissionedMenuItem>
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "sync", grant: "update" },
                          }}
                          icon={TagIcon}
                          onClick={() => {
                            setAddingLabels(true);
                          }}
                        >
                          Add labels
                        </PermissionedMenuItem>
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "sync", grant: "update" },
                          }}
                          icon={LightningBoltWithSlashIcon}
                          isDisabled={
                            processingAction || areJourneySyncsSelected
                          }
                          tooltip={
                            areJourneySyncsSelected
                              ? "Journey syncs may not be disabled."
                              : undefined
                          }
                          onClick={() => {
                            bulkUpdateStatus(false);
                          }}
                        >
                          Disable
                        </PermissionedMenuItem>
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "sync", grant: "update" },
                          }}
                          icon={LightningBoltIcon}
                          isDisabled={
                            processingAction || areJourneySyncsSelected
                          }
                          tooltip={
                            areJourneySyncsSelected
                              ? "Journey syncs may not be enabled."
                              : undefined
                          }
                          onClick={() => {
                            bulkUpdateStatus(true);
                          }}
                        >
                          Enable
                        </PermissionedMenuItem>
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "sync", grant: "start" },
                          }}
                          icon={PlayIcon}
                          isDisabled={
                            processingAction || areJourneySyncsSelected
                          }
                          tooltip={
                            areJourneySyncsSelected
                              ? "Journey syncs may only be triggered by the journey."
                              : undefined
                          }
                          onClick={() => setConfirmBulkRun("run")}
                        >
                          Trigger sync
                        </PermissionedMenuItem>
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "sync", grant: "start" },
                          }}
                          icon={RefreshIcon}
                          isDisabled={
                            processingAction || areJourneySyncsSelected
                          }
                          tooltip={
                            areJourneySyncsSelected
                              ? "Journey syncs may only be triggered by the journey."
                              : undefined
                          }
                          onClick={() => setConfirmBulkRun("resync")}
                        >
                          Trigger full resync
                        </PermissionedMenuItem>
                        <ConfirmationDialog
                          confirmButtonText={`${
                            confirmBulkRun === "run" ? "Run" : "Resync"
                          } ${pluralize("sync", selectedRows.length, true)}`}
                          isOpen={confirmBulkRun !== undefined}
                          title={`Confirm bulk ${confirmBulkRun}`}
                          variant={
                            confirmBulkRun === "run" ? "warning" : "danger"
                          }
                          onClose={() => setConfirmBulkRun(undefined)}
                          onConfirm={() => bulkRun(confirmBulkRun === "resync")}
                        >
                          <Paragraph>
                            {disabledRowsSelected &&
                              "Some of the syncs you've selected are currently disabled. "}
                            {confirmBulkRun === "resync" && (
                              <>
                                <ResyncWarning />
                                <br />
                              </>
                            )}
                            {`Are you sure you want to ${confirmBulkRun} ${pluralize(
                              "sync",
                              selectedRows.length,
                              true,
                            )}?`}
                          </Paragraph>
                        </ConfirmationDialog>
                        <MenuDivider />
                        <PermissionedMenuItem
                          permission={{
                            v1: { resource: "sync", grant: "delete" },
                          }}
                          icon={DeleteIcon}
                          tooltip={
                            areJourneySyncsSelected
                              ? "Journey syncs may only be deleted from the journey itself"
                              : undefined
                          }
                          isDisabled={
                            processingAction || areJourneySyncsSelected
                          }
                          variant="danger"
                          onClick={() => {
                            setConfirmingDelete(true);
                          }}
                        >
                          Delete
                        </PermissionedMenuItem>
                      </MenuList>
                    </Menu>
                  </Row>
                )}

                <PermissionedLinkButton
                  href="/syncs/new"
                  isDisabled={overageLockout}
                  permission={{ v1: { resource: "sync", grant: "create" } }}
                  tooltip={overageLockout && overageText}
                  variant="primary"
                  onClick={() => {
                    analytics.track("Add Sync Clicked");
                  }}
                >
                  Add sync
                </PermissionedLinkButton>
              </Row>
            </>
          }
          columns={columns}
          data={syncs}
          error={Boolean(syncsQuery.error)}
          loading={
            syncsQuery.isLoading ||
            syncsQuery.isPreviousData ||
            isSearchPending()
          }
          placeholder={placeholder}
          selectedRows={selectedRows}
          onRowClick={onRowClick}
          onSelect={onRowSelect}
          rowHeight="80px"
          pagination={{
            count: syncsCount,
            label: "syncs",
            page,
            setPage,
          }}
        />

        <ConfirmationDialog
          isOpen={confirmingDelete}
          title={`Delete ${pluralize(
            "this",
            selectedRows.length,
            false,
          )} ${pluralize("Sync", selectedRows.length, false)}?`}
          variant="danger"
          confirmButtonText="Delete"
          onClose={() => setConfirmingDelete(false)}
          onConfirm={() => bulkDelete()}
        >
          <Paragraph>
            You will lose your sync{" "}
            {pluralize("configuration", selectedRows.length, false)}.
          </Paragraph>
        </ConfirmationDialog>

        <EditLabelModal
          rows={selectedRows.length}
          isOpen={addingLabels}
          resourceType={ResourceType.Sync}
          onClose={() => setAddingLabels(false)}
          onSubmit={async (labels) => bulkEditLabels(labels)}
        />

        {movingToFolder && (
          <MoveFolder
            disabled={selectedSyncsMixesModelsAndAudiences}
            folder={null}
            folderType={
              moveToFolderType !== "journeys" &&
              moveToFolderType !== "decisioning"
                ? moveToFolderType
                : "models"
            }
            modelIds={selectedRows.map((row) => {
              const sync = syncs?.find((sync) => sync.id === row);
              return sync?.segment?.id;
            })}
            viewType="syncs"
            onClose={() => {
              setMovingToFolder(false);
              syncsQuery.refetch();
              onRowSelect([]);
            }}
          />
        )}

        {routeState?.onboardingSync && (
          <ConfettiModal syncId={routeState.onboardingSync} />
        )}
      </Page>
    </PermissionProvider>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.sync) {
    return <Syncs />;
  }

  let props;

  if (!resources?.source) {
    props = pageAlertProps.source;
  } else if (!resources?.destination) {
    props = pageAlertProps.destination;
  } else if (!resources?.model) {
    props = pageAlertProps.model;
  }

  return (
    <Page
      fullWidth
      outsideTopbar={props ? <PageAlert {...props} /> : null}
      title="Syncs"
    >
      <Heading mb={8} size="xl">
        Syncs
      </Heading>
      <Placeholder
        content={{
          image: syncPlaceholder,
          title: "No syncs in this workspace",
          body: "A sync defines how and when your data will be sent to a destination. Syncs are usually configured by mapping your data to specific fields in the downstream tool.",
          button: props ? null : (
            <PermissionedLinkButton
              permission={{ v1: { resource: "sync", grant: "create" } }}
              href="/syncs/new"
              variant="primary"
            >
              Add sync
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;

const pageAlertProps = {
  model: {
    title: "First, you need to configure a model",
    message:
      "Before you can create your first sync, you'll need to define how your data source will be queried. Hightouch supports different modeling methods such as querying with SQL, selecting an existing table, or importing models from tools like Looker and dbt.",
    button: (
      <PermissionedLinkButton
        href="/models/new"
        permission={{ v1: { resource: "model", grant: "create" } }}
        variant="primary"
      >
        Configure model
      </PermissionedLinkButton>
    ),
  },
  source: {
    title: "First, you need to configure a data source",
    message:
      "Hightouch must be connected to least one data source before you can create a sync. Your source can be a data warehouse, spreadsheet, or other data system.",
    button: (
      <PermissionedLinkButton
        href="/sources/new"
        permission={{ v2: { resource: "source", grant: "can_create" } }}
        variant="primary"
      >
        Configure data source
      </PermissionedLinkButton>
    ),
  },
  destination: {
    title: "First, you need to configure a destination",
    message: `Hightouch must be connected to at least one destination before you can create a sync. Your destination can be chosen from our catalog of ${
      import.meta.env.VITE_DESTINATIONS_COUNT
    }+ supported tools.`,
    button: (
      <PermissionedLinkButton
        href="/destinations/new"
        permission={{ v2: { resource: "destination", grant: "can_create" } }}
        variant="primary"
      >
        Configure destination
      </PermissionedLinkButton>
    ),
  },
};
