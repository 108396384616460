import {
  Column,
  Combobox,
  EmptyState,
  Heading,
  Row,
  Spinner,
} from "@hightouchio/ui";
import { LinkButton, useSearchParams } from "src/router";
import { Outlet, useOutletContext } from "src/router";
import { Suspense, useMemo } from "react";

import { Page } from "src/components/layout";
import { OutletContext } from "..";
import {
  DecisionEngineFlowQuery,
  useDecisionEngineFlowQuery,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { PageSpinner } from "src/components/loading";
import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { RouteTabs } from "src/components/route-tabs";

export type InsightsContext = OutletContext & {
  flow: NonNullable<DecisionEngineFlowQuery["decision_engine_flows_by_pk"]>;
};

export const InsightsLoader = () => {
  const engineContext = useOutletContext<OutletContext>();
  const activeFlows = engineContext.engine.flows.filter(
    (f) => f.status === "ready" || engineContext.demoDefinition,
  );

  if (!activeFlows?.length) {
    return (
      <Page
        title="Insights"
        fullWidth
        sx={{ bg: "base.lightBackground", p: 0 }}
        header={
          <Column
            justify="space-between"
            py={4}
            bg="white"
            px={6}
            borderBottom="1px"
            borderColor="base.border"
          >
            <Heading size="xl">Insights</Heading>
          </Column>
        }
      >
        <EmptyState
          m={6}
          imageUrl={genericPlaceholder}
          title="No flows"
          message="Add a flow to start seeing insights"
          actions={
            <LinkButton variant="primary" href="/ai/flows/new">
              Add flow
            </LinkButton>
          }
        />
      </Page>
    );
  }

  return <Insights />;
};

export const Insights = () => {
  const engineContext = useOutletContext<OutletContext>();
  const activeFlows = engineContext.engine.flows.filter(
    (f) => f.status === "ready" || engineContext.demoDefinition,
  );

  const [_searchParams, setSearchParams] = useSearchParams();
  const [flowId] = useQueryState("flow");

  const { data: flow } = useDecisionEngineFlowQuery(
    {
      id: flowId ? String(flowId) : activeFlows[0]!.id,
    },
    {
      select: (data) => data.decision_engine_flows_by_pk,
    },
  );

  const context: InsightsContext = useMemo(
    () => ({
      ...engineContext,
      flow: flow!,
    }),
    [engineContext, flow],
  );

  if (!flow) {
    return <PageSpinner />;
  }

  return (
    <Page
      title="Insights"
      fullWidth
      sx={{ bg: "base.lightBackground", p: 0 }}
      header={
        <Column justify="space-between" bg="white" pt={6} gap={4}>
          <Row gap={4} align="center" px={6}>
            <Heading size="xl">Insights</Heading>
            <Combobox
              width="2xs"
              value={flow.id}
              options={activeFlows}
              onChange={(value: any) => {
                if (!value) return;

                setSearchParams((params) => {
                  params.set("flow", value.toString());
                  params.delete("outcome");
                  params.delete("r1");
                  params.delete("d1");
                  params.delete("r2");
                  params.delete("d2");
                  params.delete("a");
                  params.delete("d");

                  return params;
                });
              }}
              optionValue={(flow) => flow.id}
              optionLabel={(flow) => flow.name}
            />
          </Row>
          <RouteTabs
            depth={4}
            px={6}
            tabs={[
              { path: "overview", title: "Overview" },
              { path: "decisions", title: "Decisions" },
              { path: "personalizations", title: "Personalization" },
            ]}
          />
        </Column>
      }
    >
      <Suspense fallback={<Spinner size="lg" m="auto" />}>
        <Column mx="auto" maxW="6xl" w="100%">
          <Outlet context={context} />
        </Column>
      </Suspense>
    </Page>
  );
};
