import { Suspense } from "react";

import {
  CloseIcon,
  IconButton,
  Row,
  SectionHeading,
  Spinner,
} from "@hightouchio/ui";
import { Outlet, useNavigate, useParams } from "src/router";

import { useOrganizationWorkspaceWithUserGroupQuery } from "src/graphql";
import { Drawer } from "src/components/drawer";
import { RouteTabs } from "src/components/route-tabs";

export type WorkspaceOutletContext = {
  isWorkspaceAdmin: boolean;
  isWorkspaceMember: boolean;
};

export type OutletContext = {
  isWorkspaceMember: boolean;
  isWorkspaceAdmin: boolean;
  isApprovalsRequired: boolean;
};

type Props = {
  redirectToWorkspaceSettings?: boolean;
};

export const RoleWorkspacePolicy = ({
  redirectToWorkspaceSettings = false,
}: Props) => {
  const navigate = useNavigate();

  const { workspaceId, roleId } = useParams();

  const { data: workspace } = useOrganizationWorkspaceWithUserGroupQuery(
    { workspaceId: workspaceId ?? "", userGroupId: roleId ?? "" },
    {
      select: (data) => data.workspaces_by_pk,
      enabled: Boolean(workspaceId),
      suspense: true,
    },
  );

  const workspaceUserGroup = workspace?.user_groups?.[0];
  const isWorkspaceMember = Boolean(workspaceUserGroup);
  const outletContext = {
    isWorkspaceMember,
    isWorkspaceAdmin: Boolean(workspaceUserGroup?.is_workspace_admin),
    isApprovalsRequired: Boolean(workspace?.approvals_required),
  };

  const tabs = [
    { title: "General", path: "general", disabled: !isWorkspaceMember },
    { title: "Sources", path: "sources", disabled: !isWorkspaceMember },
    {
      title: "Destinations",
      path: "destinations",
      disabled: !isWorkspaceMember,
    },
    {
      title: "Customer Studio",
      path: "parent-models",
      disabled: !isWorkspaceMember,
    },
  ];

  const onClose = () => {
    // By default we redirect to the organizations page.
    // But in some cases this may be used in a workspace context, so callers can override this behavior.
    if (redirectToWorkspaceSettings) {
      navigate("/settings/groups");
    } else {
      navigate(`/organization/groups/${roleId}/workspaces`);
    }
  };

  return (
    <Drawer isOpen={true} onClose={onClose} size="2xl">
      <Row align="center" justify="space-between" gap={4} px={6} pt={6}>
        <SectionHeading>{workspace?.name}</SectionHeading>
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      </Row>
      <RouteTabs tabs={tabs} depth={7} px={6} />
      <Suspense fallback={<Spinner size="lg" m="auto" />}>
        <Outlet context={outletContext} />
      </Suspense>
    </Drawer>
  );
};
