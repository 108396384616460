import {
  Box,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  EditableHeading,
  FormField,
  IconButton,
  Row,
  Spinner,
  useToast,
} from "@hightouchio/ui";
import { FC, Suspense } from "react";
import { captureException } from "@sentry/react";

import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";
import { DeleteButton } from "src/components/delete-button";
import { Drawer } from "src/components/drawer";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  DecisionEngineOutcomeQuery,
  useDecisionEngineOutcomeQuery,
  useDeleteDecisionEngineOutcomeMutation,
  useUpdateDecisionEngineOutcomeMutation,
} from "src/graphql";
import eventIcon from "src/pages/schema/assets/event.svg";
import { FlowContext } from "..";
import { ClassificationField } from "./components/classification-field";
import { EventModelSelect } from "./components/event-model-select";
import { OutcomeCampaignField } from "./components/outcome-campaign-field";
import { WeightField } from "./components/weight-field";
import { OutcomeModelFilter } from "./components/outcome-model-filter";

export const Outcome = () => {
  const { outcomeId, flowId } = useParams<{
    outcomeId: string;
    flowId: string;
  }>();
  const navigate = useNavigate();

  const onClose = () => {
    navigate(`/ai/flows/${flowId}`);
  };

  const { data: outcome, isLoading } = useDecisionEngineOutcomeQuery(
    {
      id: outcomeId ?? "",
    },
    { select: (data) => data.decision_engine_outcomes_by_pk },
  );

  if (isLoading) {
    return (
      <Drawer isOpen onClose={onClose} size="xl">
        <Spinner size="lg" m="auto" />
      </Drawer>
    );
  }

  if (!outcome) {
    return <Navigate to={`/ai/flows/${flowId}/outcomes`} />;
  }

  return (
    <Drawer isOpen onClose={onClose} size="xl">
      <Content outcome={outcome} />
    </Drawer>
  );
};

const Content: FC<
  Readonly<{
    outcome: NonNullable<
      DecisionEngineOutcomeQuery["decision_engine_outcomes_by_pk"]
    >;
  }>
> = ({ outcome }) => {
  const { flow, engine } = useOutletContext<FlowContext>();
  const updateMutation = useUpdateDecisionEngineOutcomeMutation();
  const deleteMutation = useDeleteDecisionEngineOutcomeMutation();

  const navigate = useNavigate();

  const onClose = () => {
    navigate(`/ai/flows/${flow.id}`);
  };

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await updateMutation.mutateAsync({
        id: outcome.id,
        input: {
          segment_id: data.model.id,
          weight: data.weight,
          filter: data.filter,
        },
        appendInput: {
          attribution: data.attribution,
        },
      });
    },
    values: {
      model: outcome.segment,
      weight: outcome.weight,
      attribution: outcome.attribution,
      filter: outcome.filter,
    },
  });

  return (
    <Form form={form}>
      <Column gap={2} p={6} borderBottom="1px" borderColor="base.border">
        <Row alignItems="center" justifyContent="space-between">
          <Heading outcome={outcome} />
          <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
        </Row>
        <Row gap={2} align="center">
          <Box as="img" src={eventIcon} width="20px" />
          <Link
            href={`/schema-v2/view/query?source=${
              engine.segment.connection!.id
            }&id=${outcome.segment.id}`}
          >
            <TextWithTooltip fontWeight="medium" color="inherit">
              {outcome.segment?.name}
            </TextWithTooltip>
          </Link>
        </Row>
      </Column>
      <DrawerBody>
        <Column gap={6} flex={1}>
          <Suspense fallback={<Spinner size="lg" m="auto" />}>
            <FormField label="Event model">
              <EventModelSelect />
            </FormField>
            <OutcomeModelFilter />
            <ClassificationField />
            <WeightField />
            <OutcomeCampaignField />
          </Suspense>
        </Column>
      </DrawerBody>
      <DrawerFooter>
        <FormActions />
        <DeleteButton
          label="outcome"
          onDelete={async () => {
            await deleteMutation.mutateAsync({ id: outcome.id });
          }}
        />
      </DrawerFooter>
    </Form>
  );
};

const Heading = ({ outcome }: { outcome: { name: string; id: string } }) => {
  const updateMutation = useUpdateDecisionEngineOutcomeMutation();
  const { toast } = useToast();

  const updateName = async (name: string) => {
    try {
      await updateMutation.mutateAsync({
        id: outcome.id,
        input: { name },
        appendInput: {},
      });
      toast({
        id: "update-outcome-name",
        variant: "success",
        title: "Outcome name updated successfully",
      });
    } catch (error) {
      toast({
        id: "update-outcome-name",
        variant: "error",
        title: "Failed to update outcome name",
      });
      captureException(error);
    }
  };

  return <EditableHeading value={outcome.name} onChange={updateName} />;
};
