import { useEffect, useMemo } from "react";

import { useFormContext } from "react-hook-form";

import { useMissingFormkitRelationships } from "src/formkit/components/sync-template-lock";

type UseSetAutoSyncColumns = {
  /**
   * The number of mappings applied.
   */
  numberOfMappingsApplied: number;
  /**
   * The field name. This corresponds to the field name in the config that's set in react-hook-form state.
   */
  mappingsFieldName: string;
  /**
   * Defaults to `autoSyncColumns`
   */
  autoSyncColumnsKey?: string;
  /**
   * Defaults value for the autoSyncColumnsKey.
   *
   * @default false
   */
  autoSyncColumnsDefault?: boolean;
  /**
   * Whether the 'sync all columns' option is visible.
   */
  allEnabled?: boolean;
  /**
   * A callback function that is called when the mappings are reset.
   */
  onResetMappings?: () => void;
};

/**
 * This hook is used to set the autoSyncColumns in the form state.
 *
 * The `mappingsFieldName` is the name of the field that contains the mappings.
 * `autoSyncColumnsKey` is the name of the key in the form state that contains the autoSyncColumns.
 *
 * `autoSyncColumns` (or whichever key is passed in) is **not** set
 * by the formkit definition.
 *
 * This hook centralizes and manages where and when that key is set.
 */
export const useManageAutoSyncColumnsState = ({
  allEnabled,
  autoSyncColumnsDefault,
  autoSyncColumnsKey = "autoSyncColumns",
  numberOfMappingsApplied,
  mappingsFieldName,
  onResetMappings,
}: UseSetAutoSyncColumns) => {
  const { setValue, watch } = useFormContext();

  const autoSyncColumnValue = watch(autoSyncColumnsKey);

  // Reset the columns to an empty array when the autoSyncColumnsKey is
  // set to true.
  useEffect(() => {
    if (autoSyncColumnValue) {
      onResetMappings?.();
    }
  }, [autoSyncColumnValue]);

  // On mount, the autoSyncColumnsKey may not be set in the form state.
  // Make sure the the autoSyncColumnsKey is set correctly in the form state
  // if the default changes in the formkit definition
  useEffect(() => {
    if (
      allEnabled &&
      autoSyncColumnValue === undefined &&
      numberOfMappingsApplied === 0
    ) {
      setValue(autoSyncColumnsKey, autoSyncColumnsDefault);
    } else if (!allEnabled) {
      setValue(autoSyncColumnsKey, undefined);
    }
  }, [autoSyncColumnsDefault, autoSyncColumnValue, numberOfMappingsApplied]);

  /**SYNC TEMPLATE OVERRIDES*/
  useMissingFormkitRelationships(
    mappingsFieldName,
    useMemo(
      () => (allEnabled ? [autoSyncColumnsKey] : []),
      [autoSyncColumnsKey, allEnabled],
    ),
  );
};
