import * as Sentry from "@sentry/react";
import { SomeJSONSchema } from "ajv/dist/types/json-schema";

import { ContractProperty } from "src/events/contracts/types";
import { EventSchemaEventType } from "src/graphql";
import * as Yup from "yup";

export const editableProperties: Record<EventSchemaEventType, string> = {
  identify: "traits",
  group: "traits",
  page: "properties",
  screen: "properties",
  track: "properties",
  alias: "properties",
};

// The visual editor only allows users to edit "properties" (or "traits") property of the schema.
// To convert to a json object wrap in the expected top level structure.
const wrapInTopLevelSchema = (
  schema: SomeJSONSchema,
  eventType: EventSchemaEventType,
): SomeJSONSchema => {
  const property = editableProperties[eventType];

  return {
    type: "object",
    properties: {
      [property]: schema,
    },
  } as SomeJSONSchema;
};

const unwrapTopLevelSchema = (
  schema: SomeJSONSchema,
  eventType: EventSchemaEventType,
): SomeJSONSchema => {
  const property = editableProperties[eventType];

  return schema.properties[property];
};

export const convertContractPropertiesToJsonSchema = (
  properties: ContractProperty[],
  eventType: EventSchemaEventType,
): SomeJSONSchema => {
  const convert = (properties: ContractProperty[]): SomeJSONSchema => {
    const jsonSchema: any = {
      type: "object",
      properties: {},
    };

    const required: string[] = [];

    properties.forEach((property) => {
      const schema: SomeJSONSchema = (jsonSchema.properties![property.name] = {
        type: property.type as any,
      });
      if (property.description) {
        schema.description = property.description;
      }
      if (property.type === "object") {
        schema.properties = convert(property.properties).properties;
        const required = property.properties
          .filter((property) => property.required)
          .map((property) => property.name);

        if (required.length > 0) {
          schema.required = required;
        }
      }
      if (property.type === "array") {
        if (property?.properties != null) {
          if (property.properties.length > 1) {
            Sentry.captureException("Arrays should only have one nested type");
            return;
          } else if (
            property.properties.length === 1 &&
            property.properties[0] != null
          ) {
            const subProperty = property.properties[0];
            switch (subProperty.type) {
              case "array":
                Sentry.captureException(
                  "Arrays of arrays are not supported for contracts yet",
                );
                return;
              case "object":
                schema.items = convert(subProperty.properties);
                break;
              default:
                schema.items = { type: subProperty.type };
            }
          }
        }
      }

      if (property.required) {
        required.push(property.name);
      }
    });

    if (required.length > 0) {
      jsonSchema.required = required;
    }

    return jsonSchema;
  };

  return wrapInTopLevelSchema(convert(properties), eventType);
};

const ALLOWED_KEYS = ["type", "properties", "items", "required", "description"];

const jsonSchemaToContractProperties = (
  jsonSchema: SomeJSONSchema,
): ContractProperty[] => {
  const keys = Object.keys(jsonSchema);

  for (const key of keys) {
    if (!ALLOWED_KEYS.includes(key)) {
      // Too complicated for our simple form
      throw new Error(`Invalid key ${key} in JSON schema`);
    }
  }

  return Object.keys(jsonSchema.properties).map((name) => {
    const property = jsonSchema.properties[name];
    const contractProperty: ContractProperty = {
      name,
      type: property.type,
      description: property.description,
      required: jsonSchema.required && jsonSchema.required.includes(name),
      properties: property.items
        ? jsonSchemaToContractProperties({
            properties: { "": property.items },
          } as any)
        : property.properties
          ? jsonSchemaToContractProperties({
              properties: property.properties,
              required: property.required,
            } as any)
          : [],
    };

    return contractProperty;
  });
};

export const convertJsonSchemaToContractProperties = (
  jsonSchema: SomeJSONSchema,
  eventType: EventSchemaEventType,
): ContractProperty[] | null => {
  const { canConvert } = canJsonSchemaBeConvertedToContractProperties(
    jsonSchema,
    eventType,
  );

  if (!canConvert) {
    return null;
  }

  try {
    const schema = unwrapTopLevelSchema(jsonSchema, eventType);
    return jsonSchemaToContractProperties(schema);
  } catch (_err) {
    // schema too complicated for simple form
    return null;
  }
};

export type ConversionSuccess = { canConvert: true };
export type ConversionFailure = {
  canConvert: false;
  error?: string;
};

export type CheckConversionResult = ConversionSuccess | ConversionFailure;

// The visual editor only allows users to edit "properties" (or "traits") property of the schema.
// This requires pretty strict validation of the top level schema... expected shape:
// {
//   "type": "object",
//   "properties": {
//       ["properties"/"traits]": {
//           "type": "object",
//           "properties": { ... }
//       }
//   }
// }
const expectedEventSchema = (property: string) =>
  Yup.object()
    .shape({
      type: Yup.string().required().oneOf(["object"]),
      properties: Yup.object()
        .shape({
          [property]: Yup.object()
            .shape({
              type: Yup.string().required().oneOf(["object"]),
              properties: Yup.object().required(),
              required: Yup.array().of(Yup.string()).optional(),
            })
            .required()
            .noUnknown(),
        })
        .required()
        .noUnknown(),
    })
    .noUnknown();

const checkTopLevelSchemaProperties = (
  schema: SomeJSONSchema,
  eventType: EventSchemaEventType,
): CheckConversionResult => {
  const property = editableProperties[eventType];
  try {
    expectedEventSchema(property).validateSync(schema, {
      stripUnknown: false,
      strict: true,
    });
  } catch (error) {
    return {
      canConvert: false,
      error: error.message,
    };
  }

  return { canConvert: true };
};

export const canJsonSchemaBeConvertedToContractProperties = (
  jsonSchema: SomeJSONSchema,
  eventType: EventSchemaEventType,
): CheckConversionResult => {
  const checkConversionResult = checkTopLevelSchemaProperties(
    jsonSchema,
    eventType,
  );
  if (!checkConversionResult.canConvert) {
    return checkConversionResult;
  }

  const checkKeys = (schema: SomeJSONSchema): boolean => {
    if (!hasOnlyAllowedKeys(schema, ALLOWED_KEYS)) {
      // Too complicated for our simple form
      return false;
    }

    // XXX: We don't validate the values of any fields other than `properties`.
    return Object.keys(schema.properties).every((name) => {
      const property = schema.properties[name];

      switch (property?.type) {
        case "string":
          return hasOnlyAllowedKeys(property, ["type", "description"]);

        case "number":
          return hasOnlyAllowedKeys(property, ["type", "description"]);

        case "boolean":
          return hasOnlyAllowedKeys(property, ["type", "description"]);

        case "array":
          return (
            hasOnlyAllowedKeys(property, ["type", "description", "items"]) &&
            (property.items == null ||
              checkKeys({
                properties: { "": property.items },
              } as any))
          );

        case "object":
          return (
            hasOnlyAllowedKeys(property, [
              "type",
              "description",
              "properties",
              "required",
            ]) &&
            (property.properties == null ||
              checkKeys({
                properties: property.properties,
              } as SomeJSONSchema))
          );

        default:
          // Note that some JSONSchema validations such as enums don't have a type at all.
          return false;
      }
    });
  };

  const result = checkKeys(jsonSchema);

  // In the future maybe we can provide more detailed error messages when deeper validation fails.
  // But for now we just return a generic error.
  return { canConvert: result };
};

const hasOnlyAllowedKeys = (
  obj: Record<string, unknown>,
  allowedKeys: string[],
) => {
  for (const key of Object.keys(obj)) {
    if (!allowedKeys.includes(key)) {
      // Too complicated for our simple form
      return false;
    }
  }
  return true;
};
