import {
  type FormkitNode,
  type RelationshipHierarchy,
  NodeType,
} from "@hightouch/formkit";
import get from "lodash/get";
import uniq from "lodash/uniq";

import { OverrideConfig } from "src/components/destinations/types";

/**
 * Get all ancestors of a key, recursively
 */
function getAllAncestors(
  key: string,
  relationshipHierarchy: RelationshipHierarchy,
): string[] {
  const ancestors = Array.from(
    get(relationshipHierarchy, [key, "ancestors"], new Set<string>()),
  );
  const ancestorsOfAncestors = ancestors.flatMap((ancestorKey) =>
    getAllAncestors(ancestorKey, relationshipHierarchy),
  );

  return uniq([...ancestors, ...ancestorsOfAncestors]);
}

/**
 * Get the unlocked state of a field and whether any of its ancestors are unlocked
 */
export function getFieldContext({
  key,
  overrideConfig,
  relationshipHierarchy,
}: {
  overrideConfig: OverrideConfig | null | undefined;
  key: string;
  relationshipHierarchy: RelationshipHierarchy | null;
}): { isUnlocked: boolean; isAnyAncestorUnlocked: boolean } {
  if (!overrideConfig || !relationshipHierarchy) {
    return { isUnlocked: false, isAnyAncestorUnlocked: false };
  }

  const isUnlocked = get(overrideConfig, [key, "overridable"], false);

  // Get all ancestors of the key, and all ancestors of the ancestors
  const ancestors = getAllAncestors(key, relationshipHierarchy);
  const isAnyAncestorUnlocked = ancestors.some((ancestor) =>
    get(overrideConfig, [ancestor, "overridable"], false),
  );

  return {
    isUnlocked,
    isAnyAncestorUnlocked,
  };
}

/**
 * Determine if a node is visible by recursively checking the formkit definition
 * against the relationship hierarchy.
 *
 * TODO(Samuel): what's a better way to do this? Right now it'll check on every render.
 *
 * @param node - The node to check.
 * @param relationshipHierarchy - The relationship hierarchy of the form.
 * @param overrideConfig - The override config of the form.
 * @returns Whether the node is visible.
 */
export function isNodeVisible({
  node,
  relationshipHierarchy,
  overrideConfig = null,
}: {
  node: FormkitNode;
  relationshipHierarchy: RelationshipHierarchy | null;
  overrideConfig: OverrideConfig | null;
}): boolean {
  if (overrideConfig === null || relationshipHierarchy === null) {
    return true;
  }

  if (node.type === NodeType.Component) {
    // If node is unlocked, this node must be visible
    const { isUnlocked, isAnyAncestorUnlocked } = getFieldContext({
      key: node.key,
      overrideConfig,
      relationshipHierarchy,
    });

    // If this field or any of the ancestors are unlocked, this node must be visible
    if (isUnlocked || isAnyAncestorUnlocked) {
      return true;
    }
  }

  // If any of the children are unlocked, this node must be visible
  if (node.children) {
    return node.children.some((child) =>
      isNodeVisible({
        node: child,
        relationshipHierarchy,
        overrideConfig,
      }),
    );
  }

  return false;
}
