import { Column, Heading, Row } from "@hightouchio/ui";
import { DraftsQuery, DraftOperation } from "src/graphql";
import { LinkButton } from "src/router";

import { formatDatetime } from "src/utils/time";

type Props = {
  title: string;
  link: string;
  draft: DraftsQuery["drafts"][0];
};

export const DraftPageHeader = ({ title, link, draft }: Props) => (
  <Column>
    <Row sx={{ mb: 2, alignItems: "center", gap: 4 }}>
      <Heading isTruncated size="lg">
        {title}
      </Heading>
      <LinkButton
        href={
          draft.operation === DraftOperation.Create
            ? link
            : `${link}?editing=true`
        }
      >
        View draft
      </LinkButton>
    </Row>
    <Row color="text.secondary">
      {draft.operation === DraftOperation.Create ? "Changed" : "Created"} by{" "}
      {draft.created_by_user?.name || "unknown"} on{" "}
      {formatDatetime(draft.created_at!)}
    </Row>
  </Column>
);
