import { FC } from "react";

import { Button, Column, Heading, Row } from "@hightouchio/ui";
import JSON5 from "json5";
import { useNavigate, useOutletContext } from "src/router";

import {
  EventSchemaEventType,
  EventSchemaOnSchemaViolation,
  EventSchemaOnUndeclaredFields,
  useCreateEventSchemaV2Mutation,
} from "src/graphql";
import { TrackView } from "src/lib/analytics";

import { EventSchemaOutletContext } from "src/events/contracts/types";
import {
  createEventPath,
  defaultSchema,
  getDefaultProperty,
  validationResolver,
} from "src/events/contracts/utils";
import { convertContractPropertiesToJsonSchema } from "src/events/contracts/contract/event-schema/transformation";
import { DetailPage } from "src/components/layout";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { ActionBar } from "src/components/action-bar";
import { NameField } from "./fields/name-field";
import { TypeField } from "./fields/type-field";
import { SchemaEditor } from "./fields/schema-editor";
import { DescriptionField } from "./fields/description-field";
import { Divider } from "./divider";
import { EnforcementSettings } from "./fields/enforcement-settings";
import { generateEventSchemaSlug } from "./slugs";
import { useSlug } from "src/utils/slug";
import { DocsLink } from "src/components/docs-link";

export const CreateEventSchema: FC = () => {
  const navigate = useNavigate();
  const { contract } = useOutletContext<EventSchemaOutletContext>();
  const { getSlug } = useSlug();

  const createEventMutation = useCreateEventSchemaV2Mutation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Circular type
  const form = useHightouchForm({
    defaultValues: {
      name: "",
      description: "",
      editorState: {
        isJson: false,
        json: "",
        properties: [getDefaultProperty()],
      },
      eventType: EventSchemaEventType.Track,
      schema: defaultSchema,
      onSchemaViolation: EventSchemaOnSchemaViolation.AllowEvent,
      onUndeclaredFields: EventSchemaOnUndeclaredFields.AllowEvent,
    },
    resolver: validationResolver,
    error: "Failed to create event schema",
    success: "Event schema created",
    onSubmit: async (data) => {
      const isJson = data.editorState.isJson;

      const schema = isJson
        ? JSON5.parse(data.editorState.json)
        : convertContractPropertiesToJsonSchema(
            data.editorState.properties,
            data.eventType,
          );

      const name =
        data.eventType === EventSchemaEventType.Track ? data.name : null;
      const slug = await generateEventSchemaSlug(
        getSlug,
        { slug: contract.slug! },
        {
          event_name: name || undefined,
          event_type: data.eventType,
          event_version: "default",
        },
      );
      const { createEventSchemaV2: result } =
        await createEventMutation.mutateAsync({
          input: {
            event_name: name,
            slug: slug,
            event_type: data.eventType,
            event_plan_id: contract.id,
            event_version: "default",
            schema: { ...schema, description: data.description },
            on_schema_violation: data.onSchemaViolation,
            on_undeclared_fields: data.onUndeclaredFields,
          },
        });

      if (result.errors.length > 0 || !result.schema) {
        throw new Error(
          result.errors[0]?.message ||
            "Unexpected error creating event schema.",
        );
      }

      navigate(
        `../${createEventPath({
          eventName: result.schema.event_name,
          eventType: result.schema.event_type,
          eventVersion: result.schema.event_version,
        })}`,
      );
    },
    onError: (error) => {
      // If no error message just let our generic form error handling take care of it
      // There isn't a specific field error message to show in this case
      if (!error.message) return;

      const isContrainstViolation = error.message.includes(
        "Uniqueness violation",
      );

      if (
        formEventType === EventSchemaEventType.Track &&
        isContrainstViolation
      ) {
        form.setError("name", {
          message: "Event name must be unique",
        });
      } else if (isContrainstViolation) {
        form.setError("eventType", {
          message: `Contract already contains a schema for event type "${formEventType}"`,
        });
      } else {
        // For other cases, assume it's a json validation error,
        // that's the only other (expected) error the backend will send
        form.setError("editorState.json", {
          message: error.message,
        });
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Circular reference for ContractProperty type
  const formEventType = form.watch("eventType");

  return (
    <DetailPage
      title={`New event | ${contract.name}`}
      crumbs={[
        { label: "All contracts", link: "/events/contracts" },
        { label: contract.name, link: `/events/contracts/${contract.id}` },
      ]}
      header={
        <Heading size="lg" mt={1}>
          New event contract
        </Heading>
      }
    >
      <Column minWidth={0} width="100%">
        <Row align="center" justify="space-between" mb={6}>
          <Heading>Configuration</Heading>
          <DocsLink name="Event contracts" href="events/contracts/management" />
        </Row>
        <Form form={form}>
          <TrackView name="New Event Page" />
          <Column gap={4} pb={20} flexGrow={1}>
            <TypeField />
            {formEventType === EventSchemaEventType.Track && <NameField />}
            <Divider />

            <DescriptionField />
            <SchemaEditor />

            <Divider />
            <EnforcementSettings />

            <ActionBar>
              <FormActions
                permission={{
                  v1: { resource: "workspace", grant: "update" },
                  v2: {
                    resource: "workspace",
                    grant: "can_update",
                  },
                }}
              />
              <Button
                isDisabled={createEventMutation.isLoading}
                size="lg"
                variant="warning"
                onClick={() => navigate("../")}
              >
                Cancel
              </Button>
            </ActionBar>
          </Column>
        </Form>
      </Column>
    </DetailPage>
  );
};
