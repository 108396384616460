import { FC, useMemo } from "react";
import { createRoot } from "react-dom/client";
import { Extension } from "@codemirror/state";
import { FilterableColumn, TraitDefinition } from "src/types/visual";
import { SqlEditor, SqlEditorProps } from "src/components/sql-editor";
import {
  Decoration,
  DecorationSet,
  EditorView,
  MatchDecorator,
  ViewPlugin,
  ViewUpdate,
  WidgetType,
} from "@codemirror/view";
import {
  CubeIcon,
  FrameStackIcon,
  Row,
  Text,
  TraitIcon,
} from "@hightouchio/ui";
import {
  FormulaColumnAutocompletion,
  InjectedColumnRegexp,
  transformModelColumnsForFormulaTrait,
} from "./utils";

type Props = {
  columns: FilterableColumn[];
  traits: TraitDefinition[];
} & Omit<SqlEditorProps, "source">;

export const FormulaTraitEditor: FC<Props> = ({
  columns,
  readOnly,
  traits,
  value,
  onChange,
}) => {
  // Create the configuration that will be used for CodeMirror's autocomplete extension.
  // Also use that configuration to Instantiate a custom CodeMirror extension.
  // This custom extension will be used for rendering pills in the textbox.
  const { config, columnPlaceholderExtension } = useMemo<{
    config: { schema: FormulaColumnAutocompletion[] };
    columnPlaceholderExtension: Extension;
  }>(() => {
    const autocompleteSuggestions: FormulaColumnAutocompletion[] =
      transformModelColumnsForFormulaTrait({ columns, traits });

    return {
      config: {
        schema: autocompleteSuggestions,
      },
      columnPlaceholderExtension: createPlaceholderExtension(
        autocompleteSuggestions,
      ),
    };
  }, [columns, traits]);

  return (
    <SqlEditor
      source={undefined}
      bg="base.lightBackground"
      minHeight="80px"
      placeholder="CASE WHEN ltv > 100 THEN 'VIP' ELSE 'Regular' END"
      value={value}
      onChange={onChange}
      sqlConfigOverride={config}
      extensions={[columnPlaceholderExtension]}
      readOnly={readOnly}
    />
  );
};

const PlaceholderComponent = ({
  label,
  detail,
  isParent,
  isMerged,
  isTrait,
}: {
  label: string;
  detail?: string;
  isParent?: boolean;
  isMerged?: boolean;
  isTrait?: boolean;
}) => {
  let backgroundColor: string;
  let Icon;

  // XXX: Using CSS vars here is intentional. Using theme colors directly does not work for some reason.
  // They are also a little darker than the colors we use elsewhere (500 vs 400) for the sake of readability.
  if (isTrait) {
    backgroundColor = "var(--chakra-colors-warning-500)";
    Icon = TraitIcon;
  } else if (isMerged) {
    backgroundColor = "var(--chakra-colors-grass-500)";
    Icon = FrameStackIcon;
  } else if (isParent) {
    backgroundColor = "var(--chakra-colors-electric-500)";
    Icon = CubeIcon;
  } else {
    backgroundColor = "var(--chakra-colors-danger-500)";
    Icon = CubeIcon;
  }

  return (
    <Row
      alignItems="center"
      aria-hidden
      bg={backgroundColor}
      border="none"
      borderRadius={8}
      css={{
        // Making this its own prop doesn't work. That's why it's nested inside the `css` prop.
        backgroundImage:
          "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)",
      }}
      gap={2}
      fontSize="12px"
      px={6}
      width="fit-content"
      height="16px"
      // Bit of a hack to get this looking like its vertically centered with the rest of the text.
      // Normally we'd use flexbox, but that doesn't work well with the surrounding elements.
      // Maybe something to revisit later, but the alignment looks pretty close with this.
      transform="translateY(1.5px)"
    >
      <Icon fill="white" />
      <Text color="white">
        {label}
        {detail && ` (${detail})`}
      </Text>
    </Row>
  );
};

class ColumnPlaceholderWidget extends WidgetType {
  constructor(
    readonly text: string | undefined,
    readonly decorationMetadata: FormulaColumnAutocompletion[],
  ) {
    super();
  }

  override eq(other: ColumnPlaceholderWidget) {
    return other.text === this.text;
  }

  toDOM() {
    const element = document.createElement("div");
    element.style.setProperty("display", "inline-block");
    if (!this.text) {
      return element;
    }

    const root = createRoot(element);

    const column = this.decorationMetadata.find(
      ({ apply }) => apply === this.text,
    );
    if (!column) {
      // We may not find the column if it was deleted or the generated alias changed somehow.
      // Just render a placeholder pill to inform the user that this column reference is broken.
      root.render(<PlaceholderComponent label="Missing column" />);
    } else {
      root.render(<PlaceholderComponent {...column} />);
    }

    return element;
  }
}

const createPlaceholderExtension = (
  decorationMetadata: FormulaColumnAutocompletion[],
) => {
  const placeholderMatcher = new MatchDecorator({
    regexp: InjectedColumnRegexp,
    decoration: (match) => {
      return Decoration.replace({
        widget: new ColumnPlaceholderWidget(match[0], decorationMetadata),
      });
    },
  });

  const extension = ViewPlugin.fromClass(
    class {
      placeholders: DecorationSet;
      constructor(view: EditorView) {
        this.placeholders = placeholderMatcher.createDeco(view);
      }
      update(update: ViewUpdate) {
        this.placeholders = placeholderMatcher.updateDeco(
          update,
          this.placeholders,
        );
      }
    },
    {
      decorations: (instance) => instance.placeholders,
      // Make every decoration an atomic object, so that deleting a
      // single character from the decoration's value will delete the whole pill.
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          return view.plugin(plugin)?.placeholders || Decoration.none;
        }),
    },
  );

  return extension;
};
