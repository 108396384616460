import { FC, useState } from "react";

import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  ButtonGroup,
  ClipboardButton,
  Dialog,
  FormField,
  Heading,
  Paragraph,
  Row,
  SectionHeading,
  Select,
  Spinner,
  Switch,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { sha256 } from "js-sha256";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { FeatureFull } from "src/components/feature-gates";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useUser } from "src/contexts/user-context";
import {
  useConfigureSsoMutation,
  useDeleteSsoGroupRolesMutation,
  useInsertSsoGroupRolesMutation,
  useUpdateOrganizationMutation,
  useWorkspaceQuery,
  useWorkspacesOrganizationsGroupsQuery,
  WorkspaceQuery,
  WorkspacesOrganizationsGroupsQuery,
} from "src/graphql";
import { newPylonMessage } from "src/lib/pylon";
import { FileUploader } from "src/ui/file";
import { Card } from "src/components/card";

export const SSO: FC = () => {
  const { workspace: _workspace } = useUser();
  const { data: workspaceData, refetch } = useWorkspaceQuery(
    { workspaceId: _workspace?.id },
    { enabled: Boolean(_workspace), suspense: true },
  );
  const [ssoModalOpen, setSsoModalOpen] = useState(false);
  const [createNewTokenOpen, setCreateNewTokenOpen] = useState(false);
  const [key, setKey] = useState<string | null>(null);

  const organization = workspaceData?.workspaces_by_pk?.organization;

  const isSsoEnabled = (organization?.auth0_connections || []).length > 0;

  const loginUrl = "https://app.hightouch.com/sso/" + organization?.slug;
  const connectionName =
    organization?.auth0_connections[0]?.name || `${organization?.slug}-1`;

  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const updateOrganizationMutation = useUpdateOrganizationMutation({
    async onMutate({ input }) {
      const queryKey = useWorkspaceQuery.getKey({
        workspaceId: _workspace?.id,
      });
      await queryClient.cancelQueries({ queryKey });

      const workspaceDataBeforeUpdate =
        queryClient.getQueryData<WorkspaceQuery>(queryKey);

      queryClient.setQueryData<WorkspaceQuery>(
        queryKey,
        (previousWorkspaceData) => {
          return {
            ...previousWorkspaceData!,
            workspaces_by_pk: {
              ...previousWorkspaceData!.workspaces_by_pk!,
              organization: {
                ...previousWorkspaceData!.workspaces_by_pk!.organization!,
                // OrganizationsSetInput has nullable types for all fields, so we need to cast it to any
                ...(input as any),
              },
            },
          };
        },
      );

      return { workspaceDataBeforeUpdate };
    },
    onSuccess() {
      toast({
        id: "sso",
        title: "SSO settings updated",
        variant: "success",
      });
    },
    onError(_error, _variables, context) {
      const queryKey = useWorkspaceQuery.getKey({
        workspaceId: _workspace?.id,
      });

      if (context?.workspaceDataBeforeUpdate) {
        queryClient.setQueryData(queryKey, context.workspaceDataBeforeUpdate);
      }

      toast({
        id: "sso",
        title: "Something went wrong",
        message: "Failed to update SSO settings, please try again.",
        variant: "error",
      });
    },
    onSettled: () => {
      void refetch();
    },
  });

  const toggleAllowingLogin = (value: boolean) => {
    updateOrganizationMutation.mutate({
      id: organization?.id,
      input: {
        can_invite_users: value,
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <FeatureFull
        enabled={organization?.plan?.sku === "business_tier"}
        featureDetails={{
          pitch: "Centralize user management through your identity provider",
          description:
            "Hightouch integrates with Okta, Microsoft Entra ID, OneLogin, Rippling, PingIdentity, and more.",
          bullets: [
            "SAML SSO enables users to authenticate through their organization's identity provider, supporting just-in-time provisioning during login.",
            "SCIM automates user management tasks such as group assignments and deactivations based on changes in the identity provider.",
          ],
          image: {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/36d7a9e1c51e347a61d2e4e062553305d13e4a72-2180x1228.png",
          },
        }}
        featureName="single sign-on"
      >
        <Row gap={4} align="center">
          <Heading>Single sign-on</Heading>
          <Button
            variant={isSsoEnabled ? "secondary" : "primary"}
            onClick={() => setSsoModalOpen(true)}
          >
            {isSsoEnabled ? "Update SAML SSO" : "Configure SAML SSO"}
          </Button>
        </Row>

        {isSsoEnabled && (
          <Alert
            message="SSO cannot be disabled in the app, but our team can take care of this for you."
            title="Looking to disable single sign-on?"
            type="info"
            variant="inline"
            actions={
              <Button
                variant="secondary"
                onClick={() =>
                  newPylonMessage(
                    "Hi, I'd like to disable SSO for my workspace.",
                  )
                }
              >
                Contact us
              </Button>
            }
          />
        )}

        <Dialog
          isOpen={createNewTokenOpen}
          variant="form"
          title="Create API Key"
          actions={
            <ButtonGroup>
              <Button onClick={() => setCreateNewTokenOpen(false)}>
                Cancel
              </Button>
              <Button
                isLoading={updateOrganizationMutation.isLoading}
                variant="primary"
                onClick={async () => {
                  if (key) {
                    await updateOrganizationMutation.mutateAsync({
                      id: organization?.id,
                      input: {
                        api_key: sha256.create().update(key).hex(),
                      },
                    });
                  }
                  setCreateNewTokenOpen(false);
                }}
              >
                Create API key
              </Button>
            </ButtonGroup>
          }
          onClose={() => {
            setCreateNewTokenOpen(false);
          }}
        >
          <FormField
            description="This key will only be displayed once, please copy it into your secrets manager."
            label="API key"
          >
            <Box display="flex" gap={3}>
              <TextInput isReadOnly value={key ?? ""} />
              <ClipboardButton text={key ?? ""} />
            </Box>
          </FormField>
        </Dialog>

        <AddSsoModal
          close={() => {
            setSsoModalOpen(false);
          }}
          connectionName={connectionName}
          open={ssoModalOpen}
        />

        {isSsoEnabled && (
          <Card gap={6}>
            <FormField
              label="Login URL"
              tip="Copy and paste this somewhere safe and use it to invite teammates to this organization."
            >
              <Box display="flex" gap={3}>
                <TextInput isReadOnly value={loginUrl} />
                <ClipboardButton text={loginUrl} />
              </Box>
            </FormField>
            <Row align="center">
              <Row gap={3}>
                <Switch
                  isChecked={Boolean(organization?.can_invite_users)}
                  isDisabled={updateOrganizationMutation.isLoading}
                  onChange={toggleAllowingLogin}
                />
                <Box opacity={updateOrganizationMutation.isLoading ? 1 : 0}>
                  <Spinner />
                </Box>
              </Row>
              <FormField
                description="By allowing users to be invited, they can still sign in with their Google or Microsoft logins in addition to SSO"
                label="Allow inviting users"
              >
                {null}
              </FormField>
            </Row>
          </Card>
        )}

        {isSsoEnabled && (
          <Card>
            <FormField
              description={
                (organization?.api_key ? "Refresh your " : "Generate a ") +
                "SCIM API token to allow your identity provider to notify us of any changes in your users or groups."
              }
              label="SCIM provisioning"
            >
              <Button
                onClick={() => {
                  setKey(uuidv4());
                  setCreateNewTokenOpen(true);
                }}
              >
                {organization?.api_key ? "Refresh " : "Generate "} SCIM token
              </Button>
            </FormField>
          </Card>
        )}
        {hasUpdatePermission && <SsoGroupMapping />}
      </FeatureFull>
    </Box>
  );
};

interface AddSsoModalProps {
  open: boolean;
  close: () => void;
  connectionName: string;
}

const AddSsoModal: FC<AddSsoModalProps> = ({ open, close, connectionName }) => {
  const [cert, setCert] = useState("");
  const [signInEndpoint, setSignInEndpoint] = useState("");
  const { toast } = useToast();

  const { mutateAsync: addSso, isLoading } = useConfigureSsoMutation();

  const handleClose = () => {
    close();
  };

  const save = async () => {
    try {
      await addSso({
        details: {
          cert,
          signInEndpoint,
        },
      });

      toast({
        id: "sso",
        title: "SSO configuration updated",
        variant: "success",
      });
      close();
    } catch (_err) {
      toast({
        id: "sso",
        title: "Failed to configure SSO",
        variant: "error",
      });
    }
  };

  const audienceValue = `urn:auth0:hightouch:${connectionName}`;
  const samlUrl = `https://hightouch.us.auth0.com/login/callback?connection=${connectionName}`;

  return (
    <Dialog
      isOpen={open}
      variant="form"
      title="Add SSO Connection"
      width="xl"
      actions={
        <ButtonGroup>
          <Button onClick={handleClose}>Close</Button>
          <Button
            isDisabled={isLoading || !cert || !signInEndpoint}
            isLoading={isLoading}
            variant="primary"
            onClick={save}
          >
            Save
          </Button>
        </ButtonGroup>
      }
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column" gap={12}>
        <Box>
          <Text
            textTransform="uppercase"
            fontWeight="semibold"
            size="sm"
            color="text.tertiary"
          >
            Step 1
          </Text>
          <SectionHeading>
            Set up an SSO application in your identity provider
          </SectionHeading>
          <Paragraph>
            If you need help settings things up, view our{" "}
            <Link href="https://hightouch.com/docs/workspace-management/sso">
              documentation
            </Link>
            .
          </Paragraph>

          <Box display="flex" flexDirection="column" gap={6} mt={4}>
            <FormField label="SAML URL">
              <Box display="flex" gap={3}>
                <TextInput isReadOnly value={samlUrl} />
                <ClipboardButton text={samlUrl} />
              </Box>
            </FormField>

            <FormField label="SAML audience URI">
              <Box display="flex" gap={3}>
                <TextInput isReadOnly value={audienceValue} />
                <ClipboardButton text={audienceValue} />
              </Box>
            </FormField>
          </Box>
        </Box>

        <Box>
          <Text
            textTransform="uppercase"
            fontWeight="semibold"
            size="sm"
            color="text.tertiary"
          >
            Step 2
          </Text>

          <SectionHeading>
            Provide the details of your SSO application
          </SectionHeading>

          <Box display="flex" flexDirection="column" gap={6} mt={4}>
            <FormField
              description="This is the URL your identity provider (Okta, Azure AD, etc.) provides when completing the configuration of a SAML application."
              label="Identity provider SSO URL"
            >
              <TextInput
                placeholder="Enter your SAML sign in endpoint"
                value={signInEndpoint}
                onChange={(event) => setSignInEndpoint(event.target.value)}
              />
            </FormField>

            <FormField
              description="This is a text file that usually starts with BEGIN CERTIFICATE. Please upload the entire file as provided by your identity provider."
              label="x.509 certificate"
            >
              <FileUploader
                acceptedFileTypes={[".pem", ".crt", ".cert", ".cer"]}
                transformation="string"
                value={cert}
                onChange={setCert}
              />
            </FormField>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const SsoGroupMapping: FC = () => {
  const { data, isLoading, refetch } = useWorkspacesOrganizationsGroupsQuery();
  const { workspace } = useUser();

  if (isLoading) {
    // return null because we return null if the user doesn't have an organization,
    // so they will just see a spinner then nothing else.
    return <Spinner />;
  }

  // we want the organization that this workspace belongs to - we might get multiple back if the user is an admin.
  const organization = data?.organizations?.find(
    (organization) => organization.id === workspace?.organization?.id,
  );

  if (!organization || organization.workspaces.length === 0) {
    // shouldn't happen as you need an organization to get here.
    return null;
  }

  const hasOrgGroups = organization.sso_groups.length > 0;
  if (!hasOrgGroups) {
    return (
      <Alert
        message="You must configure your identity provider to provision groups in Hightouch."
        title="No groups have been provisioned in Hightouch"
        type="warning"
        variant="inline"
        actions={
          <Link href="https://hightouch.com/docs/workspace-management/sso#access-management-with-sso-groups">
            Learn more
          </Link>
        }
      />
    );
  }

  return (
    <Card>
      <SectionHeading>Role mapping</SectionHeading>
      <Paragraph>Map your SSO groups to Hightouch roles.</Paragraph>

      <Box display="flex" flexDirection="column" gap={6} mt={6}>
        {organization.workspaces.map((workspace) => {
          if (organization.sso_groups.length === 0) {
            return null;
          }
          return (
            <Box key={workspace.id}>
              <Text fontWeight="semibold">{workspace.name}</Text>

              <Box
                alignItems="center"
                display="grid"
                gap={3}
                gridTemplateColumns="200px 24px 1fr"
                mt={1}
              >
                {organization.sso_groups.map((ssoGroup) => {
                  return (
                    <WorkspaceGroupMapping
                      key={ssoGroup.id}
                      group={ssoGroup}
                      refetch={refetch}
                      workspace={workspace}
                    />
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Card>
  );
};

interface WorkspaceGroupMappingProps {
  group: WorkspacesOrganizationsGroupsQuery["organizations"][0]["sso_groups"][0];
  refetch: () => Promise<unknown>;
  workspace: WorkspacesOrganizationsGroupsQuery["organizations"][0]["workspaces"][0];
}

const WorkspaceGroupMapping: FC<WorkspaceGroupMappingProps> = ({
  group,
  refetch,
  workspace,
}) => {
  const { toast } = useToast();
  const insertSsoGroupRolesMutation = useInsertSsoGroupRolesMutation();
  const deleteSsoGroupRolesMutation = useDeleteSsoGroupRolesMutation();
  const [isUpdatingRole, setIsUpdatingRole] = useState(false);

  const existingRoleMapping = workspace.sso_group_roles.find(
    (ssoGroupRole) => ssoGroupRole.sso_group.id === group.id,
  );

  return (
    <>
      <Text>{group.name}</Text>

      <Box display="flex" justifyContent="center" fontSize="16px">
        <ArrowRightIcon color="text.tertiary" />
      </Box>

      <Box>
        <Select
          isLoading={isUpdatingRole}
          optionLabel={(role) => role.name}
          isClearable
          optionValue={(role) => role.id}
          options={workspace.roles}
          placeholder="Select role..."
          value={existingRoleMapping?.role.id ?? undefined}
          onChange={async (roleId) => {
            try {
              setIsUpdatingRole(true);

              // if this group is already mapped to a role, we delete the existing mapping.
              if (existingRoleMapping) {
                await deleteSsoGroupRolesMutation.mutateAsync({
                  where: {
                    group_id: {
                      _eq: group.id,
                    },
                    role_id: {
                      _eq: existingRoleMapping.role.id,
                    },
                    workspace_id: {
                      _eq: workspace.id,
                    },
                  },
                });
              }

              // map this group to this role.
              if (roleId) {
                await insertSsoGroupRolesMutation.mutateAsync({
                  objects: {
                    role_id: roleId,
                    group_id: group.id,
                    workspace_id: workspace.id,
                  },
                });
              }

              await refetch();

              toast({
                id: "sso",
                title: "SSO settings updated",
                variant: "success",
              });
            } catch (_err: unknown) {
              toast({
                id: "sso",
                title: "Something went wrong",
                message: "Failed to update SSO settings, please try again.",
                variant: "error",
              });
            } finally {
              setIsUpdatingRole(false);
            }
          }}
        />
      </Box>
    </>
  );
};
