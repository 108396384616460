import {
  Column,
  DeleteIcon,
  IconButton,
  InformationIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Row,
  Skeleton,
  SkeletonBox,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, Outlet, useNavigate, useOutletContext } from "src/router";
import { ElementOf } from "ts-essentials";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  GoldenRecordOutletContext,
  SurvivorshipRule,
  SurvivorshipRules,
} from "src/pages/identity-resolution/types";
import { Table } from "src/ui/table";

import { OutletContext } from ".";
import {
  GoldenRecordQuery,
  useCreateGoldenRecordColumnMutation,
  useCreateGoldenRecordMutation,
  useDeleteGoldenRecordColumnMutation,
  useGoldenRecordQuery,
  useUpdateGoldenRecordMutation,
} from "src/graphql";
import { IDRV2GoldenRecordHightouchIdColumnName } from "@hightouch/lib/idr/v2/types/config-types";

type GoldenRecord = ElementOf<GoldenRecordQuery["idr_golden_records"]>;
type GoldenRecordColumn = ElementOf<GoldenRecord["idr_golden_record_columns"]>;

const DEFAULT_HT_ID_COLUMN: GoldenRecordColumn = {
  id: IDRV2GoldenRecordHightouchIdColumnName,
  column_name: IDRV2GoldenRecordHightouchIdColumnName,
  identifier: IDRV2GoldenRecordHightouchIdColumnName,
  survivorship_rule_type: SurvivorshipRule.MostRecent, // unused, just for type completeness
};

// TODO: Right now it might be possible to create an actual column named "ht_id", which conflicts with the default column name.
const isDefaultHTIdColumn = (column: GoldenRecordColumn) =>
  column.id === DEFAULT_HT_ID_COLUMN.id &&
  column.column_name === DEFAULT_HT_ID_COLUMN.column_name;

export const GoldenRecord = () => {
  const { graph, identifiers } = useOutletContext<OutletContext>();

  const { enableProfileBuilder } = useFlags();
  const { toast } = useToast();
  const navigate = useNavigate();

  const createGoldenRecord = useCreateGoldenRecordMutation();
  const updateGoldenRecord = useUpdateGoldenRecordMutation();
  const createGoldenRecordColumn = useCreateGoldenRecordColumnMutation();
  const deleteGoldenRecordColumn = useDeleteGoldenRecordColumnMutation();

  const {
    data: goldenRecord,
    isLoading: goldenRecordIsLoading,
    remove: removeGoldenRecord,
  } = useGoldenRecordQuery(
    {
      graphId: graph.id,
    },
    {
      enabled: Boolean(graph.id),
      select: (data) => data.idr_golden_records[0],
    },
  );

  if (!enableProfileBuilder) {
    return <Navigate to="../" />;
  }

  const setGoldenRecordEnabled = async (isEnabled: boolean) => {
    try {
      if (goldenRecord) {
        await updateGoldenRecord.mutateAsync({
          id: goldenRecord.id,
          input: {
            enabled: isEnabled,
          },
        });
      } else {
        const res = await createGoldenRecord.mutateAsync({
          input: {
            graphId: graph.id,
          },
        });

        // Throw api error so that the error toast is shown
        // Because the create mutation is a custom resolver,
        // it doesn't throw errors like the hasura resolvers
        if (
          res.createGoldenRecord.__typename ===
          "CreateGoldenRecordErrorResponse"
        ) {
          throw new Error(res.createGoldenRecord.error);
        }
      }

      // Remove the golden record from the query cache if we are toggling enabled state
      // This will force a full page reload, which we want because the page will change drastically
      await removeGoldenRecord();

      toast({
        id: "enable-golden-record",
        title: `Successfully ${isEnabled ? "enabled" : "disabled"} golden record`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "enable-golden-record",
        title: `Failed to ${isEnabled ? "enable" : "disable"} golden record`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const createColumn = async (identifier: string) => {
    try {
      const res = await createGoldenRecordColumn.mutateAsync({
        input: {
          // TODO: handle conflicts by creating a unique column name (eg. identifier_2)
          columnName: identifier,
          goldenRecordId: goldenRecord!.id,
          identifier,
          survivorshipRuleType: SurvivorshipRule.MostRecent,
        },
      });

      if (
        res.createGoldenRecordColumn.__typename ===
        "CreateGoldenRecordColumnErrorResponse"
      ) {
        throw new Error(res.createGoldenRecordColumn.error);
      }

      toast({
        id: "create-golden-record-column",
        title: "Successfully added golden record column",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "create-golden-record-column",
        title: "Failed to create golden record column",
        message: error.message,
        variant: "error",
      });
    }
  };

  const deleteColumn = async (columnId: string) => {
    try {
      const res = await deleteGoldenRecordColumn.mutateAsync({
        input: {
          id: columnId,
        },
      });

      if (
        res.deleteGoldenRecordColumn.__typename ===
        "DeleteGoldenRecordColumnErrorResponse"
      ) {
        throw new Error(res.deleteGoldenRecordColumn.error);
      }

      toast({
        id: "delete-golden-record-column",
        title: "Successfully deleted golden record column",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "delete-golden-record-column",
        title: "Failed to delete golden record column",
        message: error.message,
        variant: "error",
      });
    }
  };

  const isGoldenRecordEnabled = goldenRecord?.enabled ?? false;

  const isLoading =
    goldenRecordIsLoading ||
    createGoldenRecord.isLoading ||
    updateGoldenRecord.isLoading;

  const outletContext: GoldenRecordOutletContext = {
    graph,
    goldenRecord,
  };

  const columns = [
    DEFAULT_HT_ID_COLUMN,
    ...(goldenRecord?.idr_golden_record_columns ?? []),
  ];

  const addColumnsMenu = (
    <Menu>
      {/* Note: MenuButton actually accepts an `isLoading` prop, but the types don't support it yet */}
      <MenuButton {...{ isLoading: createGoldenRecordColumn.isLoading }}>
        Add column
      </MenuButton>
      <MenuList>
        <Column maxH="400px" overflow="auto">
          {identifiers.map((identifier) => (
            <MenuItem key={identifier} onClick={() => createColumn(identifier)}>
              {identifier}
            </MenuItem>
          ))}
        </Column>
      </MenuList>
    </Menu>
  );

  return (
    <Skeleton isLoading={isLoading}>
      <Column gap={4}>
        <Row justify="space-between">
          <Column gap={2}>
            <Row
              sx={{
                label: {
                  display: "flex",
                  gap: 2,
                  cursor: "pointer",
                },
              }}
            >
              <Text as="label" size="lg" fontWeight="medium">
                Enable Golden Record
                {!isLoading && (
                  <Switch
                    isChecked={isGoldenRecordEnabled}
                    onChange={setGoldenRecordEnabled}
                  />
                )}
              </Text>
            </Row>
            <Text color="text.secondary">
              Compile the best identifiers from your models to build a user
              parent model.
            </Text>
          </Column>

          {isGoldenRecordEnabled && columns.length > 0 && addColumnsMenu}
        </Row>

        {isLoading && (
          <>
            <SkeletonBox height={16} />
            <SkeletonBox height={16} />
            <SkeletonBox height={16} />
            <SkeletonBox height={16} />
            <SkeletonBox height={16} />
          </>
        )}

        {isGoldenRecordEnabled && !isLoading && (
          <Table
            placeholder={{
              image: genericPlaceholder,
              title: "No columns configured",
              body: "Add columns to create a golden record of user information.",
              error: "Columns failed to load, please try again.",
              button: addColumnsMenu,
            }}
            data={columns}
            disabled={isDefaultHTIdColumn}
            highlight={DEFAULT_HT_ID_COLUMN.id}
            columns={[
              {
                name: "Column name",
                cell: (column) => (
                  <Row minW={0} gap={1}>
                    <TextWithTooltip>{column.column_name}</TextWithTooltip>
                    {isDefaultHTIdColumn(column) && (
                      <Tooltip message="Default column for all Golden Records">
                        <InformationIcon />
                      </Tooltip>
                    )}
                  </Row>
                ),
              },
              {
                name: "Survivorship rule",
                cell: (column) => (
                  <TextWithTooltip>
                    {isDefaultHTIdColumn(column)
                      ? "--"
                      : SurvivorshipRules[column.survivorship_rule_type].label}
                  </TextWithTooltip>
                ),
              },
              {
                max: "max-content",
                cell: (column) =>
                  isDefaultHTIdColumn(column) ? null : (
                    <IconButton
                      aria-label="Delete column"
                      icon={DeleteIcon}
                      variant="danger"
                      isLoading={deleteGoldenRecordColumn.isLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteColumn(column.id);
                      }}
                    />
                  ),
              },
            ]}
            onRowClick={({ column_name }) => navigate(column_name)}
          />
        )}

        {/* Needed to render the golden record column drawer */}
        {!isLoading && <Outlet context={outletContext} />}
      </Column>
    </Skeleton>
  );
};
