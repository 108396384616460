import { useState } from "react";

import {
  Row,
  Button,
  FormField,
  Text,
  TextInput,
  Dialog,
  ButtonGroup,
} from "@hightouchio/ui";

import {
  CreateCloudCredentialV2Mutation,
  useCreateCloudCredentialV2Mutation,
} from "src/graphql";
import { AWSIcon } from "src/ui/icons/logo-aws";
import { AzureIcon } from "src/ui/icons/logo-azure";
import { GCPIcon } from "src/ui/icons/logo-gcp";
import { Selectable } from "src/ui/selectable";

import { Help } from "../help";
import { awsConfigValidator, AWSForm } from "./create-aws";
import { azureConfigValidator, AzureForm } from "./create-azure";
import { gcpConfigValidator, GCPForm } from "./create-gcp";

export const CreateCredential = ({
  defaultProvider = null,
  onClose,
  onCreate,
}: {
  defaultProvider?: string | null;
  onClose: () => void;
  onCreate?: (
    data: CreateCloudCredentialV2Mutation["createCloudCredentials"],
  ) => void | Promise<void>;
}) => {
  const [name, setName] = useState<string>();
  const [provider, setProvider] = useState<string | null>(defaultProvider);

  const [config, setConfig] = useState<{ [key: string]: any }>({});
  const isReady = () =>
    name && provider && validators[provider]
      ? validators[provider](config)
      : false;
  const { mutateAsync: save, isLoading: isCreating } =
    useCreateCloudCredentialV2Mutation();

  let form = <></>;
  let modalTitle = "Select a cloud provider";
  switch (provider) {
    case "aws":
      form = <AWSForm config={config} setConfig={setConfig} />;
      modalTitle = "Configure new credentials for AWS";
      break;

    case "gcp":
      form = <GCPForm config={config} setConfig={setConfig} />;
      modalTitle = "Configure GCP service account";
      break;

    case "azure":
      form = <AzureForm config={config} setConfig={setConfig} />;
      modalTitle = "Configure Azure Cloud service account";
      break;
  }

  return (
    <Dialog
      isOpen
      variant="form"
      width="xl"
      title={modalTitle}
      actions={
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            isDisabled={!isReady()}
            isLoading={isCreating}
            variant="primary"
            onClick={async () => {
              const resp = await save({
                input: { name: name || "", provider: provider || "", config },
              });
              if (onCreate) {
                onCreate(resp.createCloudCredentials);
              }
              onClose();
            }}
          >
            Create
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      {provider ? (
        <>
          <FormField
            label="Display name"
            tip={`e.g., "Access to production marketing database"`}
          >
            <TextInput
              value={name ?? ""}
              onChange={(event) => setName(event.target.value)}
            />
          </FormField>

          {form}

          <Help
            docs={`${import.meta.env.VITE_DOCS_URL}/security/${provider}`}
            label="Cloud integrations"
            mt={6}
          />
        </>
      ) : (
        <Row gap={4}>
          <Selectable
            flex={1}
            p={4}
            selected={false}
            onSelect={() => {
              setProvider("aws");
            }}
          >
            <Row gap={2} align="center" justify="center">
              <AWSIcon />
              <Text fontWeight="medium">Amazon Web Services</Text>
            </Row>
          </Selectable>

          <Selectable
            flex={1}
            p={4}
            selected={false}
            onSelect={() => {
              setProvider("gcp");
            }}
          >
            <Row gap={2} align="center" justify="center">
              <GCPIcon />
              <Text fontWeight="medium">Google Cloud Platform</Text>
            </Row>
          </Selectable>

          <Selectable
            flex={1}
            p={4}
            selected={false}
            onSelect={() => {
              setProvider("azure");
            }}
          >
            <Row gap={2} align="center" justify="center">
              <AzureIcon />
              <Text fontWeight="medium">Microsoft Azure</Text>
            </Row>
          </Selectable>
        </Row>
      )}
    </Dialog>
  );
};

const validators = {
  aws: awsConfigValidator,
  azure: azureConfigValidator,
  gcp: gcpConfigValidator,
};
