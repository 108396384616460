import { FC } from "react";

import { Column } from "@hightouchio/ui";
import { useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  SyncSequenceMembersInsertInput,
  useCreateSequenceMembersMutation,
  useDeleteSequenceMembersMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { SequenceForm } from "src/pages/sequences/sequence-form";

import { SequenceContext } from ".";

export const SequenceConfiguration: FC = () => {
  const { sequence } = useOutletContext<SequenceContext>();

  const form = useHightouchForm({
    values: { members: sequence.members },
    onSubmit: async (data) => {
      const members = data.members.map(
        ({ sync, abort_seq_on_failure, abort_seq_on_rejects }, index) => {
          const member: SyncSequenceMembersInsertInput = {
            sequence_id: sequence.id,
            sync_id: sync.id,
            reference_id: index + 1,
            abort_seq_on_failure,
            abort_seq_on_rejects,
            links: {
              predecessors: index > 0 ? [index] : [],
              successors: index !== data.members.length - 1 ? [index + 2] : [],
            },
          };

          return member;
        },
      );

      await deleteMembers({ id: sequence.id });
      await createMembers({
        objects: members,
      });

      analytics.track("Sequence Edited", {
        sync_sequence_id: sequence.id,
      });
    },
  });

  const { mutateAsync: createMembers } = useCreateSequenceMembersMutation();
  const { mutateAsync: deleteMembers } = useDeleteSequenceMembersMutation({
    onSuccess: () => {
      // prevents sequence query from invalidating
    },
  });

  return (
    <Column width="100%">
      <Form form={form}>
        <SequenceForm />
        <ActionBar>
          <FormActions
            // TODO[Permissions]: Add V2 permissions where you have to be able to run all syncs
            permission={{ v1: { resource: "sync", grant: "create" } }}
          />
        </ActionBar>
      </Form>
    </Column>
  );
};
