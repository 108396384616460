import { Box } from "@hightouchio/ui";

export const BarChartIcon = () => {
  return (
    <Box
      alignItems="end"
      display="grid"
      height="15px"
      width="15px"
      gridTemplateColumns="1fr 1fr 1fr 1fr"
    >
      <Box backgroundColor="cyan.400" height="3.75px" />
      <Box backgroundColor="electric.600" height="7.5px" />
      <Box backgroundColor="cyan.400" height="11.25px" />
      <Box backgroundColor="electric.600" height="15px" />
    </Box>
  );
};
