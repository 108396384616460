import { FC, useMemo } from "react";

import {
  ColumnType,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import {
  Column,
  Combobox,
  FormField,
  Row,
  Select,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { Link } from "src/router";
import sortBy from "lodash/sortBy";
import { Controller, useFormContext } from "react-hook-form";

import {
  SUPPORTED_JSON_ARRAY_SOURCES,
  useBigintSupport,
} from "src/components/audiences/utils";
import {
  EventColumn,
  RelationColumn,
} from "src/components/explore/filter-popover/constants";
import { IconBox } from "src/components/icon-box";
import { SqlEditor } from "src/components/sql-editor";
import { ParentModel } from "src/pages/audiences/types";

import { PreviewTrait } from "./preview-trait";
import { TraitConditions } from "./trait-conditions";
import {
  CALCULATION_METHODS,
  CalculationMethod,
  defaultTypeByCalculationMethod,
  formatTraitConfig,
  RAW_SQL_BIGINT_RES_TYPES,
  RAW_SQL_COMMON_RES_TYPES,
  RAW_SQL_JSON_ARR_RES_TYPES,
} from "./utils";
import { FormulaTraitEditor } from "./formula-trait-editor";

type Props = {
  parentModel: ParentModel;
  hideCalculationMethod?: boolean;
  isAssociatedToTemplate?: boolean;
  onPreviewValidation?: () => Promise<void>;
};

export const TraitCalculationForm: FC<Readonly<Props>> = ({
  parentModel,
  hideCalculationMethod,
  isAssociatedToTemplate,
  onPreviewValidation,
}) => {
  const { allowBigints } = useBigintSupport(parentModel.connection?.type ?? "");
  const { control, getValues, reset, watch } = useFormContext();
  const name = watch("name");
  const calculationMethod = watch("calculation_method");
  const relationshipId = watch("relationship_id");
  const config = watch("config");
  const type = watch("type");
  const isTemplate = watch("is_template");
  const sourceType = parentModel.connection?.type;

  const handleChangeCalculationMethod = (
    calculationMethod: CalculationMethod,
  ) => {
    reset({
      ...getValues(),
      calculation_method: calculationMethod,
      type: defaultTypeByCalculationMethod[calculationMethod],
      config: {},
    });
  };

  const handleChangeRelationship = (relationshipId: number) => {
    reset({
      ...getValues(),
      relationship_id: relationshipId,
      config: {},
    });
  };

  const calculationMethodOptions = Object.values(CALCULATION_METHODS).map(
    ({ label, description, value }) => ({
      label,
      description,
      value,
    }),
  );

  const relationship = parentModel.relationships.find(
    ({ id }) => id === relationshipId,
  );

  const relationshipOptions = sortBy(
    parentModel.relationships.map((relationship) => {
      const modelType = relationship.to_model.event
        ? EventColumn
        : RelationColumn;

      const Icon = () => (
        <IconBox
          bg={modelType.color}
          boxSize={4}
          icon={modelType.icon}
          iconSize={3}
        />
      );

      return {
        label: relationship.name || relationship.to_model.name,
        value: relationship.id, // Always save the relationship id
        icon: Icon,
      };
    }),
    "label",
  );

  const columns = useMemo(() => {
    return parentModel?.relationships.find(({ id }) => id === relationshipId)
      ?.to_model?.filterable_audience_columns;
  }, [parentModel, relationshipId]);

  const propertyOptions = useMemo(() => {
    if (columns) {
      let validColumns = columns;
      switch (type) {
        case TraitType.Sum:
          validColumns = columns.filter(
            (column) =>
              column.type === ColumnType.Number ||
              column.custom_type === ColumnType.Number ||
              column.type === ColumnType.BigInt ||
              column.custom_type === ColumnType.BigInt,
          );
          break;
        case TraitType.Average:
          // XXX: note that bigint columns might be rounded by the warehouse
          // when averaged (for example, this happens in Databricks). We allow
          // it here anyway for customers who have bigint columns but the data
          // in the columns is normal-sized.
          validColumns = columns.filter(
            (column) =>
              column.type === ColumnType.Number ||
              column.custom_type === ColumnType.Number ||
              column.type === ColumnType.BigInt ||
              column.custom_type === ColumnType.BigInt,
          );
          break;
      }

      return validColumns.map(({ alias, name, column_reference }) => ({
        label: alias || name,
        value: column_reference,
      }));
    }
    return [];
  }, [columns, type]);

  const rawSqlResultingType = [
    ...RAW_SQL_COMMON_RES_TYPES,
    ...(allowBigints ? RAW_SQL_BIGINT_RES_TYPES : []),
    ...(SUPPORTED_JSON_ARRAY_SOURCES.includes(sourceType ?? "")
      ? RAW_SQL_JSON_ARR_RES_TYPES
      : []),
  ];

  return (
    <Column gap={6} width="100%">
      {!hideCalculationMethod && (
        <Controller
          control={control}
          name="calculation_method"
          render={({ field }) => (
            <FormField label="Calculation method">
              <Combobox
                isDisabled={isAssociatedToTemplate}
                options={calculationMethodOptions}
                value={field.value}
                width="100%"
                onChange={(value) =>
                  handleChangeCalculationMethod(value as CalculationMethod)
                }
              />
            </FormField>
          )}
        />
      )}
      {calculationMethod !== CalculationMethod.Formula && (
        <Column gap={2}>
          <Controller
            control={control}
            name="relationship_id"
            render={({ field }) => (
              <FormField label="Select a related or event model">
                <Combobox
                  isDisabled={isAssociatedToTemplate}
                  options={relationshipOptions}
                  optionAccessory={(option: any) => ({
                    type: "icon",
                    icon: option.icon,
                  })}
                  placeholder="Select a model"
                  value={field.value}
                  width="100%"
                  onChange={(value) => handleChangeRelationship(value)}
                />
              </FormField>
            )}
          />

          {!isTemplate && relationshipId && (
            <Column
              borderRadius="6px"
              border="1px solid"
              borderColor="base.border"
              p={4}
              gap={4}
            >
              <TraitConditions
                parentModel={parentModel}
                relationship={relationship}
              />
            </Column>
          )}
        </Column>
      )}

      {calculationMethod === CalculationMethod.Aggregation && (
        <>
          <FormField label="Aggregation type">
            <Controller
              control={control}
              name="type"
              render={({ field: { ref, ...field } }) => (
                <Select
                  isDisabled={!relationshipId || isAssociatedToTemplate}
                  options={[
                    { label: "Sum", value: TraitType.Sum },
                    { label: "Average", value: TraitType.Average },
                  ]}
                  placeholder="Select an aggregation type"
                  {...field}
                  width="100%"
                />
              )}
            />
          </FormField>
          <FormField label={`${type !== TraitType.Sum ? "Average" : "Sum"} by`}>
            <Controller
              control={control}
              name="config.column"
              render={({ field: { ref, ...field } }) => (
                <Combobox
                  isDisabled={!relationshipId || isAssociatedToTemplate}
                  options={propertyOptions}
                  placeholder="Select a column"
                  width="100%"
                  {...field}
                />
              )}
            />
          </FormField>
        </>
      )}
      {calculationMethod === CalculationMethod.Count && (
        <FormField label="Count by">
          <Controller
            control={control}
            name="config.column"
            render={({ field: { ref, ...field } }) => (
              <Combobox
                isDisabled={!relationshipId || isAssociatedToTemplate}
                options={propertyOptions}
                placeholder="Select a column"
                width="100%"
                {...field}
              />
            )}
          />
        </FormField>
      )}
      {calculationMethod === CalculationMethod.Occurrence && (
        <>
          <FormField label="Occurrence type">
            <Controller
              control={control}
              name="type"
              render={({ field: { ref, ...field } }) => (
                <Select
                  isDisabled={!relationshipId || isAssociatedToTemplate}
                  options={[
                    { label: "First / Min", value: TraitType.First },
                    { label: "Last / Max", value: TraitType.Last },
                    { label: "Most frequent", value: TraitType.MostFrequent },
                    { label: "Least frequent", value: TraitType.LeastFrequent },
                  ]}
                  placeholder="Select an occurrence type"
                  width="100%"
                  {...field}
                />
              )}
            />
          </FormField>
          <FormField
            label="Trait value"
            tip="The column that represents the value"
          >
            <Controller
              control={control}
              name="config.toSelect"
              render={({ field: { ref, ...field } }) => (
                <Combobox
                  isDisabled={!relationshipId || isAssociatedToTemplate}
                  options={propertyOptions}
                  placeholder="Select a column"
                  width="100%"
                  {...field}
                />
              )}
            />
          </FormField>
          {[TraitType.First, TraitType.Last].includes(type) && (
            <FormField
              label="Order by"
              tip="Rows will be ordered according to this column"
            >
              <Controller
                control={control}
                name="config.orderBy"
                render={({ field: { ref, ...field } }) => (
                  <Combobox
                    isDisabled={!relationshipId || isAssociatedToTemplate}
                    options={propertyOptions}
                    placeholder="Select a column"
                    width="100%"
                    {...field}
                  />
                )}
              />
            </FormField>
          )}
        </>
      )}
      {calculationMethod === CalculationMethod.Sql && (
        <>
          <FormField
            label="SQL"
            description="Rows will be aggregated according to a custom SQL aggregation."
          >
            <Controller
              control={control}
              name="config.aggregation"
              render={({ field: { ref, ...field } }) => (
                <SqlEditor
                  placeholder={
                    "CASE WHEN SUM({{column \"price\"}}) > 100 THEN 'high' ELSE 'low' END`"
                  }
                  source={parentModel.connection}
                  {...field}
                  readOnly={!relationshipId || isAssociatedToTemplate}
                  minHeight="250px"
                />
              )}
            />
          </FormField>
          <FormField
            label="Default value"
            tip="Defines the value when the above SQL returns no rows. String values should be surrounded with quotes ('')."
          >
            <Controller
              control={control}
              name="config.defaultValue"
              render={({ field }) => (
                <TextInput
                  isReadOnly={!relationshipId || isAssociatedToTemplate}
                  placeholder="Enter the default value"
                  width="100%"
                  {...field}
                />
              )}
            />
          </FormField>
          <FormField
            label="Property type"
            tip="Defines the type of resulting value"
          >
            <Controller
              control={control}
              name="config.resultingType"
              render={({ field: { ref, ...field } }) => (
                <Combobox
                  isDisabled={!relationshipId || isAssociatedToTemplate}
                  options={rawSqlResultingType}
                  placeholder="Select a column type"
                  width="100%"
                  {...field}
                />
              )}
            />
          </FormField>
        </>
      )}
      {calculationMethod === CalculationMethod.Formula && (
        <>
          <Column gap={2}>
            <Column>
              <Text fontWeight="medium">SQL formula</Text>
              <Text color="text.secondary">
                Build a formula calculation for each user. Type the column name
                to reference other fields.{" "}
                <Link
                  isExternal
                  href={`${
                    import.meta.env.VITE_DOCS_URL
                  }/customer-studio/traits#formula-traits`}
                >
                  Learn more.
                </Link>
              </Text>
            </Column>
            <Controller
              control={control}
              name="config.transformation"
              render={({ field: { ref, ...field } }) => (
                <FormulaTraitEditor
                  columns={parentModel.filterable_audience_columns}
                  traits={parentModel.traits}
                  {...field}
                />
              )}
            />
          </Column>
          <FormField
            label="Property type"
            tip="Defines the type of resulting value"
          >
            <Controller
              control={control}
              name="config.resultingType"
              render={({ field: { ref, ...field } }) => (
                <Combobox
                  options={rawSqlResultingType}
                  placeholder="Select a column type"
                  width="100%"
                  {...field}
                />
              )}
            />
          </FormField>
        </>
      )}
      <Row mb={4}>
        <PreviewTrait
          isDisabled={
            !parentModel ||
            !type ||
            !config ||
            (type !== TraitType.Formula && !relationship?.id)
          }
          parentModel={parentModel}
          trait={{
            name,
            type: type as TraitType,
            config: formatTraitConfig(type, config as TraitConfig, parentModel),
            relationshipId: relationship?.id,
          }}
          onValidateTrait={onPreviewValidation}
        />
      </Row>
    </Column>
  );
};
