import { FC, useState } from "react";

import { Column, Heading, Row } from "@hightouchio/ui";
import { isEqual, pickBy } from "lodash";

import { ActionBar } from "src/components/action-bar";
import {
  AlertsOrderBy,
  SyncQuery,
  useUpdateSyncAlertsMutation,
  useUpdateSyncMutation,
  useUpdateSyncTemplateMutation,
  useWorkspaceAlertsQuery,
  useWorkspaceAlertsWithStatusesQuery,
} from "src/graphql";
import { useTableConfig } from "src/ui/table";
import {
  AlertSelector,
  WorkspaceAlertSortKeys,
} from "src/components/alerts/alert-selector";
import { PermissionedButton } from "src/components/permission";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import { useOutletContext } from "src/router";
import { Context } from ".";
import { DocsLink } from "src/components/docs-link";

type SyncAlert = Omit<SyncQuery["syncs"][0]["sync_alerts"][0], "__typename">;

type Props = {
  alerts: SyncAlert[];
  rowThresholdTotal?: number;
  rowThresholdAttempted?: number;
  syncId?: number;
  templateId?: number;
  permission: ResourcePermissionInput<
    "sync" | "model",
    "sync" | "sync_template"
  >;
};

export type ProcessedSyncAlerts = Record<
  string,
  { row: boolean; fatal: boolean; success: boolean }
>;

export const Alerts: FC = () => {
  const { sync } = useOutletContext<Context>();
  const id = String(sync.id);
  const syncAlerts = sync?.sync_alerts;

  return (
    <SyncAlerts
      permission={{
        v2: { resource: "sync", grant: "can_update", id },
      }}
      alerts={syncAlerts || []}
      rowThresholdAttempted={sync?.row_threshold_attempted}
      rowThresholdTotal={sync?.row_threshold_total}
      syncId={Number(id)}
    />
  );
};

export const SyncAlerts: FC<Readonly<Props>> = ({
  alerts,
  rowThresholdTotal,
  rowThresholdAttempted,
  syncId,
  templateId,
  permission,
}) => {
  const processedAlerts: ProcessedSyncAlerts = alerts?.reduce(
    (obj, instance) => {
      if (!obj[instance.id]) {
        obj[instance.id] = {};
      }
      obj[instance.id]["row"] = instance.row_error ?? null;
      obj[instance.id]["fatal"] = instance.fatal_error ?? null;
      obj[instance.id]["success"] = instance.alert_on_success ?? null;

      return obj;
    },
    {},
  );

  const [updating, setUpdating] = useState<boolean>(false);
  const [syncAlerts, setSyncAlerts] = useState(processedAlerts);
  const [thresholds, setThresholds] = useState({
    total: rowThresholdTotal,
    attempted: rowThresholdAttempted,
  });

  const { onSort, orderBy } = useTableConfig<AlertsOrderBy>({
    defaultSortKey: "name",
    sortOptions: Object.values(WorkspaceAlertSortKeys),
  });

  const { data: workspaceAlertsData } = useWorkspaceAlertsQuery(undefined, {
    enabled: Boolean(templateId),
  });

  const { data: workspaceAlertsWithStatusesData } =
    useWorkspaceAlertsWithStatusesQuery(
      { orderBy, syncId: String(syncId) },
      { enabled: Boolean(syncId) },
    );

  const workspaceAlerts =
    (workspaceAlertsData || workspaceAlertsWithStatusesData)?.alerts || [];

  const { mutateAsync: upsertSyncAlerts } = useUpdateSyncAlertsMutation();
  const { mutateAsync: updateSync } = useUpdateSyncMutation();
  const { mutateAsync: updateSyncTemplate } = useUpdateSyncTemplateMutation();

  const filteredSyncAlerts = pickBy(syncAlerts, (a) => {
    return a?.row !== null || a?.fatal !== null;
  });

  const update = async () => {
    setUpdating(true);

    const removedInstanceIds = Object.keys(syncAlerts).filter(
      (i) => !Object.keys(filteredSyncAlerts).includes(i),
    );

    const addedInstances = Object.entries(filteredSyncAlerts).map(
      ([alertId, states]) => {
        return {
          destination_instance_id: syncId ? String(syncId) : null,
          sync_template_id: templateId ? Number(templateId) : null,
          alert_id: String(alertId),
          fatal_error: states?.fatal,
          row_error: states?.row,
          alert_on_success: states?.success,
        };
      },
    );

    // We remove any alert overrides that are noop from the default
    // alert and then upsert all alert instances in the form that are not
    // a noop
    await upsertSyncAlerts({
      shouldCreateTemplateAlerts: !syncId,
      alertsToCreate: addedInstances,
      shouldDeleteAlerts: Boolean(removedInstanceIds.length),
      syncId: syncId ? syncId.toString() : undefined,
      syncTemplateId: templateId,
      alertIdsToDelete: removedInstanceIds,
      shouldCreateAlerts: Boolean(addedInstances.length),
    });

    if (
      rowThresholdAttempted !== thresholds?.attempted ||
      rowThresholdTotal !== thresholds?.total
    ) {
      if (syncId) {
        await updateSync({
          id: String(syncId),
          object: {
            row_threshold_attempted: thresholds?.attempted
              ? String(thresholds.attempted)
              : null,
            row_threshold_total: thresholds?.total
              ? String(thresholds.total)
              : null,
          },
        });
      } else {
        await updateSyncTemplate({
          id: Number(templateId),
          input: {
            row_threshold_attempted: thresholds?.attempted
              ? String(thresholds.attempted)
              : null,
            row_threshold_total: thresholds?.total
              ? String(thresholds.total)
              : null,
          },
        });
      }
    }

    setUpdating(false);
  };

  const dirty =
    rowThresholdAttempted !== thresholds?.attempted ||
    rowThresholdTotal !== thresholds?.total ||
    !isEqual(filteredSyncAlerts, processedAlerts);

  return (
    <>
      <Column>
        <Row align="center" justify="space-between" mb={6}>
          <Heading>Configure alerts</Heading>
          <DocsLink
            name="monitoring and alerting"
            href="/syncs/alerting/#setup"
          />
        </Row>
        <AlertSelector
          alerts={syncAlerts}
          orderBy={orderBy}
          showStatuses={Boolean(syncId)}
          thresholds={thresholds}
          workspaceAlerts={workspaceAlerts}
          onAlertsChange={setSyncAlerts}
          onSort={onSort}
          onThresholdsChange={setThresholds}
        />
      </Column>
      <ActionBar>
        <PermissionedButton
          permission={permission}
          size="lg"
          variant="primary"
          isDisabled={!dirty}
          isLoading={updating}
          onClick={update}
        >
          Save changes
        </PermissionedButton>
      </ActionBar>
    </>
  );
};
