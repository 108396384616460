import { Schedule } from "src/components/schedule/types";
import { DestinationInstancesSetInput } from "src/graphql";

export type SyncWithTemplateInformation = {
  id: string;
  destination: {
    // actually a number, bigint problem
    id: string;
  } | null;
  config: Record<string, unknown>;
  is_streaming: boolean | null;
  schedule: Schedule | null;
  schedule_paused: boolean | null;
  row_threshold_total: string | null;
  row_threshold_attempted: string | null;
};

export const getDetachedSyncInputFromSync = (
  sync: SyncWithTemplateInformation,
): DestinationInstancesSetInput => {
  return {
    destination_id: sync.destination?.id,
    sync_template_id: null,
    // The sync config is the transformed config
    // The sync template overrides are merged in the transformed config
    config: sync.config,
    is_streaming: sync.is_streaming,
    schedule: sync.schedule,
    schedule_paused: sync.schedule_paused,
    row_threshold_total: sync.row_threshold_total,
    row_threshold_attempted: sync.row_threshold_attempted,
    // Clear the sync template overrides column
    sync_template_overrides: null,
    // we null the draft id, it gets added on the backend and we want to be consistent
    // if a workspace turns off approvals again
    approved_draft_id: null,
  };
};
