import { Heading } from "@hightouchio/ui";
import { Navigate, Outlet, Route, Routes } from "src/router";

import { DetailPage } from "src/components/layout";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { SyncTemplateRouter } from "src/pages/audiences/setup/sync-template";
import { SyncTemplates } from "src/pages/audiences/setup/sync-templates";

export const SchemaSettings = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          index
          element={<Navigate to="sync-templates" replace />}
        />
        <Route path="/sync-templates" element={<SyncTemplates />} />
      </Route>
      <Route path="sync-templates/new" element={<CreateSyncTemplate />} />
      <Route
        path="/sync-templates/:sync_template_id/*"
        element={<SyncTemplateRouter />}
      />
    </Routes>
  );
};

const Layout = () => {
  return (
    <DetailPage
      title="Schema - Settings"
      crumbs={[{ label: "Schema", link: "/schema-v2" }]}
      header={<Heading size="xl">Settings</Heading>}
      hasBottomPadding
      tabs={[
        {
          title: "Sync templates",
          path: "sync-templates",
        },
      ]}
    >
      <Outlet />
    </DetailPage>
  );
};
