export const SlideOutAnimationTime = 0.15;
export const SlideInAnimationTime = 0.3;

// Define animation variants
export const SlideInOutVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { duration: SlideInAnimationTime, ease: "easeOut" }, // Animation speed when opening
      opacity: { duration: SlideInAnimationTime, ease: "easeOut" },
    },
  },
  closed: {
    x: "100%",
    opacity: 0,
    transition: {
      x: { duration: SlideOutAnimationTime, ease: "easeIn" }, // Animation speed when closing
      opacity: { duration: SlideOutAnimationTime, ease: "easeIn" },
    },
  },
};
