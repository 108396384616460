import { MonitorConditionTypes } from "@hightouch/lib/resource-monitoring/types";
import {
  Badge,
  Box,
  Button,
  CloseIcon,
  Column,
  EditIcon,
  IconButton,
  RefreshIcon,
  Row,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import pluralize from "pluralize";
import { isEqual, uniq } from "lodash";
import { isDefined } from "ts-extras";

import { Card } from "src/components/card";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { MonitorDefinitions } from "src/components/resource-alert-triggers/definitions";
import {
  MonitorConditionFormType,
  MonitorModes,
  getMonitorMode,
} from "src/components/resource-alert-triggers/utils";
import { CustomField } from "./fields/custom-field";
import { ModeField } from "./fields/mode-field";
import { Overrides } from "src/components/resource-alert-triggers/overrides";

export type GenericMonitorCondition = {
  type: string;
  enabled: boolean;
  warning_value: any;
  error_value: any;
};

export type MonitorConditionFormProps = {
  conditionType: MonitorConditionTypes;
  conditions: Array<GenericMonitorCondition | undefined>;
  destinationIds?: string[];
  onSubmit: (values: {
    condition: GenericMonitorCondition;
  }) => Promise<unknown>;
  onRestoreDefault?: () => Promise<unknown>;
};

export const MonitorConditionForm: FC<MonitorConditionFormProps> = ({
  conditionType,
  conditions,
  destinationIds,
  onSubmit,
  onRestoreDefault,
}) => {
  const { toast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [isRestoringDefault, setIsRestoringDefault] = useState(false);

  const definition = MonitorDefinitions[conditionType];

  const isConflicting =
    conditions.length > 0 &&
    !conditions
      .filter(isDefined)
      .every(
        (template) =>
          isEqual(template.warning_value, conditions[0]!.warning_value) &&
          isEqual(template.error_value, conditions[0]!.error_value),
      );

  const defaultCondition = {
    type: conditionType,
    enabled: false,
    error_value: null,
    warning_value: null,
  };

  const { error_value, warning_value } = conditions[0] ?? defaultCondition;
  const initialMode = getMonitorMode(conditionType, error_value, warning_value);

  const form = useHightouchForm<MonitorConditionFormType>({
    onSubmit: async ({ condition }) => {
      await onSubmit({
        condition: {
          type: conditionType,
          enabled: true,
          error_value: condition.error_value,
          warning_value: condition.warning_value,
        },
      });
      setIsEditing(false);
    },
    values: {
      mode: initialMode,
      defaultModeSelection:
        initialMode === MonitorModes.WARNING
          ? MonitorModes.WARNING
          : MonitorModes.CRITICAL,
      condition: { error_value, warning_value },
    },
    resolver: yupResolver(yup.object().shape({ condition: definition.schema })),
  });

  useEffect(() => {
    // Need to make the form dirty to allow saving to override the conflicts
    if (isConflicting && !form.formState.isDirty) {
      form.setValue("mode", MonitorModes.CRITICAL);
      form.setValue("defaultModeSelection", MonitorModes.CRITICAL);
      form.setValue("condition", defaultCondition, {
        shouldDirty: true,
      });
    }
  }, [form, isConflicting]);

  const mode = form.watch("mode");
  const condition = form.watch("condition");

  return (
    <>
      <Form form={form}>
        <Card
          footer={isEditing ? <FormActions size="md" /> : null}
          heading={definition.title}
          p={4}
          rightContent={
            <Row align="center" gap={2}>
              {onRestoreDefault && (
                <>
                  <Tooltip
                    message={
                      <Column align="flex-start" gap={1}>
                        <Text color="white" fontWeight="medium">
                          Destination default
                        </Text>
                        <Box
                          display="inline"
                          color="white"
                          fontWeight="normal"
                          textAlign="left"
                          sx={{ span: { color: "white" } }}
                        >
                          <Text color="danger.400 !important" mr={1}>
                            Critical
                          </Text>
                          <definition.summary
                            prefix={false}
                            condition={defaultCondition}
                          />
                        </Box>
                      </Column>
                    }
                  >
                    <Badge variant="primary">Override</Badge>
                  </Tooltip>
                  <Button
                    size="sm"
                    icon={RefreshIcon}
                    onClick={async () => {
                      setIsRestoringDefault(true);
                      try {
                        await onRestoreDefault();
                        toast({
                          id: "restore-default-trigger",
                          title: "Restored default",
                          variant: "success",
                        });
                      } catch (_err) {
                        toast({
                          id: "restore-default-trigger",
                          title: "Failed to restore default",
                          variant: "error",
                        });
                      } finally {
                        setIsRestoringDefault(false);
                      }
                    }}
                    isLoading={isRestoringDefault}
                  >
                    Restore default
                  </Button>
                </>
              )}
              {isEditing ? (
                <IconButton
                  aria-label="Close"
                  icon={CloseIcon}
                  onClick={() => {
                    form.reset();
                    setIsEditing(false);
                  }}
                />
              ) : (
                <Row gap={2} align="center">
                  {destinationIds && (
                    <Overrides
                      destinationIds={destinationIds}
                      conditionType={conditionType}
                    />
                  )}
                  <IconButton
                    aria-label="Edit"
                    icon={EditIcon}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  />
                </Row>
              )}
            </Row>
          }
        >
          {isEditing ? (
            <>
              <ModeField mode={mode} conditionType={conditionType} />
              {mode === MonitorModes.CUSTOM && (
                <CustomField conditionType={conditionType} />
              )}
            </>
          ) : isConflicting ? (
            <Conflicts conditions={conditions} />
          ) : mode === MonitorModes.NONE ? (
            <Text color="text.secondary">No alerts configured</Text>
          ) : (
            <Column gap={2}>
              <definition.summary condition={condition} />
            </Column>
          )}
        </Card>
      </Form>
    </>
  );
};

const Conflicts = ({
  conditions,
}: {
  conditions: Array<GenericMonitorCondition | undefined>;
}) => {
  const criticalConditions = conditions
    .filter(isDefined)
    .map((condition) => condition.error_value);
  const warningConditions = conditions
    .filter(isDefined)
    .map((condition) => condition.warning_value);
  const conflictingCritical = uniq(criticalConditions).length;
  const conflictingWarning = uniq(warningConditions).length;

  return (
    <Column gap={2}>
      {conflictingCritical > 1 && (
        <Row borderLeft="2px" pl={2} borderColor="danger.base">
          <Text>
            {conflictingCritical} conflicting{" "}
            {pluralize("alert", conflictingCritical)}
          </Text>
        </Row>
      )}
      {conflictingWarning > 1 && (
        <Row borderLeft="2px" pl={2} borderColor="warning.border">
          <Text>
            {conflictingWarning} conflicting{" "}
            {pluralize("alert", conflictingWarning)}
          </Text>
        </Row>
      )}
    </Column>
  );
};
