import { useMemo, useState } from "react";
import { orderBy as lodashOrderBy } from "lodash";
import {
  SegmentsBoolExp,
  useParentModelSelectQuery,
  SegmentsOrderBy,
} from "src/graphql";
import { exhaustiveCheck } from "src/types/visual";

export enum OrderBy {
  MostUsed = "most used",
  RecentlyUpdated = "recently updated",
  NameAscending = "A -> Z",
  NameDescending = "Z -> A",
}

export const useParentModelSelect = ({
  includeAudienceRatio,
}: {
  includeAudienceRatio: boolean;
}) => {
  const [search, setSearch] = useState("");

  const filters = useMemo(() => {
    const baseFilters: SegmentsBoolExp = {
      is_schema: { _eq: true },
      primary_key: { _is_null: false },
    };

    if (search) {
      baseFilters._and = [{ name: { _ilike: `%${search}%` } }];
    }

    return baseFilters;
  }, [search]);

  const [orderBy, setOrderBy] = useState<OrderBy>(
    includeAudienceRatio ? OrderBy.MostUsed : OrderBy.NameAscending,
  );

  const hasuraOrderBy = useMemo<SegmentsOrderBy>(() => {
    switch (orderBy) {
      case OrderBy.MostUsed:
        // This option requires sorting on the "audience_ratio" field
        // which is returned from a remote schema.
        // Unfortunately, that can't be done in Hasura.
        // Therefore, we'll do the sorting on the client-side.
        return {};
      case OrderBy.NameAscending:
        return { name: "asc" };
      case OrderBy.NameDescending:
        return { name: "desc" };
      case OrderBy.RecentlyUpdated:
        return { updated_at: "desc" };
      default:
        exhaustiveCheck(orderBy);
    }
  }, [orderBy]);

  const parentModels = useParentModelSelectQuery(
    {
      filters,
      orderBy: hasuraOrderBy,
      includeAudienceRatio,
    },
    {
      select: (data) => {
        if (orderBy === OrderBy.MostUsed && includeAudienceRatio) {
          return lodashOrderBy(
            data.segments,
            (segment) => segment.audience_ratio?.ratio,
            ["desc"],
          );
        }

        return data.segments;
      },
    },
  );

  return {
    options: parentModels.data,
    isLoading: parentModels.isLoading,
    error: parentModels.error,
    search,
    onSearch: setSearch,
    orderBy,
    setOrderBy,
  };
};
