import { KeyboardEventHandler, ReactNode, useMemo, useRef } from "react";

import groupBy from "lodash/groupBy";
import partition from "lodash/partition";

import {
  Box,
  ButtonGroup,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  Heading,
  Paragraph,
  Portal,
  Row,
  SearchInput,
  Text,
} from "@hightouchio/ui";
import sortBy from "lodash/sortBy";
import pluralize from "pluralize";
import { LinkButton } from "src/router";

import { RequiredParentModelFieldsForQueryBuilder } from "src/components/audiences/types";
import { DataTooltip } from "src/components/explore/filter-popover/data-tooltip";
import { IconBox } from "src/components/icon-box";
import { ExtentalLink } from "src/components/links/external-link";
import { useArrowKeyFocus } from "src/hooks/use-arrow-key-focus";
import { useRelativeViewportSize } from "src/hooks/use-relative-viewport-size";
import { Condition, ConditionType } from "src/types/visual";

import {
  CONTENT_SECTION_WIDTH_PX,
  EventColumn,
  FilterOption,
  FilterPopoverUI,
  MAX_INPUT_WIDTH_PX,
  MAX_POPOVER_HEIGHT_PX,
  MetadataIcons,
  MetadataType,
  PADDING,
  PLACEMENT_INFLECTION_POINT,
  RelationColumn,
  TABS,
  TAB_SECTION_WIDTH_PX,
} from "./constants";
import { FilterPopoverOption } from "./filter-popover-option";
import { FilterPopoverTab } from "./filter-popover-tab";
import { FilterSelectButton } from "./filter-select-button";
import { GroupLabels } from "./group-labels";
import { useConditionSelect } from "./use-condition-select";
import { useVirtualItems } from "./use-virtual-items";
import {
  getIconProps,
  getMetadata,
  getSearchPlaceholderText,
  getSetupLink,
  isColumnSelected,
} from "./utils";

export interface FilterPopoverProps<TCondition extends Condition> {
  condition?: TCondition;
  hasError?: boolean;
  triggerButton?: ReactNode;
  isDisabled?: boolean;
  parent: RequiredParentModelFieldsForQueryBuilder | undefined | null;
  tabs?: FilterPopoverUI[];
  onChange: (updates: TCondition) => void;
}

export function FilterPopover<TCondition extends Condition>({
  condition,
  isDisabled = false,
  hasError = false,
  onChange,
  triggerButton,
  parent,
  tabs = TABS,
}: FilterPopoverProps<TCondition>): JSX.Element {
  const searchRef = useRef<HTMLInputElement>(null);
  const optionsContainer = useRef<HTMLDivElement>(null);

  const { relativeHeight } = useRelativeViewportSize();

  const [focusedTabIndex, setFocusedTabIndex] = useArrowKeyFocus(tabs.length);

  const {
    columns,
    filter,
    search,
    selectedColumn,
    onFilterChange,
    onReset,
    onResetSelectedTab,
    onSearch,
    onSelectColumn,
  } = useConditionSelect({
    condition,
    initialTab: tabs?.[0]?.type,
  });

  const optionGroups = useMemo(() => {
    if (columns.length === 0) {
      return [];
    }

    if (filter !== FilterOption.Property) {
      return [{ label: null, options: columns }];
    }

    // Property filters include merged columns.
    // If merged columns are present, we want to group by the model name.
    const [parentModelColumns, mergedColumns] = partition(
      columns,
      (column) => column.value.type === "raw",
    );
    const mergedColumnsByModelName = groupBy(mergedColumns, "modelName");

    const showModelName = mergedColumns.length > 0;
    return [
      {
        label: showModelName ? (parent?.name ?? "Parent model") : null,
        options: parentModelColumns,
      },
      ...sortBy(
        Object.entries(mergedColumnsByModelName).map(
          ([modelName, columns]) => ({
            label: modelName,
            options: columns,
          }),
        ),
        (group) => group.label.toLowerCase(),
      ),
    ].filter((group) => group.options.length > 0);
  }, [columns, parent?.name, filter]);

  const { virtualizer, virtualItems } = useVirtualItems({
    optionGroups,
    containerRef: optionsContainer,
  });

  const changeFocus: (index: number) => KeyboardEventHandler =
    (index) => (event) => {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setFocusedTabIndex(index - 1);
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setFocusedTabIndex(index + 1);
      }
    };

  const placeholderText = getSearchPlaceholderText(filter);
  const missingColumnTypeText =
    filter === FilterOption.All ? "column" : filter.toLowerCase();
  let buttonContent: ReactNode = selectedColumn?.label;

  const selectedColumnMetadata = getMetadata(selectedColumn);

  if (selectedColumn?.label && selectedColumnMetadata) {
    buttonContent = (
      <Row maxWidth={`${MAX_INPUT_WIDTH_PX}px`} gap={2}>
        <Text isTruncated>{selectedColumn.label}</Text>
        <Row
          as={Text}
          align="center"
          color="text.secondary"
          overflow="hidden"
          sx={{ svg: { height: "12px", width: "12px", mr: 1, flexShrink: 0 } }}
        >
          {MetadataIcons[selectedColumnMetadata.type]}{" "}
          <Text isTruncated color="text.secondary">
            {selectedColumnMetadata.modelName}
          </Text>
        </Row>
      </Row>
    );
  }

  const { color, icon } = getIconProps(selectedColumn?.type) ?? {};

  return (
    <ChakraPopover
      isLazy
      preventOverflow
      initialFocusRef={searchRef}
      placement={
        relativeHeight > PLACEMENT_INFLECTION_POINT ? "bottom-start" : "right"
      }
      returnFocusOnClose={false}
      onOpen={onResetSelectedTab}
      onClose={onReset}
    >
      {({ onClose }) => (
        <>
          <Row gap={2} sx={{ "&>div": { alignSelf: "start" } }}>
            <ChakraPopoverTrigger>
              {triggerButton ? (
                triggerButton
              ) : (
                <FilterSelectButton hasError={hasError} isDisabled={isDisabled}>
                  <DataTooltip
                    isDisabled={!selectedColumn}
                    description={selectedColumn?.description}
                    label={selectedColumn?.label ?? ""}
                    placement="bottom-start"
                    subtitle={
                      selectedColumn && selectedColumnMetadata?.modelName ? (
                        <Row
                          as={Text}
                          align="center"
                          color="base.border"
                          columnGap={1}
                          display="inline-block"
                          flexWrap="wrap"
                          size="sm"
                          textAlign="left"
                          wordBreak="break-all"
                          sx={{ svg: { height: "12px", width: "12px" } }}
                        >
                          {[
                            FilterOption.Trait,
                            FilterOption.TraitTemplate,
                          ].includes(selectedColumn.type)
                            ? "Based on"
                            : "Merged from"}{" "}
                          {selectedColumnMetadata.type === MetadataType.Event
                            ? EventColumn.icon
                            : RelationColumn.icon}{" "}
                          {selectedColumnMetadata.modelName}
                        </Row>
                      ) : null
                    }
                    title={selectedColumn?.type ?? FilterOption.All}
                  >
                    <Row gap={2} pl={1.5} pr={2} py={1.5}>
                      {icon && (
                        <IconBox
                          bg={color}
                          icon={icon}
                          boxSize={5}
                          iconSize={3}
                        />
                      )}
                      {selectedColumn ? buttonContent : "Select a property..."}
                    </Row>
                  </DataTooltip>
                </FilterSelectButton>
              )}
            </ChakraPopoverTrigger>

            {condition && condition.type === ConditionType.SegmentSet && (
              <ExtentalLink
                tooltipMessage="Open Audience"
                url={`/audiences/${condition.modelId}`}
              />
            )}
          </Row>

          <Portal>
            <ChakraPopoverContent width="auto">
              <ChakraPopoverBody p={0} flex="1 0 auto">
                <Row
                  minHeight={0}
                  flex={1}
                  maxHeight={`${MAX_POPOVER_HEIGHT_PX}px`}
                  pl={PADDING}
                  gap={PADDING}
                >
                  <Column
                    flex="0 0 auto"
                    role="tablist"
                    borderRight="1px"
                    borderColor="base.border"
                    overflowY="auto"
                    overflowX="visible"
                    py={PADDING}
                    width={`${TAB_SECTION_WIDTH_PX}px`}
                  >
                    {tabs.map(({ type, color, ...tab }, index) => (
                      <FilterPopoverTab
                        key={tab.label}
                        {...tab}
                        bg={color}
                        isFocused={focusedTabIndex === index}
                        isSelected={filter === type}
                        onKeyDown={changeFocus(index)}
                        onClick={() => {
                          onFilterChange(type);
                          searchRef.current?.focus();
                        }}
                      />
                    ))}
                  </Column>
                  <Column
                    role="tabpanel"
                    position="relative"
                    flex="0 0 auto"
                    width={`${CONTENT_SECTION_WIDTH_PX}px`}
                    py={PADDING}
                    pr={PADDING}
                  >
                    <SearchInput
                      ref={searchRef}
                      placeholder={placeholderText}
                      mb={2}
                      width="100%"
                      value={search}
                      onChange={onSearch}
                    />

                    <Column
                      ref={optionsContainer}
                      position="relative"
                      height="344px"
                      overflowY="auto"
                      width={`${CONTENT_SECTION_WIDTH_PX}px`}
                    >
                      {columns.length === 0 && (
                        <Column
                          flex={1}
                          minHeight={0}
                          justify="center"
                          textAlign="center"
                        >
                          <Column mt={4}>
                            <Heading>
                              No {pluralize(missingColumnTypeText)} found
                            </Heading>

                            <Paragraph mt={2} mx={8}>
                              {search.length > 0
                                ? ` Please check your search or create a new ${missingColumnTypeText}.`
                                : null}{" "}
                              Would you like to create a new{" "}
                              {missingColumnTypeText}?
                            </Paragraph>
                          </Column>

                          <ButtonGroup mx="auto" mt={8}>
                            <LinkButton
                              href={getSetupLink(filter, parent?.id)}
                              variant="secondary"
                            >
                              Go to setup
                            </LinkButton>
                          </ButtonGroup>
                        </Column>
                      )}

                      {/* Group labels are rendered separately. 
                      This is to make sure the group labels can be sticky, 
                      since the virtual items are removed from the DOM */}
                      <GroupLabels items={virtualItems} />

                      <Box
                        height={`${virtualizer.getTotalSize()}px`}
                        flexShrink={0}
                        position="relative"
                      >
                        {virtualizer.getVirtualItems().map((virtualItem) => {
                          const item = virtualItems[virtualItem.index];

                          if (!item) {
                            return null;
                          }

                          if (item.type === "group") {
                            return (
                              <Row
                                id="placeholder"
                                key={virtualItem.key}
                                align="center"
                                width="100%"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  height: `${virtualItem.size}px`,
                                  transform: `translateY(${virtualItem.start}px)`,
                                }}
                              />
                            );
                          }

                          const column = item?.option;
                          const displayProps = getIconProps(column?.type);

                          if (!displayProps) {
                            return null;
                          }

                          return (
                            <FilterPopoverOption
                              key={virtualItem.key}
                              bg={displayProps?.color}
                              hasGradient={
                                column.type !== FilterOption.InlineTrait
                              }
                              columnType={column.type}
                              icon={displayProps?.icon}
                              iconBoxSx={displayProps?.iconBoxSx}
                              iconSx={displayProps?.iconSx}
                              isSelected={
                                Boolean(condition) &&
                                isColumnSelected(condition, column)
                              }
                              label={column.label}
                              description={column?.description}
                              disabledText={column?.disabledText}
                              metadata={getMetadata(
                                // Don't show merged names in 'properties' view
                                filter === FilterOption.Property
                                  ? undefined
                                  : column,
                              )}
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                              }}
                              onClick={() => {
                                onClose();
                                onChange(onSelectColumn(column) as any);
                              }}
                            />
                          );
                        })}
                      </Box>
                    </Column>
                  </Column>
                </Row>
              </ChakraPopoverBody>
            </ChakraPopoverContent>
          </Portal>
        </>
      )}
    </ChakraPopover>
  );
}
