import { FC, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  Radio,
  RadioGroup,
  Select,
  TextInput,
  useToast,
} from "@hightouchio/ui";

import { useFolders } from "src/components/folders/use-folders";
import { FolderType, useCreateFolderMutation } from "src/graphql";

interface Props {
  onClose: () => void;
  onSave: (folderId: string) => void;
  folderType: FolderType;
  viewType: "syncs" | "models";
  toggleDisabled?: boolean;
  defaultParentFolder?: string;
}

export const AddFolder: FC<Readonly<Props>> = ({
  onClose,
  onSave,
  folderType,
  viewType,
  toggleDisabled,
  defaultParentFolder,
}) => {
  const [createFolderType, setCreateFolderType] = useState(folderType);
  const {
    state: { flattenedFolders },
    loadingFolders,
  } = useFolders({
    folderType: createFolderType,
    viewType,
  });
  const { mutateAsync: addFolder, isLoading } = useCreateFolderMutation();
  const { toast } = useToast();

  const [selectedFolder, setSelectedFolder] = useState<string | undefined>(
    defaultParentFolder,
  );
  const [name, setName] = useState("");

  return (
    <Dialog
      isOpen
      variant="form"
      title="New folder"
      actions={
        <ButtonGroup>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            isDisabled={!name}
            isLoading={isLoading}
            variant="primary"
            onClick={async () => {
              try {
                const res = await addFolder({
                  name,
                  parent_id: selectedFolder,
                  type: createFolderType,
                });
                onClose();
                onSave(String(res?.insert_folders_one?.id));
                toast({
                  id: "create-folder",
                  title: `Folder ${name} created`,
                  variant: "success",
                });
                setName("");
                setSelectedFolder(undefined);
              } catch (_err) {
                toast({
                  id: "create-folder",
                  title: "There was an error creating the folder",
                  variant: "error",
                });
              }
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <Column gap="4">
        {!toggleDisabled && (
          <RadioGroup
            value={createFolderType}
            onChange={(value) => setCreateFolderType(value ?? "audiences")}
          >
            <Radio label="Model folder" value="models" />
            <Radio label="Audience folder" value="audiences" />
          </RadioGroup>
        )}

        <FormField isRequired label="Folder name">
          <TextInput
            placeholder="Enter a folder name..."
            value={name}
            width="lg"
            onChange={(e) => setName(e.target.value)}
          />
        </FormField>

        {(flattenedFolders?.length || 0) > 0 && (
          <FormField label="Parent folder">
            <Select
              isClearable
              isLoading={isLoading || loadingFolders}
              optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
              optionValue={(folder) => folder.id}
              options={flattenedFolders || []}
              placeholder="Select a folder..."
              value={selectedFolder}
              width="lg"
              onChange={(folder) => {
                setSelectedFolder(folder);
              }}
            />
          </FormField>
        )}
      </Column>
    </Dialog>
  );
};
