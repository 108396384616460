import { FC, useRef } from "react";

import {
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Row,
  Text,
  useDisclosure,
} from "@hightouchio/ui";

const legendWrapperStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
  gridTemplateRows: "1fr",
  gridAutoColumns: "0px",
  gridAutoRows: "0px",
  columnGap: 8,
  py: 5,
  width: "100%",
  overflow: "hidden",
};

export type LegendProps = {
  payload?: {
    key: string;
    name: string;
    legendName: string;
    color: string;
  }[];
  onHover: (key: string) => void;
};

const Legend: FC<Readonly<LegendProps>> = ({ payload, onHover }) => {
  const legendRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Get max width of one item
  const childrenWidths = [...(legendRef.current?.children ?? [])].map(
    ({ clientWidth }) => clientWidth,
  );
  const maxChildWidth = Math.max(...childrenWidths);

  // Get number of items that can fit in legend
  const visibleChildCount = Math.floor(
    (legendRef.current?.offsetWidth ?? 0) / maxChildWidth,
  );

  const visibleChildren = payload?.slice(0, visibleChildCount);
  const hiddenChildren = payload?.slice(visibleChildCount);

  return (
    <>
      {/* Rendered content */}
      <Row
        gap={4}
        justify="space-between"
        pl="60px"
        width="100%"
        onMouseLeave={onClose}
      >
        <Row {...legendWrapperStyles}>
          {visibleChildren?.map(({ key, legendName, color }) => (
            <Row
              key={key}
              gap={2}
              alignItems="center"
              justify="center"
              overflow="hidden"
              onMouseEnter={() => onHover(key)}
            >
              <Box bg={color} borderRadius="3px" h={4} w={4} flexShrink={0} />
              <Text color="text.secondary" size="sm">
                {legendName}
              </Text>
            </Row>
          ))}
        </Row>

        {hiddenChildren && hiddenChildren.length > 0 && (
          <ChakraPopover isLazy isOpen={isOpen} onClose={onClose}>
            <ChakraPopoverTrigger>
              <Row
                as={Text}
                alignSelf="center"
                color="text.secondary"
                whiteSpace="nowrap"
                onMouseEnter={onOpen}
              >
                {hiddenChildren.length} more
              </Row>
            </ChakraPopoverTrigger>

            <ChakraPopoverContent
              borderRadius="lg"
              border="none"
              maxHeight="300px"
            >
              <ChakraPopoverBody
                display="flex"
                flexDirection="column"
                background="text.primary"
                borderRadius="lg"
                boxShadow="sm"
                color="white"
                gap={2}
                overflowY="auto"
                onMouseLeave={onClose}
              >
                {hiddenChildren?.map(({ key, legendName, color }) => (
                  <Row key={key} align="center" gap={2}>
                    <Box
                      bg={color}
                      borderRadius="3px"
                      h={4}
                      w={4}
                      flexShrink={0}
                    />
                    <Text color="white" size="sm">
                      {legendName}
                    </Text>
                  </Row>
                ))}
              </ChakraPopoverBody>
            </ChakraPopoverContent>
          </ChakraPopover>
        )}
      </Row>

      {/* Used to measure and decide which elements to show */}
      <Row
        gap={4}
        pl="60px"
        height={0}
        maxHeight={0}
        overflowY="hidden"
        visibility="hidden"
      >
        <Row ref={legendRef} {...legendWrapperStyles}>
          {payload?.map(({ key, name, color }) => (
            <Row key={key} gap={2} alignItems="center" overflow="hidden">
              <Box bg={color} borderRadius="3px" h={4} w={4} flexShrink={0} />
              <Text color="text.secondary" size="sm">
                {name}
              </Text>
            </Row>
          ))}
        </Row>
        <Row
          as={Text}
          alignSelf="center"
          color="text.secondary"
          whiteSpace="nowrap"
        >
          {/* use larger number to overestimate size */}
          123456 more
        </Row>
      </Row>
    </>
  );
};

export default Legend;
