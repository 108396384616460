import { Column, Row, Text } from "@hightouchio/ui";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { QueryType } from "src/types/models";
import { QueryTypeBadge } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";

type ModelCellProps = {
  segment: {
    connection: {
      definition: {
        icon: string;
        name: string;
      };
    } | null;
    name: string;
    query_type: string | null;
    last_run_size: number | null;
  } | null;
};

export const ModelCell = ({ segment }: ModelCellProps) => {
  return (
    <Row gap={3} width="100%" align="center" overflow="hidden">
      <IntegrationIcon
        src={segment?.connection?.definition?.icon}
        name={segment?.connection?.definition?.name}
      />
      <Column gap={1} overflow="hidden">
        <Text isTruncated fontWeight="medium">
          {segment?.name ?? "Private model"}
        </Text>
        <Row gap={2}>
          <QueryTypeBadge type={segment?.query_type as QueryType}>
            {segment?.last_run_size || segment?.last_run_size === 0
              ? `${abbreviateNumber(segment?.last_run_size)} rows`
              : "Unknown size"}
          </QueryTypeBadge>
        </Row>
      </Column>
    </Row>
  );
};
