import { useMemo } from "react";

import { FormkitComponent, RelationshipHierarchy } from "@hightouch/formkit";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { OverrideConfig } from "src/components/destinations/types";
import { useFormkitContext } from "src/formkit/components/formkit-context";

import { OVERRIDE_CONFIG_FORM_KEY } from "./constants";

/**
 * Get all the ancestor or children keys recursively given a relationship diagram.
 */
export function getParentOrChildrenKeysRecursively({
  field,
  relationshipHierarchy,
  visited = new Set(),
}: {
  field: [fieldName: string, "ancestors" | "children"];
  relationshipHierarchy: RelationshipHierarchy;
  visited?: Set<string>;
}): string[] {
  const [key, relationship] = field;
  const node = get(relationshipHierarchy, key);

  if (!node) {
    return [];
  }

  if (visited.has(key)) {
    return [];
  }
  visited.add(key);

  if (relationship === "children") {
    if (!node.children) {
      return [];
    }

    return Array.from(node.children).flatMap((key) => [
      key,
      ...getParentOrChildrenKeysRecursively({
        field: [key, "children"],
        relationshipHierarchy,
        visited,
      }),
    ]);
  }

  if (node.ancestors.size === 0) {
    return [];
  }

  const result: string[] = [];
  for (const parentKey of node.ancestors) {
    result.push(
      parentKey,
      ...getParentOrChildrenKeysRecursively({
        field: [parentKey, "ancestors"],
        relationshipHierarchy,
        visited,
      }),
    );
  }

  return uniq(result);
}

export function isSwitchChecked({
  nodes,
  relationshipHierarchy,
  overrideConfig,
}: {
  nodes: FormkitComponent[];
  relationshipHierarchy: RelationshipHierarchy | null;
  overrideConfig: OverrideConfig | null;
}) {
  if (!relationshipHierarchy || !overrideConfig) {
    return true;
  }

  // Determine if all of the nodes are locked
  return nodes.every((node) => !get(overrideConfig, [node.key, "overridable"]));
}

/**
 * A hook that determines the rules around locking a field when using a sync template.
 *
 * @param nodes The formkit components
 */
export function useSyncTemplateLockingRules(
  nodes: FormkitComponent[],
  numberOfSyncLevelOverrides: number = 0,
): {
  isLocked: boolean;
  message: string | { label: string; children: string[] } | null;
} {
  const { showLockUI, relationshipHierarchy } = useFormkitContext();
  const { watch } = useFormContext();

  const overrides = watch(OVERRIDE_CONFIG_FORM_KEY);
  // Get the ancestor relationships for all of the nodes in this section
  const ancestorFields = useMemo(
    () =>
      relationshipHierarchy
        ? nodes.flatMap((node) =>
            getParentOrChildrenKeysRecursively({
              field: [node.key, "ancestors"],
              relationshipHierarchy,
            }),
          )
        : [],
    [nodes, relationshipHierarchy],
  );

  if (!showLockUI || !relationshipHierarchy) {
    return { isLocked: true, message: null };
  }

  // This field may not be overriden if it has an ancestor that is unlocked
  const hasUnlockedAncestor = ancestorFields.some((parentKey) =>
    get(overrides, [parentKey, "overridable"]),
  );

  if (hasUnlockedAncestor) {
    const lockedAncestorFields = ancestorFields
      // Get unlocked fields
      .filter((field) => get(overrides, [field, "overridable"]))
      // Get ancestor fields (if any)
      .map((parentKey) => get(relationshipHierarchy, [parentKey, "heading"]))
      // Filter out fields that don't have ancestors
      .filter(isPresent);

    return {
      isLocked: false,
      message: {
        label: "To lock this field, first lock all parent fields",
        children: lockedAncestorFields,
      },
    };
  }

  if (numberOfSyncLevelOverrides > 0) {
    return {
      isLocked: false,
      message: "To lock this field, first clear all sync-level overrides",
    };
  }

  const isLocked = isSwitchChecked({
    nodes,
    relationshipHierarchy,
    overrideConfig: overrides,
  });

  return { isLocked, message: null };
}
