import {
  type AndOrCondition,
  type RootCondition,
  type PropertyCondition,
  IntervalUnit,
  type OrCondition,
  type AndCondition,
} from "../../query/visual/types";
import * as yup from "yup";
import { audienceAndOrConditionSchema } from "../../query/visual/schema";

//
// This file contains types that appear in the database, for example config
// types that add structure to a JSONB column, or enum types that add
// constraints to a string column representing an enum. All field names should
// match what is in the database exactly so we can use them directly on top of
// database query results.
//

export enum JourneyNodeType {
  // No-Op node type. Meant only for testing.
  NoOp = "no-op",

  EntryEvent = "entry-event",
  EntryCohort = "entry-cohort",
  WaitUntilEvent = "wait-until-event",
  TimeDelay = "time-delay",
  Segments = "segments",
  Splits = "splits",
  Sync = "sync",

  // Branch nodes
  SegmentBranch = "segment-branch",
  WaitUntilEventBranch = "wait-until-event-branch",
  SplitBranch = "split-branch",
}

export const JOURNEY_ENTRY_NODE_TYPES = [JourneyNodeType.EntryCohort];

export type NoOpConfig = {
  type: JourneyNodeType.NoOp;
};

export type EntryConfig = {
  max_num_entries: number;
};

export type EntryCohortConfig = EntryConfig & {
  type: JourneyNodeType.EntryCohort;
};

export const entryCohortConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.EntryCohort]),
  max_num_entries: yup
    .number()
    .required("Maximum number of entries is required")
    .integer(),
});

export type EntryEventConfig = EntryConfig & {
  type: JourneyNodeType.EntryEvent;
  max_simultaneous_entries: number;
  last_lookup_timestamp?: string | null; // Nullable ISO 8601 string - Expected format: YYYY-MM-DDTHH:mm:ss.sssZ
};

export const entryEventConfigSchema = yup
  .object()
  .shape({
    type: yup.string().required().oneOf([JourneyNodeType.EntryEvent]),
    max_num_entries: yup
      .number()
      .required("Maximum number of entries is required")
      .integer(),
    max_simultaneous_entries: yup
      .number()
      .required("Maximum number of simultaneous entries is required")
      .integer(),
    last_lookup_timestamp: yup
      .string()
      .nullable()
      .matches(
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
        "last_lookup_timestamp must be a valid ISO 8601 string (YYYY-MM-DDTHH:mm:ss.sssZ)",
      )
      .nullable(true),
  })
  .test(
    "simultaneous-entries-constraint",
    "If max_simultaneous_entries is -1, then max_num_entries must also be -1",
    (values) => {
      if (
        values.max_simultaneous_entries === -1 &&
        values.max_num_entries !== -1
      ) {
        return false;
      }
      return true;
    },
  );

export type TimeDelayConfig = {
  type: JourneyNodeType.TimeDelay;
  delay: {
    unit: IntervalUnit;
    quantity: number;
  };
};

export const timeDelayConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.TimeDelay]),
  delay: yup.object().shape({
    unit: yup.string().required().oneOf(Object.values(IntervalUnit)),
    quantity: yup
      .number()
      .min(0, "Quantity must be greater than or equal to 0")
      .required("Quantity is required")
      .integer(),
  }),
});

export type WaitUntilEventConfig = {
  type: JourneyNodeType.WaitUntilEvent;
  timeout_duration: {
    unit: IntervalUnit;
    quantity: number;
  };
  event_conditions: AndOrCondition<PropertyCondition>;
};

export const waitUntilEventConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.WaitUntilEvent]),
  timeout_duration: yup.object().shape({
    unit: yup.string().required().oneOf(Object.values(IntervalUnit)),
    quantity: yup
      .number()
      .min(0, "Quantity must be greater than or equal to 0")
      .required("Quantity is required")
      .integer(),
  }),
});

export type SegmentsConfig = {
  // All segment nodes are treated as priority lists
  type: JourneyNodeType.Segments;
};

export const segmentsConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.Segments]),
});

export type SegmentBranchConfig = {
  type: JourneyNodeType.SegmentBranch;
  // Priority order (0 is highest priority)
  segment_priority_rank: number;
  // If true, represents the “everybody else” branch of a `segments` node
  segment_is_catch_all: boolean;
  segment_conditions: AndOrCondition<RootCondition>;
};

export const segmentBranchConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.SegmentBranch]),
  segment_priority_rank: yup.number().required().integer(),
  segment_is_catch_all: yup.boolean().required(),
  segment_conditions: audienceAndOrConditionSchema.required(),
});

export type SyncConfig = {
  type: JourneyNodeType.Sync;
};

export const syncConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.Sync]),
});

export type WaitUntilEventBranchConfig = {
  type: JourneyNodeType.WaitUntilEventBranch;
  branch: "event" | "timeout";
};

export const waitUntilEventBranchConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.WaitUntilEventBranch]),
  branch: yup.string().required().oneOf(["event", "timeout"]),
});

export type SplitsConfig = {
  type: JourneyNodeType.Splits;
  // Override to enable re-randomization
  salt_override?: string;
};

export const splitsConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.Splits]),
  salt_override: yup.string().nullable(),
});

export type SplitBranchConfig = {
  type: JourneyNodeType.SplitBranch;
  percentage: number;
};

export const splitBranchConfigSchema = yup.object().shape({
  type: yup.string().required().oneOf([JourneyNodeType.SplitBranch]),
  percentage: yup.number().lessThan(100).moreThan(0).integer().required(),
});

export type JourneyNodeConfig =
  | NoOpConfig
  | EntryCohortConfig
  | EntryEventConfig
  | TimeDelayConfig
  | WaitUntilEventConfig
  | SegmentsConfig
  | SyncConfig
  | SplitsConfig
  | SegmentBranchConfig
  | WaitUntilEventBranchConfig
  | SplitBranchConfig;

export const journeyNodeConfigSchema = yup.lazy((value: any) => {
  switch (value.type) {
    case JourneyNodeType.EntryCohort:
      return entryCohortConfigSchema;
    case JourneyNodeType.EntryEvent:
      return entryEventConfigSchema;
    case JourneyNodeType.TimeDelay:
      return timeDelayConfigSchema;
    case JourneyNodeType.WaitUntilEvent:
      return waitUntilEventConfigSchema;
    case JourneyNodeType.Segments:
      return segmentsConfigSchema;
    case JourneyNodeType.Sync:
      return syncConfigSchema;
    case JourneyNodeType.Splits:
      return splitsConfigSchema;
    case JourneyNodeType.SegmentBranch:
      return segmentBranchConfigSchema;
    case JourneyNodeType.WaitUntilEventBranch:
      return waitUntilEventBranchConfigSchema;
    case JourneyNodeType.SplitBranch:
      return splitBranchConfigSchema;
    case JourneyNodeType.NoOp:
      return yup.object();
    default:
      throw new Error(
        `JourneyNodeType ${value.type} does not have a valid schema defined`,
      );
  }
});

export enum JourneyStatus {
  Enabled = "enabled",
  Disabled = "disabled",
  Draining = "draining",
}

export const SCHEDULABLE_JOURNEY_STATUSES = [
  JourneyStatus.Enabled,
  JourneyStatus.Draining,
];

export enum JourneyRunStatus {
  Pending = "pending",
  Success = "success",
  Error = "error",
  InProgress = "in-progress",
}

export const TERMINAL_JOURNEY_RUN_STATUSES = [
  JourneyRunStatus.Success,
  JourneyRunStatus.Error,
];

export enum JourneyNodeStatus {
  Pending = "pending",
  InProgress = "in-progress",
  Success = "success",
  Error = "error",
  LocalError = "local-error",
}

export function isValidJourneyNodeStatus(
  status: unknown,
): status is JourneyNodeStatus {
  return Object.values(JourneyNodeStatus).includes(status as JourneyNodeStatus);
}

export enum JourneyNodeSyncMode {
  Cohort = "cohort",
  Trigger = "trigger",
}

export enum JourneyRunReason {
  Scheduled = "scheduled",
  ForceRemoveRows = "force-remove-rows",

  /**
   * This journey was manually run.
   */
  Manual = "manual",
}

// Reasons that a journey run could be scheduled that don't actually execute a
// full journey run. Used so the scheduler can figure out when to ignore a run
// for scheduling purposes.
export const INTERNAL_JOURNEY_RUN_REASONS = [JourneyRunReason.ForceRemoveRows];

// Type for the JSONB column in the journey_syncs table. Note that for now these
// fields are only relevant for Cohort type sync models.
export type JourneySyncExitConfig = {
  // Remove the row after it has been in the sync for this amount of time.
  remove_after?: {
    unit: IntervalUnit;
    quantity: number;
  };
  // Remove the row when it exits the journey.
  remove_on_journey_exit?: boolean;
};

export const journeySyncExitConfigSchema = yup.object().shape({
  remove_after: yup.object().shape({
    unit: yup.string().oneOf(Object.values(IntervalUnit)),
    quantity: yup.number().positive().integer(),
  }),
  remove_on_journey_exit: yup.boolean(),
});

// Type for the exit_criteria JSONB column in the journeys table.
export type JourneyExitCriteriaConfig =
  | AndCondition<RootCondition>
  | OrCondition<RootCondition>;

export const journeyExitCriteriaSchema = yup.object().shape({
  type: yup.string().oneOf(["and", "or"]).required(),
  conditions: yup.array().defined().required(),
});
