/*eslint "no-restricted-imports": ["error", {
    "patterns": [
      {
        "group": ["^(short-uuid)"],
        "message": "This file is used on the frontend, so try not to import extra code."
      }
    ]
  } 
]*/

import { uuid } from "short-uuid";

// NOTE: The frontend uses @sindresorhus/slugify, but this is a pure ESM package.
// It ends up being a bit miserable to use as a result, so we'll just use this for common code.
// If the situation improves, we can switch to @sindresorhus/slugify.

/**
 * Returns the slugified version of a string, without attempting any uniqueness checks.
 * If the name has no valid characters to generate a slug with, a UUID is returned.
 */
export function slugifyLocalName(name?: string): string {
  if (!name) {
    return uuid();
  }

  // https://gist.github.com/codeguy/6684588?permalink_comment_id=3974852#gistcomment-3974852
  const result = name
    .toString()
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .trim()
    .replace(/\s+/g, "-");

  return result || uuid();
}
