import { FC, useState } from "react";

import {
  Alert,
  CloseIcon,
  ConfirmationDialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Paragraph,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import pluralize from "pluralize";

import { useClearSyncLevelOverrides } from "./use-clear-sync-level-overrides";
import { DependentSyncs } from "./types";

type ClearSyncOverridesBannerProps = {
  dependentSyncs: DependentSyncs;
};

export const ClearSyncOverridesBanner: FC<ClearSyncOverridesBannerProps> = ({
  dependentSyncs = [],
}) => {
  const { enableSyncTemplateOverrides } = useFlags();
  const [confirmClearSyncLevelOverrides, setConfirmClearSyncLevelOverrides] =
    useState(false);

  const { clearSyncLevelOverrides } = useClearSyncLevelOverrides();

  const numberOfSyncOverrides: Record<string, any>[] = dependentSyncs
    .map(({ sync_template_overrides }) =>
      omit(sync_template_overrides, ["configVersion"]),
    )
    .filter((overrides) => !isEmpty(overrides));

  return (
    <>
      {enableSyncTemplateOverrides && numberOfSyncOverrides.length > 0 && (
        <Alert
          justify="center"
          title={`This template has sync-level overrides across ${pluralize(
            "sync",
            dependentSyncs.length,
            true,
          )}`}
          type="info"
          variant="banner"
          actions={
            <Menu>
              <MenuButton>Actions</MenuButton>
              <MenuList>
                <MenuItem
                  icon={CloseIcon}
                  variant="danger"
                  onClick={() => setConfirmClearSyncLevelOverrides(true)}
                >
                  Clear all sync level overrides
                </MenuItem>
              </MenuList>
            </Menu>
          }
        />
      )}
      <ConfirmationDialog
        isOpen={confirmClearSyncLevelOverrides}
        title="Clear all sync-level overrides"
        onConfirm={() =>
          clearSyncLevelOverrides(dependentSyncs.map(({ id }) => id))
        }
        onClose={() => setConfirmClearSyncLevelOverrides(false)}
        confirmButtonText={`Clear overrides for ${pluralize(
          "sync",
          dependentSyncs.length,
          true,
        )}`}
        variant="danger"
      >
        <Paragraph>
          Clearing sync-level overrides could change the behavior of the syncs.
          After clearing overrides, all syncs using this template will match the
          template’s exact configuration. Are you sure you want to clear all
          sync-level overrides?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
