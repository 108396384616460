import { Text, Column } from "@hightouchio/ui";

import { useParams } from "src/router";
import { useSourceQuery } from "src/graphql";

export const DemoSourceConfiguration = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source } = useSourceQuery(
    { id: String(id) },
    { select: (data) => data.connections_by_pk, suspense: true },
  );

  return (
    <Column gap={4}>
      <Text size="lg" fontWeight="semibold">
        {source!.definition?.name}
      </Text>
      <Text color="text.secondary">{source!.definition?.longPitch}</Text>
    </Column>
  );
};
