import {
  Drawer,
  DrawerHeader,
  Row,
  DrawerBody,
  IconButton,
  CloseIcon,
  SectionHeading,
  SearchInput,
} from "@hightouchio/ui";
import pluralize from "pluralize";
import { FC, useState } from "react";
import { useNavigate, useOutletContext } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { Form, useHightouchForm, SaveButton } from "src/components/form";
import { Member, Members } from "./components/members";
import { OutletContext } from "..";
import { useUpdateUserGroupMembersMutation } from "src/graphql";

export const AddUsers: FC = () => {
  const navigate = useNavigate();
  const context = useOutletContext<OutletContext>();
  const [search, setSearch] = useState("");

  const addUsersMutation = useUpdateUserGroupMembersMutation();

  const onClose = () => {
    navigate(`/organization/groups/${context.userGroup.id}/members`);
  };

  const form = useHightouchForm({
    onSubmit: async ({ members }) => {
      await addUsersMutation.mutateAsync({
        objects: Object.values(members)
          .filter(Boolean)
          .map(({ user_id }) => ({
            user_group_id: String(context.userGroup.id),
            user_id: String(user_id),
          })),
      });
      onClose();
    },
    success: (data) =>
      `Added ${Object.keys(data.members).length} users to group`,
    defaultValues: {
      members: {} as Record<string, Member>,
    },
  });

  const members = form.watch("members");
  const selected = Object.values(members)
    .filter(Boolean)
    .map(({ user_id }) => user_id);
  const count = selected.length;

  return (
    <Form form={form}>
      <Drawer isOpen={true} onClose={onClose} size="xl">
        <DrawerHeader>
          <Row justifyContent="space-between" alignItems="center" width="100%">
            Assign users to this group
            <IconButton
              aria-label="Close drawer"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody>
          <Row align="center" gap={4} justify="space-between" mb={4}>
            <Row gap={2} align="center">
              <SectionHeading>Select users</SectionHeading>
            </Row>

            <SearchInput
              value={search}
              placeholder="Search by name or email..."
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Row>
          <Members
            exclude={context.userGroup?.members?.map(({ user_id }) => user_id)}
            organizationId={context.organization.id}
            search={search}
            onSelect={(value) => {
              if (Array.isArray(value)) {
                form.setValue(
                  "members",
                  value.reduce((acc, id) => {
                    if (selected.includes(id)) {
                      return acc;
                    } else {
                      return {
                        ...acc,
                        [id]: { user_id: id },
                      };
                    }
                  }, {}),
                  { shouldDirty: true },
                );
              } else {
                form.setValue(
                  "members",
                  {
                    ...members,
                    [value]: selected.includes(value)
                      ? null
                      : { user_id: value },
                  },
                  { shouldDirty: true },
                );
              }
            }}
            selectedRows={selected}
          />
        </DrawerBody>
        <ActionBar fit>
          <SaveButton>Assign {pluralize("user", count, true)}</SaveButton>
        </ActionBar>
      </Drawer>
    </Form>
  );
};
