import { FC, useCallback, useMemo, useState } from "react";

import {
  Box,
  Button,
  Column,
  MeetingIcon,
  Row,
  SearchInput,
  Spinner,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import groupBy from "lodash/groupBy";
import noop from "lodash/noop";
import orderBy from "lodash/orderBy";
import { isPresent } from "ts-extras";
import { useFormContext } from "react-hook-form";

import { GraphSeries } from "src/components/analytics/cross-audience-graph/types";
import {
  formatMetricValue,
  getMetricDescription,
} from "src/components/analytics/cross-audience-graph/utils";
import { DateRangePicker } from "src/components/analytics/date-range-picker";
import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { accurateCommaNumber } from "src/utils/numbers";

import { useAnalyticsContext } from "src/pages/analytics/state";
import { LookbackOptions } from "src/pages/analytics/state/constants";
import {
  formatDatePickerLabel,
  getColumnReferenceFromGroupByColumn,
  isGroupByColumnRelatedToParent,
  shouldUsePercentFormat,
} from "src/pages/analytics/utils";
import {
  CellZIndex,
  GridArea,
  gridTemplateColumns,
  sharedHeaderCellStyles,
  sharedHeaderContainerStyles,
  sharedRowCellStyles,
  sharedRowContainerStyles,
  sharedSubHeaderCellStyles,
} from "./styles";
import { audienceSplitName } from "./utils";
import {
  ChartFormState,
  GroupByColumn,
  TimeOptions,
} from "src/pages/analytics/types";
import { mapNormalizationLabel } from "src/pages/metrics/utils";
import { NumericFontStyles } from "src/components/audiences/constants";
import uniqBy from "lodash/uniqBy";
import { GraphOptionsSelection } from "../common";

type AnalyticsTableProps = {
  data: GraphSeries[];
  isLoading?: boolean;
  lookbackWindow: TimeOptions;
  selectedDates: Date[];
  onSelectDateRange: (dates: Date[]) => void;
  onUpdateLookbackWindow: (value: TimeOptions) => void;
};

export const AnalyticsTable: FC<AnalyticsTableProps> = ({
  data,
  isLoading,
  lookbackWindow,
  selectedDates,
  onSelectDateRange,
  onUpdateLookbackWindow,
}) => {
  const [hoverRow, setHoverRow] = useState<number | null>();

  const { parent } = useAnalyticsContext();
  const form = useFormContext<ChartFormState>();

  const metricSelection = form.watch("metricSelection");
  const groupByColumns = form.watch("groupByColumns");

  const graphSeriesSegments = uniqBy(
    data.map((graph) => ({
      id: `${graph.audienceName}-${graph.splitName ?? "no_splits"}`,
      name: graph.audienceName,
      splitName: graph.splitName,
      displayName: audienceSplitName(graph.audienceName, graph.splitName),
    })),
    "id",
  );

  const selectedMetrics = useMemo(
    () => metricSelection.filter(({ id, name }) => id !== "" && name !== ""),
    [metricSelection],
  );

  const groupByColumnsNames: string[] = useMemo(() => {
    const foundGroupByColumnName = {};
    return (groupByColumns ?? [])
      .map((column) => {
        if (!column) return null;

        const col = getColumnReferenceFromGroupByColumn(
          column,
        ) as GroupByColumn;
        const columnName = getPropertyNameFromColumn(col);

        if (isGroupByColumnRelatedToParent(parent, col)) {
          return columnName;
        }

        // Group different event models with the same column name together
        // since we will consolidate the results into one column
        if (!columnName || foundGroupByColumnName[columnName]) {
          return null;
        }

        foundGroupByColumnName[columnName] = true;
        return columnName;
      })
      .filter(isPresent);
  }, [groupByColumns]);

  const hasGroupByColumns = groupByColumnsNames.length > 0;

  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();

    const filteredData = data.filter(
      ({ data, rawMetricName, audienceName, splitName, grouping }) => {
        const filterableValues = [
          // Group by values
          ...(grouping ?? []).map((group) => group.value),

          // Metric name
          rawMetricName,

          // Audience name
          audienceName,

          // Split name
          splitName,

          // Stringified metric value for audience-split pair
          data[0]?.metricValue
            ? accurateCommaNumber(data?.[0]?.metricValue)
            : undefined,
        ];

        return filterableValues.some((key) =>
          key?.toLowerCase().includes(lowerCaseSearch),
        );
      },
    );

    return filteredData;
  }, [data, search]);

  // breakdownValue -> MetricAudience[]
  const groupByBreakdownValue = useMemo(() => {
    const groups = groupBy(filteredData, (row) =>
      row.grouping?.map(({ value }) => value).join(":"),
    );

    return groups;
  }, [filteredData]);

  const hoverStyles = useCallback(
    (rowIndex: number) => ({
      background: rowIndex === hoverRow ? "base.background" : "white",
      onMouseOver: () => setHoverRow(rowIndex),
      onMouseLeave: () => setHoverRow(null),
      _hover: { background: "primary.background" },
    }),
    [hoverRow, setHoverRow],
  );

  return (
    <Column flex={1} minHeight={0} p={6} pt={4} pb={4} gap={4}>
      <SearchInput
        placeholder="Search rows..."
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <Row align="center" gap={2} justifyContent="space-between">
        <Row gap={2}>
          <ToggleButtonGroup
            size="sm"
            value={lookbackWindow}
            onChange={(value) => onUpdateLookbackWindow(value as TimeOptions)}
          >
            {LookbackOptions.map((option) => (
              <ToggleButton key={option.value} {...option} />
            ))}
          </ToggleButtonGroup>
          <DateRangePicker
            selectedDates={selectedDates}
            onChange={onSelectDateRange}
          >
            <Box
              as={Button}
              background={
                lookbackWindow === TimeOptions.Custom ? "gray.200" : "unset"
              }
              fontWeight={
                lookbackWindow === TimeOptions.Custom ? "semibold" : "normal"
              }
              icon={MeetingIcon}
              size="sm"
              onClick={noop}
            >
              {formatDatePickerLabel(selectedDates, lookbackWindow)}
            </Box>
          </DateRangePicker>
        </Row>

        <GraphOptionsSelection />
      </Row>
      {isLoading ? (
        <Column height="100%" alignItems="center" justifyContent="center">
          <Spinner size="lg" />
        </Column>
      ) : (
        <Column
          overflow="auto"
          gap={2}
          border="1px solid"
          borderColor="base.border"
        >
          <Box
            display="grid"
            gridTemplateAreas={
              hasGroupByColumns
                ? `"${GridArea.GroupBys} ${GridArea.Metrics}"`
                : `"${GridArea.Metrics}"`
            }
            gridTemplateRows="auto 1fr"
            gridAutoRows="1fr"
            position="relative"
          >
            {hasGroupByColumns && (
              /*
              We want to "freeze" the groupBy columns when scrolling horizontally.
              Therefore, we wrap them all in a sticky div.
              */
              <Box
                sx={{
                  ...sharedHeaderContainerStyles({
                    gridArea: GridArea.GroupBys,
                    numColumns: groupByColumnsNames.length,
                    zIndex: CellZIndex.GroupByHeader,
                  }),
                  gridTemplateColumns: gridTemplateColumns(
                    groupByColumnsNames.length,
                  ),
                }}
              >
                {groupByColumnsNames.map((columnName, index) => (
                  <Box
                    key={`${columnName}-${index}`}
                    display="grid"
                    sx={sharedHeaderCellStyles}
                  >
                    <Row alignItems="center" padding={4}>
                      <Text>{columnName ?? ""}</Text>
                    </Row>

                    {/* Dummy cell to match the size and styling of the "Audience" cells under "Metrics" column to maintain symmetry */}
                    {graphSeriesSegments.length > 1 && (
                      <Box sx={sharedSubHeaderCellStyles}></Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}

            {/* Container for the header rows representing the metrics */}
            <Box
              sx={{
                ...sharedHeaderContainerStyles({
                  gridArea: GridArea.Metrics,
                  numColumns: selectedMetrics.length,
                  zIndex: CellZIndex.MetricHeader,
                }),
              }}
            >
              {selectedMetrics.map((metric, index) => {
                const isLiveMetric = metric.eventModelId != undefined;
                const normalizationLabel = mapNormalizationLabel(
                  metric.normalization,
                );
                return (
                  <Column
                    key={`${metric.id}-${metric.aggregationMethod}-${index}`}
                    sx={sharedHeaderCellStyles}
                    alignItems="end"
                    display="grid"
                    _last={{ borderRight: "none" }}
                  >
                    <Box padding={4} textAlign="right">
                      <Box>
                        <Text>{metric.name}</Text>
                      </Box>
                      {isLiveMetric && (
                        <Box>
                          <Text color="text.secondary" fontWeight="normal">
                            {getMetricDescription({
                              aggregationMethod: metric.aggregationMethod,
                              column: metric.column,
                            })}
                            {normalizationLabel && ` [${normalizationLabel}]`}
                          </Text>
                        </Box>
                      )}
                    </Box>

                    {graphSeriesSegments.length > 1 && (
                      <Box
                        display="grid"
                        gridTemplateColumns={gridTemplateColumns(
                          graphSeriesSegments.length,
                        )}
                        width="100%"
                      >
                        {graphSeriesSegments.map(
                          ({ id, displayName }, index) => (
                            <Row
                              key={`${id}-${index}`}
                              sx={sharedSubHeaderCellStyles}
                              borderRight="1px solid"
                              _last={{ borderRight: "none" }}
                            >
                              <TextWithTooltip>{displayName}</TextWithTooltip>
                            </Row>
                          ),
                        )}
                      </Box>
                    )}
                  </Column>
                );
              })}
            </Box>

            {/* Row data */}
            {orderBy(Object.keys(groupByBreakdownValue)).map(
              (key, rowIndex) => {
                const metrics = groupByBreakdownValue[key] ?? [];
                const grouping = metrics?.[0]?.grouping;

                // Fall back to referring to the key (not reliable in case groupBy values have any `:`)
                const groupByValues = grouping
                  ? grouping.map((g) => g.value)
                  : key.split(":");
                const groupByMetricName = groupBy(
                  metrics,
                  (metric) => metric.rawMetricName,
                );

                return (
                  <>
                    {hasGroupByColumns && (
                      <Box
                        key={rowIndex}
                        sx={{
                          ...sharedRowContainerStyles({
                            gridArea: GridArea.GroupBys,
                            numColumns: groupByColumnsNames.length,
                            rowIndex,
                            zIndex: CellZIndex.GroupByCell,
                          }),
                          gridTemplateColumns: gridTemplateColumns(
                            groupByColumnsNames.length,
                          ),
                        }}
                        position="sticky"
                        left={0}
                      >
                        {groupByValues.map((value, index) => (
                          <Column
                            key={`${value}-${index}`}
                            sx={sharedRowCellStyles}
                            justifyContent="center"
                            paddingX={4}
                            paddingY={2}
                            {...hoverStyles(rowIndex)}
                          >
                            <TextWithTooltip>{value || "--"}</TextWithTooltip>
                          </Column>
                        ))}
                      </Box>
                    )}

                    <Box
                      sx={{
                        ...sharedRowContainerStyles({
                          gridArea: GridArea.Metrics,
                          numColumns: selectedMetrics.length,
                          rowIndex,
                          zIndex: CellZIndex.MetricValueCell,
                        }),
                      }}
                    >
                      {selectedMetrics.map((selectedMetric, metricIndex) => {
                        // Make sure the metrics are in the same order as the metric headers
                        const audienceMetrics =
                          groupByMetricName[selectedMetric.name] ?? [];

                        return (
                          <Box
                            key={`${selectedMetric.id}-${metricIndex}`}
                            sx={sharedRowCellStyles}
                            display="grid"
                            gridTemplateColumns={`repeat(${graphSeriesSegments.length}, 1fr)`}
                            justifyContent="right"
                            textAlign="right"
                          >
                            {graphSeriesSegments.map(
                              (selectedAudience, audienceIndex) => {
                                // Match against:
                                // 1) current selected metric
                                // 2) current selected audience
                                //
                                // This allows us to disambiguate metrics that come from the same underlying event model
                                // and are also applied to the same audience.
                                const audienceMetric = audienceMetrics.find(
                                  (metric) =>
                                    metric.aggregation ===
                                      selectedMetric.aggregationMethod &&
                                    audienceSplitName(
                                      metric.audienceName,
                                      metric.splitName,
                                    ) === selectedAudience.displayName,
                                );

                                const cellValue =
                                  audienceMetric?.data[0]?.metricValue;

                                return (
                                  <Column
                                    key={`${selectedAudience.id}-${audienceIndex}`}
                                    borderRight="1px solid"
                                    borderColor="base.border"
                                    justifyContent="center"
                                    paddingX={4}
                                    paddingY={2}
                                    _last={{ border: "none" }}
                                    {...hoverStyles(rowIndex)}
                                  >
                                    <TextWithTooltip sx={NumericFontStyles}>
                                      {formatMetricValue(
                                        cellValue ?? 0,
                                        shouldUsePercentFormat(
                                          selectedMetric.aggregationMethod,
                                          selectedMetric.normalization,
                                        ),
                                      )}
                                    </TextWithTooltip>
                                  </Column>
                                );
                              },
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                );
              },
            )}
          </Box>
        </Column>
      )}
    </Column>
  );
};
