import { Fragment, useMemo, useRef } from "react";

import { Box, Column, Row, Text } from "@hightouchio/ui";

import { VirtualItem } from "./use-virtual-items";

type GroupLabelsProps<T extends { label: string }> = {
  items: VirtualItem<T>[];
};

export const GroupLabels = <T extends { label: string }>({
  items,
}: GroupLabelsProps<T>) => {
  const groupLabelsRef = useRef<Array<HTMLDivElement | null>>([]);

  const spaceBetweenGroupLabels = useMemo(() => {
    const result = {};
    let lastGroupIndex = 0;

    for (let index = 0; index < items.length; index++) {
      const item = items[index];

      // Get the difference in height between the last group label and the current group label
      if (item?.type === "group") {
        result[item.label] =
          index === 0 ? 0 : 48 * (index - lastGroupIndex - 1);
        lastGroupIndex = index;
      }
    }
    return result;
  }, [items]);

  return (
    <Column position="absolute" top={0} width="100%">
      {items.map((item, index) => {
        if (item.type === "group") {
          return (
            <Fragment key={`${item.label}-${index}`}>
              {/* Placeholder div that accounts for heights of options. Spaces out the labels. */}
              <Box
                bg="transparent"
                flexShrink={0}
                height={
                  // -1 to account for the group label height
                  `${spaceBetweenGroupLabels[item.label]}px`
                }
              />
              <Row
                ref={(el) => (groupLabelsRef.current[index] = el)}
                position="sticky"
                top={0}
                flexShrink={0}
                align="center"
                height={`${item.height}px`}
                bg="white"
                zIndex={1}
              >
                <Text
                  color="text.secondary"
                  fontWeight="semibold"
                  size="sm"
                  textTransform="uppercase"
                >
                  {item.label}
                </Text>
              </Row>
            </Fragment>
          );
        }

        return null;
      })}
    </Column>
  );
};
