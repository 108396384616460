import { useMemo, useState, FC, useCallback } from "react";

import {
  Row,
  Box,
  Column,
  SectionHeading,
  SearchInput,
  Tooltip,
  Text,
  Menu,
  MenuList,
  ButtonGroup,
  useToast,
  MenuActionsButton,
  DeleteIcon,
  InformationIcon,
  MailIcon,
  UserIcon,
  Paragraph,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { differenceInDays, format } from "date-fns";
import pluralize from "pluralize";

import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { InviteFormModal } from "src/components/modals/invite-modal";
import {
  PermissionedMenuItem,
  PermissionedButton,
  withPermission,
} from "src/components/permission";
import { useUser } from "src/contexts/user-context";
import {
  useApproveMembershipMutation,
  useCancelInviteMutation,
  useWorkspaceQuery,
  useMembershipRequestsByWorkspaceQuery,
  usePendingInvitesQuery,
  useRejectMembershipMutation,
  useSendInviteMutation,
  useUpdateUserWorkspaceRoleMutation,
  useDeleteMembersMutation,
  MembershipsOrderBy,
} from "src/graphql";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { RoleSelect } from "src/components/role-select";

enum SortKeys {
  Name = "user.name",
  Email = "user.email",
  Role = "role.name",
  CreatedAt = "user.created_at",
}

const PermissionedRoleSelect = withPermission(RoleSelect);

export const RoleSelector: FC<{
  userId: number;
  roleId: string;
  isDisabled?: boolean;
}> = ({ userId, roleId, isDisabled = false }) => {
  const { workspace, user } = useUser();
  const { mutateAsync: updateRole, isLoading: isUpdating } =
    useUpdateUserWorkspaceRoleMutation();

  const selectRole = useCallback(
    async (newRoleId) => {
      await updateRole({
        userId: String(userId),
        roleId: String(newRoleId),
      });
    },
    [workspace, userId],
  );

  const isEditingMyself = userId === user?.id;

  return (
    <PermissionedRoleSelect
      tooltip={
        isDisabled
          ? "This user's role can only be modified by changing their SSO group role mapping"
          : isEditingMyself
            ? "You can't edit your own role"
            : undefined
      }
      permission={{
        v2: { resource: "workspace", grant: "can_update" },
      }}
      isLoading={isUpdating}
      isDisabled={isEditingMyself || isDisabled}
      value={roleId}
      onChange={selectRole}
    />
  );
};

const placeholder = {
  title: "No members",
  error: "Members failed to load, please try again.",
};

export const MembersV1 = () => {
  const { user, workspace } = useUser();
  const { toast } = useToast();

  const [inviting, setInviting] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const { limit, offset, orderBy, page, setPage, onSort } =
    useTableConfig<MembershipsOrderBy>({
      defaultSortKey: "user.name",
      defaultSortDirection: "asc",
      sortOptions: Object.values(SortKeys),
      limit: 25,
    });

  const { data: workspaceData, isRefetching: refetchingWorkspace } =
    useWorkspaceQuery(
      {
        limit,
        offset,
        search: search ? `%${search}%` : undefined,
        orderBy,
        workspaceId: workspace?.id,
      },
      {
        keepPreviousData: true,
        suspense: true,
      },
    );

  const { mutateAsync: deleteMembers } = useDeleteMembersMutation();

  const bulkDeleteMembers = async () => {
    for (const userId of selectedRows) {
      const user = users?.find((user) => user.id === userId);

      if (user?.is_sso) {
        toast({
          id: "bulk-delete-members",
          title: "You cannot remove an SSO user.",
          variant: "error",
        });

        return;
      }
    }

    const count = selectedRows.length;
    const pluralizedLabel = pluralize("member", count);

    try {
      await deleteMembers({
        workspaceId: workspace?.id,
        userIds: selectedRows.map(String),
      });

      toast({
        id: "bulk-delete-priority-lists",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-priority-lists",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const memberships = workspaceData?.workspaces_by_pk?.memberships;
  const membershipCount =
    workspaceData?.all_memberships_aggregate?.aggregate?.count ?? 0;

  // Extract users and inject role_id into each row.
  const users = useMemo(
    () =>
      memberships?.map((membership) => ({
        ...membership.user,
        role_id: membership.role?.id,
        is_sso: membership.is_sso,
      })),
    [memberships],
  );

  const currentUserSelected = selectedRows.includes(Number(user?.id));

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.user?.name,
      cell: ({ name }) => (
        <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
      ),
      onClick: () => onSort(SortKeys.Name),
      breakpoint: "sm",
    },
    {
      name: "Email address",
      cell: ({ email }) => email,
      max: "240px",
      sortDirection: orderBy?.user?.email,
      onClick: () => onSort(SortKeys.Email),
    },
    {
      header: () => {
        return (
          <>
            Role
            <Tooltip message="Only available in Business Tier workspaces. Role changes may take up to 15 seconds to take effect.">
              <Row align="center" fontSize="20px" ml={1}>
                <InformationIcon color="text.secondary" />
              </Row>
            </Tooltip>
          </>
        );
      },
      max: "240px",
      sortDirection: orderBy?.role?.name,
      onClick: () => onSort(SortKeys.Role),
      cell: ({ role_id: roleId, id: userId, is_sso: isSso }) => (
        <RoleSelector
          isDisabled={
            isSso &&
            !workspaceData?.workspaces_by_pk?.organization?.override_sso_roles
          }
          roleId={roleId}
          userId={userId}
        />
      ),
    },
    {
      name: "Auth method",
      cell: ({ auth0_auth_id, google_auth_id, azure_ad_auth_id }) => {
        if (google_auth_id) {
          return "Google";
        } else if (azure_ad_auth_id) {
          return "Microsoft";
        } else if (auth0_auth_id) {
          return "Single Sign-On";
        }

        return "Email";
      },
      breakpoint: "md",
    },
    {
      name: "Member since",

      max: "240px",
      sortDirection: orderBy?.user?.created_at,
      onClick: () => onSort(SortKeys.CreatedAt),
      cell: ({ created_at }) => format(new Date(created_at), "MMMM do y"),
      breakpoint: "md",
    },
  ];

  return (
    <>
      <Column gap={10} flex={1}>
        <PendingInvites
          defaultWorkspaceRoleId={workspace?.default_role_id}
          workspaceId={workspace?.id}
        />
        <AwaitingApproval workspaceId={workspace?.id} />
        <Column flex={1}>
          <Row align="center" justify="space-between">
            <SectionHeading mb={4}>Members</SectionHeading>

            {/* Organizations (only created on paid plans) can disable manually inviting users. */}
            {(!workspaceData?.workspaces_by_pk?.organization ||
              workspaceData?.workspaces_by_pk?.organization
                ?.can_invite_users) && (
              <PermissionedButton
                permission={{
                  v2: { resource: "workspace", grant: "can_update" },
                }}
                variant="primary"
                onClick={() => setInviting(true)}
              >
                Invite new members
              </PermissionedButton>
            )}
          </Row>
          <Row justify="space-between" mb={4}>
            <SearchInput
              placeholder="Search by name or email..."
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            {selectedRows.length > 0 && (
              <PermissionedButton
                permission={{
                  v2: { resource: "workspace", grant: "can_update" },
                }}
                variant="warning"
                onClick={() => setConfirmingDelete(true)}
              >
                Delete selected members
              </PermissionedButton>
            )}
          </Row>
          <Table
            columns={columns}
            data={users}
            loading={refetchingWorkspace}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onSelect={onRowSelect}
          />
          <Row mt={4} justify="flex-end" width="100%">
            <Pagination
              count={membershipCount}
              label="members"
              page={page}
              rowsPerPage={limit}
              setPage={setPage}
            />
          </Row>
        </Column>
      </Column>
      <InviteFormModal
        close={() => setInviting(false)}
        name={workspace?.name ?? ""}
        open={inviting}
      />
      <BulkDeleteConfirmationModal
        content={
          currentUserSelected ? (
            <Paragraph>
              You can not remove yourself from a workspace. Please update your
              selection.
            </Paragraph>
          ) : undefined
        }
        count={selectedRows.length}
        disabled={currentUserSelected}
        isOpen={confirmingDelete}
        label="member"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteMembers}
      />
    </>
  );
};

const AwaitingApproval = ({ workspaceId }) => {
  const { data } = useMembershipRequestsByWorkspaceQuery({
    workspaceId,
  });

  const awaitingApprovals = data?.membership_requests;

  if (!awaitingApprovals?.length) {
    return null;
  }

  return (
    <Column>
      <SectionHeading mb={4}>Awaiting approval</SectionHeading>
      <Column gap={2}>
        {awaitingApprovals?.map(({ user: { name, email, id } }) => (
          <MembershipRequest
            key={id}
            email={email}
            name={name}
            userId={id}
            workspaceId={workspaceId}
          />
        ))}
      </Column>
    </Column>
  );
};

const MembershipRequest: FC<{
  name: string;
  email: string;
  workspaceId: string;
  userId: string;
}> = ({ workspaceId, userId, name, email }) => {
  const { toast } = useToast();
  const { mutate: rejectRequest } = useRejectMembershipMutation();
  const { mutate: approveRequest } = useApproveMembershipMutation();

  return (
    <Box
      alignItems="center"
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      display="flex"
      justifyContent="space-between"
      px={3}
      py={2}
    >
      <Box alignItems="center" display="flex" gap={2}>
        <Box
          alignItems="center"
          bg="forest.background"
          borderRadius="full"
          boxSize={7}
          display="flex"
          justifyContent="center"
        >
          <Box as={UserIcon} boxSize={4} color="forest.500" />
        </Box>

        <Text fontWeight="medium">{name}</Text>
        <Text>{email}</Text>
      </Box>

      <ButtonGroup>
        <PermissionedButton
          permission={{
            v2: { resource: "workspace", grant: "can_update" },
          }}
          onClick={() => {
            rejectRequest({
              workspaceId: String(workspaceId),
              userId: String(userId),
            });
          }}
        >
          Ignore
        </PermissionedButton>

        <PermissionedButton
          permission={{
            v2: { resource: "workspace", grant: "can_update" },
          }}
          variant="primary"
          onClick={async () => {
            try {
              await approveRequest({
                userId: String(userId),
              });

              toast({
                id: "approve-membership",
                title: `Workspace access for ${name} approved`,
                variant: "success",
              });
            } catch (_err: unknown) {
              toast({
                id: "approve-membership",
                title: "Something went wrong",
                message:
                  "Failed to approve workspace access, please try again.",
                variant: "error",
              });
            }
          }}
        >
          Approve access
        </PermissionedButton>
      </ButtonGroup>
    </Box>
  );
};

const PendingInvites = ({ workspaceId, defaultWorkspaceRoleId }) => {
  const { data: pendingInvitesData } = usePendingInvitesQuery(
    {
      workspaceId,
    },
    {
      refetchInterval: 3000,
    },
  );

  const pendingInvites = pendingInvitesData?.outbound_user_invites;

  if (!pendingInvites?.length) {
    return null;
  }

  return (
    <Column>
      <SectionHeading mb={4}>Pending invitations</SectionHeading>
      <Column gap={2}>
        {pendingInvites?.map(({ recipient_email, expires_at, role_id }) => (
          <PendingInvite
            key={recipient_email}
            defaultWorkspaceRoleId={defaultWorkspaceRoleId}
            expiration={expires_at}
            recipient={recipient_email}
            roleID={role_id}
            workspaceId={workspaceId}
          />
        ))}
      </Column>
    </Column>
  );
};

const PendingInvite: FC<{
  recipient: string;
  expiration: string;
  workspaceId: string;
  roleID?: number | null;
  defaultWorkspaceRoleId: number;
}> = ({
  workspaceId,
  recipient,
  expiration,
  roleID,
  defaultWorkspaceRoleId,
}) => {
  const { toast } = useToast();

  const { mutate: cancelInvite } = useCancelInviteMutation();
  const { mutateAsync: sendInvite } = useSendInviteMutation();

  const now = new Date();
  const expiryDate = new Date(expiration);

  const expired = expiryDate < now;
  const daysLeftUntilExpiry = differenceInDays(expiryDate, now);

  return (
    <Box
      alignItems="center"
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      display="flex"
      justifyContent="space-between"
      px={3}
      py={1.5}
    >
      <Box alignItems="center" display="flex" gap={2}>
        <Box
          alignItems="center"
          bg="forest.100"
          borderRadius="full"
          boxSize={7}
          display="flex"
          justifyContent="center"
        >
          <Box as={UserIcon} boxSize={4} color="forest.500" />
        </Box>
        <Text fontWeight="medium">{recipient}</Text>
      </Box>
      <Box alignItems="center" display="flex" gap={3}>
        {expired ? (
          <Text>Expired</Text>
        ) : (
          <Text>
            Expires in{" "}
            {daysLeftUntilExpiry ? `${daysLeftUntilExpiry} days` : "<1 day"}
          </Text>
        )}
        <Menu>
          <MenuActionsButton />
          <MenuList>
            <PermissionedMenuItem
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              icon={MailIcon}
              onClick={async () => {
                try {
                  await sendInvite({
                    recipientEmail: recipient,
                    recipientRoleID: roleID || defaultWorkspaceRoleId,
                  });

                  toast({
                    id: "resend-invite",
                    title: `Invitation for ${recipient} was resent`,
                    variant: "success",
                  });
                } catch (_err: unknown) {
                  toast({
                    id: "resend-invite",
                    title: "Something went wrong",
                    message:
                      "Failed to resend an invitation, please try again.",
                    variant: "error",
                  });
                }
              }}
            >
              Resend invitation
            </PermissionedMenuItem>
            <PermissionedMenuItem
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              icon={DeleteIcon}
              onClick={() => {
                cancelInvite({
                  recipientEmail: recipient,
                  workspaceId,
                });
              }}
            >
              Cancel invitation
            </PermissionedMenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
};
