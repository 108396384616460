import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";
import { Navigate, Outlet, useParams } from "src/router";
import type { ElementOf } from "ts-essentials";

import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { DetailPage } from "src/components/layout";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { SyncName } from "src/pages/syncs/sync/components/sync-name";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import {
  ResourceToPermission,
  SyncAttemptQuery,
  useLatestJourneyVersionFromDestinationInstanceIdQuery,
  useSyncAttemptQuery,
  useSyncQuery,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import {
  getSyncRunOperations,
  syncRunStatusOrUnknownStatus,
} from "src/utils/syncs";
import * as time from "src/utils/time";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import { QueryType } from "src/types/models";
import { SyncRunStatusIndicator } from "src/components/syncs/sync-run-status-indicator";
import { useFolder } from "src/components/folders/use-folder";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { Warning } from "src/components/warning";
import { Sync } from "src/pages/syncs/sync/utils/types";

export type Context = {
  sync: Sync;
  attempt: ElementOf<SyncAttemptQuery["sync_attempts"]> | undefined;
};

export const SyncRunWrapper: FC = () => {
  const { run_id: runId, sync_id: syncId } = useParams<{
    run_id: string;
    sync_id: string;
  }>();

  const {
    data: sync,
    error: syncError,
    isLoading: syncIsLoading,
  } = useSyncQuery(
    {
      id: syncId ?? "",
    },
    {
      enabled: Boolean(syncId),
      select: (data) => data.syncs[0],
    },
  );

  const {
    data: attempt,
    error: syncAttemptError,
    isLoading: syncAttemptLoading,
  } = useSyncAttemptQuery(
    {
      syncRequestId: runId ?? "",
    },
    {
      enabled: Boolean(runId),
      select: (data) => data.sync_attempts[0],
      refetchInterval: (attempt) => {
        if (attempt?.finished_at) {
          // Stop polling if this sync attempt is finished.
          return false;
        }

        return 5000;
      },
    },
  );

  if (!syncId || !runId) {
    return <Navigate to="/syncs" />;
  }

  if (syncError || syncAttemptError) {
    return (
      <Warning title="Something went wrong" subtitle="Please try again later" />
    );
  }

  if (syncIsLoading || syncAttemptLoading) {
    return <PageSpinner />;
  }

  if (!sync) {
    return <Warning title="Sync not found" />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={sync.draft || false}
      resourceId={syncId}
      resourceType={ResourceToPermission.Sync}
    >
      <SyncRunLayout sync={sync} attempt={attempt} />
    </DraftProvider>
  );
};

const SyncRunLayout = ({ sync, attempt }: Context) => {
  const { draft, editingDraft, onViewDraft, setEditingDraft } = useDraft();

  const syncId = sync.id;
  const runId = attempt?.sync_request?.id;
  const isJourneyTriggered =
    sync?.segment?.query_type === QueryType.JourneyNode;
  const model = sync?.segment;
  const source = model?.connection;
  const destination = sync?.destination;

  const journeyQuery = useLatestJourneyVersionFromDestinationInstanceIdQuery(
    { destination_instance_id: syncId ?? "" },
    {
      enabled: Boolean(isJourneyTriggered && syncId),
      select: (data) => data.journeys,
    },
  );
  const journey = journeyQuery.data?.[0];
  const journeyId = journey?.id;

  const syncRequest = attempt?.sync_request;
  const syncError =
    syncRequest?.error ??
    (attempt?.error ? { message: attempt.error } : undefined);
  const resyncReason = syncRequest?.resync_reason;
  const errorCodeDetail = syncRequest?.error_code_detail;

  if (syncError && errorCodeDetail) syncError.errorCodeDetail = errorCodeDetail;

  const {
    successful: { total: successfulRows },
    rejected: { total: rejectedRows },
  } = getSyncRunOperations({
    attempt,
    syncRequest,
    queryRun: syncRequest?.query_run,
  });

  const pendingRows = syncRequest?.query_run?.pending_rows ?? 0;

  const showPendingRows = isSyncMatchBoosted(sync?.config) || pendingRows > 0;

  const folder = useFolder({
    folderId: sync?.segment?.folder?.id || null,
    folderType: sync?.segment?.query_type === "visual" ? "audiences" : "models",
    viewType: "syncs",
  });

  const crumbs: Crumb[] = [{ label: "All syncs", link: "/syncs" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/syncs?folder=" + folder.id,
      });
    });
  }

  crumbs.push({
    label: "Sync",
    link: `/syncs/${syncId}${editingDraft ? "?editing=true" : ""}`,
  });

  crumbs.push({
    label: "Runs",
    link: `/syncs/${syncId}/runs${editingDraft ? "?editing=true" : ""}`,
  });

  const context: Context = { sync, attempt };

  return (
    <DetailPage
      crumbs={crumbs}
      tabs={[
        {
          title: "Successful",
          path: "successful",
          count: successfulRows,
        },
        {
          title: "Pending",
          path: "pending",
          count: pendingRows,
          isHidden: !showPendingRows,
        },
        {
          title: "Rejected",
          path: "rejected",
          count: rejectedRows,
        },
        {
          title: "Configuration",
          path: "configuration",
        },
      ]}
      tabDepth={6}
      hasBottomPadding
      outsideTopbar={
        draft && (
          <EditingDraftWarning
            draft={draft}
            editingDraft={editingDraft}
            resourceType={ResourceToPermission.Sync}
            setEditingDraft={setEditingDraft}
            onViewDraft={onViewDraft}
          />
        )
      }
      title={`Run - ${model?.name ?? "Private model"} to ${
        destination?.name ??
        destination?.definition?.name ??
        "private destination"
      } - Syncs`}
      header={
        <Column gap={4}>
          <SyncName
            // Make sure journey is loaded
            isDisabled={journeyQuery.isLoading}
            destination={destination}
            model={model}
            source={source}
            sync={sync}
            journeyId={journeyId}
          />

          <MetadataBar>
            <Column>
              <MetadataLabel>Run status</MetadataLabel>
              <SyncRunStatusIndicator
                status={syncRunStatusOrUnknownStatus(
                  syncRequest?.status_computed,
                )}
                completionRatio={syncRequest?.completion_ratio || null}
              />
            </Column>
            <Column>
              <MetadataLabel>Started at</MetadataLabel>
              <Text>
                {syncRequest?.created_at &&
                  time.formatDatetime(syncRequest.created_at)}
              </Text>
            </Column>
            {attempt?.finished_at && (
              <Column>
                <MetadataLabel>Duration</MetadataLabel>
                <Text textTransform="capitalize">
                  {time.diff(
                    attempt.sync_request?.created_at || attempt.created_at, // Ideally we want to know the duration from when the request was created, not the attempt
                    attempt.finished_at,
                  )}
                </Text>
              </Column>
            )}
            <Column>
              <MetadataLabel>Run ID</MetadataLabel>
              <Text>{runId}</Text>
            </Column>
            {resyncReason && (
              <Column>
                <MetadataLabel>Resync reason</MetadataLabel>
                <Text>
                  {{
                    "explicit-request": "Triggered by user",
                    "added-fields": "Fields were added",
                    "changed-config": "Configuration was changed",
                    "changed-mappings": "Mappings changed",
                    "changed-source-types": "Source types changed",
                    "changed-primary-key": "Model primary key was changed",
                    "retry-failed-resync": "Retrying failed resync",
                    "archive-mode-resync": "Resyncing for archive mode",
                    "next-diff-mode-override": "Next sync run override",
                  }[resyncReason] ?? resyncReason}
                </Text>
              </Column>
            )}
          </MetadataBar>
        </Column>
      }
    >
      <Outlet context={context} />
    </DetailPage>
  );
};
