import { BoxProps } from "@hightouchio/ui";
import { ColumnOption } from "src/formkit/components/formkit-context";

export interface Option {
  [key: string]: any;
  label: string;
  subLabel?: string;
  value: any;
  sx?: BoxProps["sx"];
}

export const flattenOptions = (
  options: Array<Option | ColumnOption>,
): Option[] =>
  options.flatMap((option) => (option.options ? option.options : [option]));
