import { FullResourceLinkForm } from "src/components/deployments/linking";
import { useParams } from "src/router";

export const Element = () => {
  const { destination_id: id } = useParams<{ destination_id: string }>();

  return (
    <FullResourceLinkForm
      sourceResourceId={String(id)}
      resourceType="destinations"
    />
  );
};
