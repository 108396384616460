import { Box } from "@hightouchio/ui";

const ContainerHeight = 48;
const ContainerWidth = 75;

const LeftCircleDiameter = ContainerHeight;
const RightCircleDiameter = ContainerHeight * 0.75;

export const OverlapIcon = ({ opacity = 0.5 }: { opacity?: number }) => (
  <Box
    position="relative"
    height={`${ContainerHeight}px`}
    width={`${ContainerWidth}px`}
    opacity={opacity}
  >
    <Box
      id="leftCircle"
      className="hightouch-ui-skeleton-box"
      // Skeleton applies relative positioning. This is a workaround to make sure the position
      // is correct when rendered inside a skeleton
      sx={{ position: "absolute !important" }}
      left={0}
      borderRadius="50%"
      bg="teal.400"
      height={`${LeftCircleDiameter}px`}
      width={`${LeftCircleDiameter}px`}
      mixBlendMode="color-burn"
    />
    <Box
      id="rightCircle"
      className="hightouch-ui-skeleton-box"
      // Skeleton applies relative positioning. This is a workaround to make sure the position
      // is correct when rendered inside a skeleton
      sx={{ position: "absolute !important" }}
      right={0}
      top={`${(ContainerHeight - RightCircleDiameter) / 2}px`}
      borderRadius="50%"
      bg="warning.400"
      height={`${RightCircleDiameter}px`}
      width={`${RightCircleDiameter}px`}
      mixBlendMode="color-burn"
    />
  </Box>
);
