import { FC, useMemo } from "react";

import { Row } from "@hightouchio/ui";
import { maxBy } from "lodash";
import pluralize from "pluralize";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { AnalyticsCell } from "src/pages/analytics/analytics-table/analytics-cell";
import { Table } from "src/ui/table";
import { accurateCommaNumber } from "src/utils/numbers";
import { HexColors } from "src/components/audiences/constants";

function transformTableData(
  data: {
    value: string | boolean;
    count: number;
    percentage?: number;
  }[],
): {
  value: string | boolean;
  count: number;
  percentage?: number;
}[] {
  if (data.length === 0) return [];

  if (data.length === 1) {
    const value = data[0]!.value;

    if (typeof value === "boolean") {
      return [
        ...data,
        {
          value: !value,
          count: 0,
          percentage: 0,
        },
      ];
    }
  }

  return data;
}
export const AudiencePropertyBreakdownTable: FC<{
  data: {
    value: string | boolean;
    count: number;
    percentage?: number;
  }[];
}> = ({ data }) => {
  const maxCount = useMemo(
    () => maxBy(data, (row) => row.count)?.count || 1,
    [data],
  );

  return (
    <Row>
      <Table
        showHeaders={false}
        columns={[
          {
            min: "25%",
            max: "25%",
            cell: ({ value }) => (
              <TextWithTooltip message={value.toString()}>
                {String(value)}
              </TextWithTooltip>
            ),
            cellSx: {
              border: "0 !important",
              padding: "0 !important",
              justifyContent: "left",
            },
          },
          {
            cellSx: { border: "0 !important" },
            min: "75%",
            max: "75%",
            cell: ({ count, value, percentage }, index) => {
              const width = 100 * (count / maxCount);
              const color = HexColors[index % HexColors.length]!.base;
              return (
                <AnalyticsCell
                  color={color}
                  metricName={value.toString()}
                  width={width}
                  value={accurateCommaNumber(count)}
                  tooltipValue={`${accurateCommaNumber(count)} ${pluralize("user", count)} or ${((percentage ?? 0) * 100).toFixed(2)}%`}
                />
              );
            },
          },
        ]}
        data={transformTableData(data)}
        rowHeight="30px"
        backgroundColor="unset"
      />
    </Row>
  );
};
