import { FC, useState } from "react";

import {
  Text,
  Tag,
  Column,
  Row,
  Heading,
  Pill,
  SearchInput,
  StarBadgeIcon,
  Tooltip,
  IconButton,
  DeleteIcon,
  useToast,
  SectionHeading,
} from "@hightouchio/ui";
import { Outlet, useNavigate, useOutletContext } from "src/router";

import { useUser } from "src/contexts/user-context";
import {
  OrganizationInvitesQuery,
  OrganizationMembersOrderBy,
  useDeleteOrganizationInviteMutation,
  useOrganizationInvitesQuery,
  useOrganizationMembersQuery,
} from "src/graphql";
import { Table, useTableConfig } from "src/ui/table";

import { OutletContext as OrganizationOutletContext } from "..";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Card } from "src/components/card";
import { captureException } from "@sentry/react";
import { differenceInDays } from "date-fns";
import { PermissionedLinkButton } from "src/components/permission";
import { sortBy } from "lodash";

enum SortKeys {
  Name = "user.name",
  Email = "user.email",
  Role = "role.name",
  CreatedAt = "user.created_at",
}

export const Users: FC = () => {
  const { organization } = useOutletContext<OrganizationOutletContext>();
  const { user: currentUser } = useUser();
  const navigate = useNavigate();

  const { orderBy, onSort } = useTableConfig<OrganizationMembersOrderBy>({
    defaultSortKey: "user.name",
    defaultSortDirection: "asc",
    sortOptions: Object.values(SortKeys),
  });

  const [search, setSearch] = useState<string>("");

  const { data, isLoading } = useOrganizationMembersQuery(
    {
      orderBy,
      search: search ? `%${search}%` : undefined,
      organizationId: organization.id,
    },
    {
      select: (data) => ({
        users: data.organization_members_distinct.map((member) => ({
          ...member,
          // Built in roles first (org admin/viewer), otherwise sort by user group name
          memberships: sortBy(member.memberships, [
            "user_group?.built_in_role_type",
            "user_group?.name",
          ]),
        })),
        count: data.organization_members_distinct_aggregate.aggregate?.count,
      }),
      keepPreviousData: true,
      suspense: true,
    },
  );
  return (
    <Column flex={1} minH={0} overflow="auto" gap={4}>
      <Row align="center" gap={4} justify="space-between">
        <Row gap={4} align="center">
          <Row align="center" gap={2}>
            <Heading>Users</Heading>
            <Pill>{data?.count}</Pill>
          </Row>
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by name or email..."
          />
        </Row>

        <PermissionedLinkButton
          organizationPermission={{
            resource: "organization",
          }}
          tooltip={
            !organization.can_invite_users
              ? "Manual invites are disabled due to your organization's SSO settings. New users must authenticate through SSO."
              : undefined
          }
          isDisabled={!organization.can_invite_users}
          variant="primary"
          href="invite"
        >
          Invite user
        </PermissionedLinkButton>
      </Row>
      <Invites organizationId={organization.id} />
      <Column flex={1} minH={0}>
        <Column flex={1}>
          <Table
            rowHeight="auto"
            columns={[
              {
                name: "Name",
                sortDirection: orderBy?.user?.name,
                cell: ({ user }) => (
                  <Column>
                    <TextWithTooltip fontWeight="medium">
                      <>
                        {user?.name} {currentUser?.id === user?.id && "(you)"}
                      </>
                    </TextWithTooltip>
                    <TextWithTooltip color="text.secondary">
                      {user?.email}
                    </TextWithTooltip>
                  </Column>
                ),
                onClick: () => onSort(SortKeys.Name),
              },
              {
                name: "Auth method",
                sortDirection: orderBy?.user?.name,
                cell: ({ user }) => {
                  let authMethod = "Email (magic link)";

                  if (user?.google_auth_id) {
                    authMethod = "Google";
                  } else if (user?.azure_ad_auth_id) {
                    authMethod = "Microsoft";
                  } else if (user?.auth0_auth_id) {
                    authMethod = "Single sign-on";
                  }

                  return <TextWithTooltip>{authMethod}</TextWithTooltip>;
                },
                breakpoint: "sm",
                max: "0.5fr",
              },
              {
                name: "Groups",
                cell: ({ memberships }) => (
                  <Row
                    gap={2}
                    flexWrap="wrap"
                    py={2}
                    minHeight="80px"
                    alignItems="center"
                  >
                    {memberships.map(({ user_group, is_sso }) => (
                      <Tag key={`${user_group?.id}-${is_sso}`}>
                        <Row gap={1}>
                          {user_group?.is_organization_admin && (
                            <Tooltip message="Organization admin">
                              <StarBadgeIcon />
                            </Tooltip>
                          )}
                          <TextWithTooltip>{user_group?.name}</TextWithTooltip>
                          {is_sso && <Text>(via SSO)</Text>}
                        </Row>
                      </Tag>
                    ))}
                  </Row>
                ),
              },
            ]}
            data={data?.users || []}
            loading={isLoading}
            placeholder={{
              title: "No users",
              error: "Users failed to load, please try again.",
              body: search
                ? "No users match your search."
                : "No users in this organization",
            }}
            key="user_id"
            onRowClick={(row) => {
              navigate(`${row.user_id}`);
            }}
          />
        </Column>
      </Column>

      <Outlet context={{ organization }} />
    </Column>
  );
};

const Invites = ({ organizationId }) => {
  const { data } = useOrganizationInvitesQuery(
    { organizationId },
    { suspense: true, select: (data) => data.organization_invites },
  );

  if (!data?.length) {
    return null;
  }

  return (
    <Column width="50%" minW="300px" gap={3}>
      <SectionHeading>Pending invites</SectionHeading>
      {data?.map((invite) => <Invite key={invite.id} invite={invite} />)}
    </Column>
  );
};

const Invite: FC<
  Readonly<{ invite: OrganizationInvitesQuery["organization_invites"][0] }>
> = ({ invite }) => {
  const { toast } = useToast();

  const deleteMutation = useDeleteOrganizationInviteMutation();

  const onDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync({ id });

      toast({
        id: "delete-invite",
        title: "Invite deleted",
        variant: "success",
      });
    } catch (e) {
      captureException(e);
      toast({
        id: "delete-invite",
        title: "Failed to delete invite",
        message: e.message,
        variant: "error",
      });
    }
  };

  const now = new Date();
  const expiryDate = new Date(invite.expires_at);

  const expired = expiryDate < now;
  const daysLeftUntilExpiry = differenceInDays(expiryDate, now);

  return (
    <Card key={invite.id} p={2}>
      <Row gap={4} justify="space-between" align="center">
        <TextWithTooltip fontWeight="medium">
          {invite.recipient_email}
        </TextWithTooltip>
        <Row gap={4} align="center">
          <Text color="text.secondary">
            {expired
              ? "Expired"
              : daysLeftUntilExpiry === 0
                ? "Expires today"
                : `Expires in ${daysLeftUntilExpiry} days`}
          </Text>
          <IconButton
            isLoading={deleteMutation.isLoading}
            aria-label="Delete invite"
            icon={DeleteIcon}
            onClick={() => {
              onDelete(invite.id);
            }}
          />
        </Row>
      </Row>
    </Card>
  );
};
