import { useEffect, useMemo, useState } from "react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  ButtonGroup,
  ButtonProps,
  Column,
  Dialog,
  FormField,
  Heading,
  Paragraph,
  RefreshIcon,
  Row,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FormProvider } from "react-hook-form";
import { Link, useNavigate } from "src/router";

import { AccordionSection } from "src/components/accordion-section";
import { useHightouchForm } from "src/components/form";
import { GrantSelect } from "src/components/grant-select";
import { Grants } from "src/components/grant-select/types";
import { convertGrants } from "src/components/grant-select/util";
import { getTagsFromLabels, LabelForm } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import { SourceForm } from "src/components/sources/setup";
import {
  DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  WarehouseSchemaConfig,
} from "src/components/sources/setup/constants";
import { SourceCatalog } from "src/components/sources/source-catalog";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { WizardStep, DeprecatedWizard } from "src/components/wizard";
import { useUser } from "src/contexts/user-context";
import {
  ListSourceTestStepsQueryVariables,
  SourceDefinition,
  useCreateSourceV2Mutation,
  useFormkitSourceDefinitionQuery,
  useSampleDataSourceDefinitionsQuery,
  useSourceDefinitionsQuery,
  useSourceQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import * as storage from "src/utils/storage";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { DocsLink } from "src/components/docs-link";
import { IntegrationIcon } from "src/components/integrations/integration-icon";

type CreateSourceProps = {
  initialSourceDefinition?: SourceDefinition;
  onSubmit: ({
    id,
    definition,
  }: {
    id: string;
    definition: SourceDefinition;
  }) => void;
  onCancel: () => void;
  onConnectClick?: (defintion: SourceDefinition) => void;
};

export const CreateSourceWizard = ({
  initialSourceDefinition,
  onSubmit,
  onConnectClick,
  onCancel,
}: Readonly<CreateSourceProps>) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(SlugResourceType.Connections);
  const [definition, setDefinition] = useState<SourceDefinition>();
  const [config, setConfig] = useState<Record<string, unknown>>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();
  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>(
    false,
  );
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [schema, setSchema] = useState<WarehouseSchemaConfig>(
    DEFAULT_LIGHTNING_SCHEMA_CONFIG,
  );
  const [isContinueModalOpen, setIsContinueModalOpen] =
    useState<boolean>(false);
  const [lightningSchemaMode, setLightningSchemaMode] = useState<
    "shared" | "separate"
  >("separate");

  const { appBypassSourceTests } = useFlags();

  const {
    results: testResults,
    steps: testSteps,
    getTestSteps,
    runTest,
    timeElapsed,
  } = useSourceTesting();

  const {
    data: { id }, //IDs would exist for sources that are authenticated by oauth, yet can still be incomplete.
  } = useQueryString();

  const [step, setStep] = useWizardStepper(0);

  useSourceQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.connections_by_pk,
      onSuccess: (data) => {
        if (data) {
          setConfig(data.config);
          setDefinition(data.definition);
        }
      },
    },
  );
  const { mutateAsync: createSource, isLoading: creating } =
    useCreateSourceV2Mutation();
  const { mutateAsync: updateSource, isLoading: updating } =
    useUpdateSourceV2Mutation();
  const { data: definitions } = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
  });
  const { data: sampleDefinitions } = useSampleDataSourceDefinitionsQuery(
    undefined,
    {
      select: (data) => data.getSampleDataSourceDefinitions,
    },
  );
  const { data: formkitMethods, isLoading: loadingFormkit } =
    useFormkitSourceDefinitionQuery(
      { type: definition?.type || "" },
      {
        enabled: Boolean(definition),
        select: (data) => data.formkitSourceDefinition,
      },
    );

  const isOAuth = useMemo(() => {
    if (Array.isArray(formkitMethods)) {
      if (formkitMethods.length === 1) {
        return formkitMethods[0]?.method === "oauth";
      }
      return (
        formkitMethods.find((s) => config?.methodKey === s.key)?.method ===
        "oauth"
      );
    }
    return false;
  }, [formkitMethods, config?.methodKey]);

  useEffect(() => {
    if (definition) {
      setConfig({});
      setName(definition.name);
    }
  }, [definition]);

  useEffect(() => {
    if (id) {
      if (storage.load("onboarding")) {
        storage.remove("onboarding");
        navigate(`/onboarding/source/new?id=${id}`, { replace: true });
      }
      if (initialSourceDefinition) {
        setStep(0);
      } else {
        setStep(1);
      }
    }
  }, [id]);

  useEffect(() => {
    setDefinition(initialSourceDefinition);
  }, [initialSourceDefinition]);

  const form = useHightouchForm({
    onSubmit: async (data) => {
      let newId;

      const slug = await getSlug(name);

      if (id) {
        await updateSource({
          id: String(id),
          source: {
            slug,
            config,
            name,
            tags: getTagsFromLabels(data.labels),
            setup_complete: true,
          },
          grants: convertGrants(data.grants),
        });
      } else {
        const { createSourceWithSecrets } = await createSource({
          source: {
            slug,
            name,
            config,
            tags: getTagsFromLabels(data.labels),
            type: definition?.isSampleDataSource
              ? "sample-data"
              : definition?.type,
            setup_complete: true,
            created_by: user?.id != null ? String(user?.id) : undefined,
            tunnel_id: tunnelId,
            credential_id: credentialId ? String(credentialId) : undefined,
            plan_in_warehouse: lightningEnabled,
            sample_data_source_id: definition?.isSampleDataSource
              ? definition.type
              : null,
            plan_in_warehouse_config: lightningEnabled
              ? { plannerDatabase, schema }
              : null,
          },
          grants: convertGrants(data.grants),
        });
        newId = createSourceWithSecrets?.id.toString();
      }

      const sourceId = String(newId ?? id);

      analytics.track("Source Created", {
        source_name: name,
        source_type: definition?.type ?? "",
      });

      onSubmit({ id: sourceId, definition: definition! });
    },
    success: `Source "${name}" was created`,
    defaultValues: {
      labels: [{ key: "", value: "" }],
      grants: {} as Grants<"source">,
    },
  });

  const variables: ListSourceTestStepsQueryVariables = {
    sourceId: id,
    sourceType: definition?.type,
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled
      ? { plannerDatabase, schema }
      : undefined,
  };

  const steps: WizardStep[] = [];

  if (!initialSourceDefinition) {
    steps.push({
      title: "Select source",
      continue: "Click on a source to continue",
      header: (
        <Row alignItems="center" gap={4}>
          <Heading>Select a data source</Heading>
        </Row>
      ),
      render: () => (
        <Column gap={8}>
          {sampleDefinitions && definitions && (
            <SourceCatalog
              sampleDefinitions={sampleDefinitions}
              selection={definition}
              definitions={definitions}
              onSelect={(source) => {
                setDefinition(source);
                setStep((step) => step + 1);
              }}
            />
          )}
        </Column>
      ),
    });
  }

  const LIGHTNING_ONLY_TEST_IDS = [
    "validatePlannerSchemaPermissions",
    "validateAuditSchemaPermissions",
  ];
  const allNonLightningTestsPassed = testResults?.stepResults
    ?.filter((r) => !LIGHTNING_ONLY_TEST_IDS.includes(r.id))
    .every((r) => r.success);
  const canProgressWithFailedTest =
    testResults?.success === false &&
    allNonLightningTestsPassed &&
    definition?.supportsInWarehouseDiffing;

  if (!definition?.isSampleDataSource) {
    const continueProps: Pick<ButtonProps, "form" & "type"> = {
      form: "source-form",
      type: "submit",
    };
    steps.push(
      ...[
        {
          title: `Connect source`,
          loading: loadingFormkit,
          continue:
            isOAuth && !id ? "Authorize connection to continue" : undefined,
          continueProps,
          disabled: tunnelId === null,
          onContinue: () => {},
          header: (
            <Row align="center" justify="space-between" w="100%">
              <Row align="center" gap={4}>
                <IntegrationIcon
                  src={definition?.icon}
                  name={definition?.name}
                />
                <Heading>
                  {isOAuth
                    ? `Authorize connection to ${definition?.name}`
                    : `Connect to ${definition?.name}`}
                </Heading>
              </Row>
              <DocsLink
                href={definition?.docs ?? ""}
                name={definition?.name ?? ""}
              />
            </Row>
          ),
          render: () => {
            if (!definition) {
              return null;
            }

            return (
              <SourceForm
                config={config}
                credentialId={credentialId}
                definition={definition}
                disableAuthMethod={Boolean(id)}
                hasSetupLightning={false}
                isSetup={true}
                lightningEnabled={lightningEnabled}
                plannerDatabase={plannerDatabase}
                setConfig={setConfig}
                setCredentialId={setCredentialId}
                setLightningEnabled={setLightningEnabled}
                setPlannerDatabase={setPlannerDatabase}
                setTunnelId={setTunnelId}
                sourceId={id}
                tunnelId={tunnelId}
                schema={schema}
                setSchema={setSchema}
                lightningSchemaMode={lightningSchemaMode}
                setLightningSchemaMode={setLightningSchemaMode}
                onConnectClick={onConnectClick}
                onSubmit={async () => {
                  await getTestSteps(variables);
                  runTest(variables);
                  setStep(step + 1);
                }}
              />
            );
          },
        },
        {
          title: `Test source`,
          disabled:
            !testResults?.success &&
            !canProgressWithFailedTest &&
            !appBypassSourceTests,
          continueTooltip:
            !testResults?.success &&
            !canProgressWithFailedTest &&
            !appBypassSourceTests
              ? "Cannot continue until tests pass"
              : undefined,
          continue:
            !testResults?.success && canProgressWithFailedTest
              ? "Continue without Lightning sync engine"
              : undefined,
          onContinue:
            !testResults?.success && canProgressWithFailedTest
              ? () => {
                  setIsContinueModalOpen(true);
                }
              : undefined,
          actions:
            testResults?.success === false ? (
              <Button
                icon={RefreshIcon}
                ml={4}
                size="lg"
                onClick={() => runTest(variables)}
              >
                Test again
              </Button>
            ) : undefined,

          header: (
            <Row align="center" justify="space-between" w="100%">
              <Row align="center" gap={4}>
                <IntegrationIcon
                  src={definition?.icon}
                  name={definition?.name}
                />
                <Heading>Test connection to {definition?.name}</Heading>
              </Row>
              <DocsLink
                href={definition?.docs ?? ""}
                name={definition?.name ?? ""}
              />
            </Row>
          ),
          render: () => {
            if (!definition) {
              return null;
            }

            return (
              <>
                <Testing
                  config={config}
                  credentialId={credentialId}
                  isSetup={true}
                  plannerDatabase={plannerDatabase}
                  results={testResults}
                  sourceDefinition={definition}
                  steps={testSteps}
                  timeElapsed={timeElapsed}
                  onContinue={() => {
                    setStep((step) => step + 1);
                  }}
                />
                <Dialog
                  isOpen={isContinueModalOpen}
                  variant="form"
                  width="xl"
                  title="Lightning sync engine will be disabled"
                  actions={
                    <ButtonGroup>
                      <Button
                        icon={ArrowLeftIcon}
                        onClick={() => {
                          setIsContinueModalOpen(false);
                        }}
                      >
                        Go back
                      </Button>
                      <Button
                        variant="primary"
                        directionIcon={ArrowRightIcon}
                        onClick={() => {
                          setPlannerDatabase(undefined);
                          setLightningEnabled(false);
                          setStep((step) => step + 1);
                          setIsContinueModalOpen(false);
                        }}
                      >
                        Continue with read-only Basic sync engine
                      </Button>
                    </ButtonGroup>
                  }
                  onClose={() => {
                    setIsContinueModalOpen(false);
                  }}
                >
                  <Paragraph>
                    Hightouch was unable to verify the read and write
                    permissions required by the Lightning sync engine. Please
                    address the failed connection tests or continue with the
                    read-only Basic sync engine.
                  </Paragraph>
                </Dialog>
              </>
            );
          },
        },
      ],
    );
  }

  steps.push({
    title: "Finalize source",
    submitting: creating || updating,
    disabled: !name,
    header: <Heading>Finalize settings for this source</Heading>,
    render: () => (
      <Column gap={8}>
        <FormField
          description="Including details about the source's environment (prod/dev), data contents, and owners"
          label="Source name"
        >
          <TextInput
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </FormField>
        <FormProvider {...form}>
          {user?.permissions_v2_enabled && (
            <FormField
              label="Permissions"
              description={
                <Text>
                  Use the checkboxes below to configure which actions each user
                  group can perform for this source and its related resources.{" "}
                  <Link
                    href={`${import.meta.env.VITE_DOCS_URL}/workspace-management/roles#source`}
                    isExternal
                  >
                    See documentation
                  </Link>{" "}
                  for details.
                </Text>
              }
            >
              <GrantSelect type="source" />
            </FormField>
          )}
          <AccordionSection label="Advanced configuration (optional)">
            <LabelForm
              heading="Add labels"
              hint="Example keys: team, project, region, env."
              resourceType={ResourceType.Source}
            />
          </AccordionSection>
        </FormProvider>
      </Column>
    ),
  });

  return (
    <DeprecatedWizard
      steps={steps}
      step={step}
      setStep={setStep}
      title="Create source"
      onCancel={onCancel}
      onSubmit={form.submit}
    />
  );
};
