import { groupBy } from "lodash";
import { ReactNode } from "react";

import { Column, GroupedCombobox } from "@hightouchio/ui";

import { RequiredParentModelFieldsForQueryBuilder } from "src/components/audiences/types";
import { useQueryBuilderContext } from "src/components/explore/context/hooks";
import {
  ColumnValue,
  FilterableColumn,
  PropertyCondition,
  ValueType,
  isColumnReference,
} from "src/types/visual";

import { ErrorMessage } from "./error-message";
import { getModelIdFromColumn, getPropertyNameFromProperty } from "./utils";

export const PropertySelector = ({
  error,
  condition,
  value,
  onChange,
  columns: columnsOverride,
  parent,
  removePortal,
}: {
  error?: ReactNode;
  condition: PropertyCondition;
  value?: ColumnValue | undefined;
  onChange: (value: ColumnValue | undefined) => void;
  columns: FilterableColumn[] | undefined;
  parent: RequiredParentModelFieldsForQueryBuilder | undefined;
  removePortal?: boolean;
}) => {
  const { columns: allColumns } = useQueryBuilderContext();
  const columns = columnsOverride ?? allColumns;

  const { property, propertyType } = condition;
  const filterableColumns = (columns ?? []).filter((c) => {
    const modelId =
      isColumnReference(property) && getModelIdFromColumn(property);

    return (
      c.type === propertyType &&
      !(
        // Filter out the left operand's column (want to be sure it's from the
        // same model since we also show columns from other merge models)
        (
          c.model_id == modelId &&
          c.name === getPropertyNameFromProperty(property)
        )
      )
    );
  });

  const columnsGroupedByModel = groupBy(
    filterableColumns,
    ({ model_name }) => model_name,
  );
  const modelNames = Object.keys(columnsGroupedByModel);

  const options = modelNames.map((model) => ({
    label: model === parent?.name ? "Properties" : model,
    options: columnsGroupedByModel[model]?.map(transformColumnToOption) ?? [],
  }));

  return (
    <Column sx={{ width: "auto", flex: "0 0 auto" }}>
      <GroupedCombobox
        removePortal={removePortal}
        optionGroups={options}
        placeholder="Select property..."
        width="2xs"
        value={value}
        onChange={onChange}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Column>
  );
};

const transformColumnToOption = (c: FilterableColumn) => ({
  label: c.name,
  value: {
    type: ValueType.Column,
    property: c.column_reference,
    propertyType: c.type,
  } as ColumnValue,
});
