import { FC, useState } from "react";
import merge from "lodash/merge";

import {
  Column,
  FormField,
  Heading,
  Textarea,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";
import { useNavigate } from "src/router";

import { ParentModelSelect } from "src/components/models/parent-model-select";
import {
  useAudiencesForGoalsQuery,
  useCreateGoalMutation,
  useParentModelForMetricsViewsQuery,
} from "src/graphql";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import { useSchemaObjectCreationPermissions } from "src/components/permission/creation/schema";
import { PageSpinner } from "src/components/loading";

import { MetricForm } from "./metric-form";
import { MetricFormData, useMetricForm } from "./use-metric-form";
import { getAggregationConfiguration } from "./utils";
import { FormProvider } from "react-hook-form";

export const CreateMetric: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [parentModelId, setParentModelId] = useState<string | undefined>();
  const [metricName, setMetricName] = useState("");
  const [metricDescription, setMetricDescription] = useState("");

  const parentModelForQueryBuilderQuery = useParentModelForMetricsViewsQuery(
    {
      parentModelId: parentModelId ?? "",
    },
    {
      enabled: Boolean(parentModelId),
      select: (data) => {
        // enabled_sampeld_segments isn't relevant here, so we can hardcode an empty array.
        // It's needed to satisfy the QueryBuilder type. This should be fixed.
        return merge({}, { enabled_sampled_segments: [] }, data.segments_by_pk);
      },
    },
  );

  const parentModelDataLoading = parentModelForQueryBuilderQuery.isLoading;
  const parentModel = parentModelForQueryBuilderQuery.data;

  const { data: audiences } = useAudiencesForGoalsQuery(
    { parentModelId: parentModel?.id.toString() || "" },
    { enabled: Boolean(parentModel?.id), select: (data) => data.segments },
  );

  const { mutateAsync: createMetric } = useCreateGoalMutation();
  const form = useMetricForm();
  const { handleSubmit, ...metricFormProps } = form;

  const submit = async (metricData: MetricFormData) => {
    if (parentModel?.id === undefined) {
      Sentry.captureException(
        new Error("[Metrics form]: Parent model not selected"),
      );

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "No parent model selected.",
        variant: "error",
      });

      return;
    }

    if (!metricData) {
      Sentry.captureException(new Error("[Metrics form]: No metric data"));

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "Metric configured incorrectly.",
        variant: "error",
      });

      return;
    }

    try {
      const {
        aggregationType,
        config,
        attributionWindow,
        attributionMethods,
        column,
      } = metricData || {};

      const aggregationConfig = getAggregationConfiguration(aggregationType);

      const result = await createMetric({
        input: {
          name: metricName,
          description: metricDescription,
          parent_model_id: parentModel.id,
          aggregation: aggregationConfig?.aggregation,
          audience_aggregation: aggregationConfig?.audienceAggregation,
          config: { ...config, column },
          attribution_window: attributionWindow,
          enabled: true,
          audience_goals: {
            data:
              audiences?.map((audience) => ({
                segment_id: audience.id,
                enabled: true,
              })) ?? [],
          },
          ...(attributionMethods.length >= 1 && {
            goals_attribution_methods: {
              data: attributionMethods.map((attributionMethod) => ({
                attribution_method_id: attributionMethod.id,
              })),
            },
          }),
        },
      });

      toast({
        id: "metrics-form-toast",
        title: "Metric created!",
        variant: "success",
      });

      navigate(`/metrics/${result.insert_goals_one?.id}`);
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "Error creating metric.",
        variant: "error",
      });
    }
  };

  const { parentModelFilter } = useSchemaObjectCreationPermissions();

  const steps: WizardStep[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: <Heading>Select a parent model</Heading>,
      render: () => (
        <ParentModelSelect
          isOptionAvailable={parentModelFilter}
          onSelect={(model) => {
            setParentModelId(model.id);
            setStep(1);
          }}
        />
      ),
    },
    {
      title: "Configure metric",
      header: parentModelDataLoading ? null : (
        <Heading>Define how this metric will be calculated</Heading>
      ),
      render: () =>
        parentModelDataLoading ? (
          <PageSpinner />
        ) : (
          <FormProvider {...form}>
            <MetricForm
              isCreate
              metricFormProps={metricFormProps}
              parentModel={parentModel}
            />
          </FormProvider>
        ),
      onContinue: handleSubmit(
        () => {
          setStep(2);
        },
        () => {
          toast({
            id: "metrics-form-toast",
            title: "Metric creation error",
            message: "Metric configured incorrectly.",
            variant: "error",
          });
        },
      ),
    },
    {
      title: "Finalize metric",
      disabled: metricName.length === 0,
      header: <Heading>Finalize settings for this metric</Heading>,
      render: () => (
        <Column>
          <FormField label="Metric name">
            <TextInput
              value={metricName}
              onChange={(event) => setMetricName(event.target.value)}
            />
          </FormField>
          <FormField label="Metric description (optional)" mt={4}>
            <Textarea
              placeholder="Descriptions help collaborators understand this metric..."
              value={metricDescription}
              onChange={(event) => setMetricDescription(event.target.value)}
            />
          </FormField>
        </Column>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>New metric</title>
      </Helmet>
      <DeprecatedWizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New metric"
        onCancel={() => navigate("/metrics")}
        onSubmit={handleSubmit(submit)}
      />
    </>
  );
};
