import { ColumnOption } from "./formkit-context";
import { BoxProps } from "@hightouchio/ui";

export type TemplateInfo = {
  name: string;
  description: string;
  placeholders?: string[];
  inputValue?: string;
};

export type FormProps = {
  value: any;
  templates: TemplateInfo[];
  onChange: (value: any) => void;
  onReloadEligibleInlineMapperColumns: (currentSelectedColumn?: string) => void;
  useHightouchUi?: boolean;
} & OverwriteStandardInputWithArrayProps;

export type OverwriteStandardInputWithArrayProps =
  | {
      overwriteColumnsWithArrayProps: true;
      columnOptions: ColumnOption[] | undefined;
    }
  | {
      overwriteColumnsWithArrayProps?: false;
      columnOptions?: ColumnOption[] | undefined;
    };

export type JsonColumnProps = {
  selectedColumnProps: Set<string> | string[] | undefined;
  allColumnsProps: { [column: string]: Set<string> | string[] } | undefined;
};

export interface CustomColumnOption {
  label: ColumnOption["label"];
  type?: ColumnOption["type"];
  value: ColumnOption["value"];
  options?: ColumnOption["options"];
}

export type Props = {
  useHightouchUi?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  placeholder?: string;
} & FormProps;

export type Mapping =
  | StandardMapping
  | StaticMapping
  | VariableMapping
  | TemplateMapping
  | ObjectMapping
  | ArrayMapping;

export interface ArrayMapping {
  type: MappingType.ARRAY;
  from?: string;
  to?: string;
  children: Mapping[];
}

export interface ObjectMapping {
  type: MappingType.OBJECT;
  from?: Mapping[];
  to?: string;
}

export interface StandardMapping {
  type: MappingType.STANDARD;
  from?: string;
  to?: string;
}

export interface StaticMapping {
  type: MappingType.STATIC;
  to: string;
  value: unknown;
  valueType: string;
}

export interface VariableMapping {
  type: MappingType.VARIABLE;
  to: string;
  variable: string;
}

export interface TemplateMapping {
  type: MappingType.TEMPLATE;
  to: string;
  template: string;
}

export enum MappingType {
  STANDARD = "standard",
  FIELD = "field", // Frontend only
  STATIC = "static",
  VARIABLE = "variable",
  TEMPLATE = "template",
  OBJECT = "object",
  ARRAY = "array",
  ARRAY_FIELD = "array_field", // Frontend only
  BOOSTED = "boosted", // THIS IS A FAKE TYPE. only used on the frontend.
}

const MappingTypeWithAssociation = {
  ...MappingType,
  REFERENCE: "reference",
};

export enum StaticType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  NULL = "Null",
}

export const STATIC_OPTIONS = [
  {
    label: "String",
    type: StaticType.STRING,
  },
  {
    label: "Number",
    type: StaticType.NUMBER,
  },
  {
    defaultValue: true,
    label: "Boolean",
    type: StaticType.BOOLEAN,
  },
  // TODO: Implement new design for timestamp input
  // {
  //   label: "Timestamp",
  //   type: StaticType.TIMESTAMP,
  // },
  {
    label: "Null",
    type: StaticType.NULL,
  },
];

export type Option = {
  label: string;
  value: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
};

export type SelectedOptionContext = Option & {
  object?: Record<string, unknown>;
  properties?: Option[];
};

export function isMapping(m: unknown): m is Mapping {
  return (
    typeof m === "object" &&
    m !== null &&
    m !== undefined &&
    Object.keys(MappingTypeWithAssociation).includes(m["type"]?.toUpperCase())
  );
}

export function isArrayMapping(m: unknown): m is ArrayMapping {
  return (
    isMapping(m) && m.type === MappingType.ARRAY && m.children !== undefined
  );
}

export type InlineMapperProps = {
  isDisabled?: boolean;
  type: "array" | "object";
  currentDepth: number;
  required?: boolean;
  errorPrefix: string;
  parentMapping?: Mapping;
  jsonColumnProperties: JsonColumnProps;
  enabledNestedInlineMapper: boolean;
  onChangeJsonColumnProperties: React.Dispatch<
    React.SetStateAction<JsonColumnProps>
  >;
  onReloadEligibleInlineMapperColumns: (currentSelectedColumn?: string) => void;
  retrieveErrorMessage: (
    errors: Record<string, unknown>,
    mappingCount: number,
    name: string,
  ) => string;
  onChange: (value: Mapping[]) => void;
  value: ArrayMapping | ObjectMapping;
  outerContainerSx?: BoxProps["sx"];
  selectedOptionContext: SelectedOptionContext[] | undefined;
  overwriteColumnsWithArrayProps?: boolean;
  useHightouchUi?: boolean;
  templates?: { name: string; description: string; placeholders?: string[] }[];
};
