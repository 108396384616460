import { cloneDeep } from "lodash";
import {
  type BooleanOperand,
  BooleanType,
  type FormkitBoolean,
  type FormkitNode,
  type GraphQLVariables,
  ModifierType,
  NodeType,
  ReferenceType,
  isFormkitReference,
  isGraphQLReference,
  isHandlerGraphQLVariables,
  isStateReference,
} from "../../api";

export type RelationshipNode = {
  /**
   *  Readable name of the node
   */
  heading?: string;
  /**
   *  Children keys
   */
  children: Set<string>;
  /**
   *  Ancestor keys
   */
  ancestors: Set<string>;
};

export type RelationshipHierarchy = {
  [key: string]: RelationshipNode;
};

function getStateReferenceVariablesFromOperandVariables(
  variables: GraphQLVariables,
): string[] {
  if (isHandlerGraphQLVariables(variables)) {
    return variables.input.variables
      ? getStateReferenceVariablesFromOperandVariables(
          variables.input.variables,
        )
      : [];
  }

  const entries = Object.entries(variables);
  const result: string[] = [];

  for (const [key, value] of entries) {
    if (isStateReference(value)) {
      result.push(key);
    }
  }

  return result;
}

function getRelatedKeysFromFormkitReference(
  operand: FormkitBoolean | BooleanOperand,
): string[] {
  if (
    typeof operand === "string" ||
    typeof operand === "number" ||
    typeof operand === "boolean"
  ) {
    // These operand types have no parent key
    return [];
  }

  if (operand.type === BooleanType.Unary) {
    return getRelatedKeysFromFormkitReference(operand.operand);
  }

  if (operand.type === BooleanType.Binary) {
    return [
      ...getRelatedKeysFromFormkitReference(operand.leftOperand),
      ...getRelatedKeysFromFormkitReference(operand.rightOperand),
    ];
  }

  if (operand.type === ReferenceType.State) {
    return [operand.key];
  }

  if (operand.type === ReferenceType.GraphQL && operand.variables) {
    return getStateReferenceVariablesFromOperandVariables(operand.variables);
  }

  return [];
}

function initializeKeyInRelationshipHierarchy(
  hierarchy: RelationshipHierarchy,
  key: string,
  heading?: string,
) {
  if (!hierarchy[key]) {
    hierarchy[key] = {
      heading,
      children: new Set(),
      ancestors: new Set(),
    };
  } else {
    hierarchy[key].heading = heading ?? hierarchy[key].heading;
  }
}

/**
 * Some keys do not exist in the formkit definition, but are necessary in the hierarchy.
 * This function will add the key to the hierarchy.
 *
 * Ex: `autoSyncColumns` is not in the google sheets formkit definition, but if it's true then `mappings` will be ignored
 * `autoSyncColumns` is set in the mappings component
 */
export function addMissingRelationshipToHierarchy({
  hierarchy,
  parent,
  keys,
}: {
  hierarchy: RelationshipHierarchy;
  parent: string;
  keys: string[];
}): RelationshipHierarchy {
  const clonedHierarchy: RelationshipHierarchy = cloneDeep(hierarchy);
  keys.forEach((key) => {
    initializeKeyInRelationshipHierarchy(clonedHierarchy, key);
    clonedHierarchy[key]!.ancestors.add(parent);

    // Add key to parent's children
    clonedHierarchy[parent]!.children.add(key);
  });

  // Find all references to the parent key in the hierarchy as a child
  // Add add this key to those as well
  Object.entries(clonedHierarchy).forEach(([property, node]) => {
    if (node.children.has(parent)) {
      keys.forEach((key) => {
        // Add the new property as a value in the parent field's `children` set
        node.children.add(key);
        // Add the parent key as an ancestor to the new property
        clonedHierarchy[key]!.ancestors.add(property);
      });
    }
  });

  return clonedHierarchy;
}

/**
 * Recursively build a relationship hierarchy with both children and ancestors from the FormkitNode structure.
 *
 * @param node - The recursive form structure.
 * @param hierarchy - The relationship hierarchy being constructed.
 * @param ancestorKeys - The ancestor node key (if any).
 */
export const buildRelationshipHierarchy = ({
  node,
  hierarchy = {},
  ancestorKeys = [],
  heading,
}: {
  node: FormkitNode;
  hierarchy?: RelationshipHierarchy;
  ancestorKeys?: string[];
  heading?: string;
}): RelationshipHierarchy => {
  if (node.type === NodeType.Component) {
    const currentKey = node.key;

    initializeKeyInRelationshipHierarchy(hierarchy, currentKey, heading);

    ancestorKeys.forEach((parentKey) => {
      initializeKeyInRelationshipHierarchy(hierarchy, parentKey);
      hierarchy[currentKey]!.ancestors.add(parentKey);
      hierarchy[parentKey]!.children.add(currentKey);
    });

    if (node.props) {
      Object.values(node.props).forEach((prop) => {
        if (isFormkitReference(prop)) {
          const reference = prop;
          if (isStateReference(reference)) {
            initializeKeyInRelationshipHierarchy(hierarchy, reference.key);
            hierarchy[currentKey]!.ancestors.add(reference.key);
            hierarchy[reference.key]!.children.add(currentKey);
          } else if (isGraphQLReference(reference) && reference.variables) {
            getRelatedKeysFromFormkitReference(reference).forEach(
              (parentKey) => {
                initializeKeyInRelationshipHierarchy(hierarchy, parentKey);
                hierarchy[currentKey]!.ancestors.add(parentKey);
                hierarchy[parentKey]!.children.add(currentKey);
              },
            );
          }
        }
      });
    }

    if (node.children) {
      node.children.forEach((child) =>
        buildRelationshipHierarchy({
          node: child,
          hierarchy,
          ancestorKeys,
        }),
      );
    }
  } else if (node.type === NodeType.Modifier) {
    let parentKeysFromModifier = ancestorKeys;

    if (node.modifier === ModifierType.Show) {
      parentKeysFromModifier = getRelatedKeysFromFormkitReference(
        node.condition,
      );
    } else if (
      node.modifier === ModifierType.AsyncReference &&
      node.handler.variables
    ) {
      parentKeysFromModifier = Object.keys(node.handler.variables);
    }

    node.children.forEach((child) =>
      buildRelationshipHierarchy({
        node: child,
        hierarchy,
        ancestorKeys: parentKeysFromModifier,
      }),
    );
  } else if (node.type === NodeType.Layout && node.children) {
    node.children.forEach((child) =>
      buildRelationshipHierarchy({
        node: child,
        hierarchy,
        ancestorKeys,
        heading: "heading" in node ? node.heading : heading,
      }),
    );
  }

  return hierarchy;
};
