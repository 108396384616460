import { useRef, useState } from "react";

import { Button, ButtonGroup, Dialog, useToast } from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";
import omit from "lodash/omit";
import merge from "lodash/merge";
import pick from "lodash/pick";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Editor } from "src/components/editor";

type JsonEditorProps = {
  onClose: () => void;
  keysToOmit?: string[];
  formkit: boolean;
};

export const JsonEditor = ({
  onClose,
  formkit,
  keysToOmit = [],
}: JsonEditorProps) => {
  const { toast } = useToast();
  const { getValues, reset } = useFormContext();
  const { setConfig: _setConfig, config: _config } = useDestinationForm();

  /**
   * Get the config from the form context or the destination form context, depending on the formkit flag
   */
  function getConfigFromContext() {
    return formkit ? getValues() : _config;
  }

  function updateConfig(config: Record<string, any>) {
    return formkit ? reset(config) : _setConfig(config);
  }

  const [config, setConfig] = useState<string>(
    JSON.stringify(omit(getConfigFromContext(), keysToOmit), null, 4),
  );

  // This data does not change, so use a ref to store it until submission
  const omittedDataRef = useRef<Record<string, any>>(
    pick(getConfigFromContext(), keysToOmit),
  );

  const beautifyJSON = () => {
    try {
      const obj = JSON.parse(config);
      setConfig(JSON.stringify(obj, null, 4));
    } catch (err) {
      toast({
        id: "beautify-json",
        title: "This configuration is not valid JSON",
        message: String(err),
        variant: "warning",
      });
    }
  };

  const submit = () => {
    try {
      const obj: Record<string, any> = JSON.parse(config);
      const newConfig = merge(obj, omittedDataRef.current);

      updateConfig(newConfig);

      onClose();
    } catch (err) {
      toast({
        id: "apply-json-config",
        title: "This configuration is not valid JSON",
        message: String(err),
        variant: "warning",
      });
    }
  };

  return (
    <Dialog
      isOpen
      variant="form"
      title="Edit as JSON"
      width="xl"
      actions={
        <ButtonGroup>
          <Button onClick={beautifyJSON}>Beautify JSON</Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="primary" onClick={submit}>
            Apply
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <Editor language="json" value={config} onChange={setConfig} />
    </Dialog>
  );
};
