import { ConfirmationDialog, useDisclosure } from "@hightouchio/ui";
import capitalize from "lodash/capitalize";
import { createContext, FC, ReactNode, useContext, useState } from "react";

import { useNavigate, useSearchParams } from "src/router";

import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  DraftsQuery,
  ModelDraft,
  SyncDraft,
} from "src/graphql";
import { useDraftMerger } from "src/hooks/use-draft-merger";

type UpdateResourceOrDraftFn = (
  updatePayload: SyncDraft | ModelDraft,
  onUpdate: () => void,
  update: UpdateResourceFunc,
  resourceIsDraft: boolean,
) => Promise<void>;

type DraftProviderType = {
  draft: DraftsQuery["drafts"][0] | undefined;
  draftChange: SyncDraft | ModelDraft | undefined;
  setDraftChange: (change: SyncDraft | ModelDraft) => void;
  editingDraft: boolean;
  editingDraftChanges: boolean;
  setEditingDraft: (editing: boolean) => void;
  setSubmitDraftModalOpen: (open: boolean) => void;
  resourceType: "model" | "sync" | undefined;
  onViewDraft: () => void;
  updateResourceOrDraft: UpdateResourceOrDraftFn | undefined;
  mergeResourceWithDraft: (
    resource: Record<string, unknown>,
  ) => Record<string, unknown>;
};

const defaultDraftContext: DraftProviderType = {
  draft: undefined,
  draftChange: undefined,
  setDraftChange: () => undefined,
  editingDraft: false,
  editingDraftChanges: false,
  setEditingDraft: () => undefined,
  setSubmitDraftModalOpen: () => undefined,
  resourceType: undefined,
  onViewDraft: () => undefined,
  updateResourceOrDraft: undefined,
  mergeResourceWithDraft: (resource) => resource,
};

export const DraftContext =
  createContext<DraftProviderType>(defaultDraftContext);

type UpdateResourceFunc = () => Promise<unknown>;

interface Props {
  children: ReactNode;
  initialResourceIsDraft: boolean;
  resourceType: "model" | "sync";
  resourceId: string | undefined;
  onPublish?: () => void;
}

export const DraftProvider: FC<Props> = ({
  children,
  resourceType,
  resourceId,
  initialResourceIsDraft,
  onPublish,
}) => {
  const [draftChange, setDraftChange] = useState<SyncDraft | ModelDraft>();
  const [submitDraftModalOpen, setSubmitDraftModalOpen] = useState(false);

  const {
    isOpen: existingChangesModalOpen,
    onOpen: openExistingChangesModal,
    onClose: closeExistingChangesModal,
  } = useDisclosure();
  const [handleUpdate, setHandleUpdate] = useState<() => void>();
  const navigate = useNavigate();
  const { workspace } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const editingDraft = searchParams.get("editing") === "true";

  const { draft, mergeResourceWithDraft } = useDraftMerger({
    resourceId: resourceId?.toString() ?? "",
    resourceType,
  });

  const setEditingDraft = (editing: boolean) => {
    if (editing) {
      setSearchParams((prevParams) => {
        prevParams.set("editing", "true");

        return prevParams;
      });
    }

    if (!editing && searchParams.has("editing")) {
      setSearchParams((prevParams) => {
        prevParams.delete("editing");

        return prevParams;
      });
    }
  };

  const updateResourceOrDraft = async (
    updatePayload: SyncDraft | ModelDraft,
    onUpdate: () => void,
    update: UpdateResourceFunc,
    resourceIsDraft: boolean,
  ) => {
    if (!resourceId) {
      return;
    }

    if (!workspace?.approvals_required || resourceIsDraft) {
      await update();
      onUpdate();
      setEditingDraft(false);
      return;
    }

    // only set the draft change if we're currently editing - otherwise, we're going to show
    // the modal confirmation to delete the existing draft.
    if (draft && !editingDraft) {
      openExistingChangesModal();
      return;
    }

    setEditingDraft(true);
    setHandleUpdate(() => onUpdate);
    setSubmitDraftModalOpen(true);
    setDraftChange(updatePayload);
  };

  const onViewDraft = () => {
    // this can't happen - we can't view a draft with no resource id set.
    if (!resourceId) {
      return;
    }

    if (draft?.approval_requests.length === 0) {
      setSubmitDraftModalOpen(true);
    } else {
      navigate(makeViewDraftUrl(resourceType, resourceId));
    }
  };

  const customMergeResourceWithDraft = (
    resource: Readonly<Record<string, unknown>>,
  ) => {
    if (!editingDraft) {
      return resource;
    }

    return mergeResourceWithDraft(resource);
  };

  const editingDraftChanges =
    editingDraft && draft?.operation === DraftOperation.Update;

  return (
    <DraftContext.Provider
      value={{
        draft,
        setDraftChange,
        editingDraft,
        editingDraftChanges,
        setEditingDraft,
        setSubmitDraftModalOpen,
        resourceType,
        draftChange,
        onViewDraft,
        updateResourceOrDraft,
        mergeResourceWithDraft: customMergeResourceWithDraft,
      }}
    >
      <>
        {children}
        {workspace?.approvals_required && (
          <ConfirmationDialog
            onClose={closeExistingChangesModal}
            onConfirm={() => setEditingDraft(true)}
            variant="warning"
            title={`${capitalize(resourceType)} has existing draft changes`}
            isOpen={existingChangesModalOpen}
            confirmButtonText="View existing changes"
          >
            This {resourceType} has existing draft changes, go to the draft to
            view or discard them before making any further changes.
          </ConfirmationDialog>
        )}
        {workspace?.approvals_required && (
          <DraftSubmissionModal
            approverOptions={
              resourceType === "model"
                ? { type: "model", modelId: resourceId ?? "" }
                : { type: "sync", syncId: resourceId ?? "" }
            }
            permission={
              resourceType === "model"
                ? {
                    v2: {
                      resource: "model",
                      grant: "can_approve",
                      id: resourceId ?? "",
                    },
                  }
                : {
                    v2: {
                      resource: "sync",
                      grant: "can_approve",
                      id: resourceId ?? "",
                    },
                  }
            }
            draft={draftChange}
            getResourceId={() => (resourceId ? String(resourceId) : "")}
            open={submitDraftModalOpen}
            operation={
              initialResourceIsDraft
                ? DraftOperation.Create
                : DraftOperation.Update
            }
            resource={resourceType}
            onClose={() => {
              setSubmitDraftModalOpen(false);
            }}
            onSubmit={(
              _resourceId: string,
              { publishNow }: { publishNow: boolean },
            ) => {
              if (publishNow) {
                if (handleUpdate) {
                  handleUpdate();
                }

                if (onPublish) {
                  onPublish();
                }
              }
            }}
          />
        )}
      </>
    </DraftContext.Provider>
  );
};

const makeViewDraftUrl = (resourceType: "model" | "sync", resourceId: string) =>
  `/${resourceType === "model" ? "models" : "syncs"}/${resourceId}/draft`;

export const useDraft = () => useContext(DraftContext);
