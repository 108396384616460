import { Fragment, useEffect, useRef, useState } from "react";

import {
  Alert,
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Column,
  Dialog,
  Row,
  Spinner,
} from "@hightouchio/ui";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import pluralize from "pluralize";

import {
  DestinationForm,
  FormRef,
} from "src/components/destinations/sync-form";
import { OverrideConfig } from "src/components/destinations/types";
import {
  hasUnlockedFields,
  keepDefined,
} from "src/components/destinations/utils";
import { VerticalWizard, VerticalWizardStep } from "src/components/wizard";
import { useSyncTemplateDefinitionsQuery } from "src/graphql";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { SyncTemplateDefinition } from "./types";
import { FormkitSyncTemplateConfig } from "src/formkit/components/formkit-context";

type AddSyncOverridesWizardProps = {
  isOpen: boolean;
  syncTemplateIds: number[];
  onSubmit: (templates: SyncTemplateDefinition[]) => Promise<void>;
  onClose: () => void;
  onReturn: () => void;
};

function findNextFormWithUnlockedFields(
  syncTemplateData: { override_config: OverrideConfig | null }[],
  currentIndex: number = -1,
): number {
  return syncTemplateData.findIndex(
    ({ override_config }, index) =>
      index > currentIndex && hasUnlockedFields(override_config),
  );
}

export const AddSyncsFromTemplatesWizard = ({
  isOpen = false,
  syncTemplateIds,
  onSubmit,
  onClose,
  onReturn,
}: AddSyncOverridesWizardProps) => {
  // Always order the non-overridable templates first
  const [step, setStep] = useWizardStepper(0);
  const [validating, setValidating] = useState(false);

  // Needed to hook into the form's submit so that a loading state can be set
  const destinationFormRef = useRef<FormRef>(null);

  const [overrideConfigs, setOverrideConfigs] = useState<
    (Record<string, unknown> | null)[]
  >(syncTemplateIds.map(() => null));

  useEffect(() => {
    setOverrideConfigs(syncTemplateIds.map(() => null));
  }, [syncTemplateIds]);

  const syncTemplateDefinitionsQuery = useSyncTemplateDefinitionsQuery(
    {
      ids: syncTemplateIds,
    },
    {
      enabled: syncTemplateIds.length > 0,
      select: (data) =>
        sortBy(
          data.sync_templates.map((template) => ({
            ...template,
            hasUnlockedFields: hasUnlockedFields(template.override_config),
          })),
          "hasUnlockedFields",
        ),
    },
  );
  const syncTemplates = syncTemplateDefinitionsQuery.data ?? [];
  const currentStep = syncTemplates[step];

  // Set the initial step to be the first form with unlocked fields
  useEffect(() => {
    const firstUnlockedFormIndex =
      findNextFormWithUnlockedFields(syncTemplates);

    if (firstUnlockedFormIndex !== -1) {
      setStep(firstUnlockedFormIndex);
    }
  }, [syncTemplates]);

  const submitForm = async (
    syncTemplateOverrides: (Record<string, unknown> | null)[],
  ) => {
    await onSubmit(
      syncTemplates.map((template, index) => {
        // Submit the new overrides
        const overrides = syncTemplateOverrides[index];
        if (!overrides) return template;

        return {
          ...template,
          sync_template_overrides: overrides.sync_template_overrides,
        };
      }),
    );
  };

  const savePreparedConfig = async (
    { sync_template_overrides }: { sync_template_overrides: any },
    index: number,
  ) => {
    const syncTemplate = syncTemplates?.[index];

    // First save config to the state
    const syncTemplateConfig = syncTemplate?.config;
    const oldOverrideConfig: OverrideConfig | null =
      syncTemplate?.override_config;

    if (!oldOverrideConfig || !syncTemplateConfig) return;

    const syncTemplateOverrides = keepDefined(sync_template_overrides);

    const updatedPayload: Record<string, unknown> = {
      sync_template_overrides: !isEmpty(syncTemplateOverrides)
        ? {
            ...syncTemplateOverrides,
            // Ensure config version is included on sync_template_overrides
            configVersion: syncTemplateConfig.configVersion,
          }
        : null,
    };

    // Find next form to configure
    const nextOverridableConfigIndex = findNextFormWithUnlockedFields(
      syncTemplates,
      index,
    );

    // Step to that form or submit all configs
    const newOverrides = [...overrideConfigs];
    newOverrides[index] = updatedPayload;

    if (nextOverridableConfigIndex !== -1) {
      setOverrideConfigs(newOverrides);
      setStep(nextOverridableConfigIndex);
    } else {
      await submitForm(newOverrides);
    }
  };

  const steps: VerticalWizardStep[] = syncTemplates.map(
    ({ id, name, destination, segment, config, override_config }, index) => {
      const templateHasUnlockedFields = hasUnlockedFields(override_config);

      const syncTemplateConfig: FormkitSyncTemplateConfig = {
        id,
        config,
        override_config,
      };

      return {
        label: name,
        description: templateHasUnlockedFields
          ? undefined
          : "No custom options",
        integrationIcon: {
          name: destination.definition.name,
          src: destination.definition.icon,
        },
        render: () => (
          <Fragment key={id}>
            {templateHasUnlockedFields ? (
              <DestinationForm
                hideSave
                hideSidebar
                isSyncForm
                syncTemplate={syncTemplateConfig}
                syncConfig={config}
                ref={destinationFormRef}
                permission={{
                  v2: {
                    resource: "sync",
                    grant: "can_create",
                    creationOptions: {
                      modelId: segment.id.toString(),
                      destinationId: destination.id.toString(),
                    },
                  },
                  v1: {
                    resource: "sync",
                    grant: "create",
                  },
                }}
                testPermission={{
                  v2: {
                    resource: "sync",
                    grant: "can_test",
                    creationOptions: {
                      modelId: segment.id.toString(),
                      destinationId: destination.id.toString(),
                    },
                  },
                  v1: {
                    resource: "sync",
                    grant: "testrow",
                  },
                }}
                destination={destination}
                destinationDefinition={destination?.definition}
                model={segment}
                slug={destination.type}
                sourceDefinition={segment.connection?.definition}
                onSubmit={(config) => savePreparedConfig(config, index)}
              />
            ) : (
              <Alert
                title="Nothing to configure"
                type="info"
                message="Click continue to configure the next form"
              />
            )}
          </Fragment>
        ),
      };
    },
  );

  return (
    <Dialog
      isOpen={isOpen}
      variant="info"
      maxHeight="80vh"
      title={`Finalize ${pluralize("sync", syncTemplateIds.length, true)}`}
      actions={
        <Row justify="space-between" width="100%">
          <Button
            icon={ArrowLeftIcon}
            onClick={() => {
              if (step > 0) {
                setStep((configStep) => configStep - 1);
              } else {
                onReturn();
              }
            }}
          >
            Go back
          </Button>
          <Button
            form="destination-form"
            type="submit"
            isLoading={validating || syncTemplateDefinitionsQuery.isLoading}
            directionIcon={ArrowRightIcon}
            variant="primary"
            onClick={async () => {
              if (!currentStep) return;

              if (!currentStep.hasUnlockedFields) {
                const nextFormIndex = findNextFormWithUnlockedFields(
                  syncTemplates,
                  step,
                );

                if (nextFormIndex !== -1) {
                  setStep(nextFormIndex);
                } else {
                  await submitForm(overrideConfigs);
                }
              } else {
                setValidating(true);
                // Destination form submit is not exposed through the destination form.
                // To track the promise, we need to access it through the ref.
                await destinationFormRef.current?.submit();
                setValidating(false);
              }
            }}
          >
            Continue
          </Button>
        </Row>
      }
      width="6xl"
      // TODO(samuel): add close confirmation?
      onClose={onClose}
    >
      {syncTemplateDefinitionsQuery.isLoading ? (
        <Column height="100%" justify="center" align="center" minHeight="60vh">
          <Spinner size="lg" />
        </Column>
      ) : (
        <VerticalWizard step={step} steps={steps} />
      )}
    </Dialog>
  );
};
