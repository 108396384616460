import {
  Box,
  Column,
  DollarIcon,
  Heading,
  Row,
  StatsItemTitle,
  Text,
} from "@hightouchio/ui";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { ContentType } from "recharts/types/component/DefaultLegendContent";
import { useMemo, useState } from "react";
import { format } from "date-fns";

import { abbreviateNumber } from "src/utils/numbers";
import useQueryState from "src/hooks/use-query-state";
import { OutcomeClassification } from "src/pages/decision-engines/flows/flow/outcomes/components/outcome-classification";
import { DecisionEngineOutcomeReward } from "@hightouch/lib/customer-data/decision-engine/types";
import { sortOutcomes } from "src/pages/decision-engines/utils";

type Data = {
  holdout: number;
  treatment: number;
  timestamp: string;
};

const generateMockData = (length: number): Data[] => {
  const data: Data[] = [];
  for (let i = 0; i < length; i++) {
    const holdout = 5000 + Math.random() * 5000;
    data.push({
      holdout,
      treatment: 10000 + (500 + Math.random() * 500) * i,
      timestamp: new Date(
        Date.now() - (length - i) * 1000 * 60 * 60 * 24,
      ).toISOString(),
    });
  }
  return data;
};

export const InsightsOutcomes = ({
  isUrlState = true,
  outcomes,
}: {
  isUrlState?: boolean;
  outcomes: Array<{
    outcome: {
      id: string;
      name: string;
      weight: DecisionEngineOutcomeReward;
    };
  }>;
}) => {
  const [outcomeId, setOutcomeId] = isUrlState
    ? useQueryState("outcome")
    : useState(outcomes[0]?.outcome.id);

  const selectedOutcome = outcomeId
    ? outcomes.find(({ outcome }) => outcome.id === outcomeId)?.outcome
    : outcomes[0]?.outcome;

  const mockData = useMemo(() => {
    return (
      outcomes?.reduce(
        (acc, { outcome }) => ({
          ...acc,
          [outcome.id]: generateMockData(30),
        }),
        {},
      ) ?? {}
    );
  }, [outcomes]);

  return (
    <Column gap={4}>
      <Row align="center" gap={4}>
        <Row
          align="center"
          justify="center"
          p={2}
          borderRadius="50%"
          bg="gray.100"
        >
          <Box as={DollarIcon} boxSize={5} color="text.secondary" />
        </Row>
        <Heading>Outcomes</Heading>
      </Row>
      <Row gap={6}>
        <Column flex={1} gap={2}>
          {outcomes
            .sort((a, b) => sortOutcomes(a.outcome.weight, b.outcome.weight))
            .map(({ outcome }) => {
              const isSelected = outcome.id === selectedOutcome?.id;
              const data = mockData[outcome.id];
              const totals = {
                holdout: data.reduce((acc, { holdout }) => acc + holdout, 0),
                treatment: data.reduce(
                  (acc, { treatment }) => acc + treatment,
                  0,
                ),
              };

              return (
                <Row
                  align="center"
                  justify="space-between"
                  gap={2}
                  key={outcome.id}
                  borderRadius="md"
                  px={4}
                  py={2}
                  cursor="pointer"
                  bg={isSelected ? "gray.100" : "transparent"}
                  _hover={{
                    bg: "gray.50",
                  }}
                  pointerEvents={isSelected ? "none" : "auto"}
                  onClick={() => {
                    setOutcomeId(outcome.id);
                  }}
                >
                  <Row gap={2} align="center">
                    <OutcomeClassification weight={outcome.weight} />
                    <Text fontWeight="medium">{outcome.name}</Text>
                  </Row>

                  <Text fontWeight="medium" color="success.base">
                    {Math.round((totals.treatment / totals.holdout) * 100)}%
                  </Text>
                </Row>
              );
            })}
        </Column>
        <Column flex={3}>
          <Column width="100%" height="300px" gap={1} position="relative">
            {selectedOutcome && <Chart data={mockData[selectedOutcome.id]} />}
          </Column>
        </Column>
      </Row>
    </Column>
  );
};

const Chart = ({ data }: { data: Data[] }) => {
  const totals = {
    holdout: data.reduce((acc, { holdout }) => acc + holdout, 0),
    treatment: data.reduce((acc, { treatment }) => acc + treatment, 0),
  };

  const renderLegend: ContentType = ({ payload }) => {
    return (
      <Row gap={6} mb={8}>
        {payload?.map((entry) => {
          const { value, color, id } = entry;
          return (
            <Column key={id}>
              <Box fontSize="2xl" fontFamily="sharpSansDisplayNo1">
                {abbreviateNumber(totals[value])}
              </Box>
              <Row gap={1} align="center">
                <Box bg={color} w="12px" h="12px" borderRadius="sm" />
                <StatsItemTitle>
                  {value === "treatment" ? "AI Decisioning" : "Holdout"}
                </StatsItemTitle>
              </Row>
            </Column>
          );
        })}
      </Row>
    );
  };

  return (
    <ResponsiveContainer>
      <LineChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <Legend
          layout="horizontal"
          align="center"
          verticalAlign="top"
          content={renderLegend}
        />
        <XAxis
          tickLine={false}
          axisLine={false}
          dataKey="timestamp"
          domain={["auto", "auto"]}
          tickSize={10}
          tickMargin={4}
          padding={{ left: 5, right: 5 }}
          tickFormatter={tickFormatter}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          type="number"
          tickFormatter={(value) => abbreviateNumber(value)}
        />
        <CartesianGrid
          vertical={false}
          stroke="var(--chakra-colors-base-divider)"
        />
        <Line
          dot={true}
          type="linear"
          dataKey="holdout"
          strokeWidth={3}
          stroke="var(--chakra-colors-gray-500)"
        />
        <Line
          dot={true}
          type="linear"
          dataKey="treatment"
          strokeWidth={3}
          stroke="var(--chakra-colors-electric-500)"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const tickFormatter = (timestamp: string) => {
  const tick = format(new Date(timestamp), "MMM d");
  return tick;
};
