import { FC, useState } from "react";

import {
  Box,
  CloseIcon,
  Column,
  Combobox,
  IconButton,
  MergeIcon,
  Row,
  Skeleton,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@hightouchio/ui";
import isEqual from "lodash/isEqual";
import sumBy from "lodash/sumBy";

import {
  BreakdownGraph,
  ChartType,
} from "src/components/audiences/breakdowns/breakdown-graph";
import { BreakdownIcon } from "src/components/audiences/insights/placeholders";
import {
  QueryArguments,
  SharedChartQueryParameters,
} from "src/components/audiences/insights/types";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useAudienceBreakdownQuery } from "src/graphql";
import {
  ColumnReference,
  FilterableColumn,
  isRelatedColumn,
} from "src/types/visual";

import { ChartCard, ChartCardProps, LoadingBar } from "./shared";

const EMPTY_AUDIENCE_BREAKDOWN_ERROR =
  "audience has 0 rows, cannot perform breakdown";

export type BreakdownData = {
  type: "breakdown";
  columnReference: ColumnReference | null;
  queryParameters: SharedChartQueryParameters | null;
};

type BreakdownChartCardProps = ChartCardProps &
  QueryArguments & {
    options: FilterableColumn[];
    optionsLoading?: boolean;
    value: BreakdownData;
    onChange: (value: ColumnReference | null) => void;
  };

export const BreakdownChartCard: FC<BreakdownChartCardProps> = ({
  sourceId,
  parentModelId,
  options,
  optionsLoading = false,
  value,
  onChange,
  onClose,
}) => {
  const [chartType, setChartType] = useState<ChartType>("table");

  const audienceBreakdownQuery = useAudienceBreakdownQuery(
    {
      id: sourceId,
      parentModelId,
      columns: [value.columnReference].filter(Boolean),
      filter: value.queryParameters?.filter,
      audienceId: value.queryParameters?.audienceId,
      subsetIds: value.queryParameters?.subsetIds,
      destinationId: value.queryParameters?.destinationId,
      useSampledModels: value.queryParameters?.isFastQuery,
      perColumnLimit: 10,
    },
    {
      enabled: Boolean(value.columnReference),
      select: (data) => {
        // Will always return a single column, so we can safely assume the first column is the one we want
        const breakdown = data.audienceBreakdown.columns?.[0];
        if (data.audienceBreakdown.columns.length === 0 || !breakdown) {
          return null;
        }

        const sumOfValues = sumBy(breakdown.values, "count");

        const result = {
          name: breakdown.name,
          values: breakdown.values.map((value) => ({
            ...value,
            percentage:
              sumOfValues && data.audienceBreakdown.audienceSize > 0
                ? value.count / data.audienceBreakdown.audienceSize
                : 0,
          })),
        };

        const numberOfRemainingValues =
          data.audienceBreakdown.audienceSize - sumOfValues;

        if (numberOfRemainingValues > 0) {
          result.values = result.values.concat({
            value: "Other",
            count: numberOfRemainingValues,
            percentage:
              data.audienceBreakdown.audienceSize > 0
                ? numberOfRemainingValues / data.audienceBreakdown.audienceSize
                : 0,
          });
        }

        result.values.sort((valueA, valueB) => valueB.count - valueA.count);

        return result;
      },
    },
  );

  const selectedColumn = options.find((option) =>
    isEqual(option.column_reference, value.columnReference),
  );
  const title = selectedColumn
    ? `Breakdown by ${selectedColumn.alias ?? selectedColumn.name}`
    : "Breakdown of property";

  let content = <PlaceholderContent />;
  const error = audienceBreakdownQuery.error;
  const isEmptyAudience = error?.message.includes(
    EMPTY_AUDIENCE_BREAKDOWN_ERROR,
  );

  const breakdownData = audienceBreakdownQuery.data;
  if ((audienceBreakdownQuery.data && breakdownData) || isEmptyAudience) {
    content = (
      <BreakdownGraph
        data={breakdownData?.values ?? []}
        chartType={chartType}
      />
    );
  }

  return (
    <ChartCard>
      <Row justify="space-between" align="center">
        <TextWithTooltip fontWeight="medium" size="lg" message={title}>
          {title}
        </TextWithTooltip>
        <Row align="center" gap={1}>
          {!audienceBreakdownQuery.isLoading && value.columnReference && (
            <ToggleButtonGroup
              value={chartType}
              onChange={(value) => setChartType(value as ChartType)}
              size="sm"
            >
              <ToggleButton label="Bar" value="table" />
              <ToggleButton label="Pie" value="pie" />
            </ToggleButtonGroup>
          )}
          <Tooltip message="Remove chart" placement="top-end" openSpeed="slow">
            <IconButton
              aria-label="Remove chart."
              icon={CloseIcon}
              onClick={onClose}
            />
          </Tooltip>
        </Row>
      </Row>
      {audienceBreakdownQuery.isLoading ? (
        <LoadingContent />
      ) : (
        <>
          {content}
          <Column>
            <Combobox
              isClearable
              isLoading={optionsLoading}
              isInvalid={Boolean(error && !isEmptyAudience)}
              placeholder={`Select ${
                value.columnReference ? "a different" : "a"
              } column to breakdown`}
              options={options}
              optionValue={(column) => column.column_reference}
              optionLabel={(column) => column.alias || column.name}
              valueLabel={(column) => column.alias || column.name}
              optionDescription={(column) => column.description ?? ""}
              _internalRenderLabelContent={(column) => (
                <>
                  {column.alias || column.name}
                  {isRelatedColumn(column.column_reference) && (
                    <Row
                      as={Text}
                      color="text.secondary"
                      align="center"
                      display="contents"
                    >
                      <Box as={MergeIcon} flexShrink={0} ml={1} mb={0.5} />{" "}
                      {column.model_name}
                    </Row>
                  )}
                </>
              )}
              value={value.columnReference}
              width="100%"
              onChange={(value) => onChange(value ?? null)}
            />
            {error && !isEmptyAudience && (
              <ErrorMessage>{error.message}</ErrorMessage>
            )}
          </Column>
        </>
      )}
    </ChartCard>
  );
};

const LoadingContent = () => (
  <Skeleton isLoading>
    <Box
      display="grid"
      rowGap={3}
      columnGap={2}
      gridTemplateColumns="repeat(24, 1fr)"
    >
      <LoadingBar gridColumn="1 / 8" />
      <LoadingBar gridColumn="8 / 25" />

      <LoadingBar gridColumn="1 / 5" />
      <LoadingBar gridColumn="8 / 24" />

      <LoadingBar gridColumn="1 / 7" />
      <LoadingBar gridColumn="8 / 22" />

      <LoadingBar gridColumn="1 / 6" />
      <LoadingBar gridColumn="8 / 19" />

      <LoadingBar gridColumn="1 / 8" />
      <LoadingBar gridColumn="8 / 14" />
    </Box>
  </Skeleton>
);

const PlaceholderContent = () => (
  <>
    <Row align="center" justify="center">
      <BreakdownIcon />
    </Row>
    <Row justify="center">
      <Text color="text.secondary">
        View the count of values on a specific property
      </Text>
    </Row>
  </>
);
