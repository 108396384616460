import { FC, useMemo } from "react";

import {
  EmptyState,
  FrameStackIcon,
  InformationIcon,
  Row,
  Text,
  TimeIcon,
  Tooltip,
} from "@hightouchio/ui";
import { Outlet, useNavigate, useParams, useSearchParams } from "src/router";

import placeholderIcon from "src/assets/placeholders/generic.svg";
import { IconBox } from "src/components/icon-box";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { TRAIT_TYPE_LABELS } from "src/components/traits/utils";
import {
  OrderBy,
  TraitDefinitionsOrderBy,
  TraitsQuery,
  useTraitsQuery,
} from "src/graphql";
import { Pagination, useTableConfig } from "src/ui/table";
import { Table, TableColumn } from "src/ui/table/table";

import { formatDatetime } from "src/utils/time";
import { TraitType } from "./types";
import { getTraitsFilter } from "./utils";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

type Props = {
  type: TraitType;
};

type Trait = NonNullable<TraitsQuery["trait_definitions"][number]>;

export const TraitsTable: FC<Readonly<Props>> = ({ type }) => {
  const navigate = useNavigate();
  const { traitId } = useParams();
  const [searchParams] = useSearchParams();

  const search = searchParams.get("search") ?? "";

  const filter = useMemo(
    () => getTraitsFilter({ search, type }),
    [type, search],
  );

  const { limit, offset, page, setPage } = useTableConfig({ limit: 20 });
  const { orderBy, onSort } = useTableConfig<TraitDefinitionsOrderBy>({
    defaultSortKey: SortKeys.UpdatedAt,
    defaultSortDirection: OrderBy.Desc,
    sortOptions: Object.values(SortKeys),
  });

  const sharedColumns: TableColumn<Trait>[] = [
    {
      name: "Name",
      onClick: () => onSort(SortKeys.Name),
      sortDirection: orderBy?.name,
      cell: ({ name }) => <TextWithTooltip>{name}</TextWithTooltip>,
      cellSx: {
        fontWeight: "medium",
        pl: "4 !important",
      },
      headerSx: {
        "&:first-of-type": {
          pl: 4,
        },
      },
    },
    {
      name: "Parent model",
      cell: ({ parent_model }) => (
        <Row gap={2} width="100%">
          <IntegrationIcon
            src={parent_model?.connection?.definition?.icon}
            name={parent_model?.connection?.definition?.name}
            size={5}
          />
          <TextWithTooltip textTransform="capitalize">
            {parent_model.name}
          </TextWithTooltip>
        </Row>
      ),
    },
    {
      onClick: () => onSort(SortKeys.Type),
      sortDirection: orderBy?.type,
      name: "Type",
      cell: ({ type }) => (
        <TextWithTooltip textTransform="capitalize">
          {TRAIT_TYPE_LABELS[type]}
        </TextWithTooltip>
      ),
      min: "min-content",
      max: "min-content",
    },
    {
      name: "Related model",
      cell: ({ relationship }) => {
        if (!relationship) {
          return (
            <Row as={Text} color="text.secondary" align="center">
              None
              <Tooltip message="Formula traits do not use related models">
                <Text color="text.secondary" ml={2}>
                  <InformationIcon />
                </Text>
              </Tooltip>
            </Row>
          );
        }

        const isEventModel = relationship.to_model?.event?.timestamp_column;

        return (
          <Row gap={2} width="100%">
            <IconBox
              icon={isEventModel ? <TimeIcon /> : <FrameStackIcon />}
              bg={isEventModel ? "cyan.400" : "grass.400"}
              boxSize={5}
              iconSize={3}
            />
            <TextWithTooltip textTransform="capitalize">
              {relationship.to_model.name}
            </TextWithTooltip>
          </Row>
        );
      },
    },
    {
      name: "Last updated",
      onClick: () => onSort(SortKeys.UpdatedAt),
      sortDirection: orderBy?.updated_at,
      cell: ({ updated_at }) => formatDatetime(updated_at),
      cellSx: {
        fontWeight: "medium",
        pl: "4 !important",
      },
      headerSx: {
        "&:first-of-type": {
          pl: 4,
        },
      },
    },
  ];

  const activeColumns: TableColumn[] = sharedColumns;

  const templateColumns: TableColumn[] = [
    ...sharedColumns,
    {
      header: () => (
        <Row width="100%" justifyContent="end" textTransform="uppercase">
          Active traits
        </Row>
      ),
      cell: ({ active_traits }) => (
        <Row width="100%" justifyContent="end">
          {active_traits.length}
        </Row>
      ),
      min: "min-content",
      max: "min-content",
    },
  ];

  const archivedColumns: TableColumn[] = sharedColumns;

  const {
    data: traits,
    isLoading,
    isError,
  } = useTraitsQuery({
    filter,
    limit,
    offset,
    orderBy,
  });

  let columns: TableColumn[] = [];

  if (type === TraitType.Active) {
    columns = activeColumns;
  } else if (type === TraitType.Templates) {
    columns = templateColumns;
  } else if (type === TraitType.Archived) {
    columns = archivedColumns;
  }

  return (
    <>
      <Table
        columns={columns}
        data={traits?.trait_definitions ?? []}
        error={isError}
        highlight={traitId}
        loading={isLoading}
        placeholder={{
          custom: <EmptyPlaceholder search={search} type={type} />,
        }}
        primaryKey="id"
        onRowClick={(row) => {
          navigate(`${row.id}`);
        }}
      />
      <Row justifyContent="right" mt={4}>
        <Pagination
          count={traits?.trait_definitions_aggregate?.aggregate?.count}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>

      <Outlet />
    </>
  );
};

const EmptyPlaceholder = ({
  search,
  type,
}: {
  search: string;
  type: TraitType;
}) => {
  let title;
  let message;
  if (type === TraitType.Active) {
    title = search
      ? "No active traits found"
      : "You haven't created any traits";
    message =
      "Traits allow you to define and sync specific data from this model.";
  } else if (type === TraitType.Templates) {
    title = search
      ? "No trait templates found"
      : "You haven't created any trait templates";
    message =
      "Templates allow you to define a specific calculation from this model.";
  } else if (type === TraitType.Archived) {
    title = search
      ? "No archived traits found"
      : "You haven't archived any traits";
    message = "Anyhing you archive can be restored from this list.";
  }

  return (
    <EmptyState imageUrl={placeholderIcon} title={title} message={message} />
  );
};
