import { useParams } from "src/router";
import { FullResourceLinkForm } from "src/components/deployments/linking";
import { useSourceQuery } from "src/graphql";

export const SourceLinking = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source } = useSourceQuery(
    { id: String(id) },
    { select: (data) => data.connections_by_pk, suspense: true },
  );

  return (
    <FullResourceLinkForm
      sourceResourceId={source!.id}
      resourceType="connections"
    />
  );
};
