import { FC, useCallback, useEffect, useMemo } from "react";

import {
  Box,
  Column,
  InformationIcon,
  Row,
  TagInput,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import orderBy from "lodash/orderBy";
import pluralize from "pluralize";
import { useUser } from "src/contexts/user-context";
import { useSubsetsForParentModelQuery } from "src/graphql";

import { SubsetConditions } from "@hightouch/lib/query/visual/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { ErrorMessage } from "./visual/error-message";

type SubsetSelectorProps = {
  parentModelId: string | undefined;
  value: string[];
  onChange: (subsetIds: string[]) => void;
};

export const SubsetSelector: FC<SubsetSelectorProps> = ({
  parentModelId,
  value,
  onChange,
}) => {
  const { appSubsetsEnabled } = useFlags();
  const { user, isLoading: isUserLoading } = useUser();

  const { data: subsetCategories = [] } = useSubsetsForParentModelQuery(
    {
      parentModelId: String(parentModelId),
      userId: String(user?.id),
      permissionsV2Enabled: user?.permissions_v2_enabled ?? false,
    },
    {
      enabled: Boolean(!isUserLoading && parentModelId && appSubsetsEnabled),
      select: (data) => data.audience_subset_groups,
    },
  );

  // Map subset ID to subset information
  const subsetsMap: Record<
    string,
    {
      id: string;
      name: string;
      conditions: SubsetConditions;
    }
  > = useMemo(() => {
    return subsetCategories?.reduce((accum, curr) => {
      curr.subset_values.forEach((subset) => {
        accum[subset.id] = subset;
      });

      return accum;
    }, {});
  }, [subsetCategories]);

  // Sum the number of top-level filters applied by the selected subsets
  const filterCount = useMemo(() => {
    return value.reduce((count, subsetId) => {
      const subset = subsetsMap[subsetId];
      return count + Number(subset?.conditions?.conditions?.length);
    }, 0);
  }, [value, subsetsMap]);

  if (!appSubsetsEnabled || subsetCategories?.length === 0) {
    return null;
  }

  return (
    <Column
      p={4}
      border="1px solid"
      borderColor="base.border"
      bg="base.background"
      boxShadow="sm"
      borderRadius="lg"
      gap={2}
    >
      <Row gap={4} flexWrap="wrap">
        {subsetCategories.map((category) => (
          <SubsetCategoryInput
            key={category.id}
            id={category.id}
            name={category.name}
            description={category.description}
            isRequired={category.is_required}
            options={category.subset_values}
            value={value}
            onChange={onChange}
          />
        ))}
      </Row>

      <Text color="text.secondary">
        {pluralize("filters", filterCount, true)} automatically applied based on
        your selections
      </Text>
    </Column>
  );
};

type SubsetInputProps = {
  id: string;
  name: string;
  description: string | null;
  isRequired: boolean;
  options: {
    id: string;
    name: string;
    v1_grants: {
      user_id: number;
    }[];
    v2_grants: {
      user_group_id: number;
    }[];
  }[];
  value: string[];
  onChange: (value: string[]) => void;
};

const SubsetCategoryInput: FC<Readonly<SubsetInputProps>> = ({
  id,
  name,
  description,
  isRequired,
  options,
  value,
  onChange,
}) => {
  const { user } = useUser();
  const permissionsV2Enabled = user?.permissions_v2_enabled ?? false;
  const { setFieldError, getErrors } = useFormErrorContext();

  const categoryValues = useMemo(
    () => value.filter((id) => options.map((o) => o.id).includes(id)),
    [options, value],
  );

  const onCategoryChange = useCallback(
    (subsets: string[]): void => {
      onChange([
        ...value.filter((id) => !options.map((o) => o.id).includes(id)),
        ...subsets,
      ]);
    },
    [onChange, value, options],
  );

  // Validate that at least one subset is selected for required subset categories
  useEffect(() => {
    if (categoryValues.length) {
      // the user must have access to every selected subset in the category
      if (
        user?.is_impersonating ||
        categoryValues
          .map((v) => options.find((o) => o.id === v))
          .every((v) =>
            permissionsV2Enabled ? v?.v2_grants.length : v?.v1_grants.length,
          )
      ) {
        setFieldError(id, null);
      } else {
        setFieldError(id, {
          value: "You do not have access to all the selected subsets",
        });
      }
    } else {
      if (isRequired) {
        setFieldError(id, { value: "This field is required" });
      } else {
        setFieldError(id, null);
      }
    }
  }, [isRequired, categoryValues, options, permissionsV2Enabled]);

  // if there is no values selected and it's required, select the only option if permitted
  useEffect(() => {
    if (!categoryValues.length && isRequired) {
      const permittedOptions = user?.is_impersonating
        ? options
        : options.filter((option) =>
            permissionsV2Enabled
              ? option?.v2_grants.length
              : option?.v1_grants.length,
          );
      if (permittedOptions.length === 1 && permittedOptions[0]?.id) {
        onCategoryChange([permittedOptions[0].id]);
      }
    }
    // intentionally does not have category values in the dependency array,
    // so that removing a category value will not trigger this effect
  }, [options, isRequired]);

  const valueError = getErrors(id)?.value;

  return (
    <Column key={id} gap={2}>
      <Row as={Text} align="center" gap={2} fontWeight="medium">
        {name}{" "}
        {isRequired ? (
          ""
        ) : (
          <Text color="text.secondary" fontWeight="normal">
            (optional)
          </Text>
        )}
        {description && (
          <Tooltip message={description}>
            <Box as={InformationIcon} color="text.secondary" fontSize="20px" />
          </Tooltip>
        )}
      </Row>
      <Column>
        <TagInput
          placeholder="Select an option..."
          emptyOptionsMessage="No subsets available"
          size="sm"
          options={orderBy(options, "name")}
          optionValue={(option) => option.id}
          optionLabel={(option) => option.name}
          isOptionDisabled={(option) => {
            const userHasAccess = permissionsV2Enabled
              ? option?.v2_grants.length
              : option?.v1_grants.length;
            return !user?.is_impersonating && !userHasAccess;
          }}
          isInvalid={Boolean(valueError)}
          value={categoryValues}
          onChange={onCategoryChange}
        />
        {valueError && <ErrorMessage>{valueError}</ErrorMessage>}
      </Column>
    </Column>
  );
};
