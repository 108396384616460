import { useNavigate, useParams } from "src/router";

import { ModelDraftPage } from "src/components/drafts/model-draft";
import { DetailPage } from "src/components/layout";
import {
  DraftOperation,
  ResourceToPermission,
  useDraftsQuery,
  useModelQuery,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { DraftPageHeader } from "src/components/drafts/draft-page-header";

export const ModelDraft = () => {
  const { model_id: id } = useParams<{ model_id: string }>();
  const navigate = useNavigate();

  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
    },
  );
  const model = modelData?.segments_by_pk;

  const { data: drafts } = useDraftsQuery(
    {
      resourceId: id?.toString() || "",
      resourceType: ResourceToPermission.Model,
      status: "pending",
    },
    {
      enabled: Boolean(id),
    },
  );
  const draft = drafts?.drafts?.[0];

  if (modelLoading) {
    return <PageSpinner />;
  }

  if (!draft) {
    navigate(`/models/${id}`);
    return null;
  }

  if (!model) {
    navigate("/models");
    return null;
  }

  const link = `/models/${model.id}`;

  return (
    <DetailPage
      crumbs={[
        { label: "All models", link: "/models" },
        {
          label: model?.name ?? "",
          link:
            draft.operation === DraftOperation.Create
              ? link
              : `${link}?editing=true`,
        },
      ]}
      title="Draft request - Models"
      header={
        <DraftPageHeader
          title={`Draft of ${model.name} model`}
          link={link}
          draft={draft}
        />
      }
      hasBottomPadding
    >
      <ModelDraftPage draft={draft} model={model} />
    </DetailPage>
  );
};
